import {Field, FieldArray, Form, Formik, FormikProps} from 'formik';
import React, {useEffect, useRef, useState} from 'react';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import * as Yup from 'yup';
import {Delete} from '@material-ui/icons';
import clsx from 'clsx';
import currency from 'currency.js';
import {isEmpty} from 'lodash';
import {tooltip, Tooltip, Typography} from 'spenda-ui-react';
import ErrorIcon from '../../../assets/svg/ErrorIcon';
import IconCamera from '../../../assets/svg/IconCamera';
import IconChatClaims from '../../../assets/svg/IconChatClaims';
import {UndoClaimIcon} from '../../../assets/svg/UndoClaimIcon';
import {ICreateClaimModalFormValues} from '../../../components/AccountsReceivable/CreateClaimRequestDialog';
import EditEvidenceDialog from '../../../components/AccountsReceivable/EditEvidenceDialog';
import {PSBLFooter} from '../../../components/AccountsReceivable/PSBLFooter';
import {ClaimLineItemNoteDialog} from '../../../components/AccountsReceivable/modals/ClaimLineItemNoteDialog';
import {ConversationChatBox} from '../../../components/conversationChatBox/ConversationChatBox';
import {SCheckbox} from '../../../components/inputs/SCheckbox';
import {Layout} from '../../../components/layout/Layout';
import LoadingIndicator from '../../../components/ui/LoadingIndicator';
import {useClaimsAndReturnHook} from '../../../hooks/useARHook';
import {useFeatureFlags} from '../../../hooks/useFeatureFlags';
import {useTenantInfo} from '../../../hooks/useTenantInfo';
import {IClaimRequestDetailsResponse, IClaimsReasonResponse} from '../../../model/claims/ClaimsAndReturns';
import {
  ClaimActions,
  ClaimAndReturnRowAction,
  ClaimReasons,
  ClaimsAndReturnsStatus,
} from '../../../model/constants/Constants';
import {IInvoice} from '../../../model/invoice/Invoice';
import {AUTH_SELLING_AR_CLAIM_REQUESTS_ROUTE} from '../../../routes/AuthenticatedRoutes';
import useClaimsAndReturnsAPI, {
  ICreateClaimRequestPayload,
  IUpdateClaimRequestPayload,
} from '../../../services/useClaimsAndReturnsAPI';
import {useCustomerStatementsAPI} from '../../../services/useCustomerStatementsAPI';
import {useSupplierTransactionsAPI} from '../../../services/useSupplierTransactionsAPI';
import {PriceFormat} from '../../../utils/formatter';
import {MarketplaceLayout} from '../../purchasing/MarketplaceLayout';
import {ClaimsAddAdhocLine} from './ClaimsAddAdhocLine';
import {CreateAndEditClaimRequestFooter} from './CreateAndEditClaimRequestFooter';
import {CreateAndEditClaimRequestHeader} from './CreateAndEditClaimRequestHeader';

export interface IClaimRequestLineItemsFormValues {
  notes: string;
  claimRequestID?: number;
  claimRequestLineID?: number;
  inventoryID: number | null;
  invoiceLineID: number | null;
  code: string | null;
  shortDescription: string;
  description: string;
  uoM: string;
  quantity: number;
  price: number;
  reason: string;
  action: string;
  lineTotalEx: number;
  rowAction: ClaimAndReturnRowAction;
  availableQty: number | null;
  isTaxExempt?: boolean;
  taxPercent?: number;
  isAttachmentUpload?: boolean;
}

export interface IClaimRequestFormValues {
  invoiceId: number | null;
  defaultReason: string;
  defaultAction: string;
  status: ClaimsAndReturnsStatus;
  comment: string | null;
  lineItems: IClaimRequestLineItemsFormValues[];
  claimCode?: string;
  actionStatus?: ClaimsAndReturnsStatus;
  approvePaymentDate?: string;
  approveReferenceNo?: string;
  vendorId?: number;
  claimRequestID?: number;
  invoiceDate?: string;
  invoiceRefNumber?: string;
  vendorName?: string;
  vendorRefNumber?: string;
  isPendingCustomerAction?: boolean;
  activeInput?: string;
}

interface ICreateAndEditParams {
  supplierId?: string;
  accountCustomerId?: string;
  claimRequestId?: string;
}

const formInitialValues = {
  invoiceId: null,
  defaultReason: '',
  defaultAction: ClaimActions.Credit,
  status: ClaimsAndReturnsStatus.Draft,
  comment: '',
  lineItems: [],
};

const CreateAndEditClaimRequest = () => {
  // Hooks
  const {search, state} = useLocation<ICreateClaimModalFormValues>();
  const history = useHistory();
  const {isInBuyerContext, isInSupplierContext} = useTenantInfo();
  const {getFilterNameByStatus} = useClaimsAndReturnHook();
  const {supplierId, accountCustomerId, claimRequestId} = useParams<ICreateAndEditParams>();

  // Ref
  const formikRef = useRef<FormikProps<IClaimRequestFormValues> | null>(null);

  // Constant
  const query = new URLSearchParams(search);
  const txGuid = query.get('txGuid');
  const _supplierId = Number(supplierId) || undefined;
  const _accountCustomerId = Number(accountCustomerId) || undefined;
  const redirectIfStatus = [
    ClaimsAndReturnsStatus.Approved,
    ClaimsAndReturnsStatus.Rejected,
    ClaimsAndReturnsStatus.Cancelled,
    ClaimsAndReturnsStatus.Deleted,
  ];

  // Feature Flags
  const {claimAndReturnsV272602: claimAndReturnsV2Supplier} = useFeatureFlags().tenantOwned();
  const {claimAndReturnsV272602: claimAndReturnsV2Buyer} = useFeatureFlags().supplierDriven();
  const claimAndReturnsV272602 = isInBuyerContext
    ? claimAndReturnsV2Buyer && claimAndReturnsV2Supplier
    : claimAndReturnsV2Supplier;

  // APIs
  const buyerAPI = useSupplierTransactionsAPI();
  const supplierAPI = useCustomerStatementsAPI();
  const {getClaimRquestDetailSupplier, getClaimRquestDetailBuyer, getClaimReasonBuyer, getClaimReasonSupplier} =
    useClaimsAndReturnsAPI();
  const {
    createClaimRequest,
    updateClaimRequest,
    updateClaimAndReturnStatus,
    isLoading: isSubmitting,
  } = useClaimsAndReturnsAPI();

  // State
  const [claimRequestDetails, setClaimRequestDetails] = useState<IClaimRequestDetailsResponse>();
  const [formValues, setFormValues] = useState<IClaimRequestFormValues>(formInitialValues);
  const [claimUploadAttachmentData, setClaimUploadAttachmentsData] = useState<IClaimRequestLineItemsFormValues>();
  const [claimLineItemNote, setClaimLineItemNote] = useState<{name: string; value: string}>();
  const [isLoading, setIsLoading] = useState(false);
  const [reasonDropdownOptions, setReasonDropdownOptions] = useState<IClaimsReasonResponse[]>();

  // Constant
  const claimLineItemReason = reasonDropdownOptions?.[0]?.subReasons?.length
    ? reasonDropdownOptions?.[0]?.subReasons?.[0]?.reason
    : reasonDropdownOptions?.[0]?.parentReason?.reason;
  const claimId = claimRequestDetails
    ? claimRequestDetails?.claimRequestID
    : claimRequestId
      ? Number(claimRequestId)
      : undefined;

  // useEffect
  useEffect(() => {
    fetchData();
  }, [_supplierId, claimRequestId, txGuid]);

  useEffect(() => {
    if (state && !txGuid) {
      setFormValues(oldVal => ({
        ...oldVal,
        defaultAction: state?.defaultAction || ClaimActions.Credit,
        defaultReason: state?.defaultReason || '',
        comment: state?.comment || '',
        vendorId: !state?.txGuid ? state?.vendorId : oldVal?.vendorId,
        invoiceDate: !state?.txGuid ? state?.invoiceDate : oldVal?.invoiceDate,
        invoiceRefNumber: !state?.txGuid ? state?.invoiceRefNumber : oldVal?.invoiceRefNumber,
        vendorName: state?.vendorName || '',
        vendorRefNumber: state?.vendorRefNumber || '',
      }));
    }
  }, [state, txGuid]);

  const fetchClaimReasons = async () => {
    try {
      let claimReasonResponse: IClaimsReasonResponse[] | undefined;
      if (isInBuyerContext && _supplierId) {
        claimReasonResponse = await getClaimReasonBuyer(_supplierId);
      } else if (isInSupplierContext) {
        claimReasonResponse = await getClaimReasonSupplier();
      }
      setReasonDropdownOptions(claimReasonResponse);
      return claimReasonResponse;
    } catch {}
  };

  const fetchData = async () => {
    setIsLoading(true);
    const claimReason = claimAndReturnsV272602 ? await fetchClaimReasons() : undefined;

    if (claimRequestId) {
      await fetchClaimDetails(Number(claimRequestId));
      setIsLoading(false);
      return;
    }

    if (txGuid) {
      const tr = await fetchInvoiceDetails(txGuid);
      if (!tr) {
        setIsLoading(false);
        return;
      }

      if (claimAndReturnsV272602) {
        const {parentReason, subReasons} = claimReason?.[0] || {};
        const claimDefaultReason = parentReason?.reason;
        const claimLineItemReason = subReasons?.length ? subReasons?.[0]?.reason : parentReason?.reason;

        const lineItems = tr?.Lines?.reduce((acc: IClaimRequestLineItemsFormValues[], l) => {
          const claimableQuantity = l?.Quantity - l?.QuantityClaimed;
          const line = {
            inventoryID: l?.InventoryID,
            invoiceLineID: l?.LineID,
            code: l?.Code,
            shortDescription: l?.ShortDescription,
            description: l?.Description,
            uoM: l?.UoM,
            quantity: claimableQuantity || 0,
            price: l?.SellPriceEx,
            reason: claimLineItemReason || '',
            action: ClaimActions.Credit,
            lineTotalEx: l?.SellPriceEx * claimableQuantity,
            isTaxExempt: l?.IsTaxable ? false : true,
            rowAction: claimableQuantity ? ClaimAndReturnRowAction.Create : ClaimAndReturnRowAction.Delete,
            availableQty: claimableQuantity,
            taxPercent: l?.IsTaxable ? (l?.TaxRate ? l?.TaxRate : l?.SellPriceEx / l?.SellPriceTax) : undefined,
            notes: '',
            isAttachmentUpload: false,
          };
          acc.push(line);
          return acc;
        }, []);

        setFormValues(oldVal => ({
          ...oldVal,
          invoiceId: tr?.ID,
          vendorId: tr?.VendorID || undefined,
          defaultReason: state?.defaultReason || claimDefaultReason || '',
          status: ClaimsAndReturnsStatus.Draft,
          invoiceRefNumber: tr?.RefNumber,
          vendorName: tr?.VendorName,
          vendorRefNumber: tr?.VendorTransRefNumber,
          lineItems: lineItems,
        }));
      } else {
        const lineItems: IClaimRequestLineItemsFormValues[] = tr?.Lines?.map(l => ({
          inventoryID: l?.InventoryID,
          invoiceLineID: l?.LineID,
          code: l?.Code,
          shortDescription: l?.ShortDescription,
          description: l?.Description,
          uoM: l?.UoM,
          quantity: l?.Quantity,
          price: l?.SellPriceEx,
          reason: ClaimReasons.Damaged,
          action: ClaimActions.Credit,
          lineTotalEx: l?.LineTotalEx,
          isTaxExempt: l?.IsTaxable ? false : true,
          rowAction: ClaimAndReturnRowAction.Create,
          availableQty: l?.Quantity - l?.QuantityClaimed,
          taxPercent: l?.IsTaxable ? (l?.TaxRate ? l?.TaxRate : l?.SellPriceEx / l?.SellPriceTax) : undefined,
          notes: '',
          isAttachmentUpload: false,
        }));

        setFormValues(oldVal => ({
          ...oldVal,
          invoiceId: tr?.ID,
          vendorId: tr?.VendorID || undefined,
          status: ClaimsAndReturnsStatus.Draft,
          defaultReason: ClaimReasons.Damaged,
          invoiceRefNumber: tr?.RefNumber,
          vendorName: tr?.VendorName,
          vendorRefNumber: tr?.VendorTransRefNumber,
          lineItems: lineItems,
        }));
      }
      setIsLoading(false);
    }

    setIsLoading(false);
  };

  const fetchClaimDetails = async (crId: number) => {
    try {
      let crd: IClaimRequestDetailsResponse | undefined = undefined;
      if (isInBuyerContext && _supplierId) {
        crd = await getClaimRquestDetailBuyer(_supplierId, crId);
      } else if (isInSupplierContext && _accountCustomerId) {
        crd = await getClaimRquestDetailSupplier(_accountCustomerId, crId);
      }

      setClaimRequestDetails(crd);

      if (!crd) return;

      if (crd?.status && redirectIfStatus?.includes(crd?.status)) {
        history.goBack();
      }

      let tr: IInvoice | undefined;
      if (crd?.invoiceGUID) {
        tr = await fetchInvoiceDetails(crd?.invoiceGUID);
      }

      const lineItems: IClaimRequestLineItemsFormValues[] = crd?.lines?.map(l => ({
        claimRequestID: l?.claimRequestID,
        claimRequestLineID: l?.claimRequestLineID,
        inventoryID: l?.inventoryID,
        invoiceLineID: l?.invoiceLineID,
        code: l?.code,
        shortDescription: l?.shortDescription,
        description: l?.description,
        uoM: l?.uoM,
        quantity: l?.quantity,
        price: l?.sellPriceEx,
        lineTotalEx: l?.lineTotalEx,
        isTaxExempt: l?.isTaxExempt,
        reason: l?.reason,
        action: l?.action,
        availableQty: l?.claimableQuantity,
        taxPercent: l?.taxRate,
        rowAction: ClaimAndReturnRowAction.Update,
        notes: l?.notes || '',
        isAttachmentUpload: Boolean(l?.claimRequestAttachments?.length),
      }));

      const formVal: IClaimRequestFormValues = {
        invoiceId: crd?.invoiceID,
        vendorId: crd?.vendorID || undefined,
        invoiceRefNumber: crd?.invoiceRefNumber || undefined,
        vendorName: crd?.vendorName || undefined,
        vendorRefNumber: tr?.VendorTransRefNumber,
        claimRequestID: crd?.claimRequestID,
        claimCode: crd?.claimCode,
        comment: crd?.description,
        defaultAction: crd?.action,
        defaultReason: crd?.reason,
        status: crd?.status,
        lineItems: lineItems,
        isPendingCustomerAction: crd?.isPendingCustomerAction,
      };

      setFormValues(formVal);
    } catch {
      setIsLoading(false);
    }
  };

  const fetchInvoiceDetails = async (txGuidId: string) => {
    try {
      let fetchTransaction: () => Promise<IInvoice | undefined>;

      if (isInSupplierContext) fetchTransaction = () => supplierAPI.getInvoiceByGuid({transactionGuid: txGuidId});
      else if (isInBuyerContext)
        fetchTransaction = () => buyerAPI.getInvoice({LinkedSupplierID: _supplierId, TransactionGuid: txGuidId});
      else fetchTransaction = () => new Promise(resolve => resolve(undefined));

      return await fetchTransaction();
    } catch {
      setIsLoading(false);
    }
  };

  const updateFormValue = (cr: IClaimRequestDetailsResponse) => {
    setClaimRequestDetails(cr);

    if (!cr?.status || redirectIfStatus?.includes(cr?.status)) {
      history.goBack();
      return;
    }

    const {claimRequestLineID, invoiceLineID} = claimUploadAttachmentData || {};
    if (claimUploadAttachmentData && !claimRequestLineID) {
      const updatedLine = cr?.lines?.find(
        l => l?.claimRequestLineID === claimRequestLineID || l?.invoiceLineID === invoiceLineID,
      );
      setClaimUploadAttachmentsData(oldval => {
        if (oldval) {
          return {
            ...oldval,
            claimRequestLineID: updatedLine?.claimRequestLineID,
            claimRequestID: updatedLine?.claimRequestID,
          };
        }
        return oldval;
      });
    }

    const lineItems: IClaimRequestLineItemsFormValues[] = [];
    formikRef?.current?.values?.lineItems?.forEach(l => {
      const claimRequestLine = cr?.lines?.find(crdl => {
        return (
          (crdl?.invoiceLineID && crdl?.invoiceLineID === l?.invoiceLineID) ||
          (crdl?.claimRequestLineID && crdl?.claimRequestLineID === l?.claimRequestLineID)
        );
      });

      const rowAction =
        l?.rowAction === ClaimAndReturnRowAction?.Create &&
        (claimRequestLine?.claimRequestLineID || l?.claimRequestLineID)
          ? ClaimAndReturnRowAction?.Update
          : l?.rowAction;

      const line = {
        claimRequestID: claimRequestLine?.claimRequestID || l?.claimRequestID,
        claimRequestLineID: claimRequestLine?.claimRequestLineID || l?.claimRequestLineID,
        inventoryID: l?.inventoryID,
        invoiceLineID: l?.invoiceLineID,
        code: l?.code,
        shortDescription: l?.shortDescription,
        description: l?.description,
        uoM: l?.uoM,
        quantity: l?.quantity,
        price: l?.price,
        reason: l?.reason,
        action: l?.action,
        lineTotalEx: l?.lineTotalEx,
        isTaxExempt: l?.isTaxExempt,
        rowAction: rowAction,
        availableQty: l?.availableQty,
        taxPercent: l?.taxPercent,
        notes: l?.notes,
        isAttachmentUpload: Boolean(claimRequestLine?.claimRequestAttachments?.length),
      };

      lineItems?.push(line);
    });

    const adHocLines = cr?.lines?.filter(cl => {
      if (!lineItems?.length) return true;
      return lineItems?.findIndex(l => l?.claimRequestLineID === cl?.claimRequestLineID) === -1 ? true : false;
    });

    adHocLines?.forEach(adl => {
      const line = {
        claimRequestID: adl?.claimRequestID,
        claimRequestLineID: adl?.claimRequestLineID,
        inventoryID: adl?.inventoryID,
        invoiceLineID: adl?.invoiceLineID,
        code: adl?.code,
        shortDescription: adl?.shortDescription,
        description: adl?.description,
        uoM: adl?.uoM,
        quantity: adl?.quantity,
        price: adl?.sellPriceEx,
        reason: adl?.reason,
        action: adl?.action,
        lineTotalEx: adl?.lineTotalEx,
        isTaxExempt: adl?.isTaxExempt,
        rowAction: ClaimAndReturnRowAction.Update,
        availableQty: adl?.claimableQuantity,
        taxPercent: adl?.taxRate,
        notes: adl?.notes || '',
        isAttachmentUpload: Boolean(adl?.claimRequestAttachments?.length),
      };

      lineItems?.push(line);
    });

    setFormValues(oldVal => ({
      ...oldVal,
      invoiceId: cr?.invoiceID || null,
      vendorId: cr?.vendorID || undefined,
      invoiceRefNumber: cr?.invoiceRefNumber || undefined,
      vendorName: cr?.vendorName || undefined,
      claimRequestID: cr?.claimRequestID,
      claimCode: cr?.claimCode,
      comment: cr?.description,
      defaultAction: cr?.action,
      defaultReason: cr?.reason,
      status: cr?.status,
      lineItems: lineItems,
      isPendingCustomerAction: cr?.isPendingCustomerAction,
    }));
    setIsLoading(false);
  };

  const updateClaimsStatus = async (status: ClaimsAndReturnsStatus, rejectReason?: string) => {
    try {
      if (!claimId || !claimRequestDetails) return;
      const usr = await updateClaimAndReturnStatus(claimId, {
        status,
        comment: rejectReason,
        linkedSupplierID: isInBuyerContext && _supplierId ? _supplierId : undefined,
        accountCustomerID: isInSupplierContext ? claimRequestDetails?.accountCustomerID || undefined : undefined,
      });
      redirectToHighLevel(usr?.claimRequestID, status);
    } catch {}
  };

  const redirectToHighLevel = (claimRequestID: number, status?: ClaimsAndReturnsStatus) => {
    const searchQuery = new URLSearchParams();
    if (status && status !== ClaimsAndReturnsStatus.Deleted) {
      searchQuery.set('claimRequestId', String(claimRequestID));
      const filterBy = getFilterNameByStatus(status);
      filterBy && searchQuery.set('filterBy', filterBy);
      isInSupplierContext && _accountCustomerId && searchQuery.set('accountCustomerId', String(_accountCustomerId));
    }
    if (isInBuyerContext && _supplierId) {
      history.push({pathname: `/supplier/${_supplierId}/claim-request`, search: searchQuery?.toString()});
    } else if (isInSupplierContext && _accountCustomerId) {
      history.push({pathname: AUTH_SELLING_AR_CLAIM_REQUESTS_ROUTE, search: searchQuery?.toString()});
    }
  };

  const handleSubmit = async (values: IClaimRequestFormValues, isReturnResponse?: boolean) => {
    try {
      let claimRequestResponse: IClaimRequestDetailsResponse | undefined;

      if (claimId) {
        const filteredLineItems = values?.lineItems?.filter(
          l =>
            l?.rowAction !== undefined && !(ClaimAndReturnRowAction.Delete === l?.rowAction && !l?.claimRequestLineID),
        );

        const payload: IUpdateClaimRequestPayload = {
          accountCustomerID: isInSupplierContext ? _accountCustomerId || undefined : undefined,
          linkedSupplierID: isInBuyerContext && _supplierId ? _supplierId : undefined,
          vendorID: values?.vendorId || undefined,
          invoiceID: values?.invoiceId || undefined,
          defaultClaimReason: values?.defaultReason,
          defaultReturnType: values?.defaultAction,
          comment: values?.status === ClaimsAndReturnsStatus.Draft ? values?.comment : undefined,
          isPendingCustomerAction: values?.isPendingCustomerAction,
          lines: filteredLineItems?.map(l => ({
            claimRequestLineID: l?.claimRequestLineID,
            invoiceLineID: l?.invoiceLineID,
            quantity: l?.quantity,
            claimReason: l?.reason,
            returnType: l?.action,
            isTaxExempt: l?.isTaxExempt,
            amountEx: l?.price,
            description: !l?.invoiceLineID ? l?.description : undefined,
            action: !l?.claimRequestLineID ? ClaimAndReturnRowAction.Create : l?.rowAction!,
            notes: l?.notes || undefined,
            inventoryID: l?.inventoryID,
          })),
        };

        claimRequestResponse = await updateClaimRequest(claimId, payload);

        if (values?.actionStatus) {
          await updateClaimAndReturnStatus(claimId, {
            status: values?.actionStatus,
            VendorCreditNoteDate:
              values.actionStatus === ClaimsAndReturnsStatus.Approved &&
              claimAndReturnsV272602 &&
              values?.approvePaymentDate
                ? values?.approvePaymentDate
                : undefined,
            SupplierReferenceNumber:
              values.actionStatus === ClaimsAndReturnsStatus.Approved &&
              claimAndReturnsV272602 &&
              values?.approveReferenceNo
                ? values?.approveReferenceNo
                : undefined,
            linkedSupplierID: isInBuyerContext && _supplierId ? _supplierId : undefined,
            accountCustomerID: isInSupplierContext ? claimRequestResponse?.accountCustomerID || undefined : undefined,
          });
        }
      } else {
        const filteredLineItems = values?.lineItems?.filter(
          l => l?.rowAction !== undefined && l?.rowAction !== ClaimAndReturnRowAction.Delete,
        );

        const payload: ICreateClaimRequestPayload = {
          accountCustomerID: isInSupplierContext ? _accountCustomerId : undefined,
          linkedSupplierID: isInBuyerContext && _supplierId ? _supplierId : undefined,
          vendorID: values?.vendorId,
          invoiceID: values?.invoiceId || undefined,
          defaultClaimReason: values?.defaultReason,
          defaultReturnType: values?.defaultAction,
          comment: values?.comment,
          invoiceDate: values?.invoiceId ? undefined : values?.invoiceDate,
          invoiceRefNumber: values?.invoiceId ? undefined : values?.invoiceRefNumber,
          lines: filteredLineItems?.map(l => ({
            invoiceLineID: l?.invoiceLineID,
            quantity: l?.quantity,
            claimReason: l?.reason,
            returnType: l?.action,
            amountEx: l?.price,
            isTaxExempt: l?.isTaxExempt,
            description: l?.description,
            action: ClaimAndReturnRowAction.Create,
            notes: l?.notes || undefined,
            inventoryID: l?.inventoryID,
          })),
        };

        claimRequestResponse = await createClaimRequest(payload);

        if (claimRequestResponse && values?.actionStatus) {
          await updateClaimAndReturnStatus(claimRequestResponse?.claimRequestID, {
            status: values?.actionStatus,
            linkedSupplierID: isInBuyerContext && _supplierId ? _supplierId : undefined,
            accountCustomerID: isInSupplierContext ? claimRequestResponse?.accountCustomerID || undefined : undefined,
          });
        }
      }

      if (isReturnResponse) {
        return claimRequestResponse;
      }

      redirectToHighLevel(claimRequestResponse?.claimRequestID, values?.actionStatus || claimRequestResponse?.status);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  const handleSelectAndUnSelectAll = (
    values: IClaimRequestFormValues,
    setValues: (values: React.SetStateAction<IClaimRequestFormValues>, shouldValidate?: boolean | undefined) => void,
  ) => {
    const isAlreadySelectedAll = values?.lineItems?.every(l => l.rowAction === ClaimAndReturnRowAction.Delete);
    let updatedLineItems: IClaimRequestLineItemsFormValues[];

    if (!isAlreadySelectedAll) {
      updatedLineItems = values?.lineItems?.map(l => ({...l, rowAction: ClaimAndReturnRowAction.Delete}));
    } else {
      updatedLineItems = values?.lineItems?.map(l => {
        return l?.claimRequestLineID
          ? {...l, rowAction: ClaimAndReturnRowAction.Update}
          : {...l, rowAction: ClaimAndReturnRowAction.Create};
      });
    }

    setValues({...values, lineItems: updatedLineItems});
  };

  const getCurrencyStrFromDecimal = (num: string) => {
    if (num === '-') return num;
    if (!num) return '';
    const afterDecimalLength = num?.split('.')[1]?.length;
    const precision = afterDecimalLength ?? 0;
    return currency(num, {precision: precision}).format();
  };

  const getClaimTotalExTax = (lineItems: IClaimRequestLineItemsFormValues[] | undefined) => {
    return Number(
      lineItems
        ?.filter(f => f.rowAction !== ClaimAndReturnRowAction.Delete)
        .reduce((a, c) => a + c?.quantity * c?.price, 0),
    );
  };

  const getClaimTotalTax = (lineItems: IClaimRequestLineItemsFormValues[] | undefined) => {
    return Number(
      lineItems
        ?.filter(f => f.rowAction !== ClaimAndReturnRowAction.Delete)
        .reduce((a, c) => a + c?.quantity * ((c?.price * (c?.taxPercent || 0)) / 100), 0),
    );
  };

  const getClaimTotalIncTax = (lineItems: IClaimRequestLineItemsFormValues[] | undefined) => {
    return Number(
      lineItems
        ?.filter(f => f.rowAction !== ClaimAndReturnRowAction.Delete)
        .reduce((a, c) => a + c?.quantity * c?.price + (c?.quantity * (c?.price * (c?.taxPercent || 0))) / 100, 0),
    );
  };

  // Validation schema
  const CreateAndUpdateClaimRequestValidation = Yup.object({
    defaultAction: Yup.string().required('Default Action is required.'),
    defaultReason: Yup.string().required('Default Reason is required.'),
    comment: Yup.string().nullable().max(250, 'Max 250 char allowed'),
    lineItems: Yup.array().of(
      Yup.object().shape({
        quantity: Yup.number()
          .nullable(true)
          .test('avl-quantity-check', 'Quantity should not be greater than available quantity', (v, c) => {
            if (c?.parent?.rowAction === ClaimAndReturnRowAction.Delete) return true;
            if (!v) return true;
            const availQty = c?.parent?.availableQty;
            return typeof availQty === 'number' ? v <= availQty : true;
          })
          .test('quantity-check', 'Quantity should be greater than zero', (v, c) => {
            if (c?.parent?.rowAction === ClaimAndReturnRowAction.Delete) return true;
            if (!v) return false;
            return v > 0;
          }),
        price: Yup.string().when('rowAction', {
          is: 2,
          then: Yup.string().optional(),
          otherwise: Yup.string()
            .required('Price is required')
            .test('total-price-check', 'Claim total(ex tax) should be positive', (v, c) => {
              if (c?.parent?.rowAction === ClaimAndReturnRowAction.Delete || Number(v) > 0) return true;
              const totalPrice = Number(
                formikRef?.current?.values?.lineItems
                  ?.filter(f => f.rowAction !== ClaimAndReturnRowAction.Delete)
                  .reduce((a, c) => a + c?.quantity * c?.price, 0)
                  .toFixed(2),
              );
              return totalPrice > 0;
            }),
        }),
        action: Yup.string().required('Action is required'),
        reason: Yup.string().required('Reason is required'),
      }),
    ),
  });

  const leftPanel = (
    <div className="relative h-full w-full">
      <Formik
        initialValues={formValues}
        onSubmit={v => handleSubmit(v)}
        validationSchema={CreateAndUpdateClaimRequestValidation}
        enableReinitialize
        validateOnChange={false}
        innerRef={formikRef}
      >
        {formikProps => (
          <Form className="h-full">
            <CreateAndEditClaimRequestHeader
              claimRequestDetails={claimRequestDetails}
              claimRequestId={claimRequestId}
              linkedSupplierId={_supplierId}
              accountCustomerId={_accountCustomerId}
            />
            <div className="mt-4 h-full w-full rounded-[6px] bg-white shadow-[0px_0px_6px_#D3E5EF]">
              <div className="mx-2.5 flex items-center justify-between bg-white py-4">
                <p className="font-poppins font-medium text-black-800">Order Details</p>
                <div className="mr-3 font-poppins font-medium text-primary">
                  <button
                    data-autoid={`btnSelectUnselect`}
                    type="button"
                    onClick={() => handleSelectAndUnSelectAll(formikProps?.values, formikProps?.setValues)}
                  >
                    {formikProps?.values?.lineItems?.every(l => l.rowAction === ClaimAndReturnRowAction.Delete)
                      ? 'Select all'
                      : 'Unselect all'}
                  </button>
                </div>
              </div>
              <>
                <FieldArray
                  name="lineItems"
                  key={'AdHocForm'}
                  render={() => (
                    <div className="flex h-full w-full flex-col items-start">
                      {isLoading ? (
                        <LoadingIndicator
                          isLoading={isLoading}
                          size="md"
                          color={'#3398A7'}
                          position={{right: '46%', bottom: '50%'}}
                        />
                      ) : (
                        <div className="flex h-full w-full flex-col items-start pb-[80px]">
                          <div className="flex h-[calc(100%-300px)] w-full flex-col overflow-auto">
                            <div className="!relative flex flex-col px-2.5 pb-3">
                              <table className="sticky w-full">
                                <thead className="sticky top-0 z-10">
                                  <tr className="h-10 rounded-[2px] bg-[#F1F1F1]">
                                    <th
                                      align="left"
                                      className="min-w-[180px] px-2.5 font-poppins text-base font-semibold leading-normal text-spenda-labeltext"
                                    >
                                      Code
                                    </th>
                                    <th
                                      align="left"
                                      className="px-2.5 font-poppins text-base font-semibold leading-normal text-spenda-labeltext"
                                    >
                                      Products
                                    </th>
                                    <th
                                      align="right"
                                      className="px-2.5 font-poppins text-base font-semibold leading-normal text-spenda-labeltext"
                                    >
                                      UoM
                                    </th>
                                    <th
                                      align="right"
                                      className="px-2.5 font-poppins text-base font-semibold leading-normal text-spenda-labeltext"
                                    >
                                      Quantity
                                    </th>
                                    <th
                                      align="right"
                                      className="px-2.5 font-poppins text-base font-semibold leading-normal text-spenda-labeltext"
                                    >
                                      Excluding Tax
                                    </th>
                                    <th
                                      align="right"
                                      className="px-2.5 font-poppins text-base font-semibold leading-normal text-spenda-labeltext"
                                    >
                                      Claim Total
                                    </th>
                                    {claimAndReturnsV272602 && (
                                      <th
                                        align="center"
                                        className="px-2.5 font-poppins text-base font-semibold leading-normal text-spenda-labeltext"
                                      >
                                        Tax exempt
                                      </th>
                                    )}
                                    <th
                                      align="left"
                                      className="px-2.5 font-poppins text-base font-semibold leading-normal text-spenda-labeltext"
                                    >
                                      Add Reason
                                    </th>
                                    <th
                                      align="left"
                                      className="px-2.5 font-poppins text-base font-semibold leading-normal text-spenda-labeltext"
                                    >
                                      Action
                                    </th>
                                    <th
                                      align="center"
                                      className="px-2.5 font-poppins text-base font-semibold leading-normal text-spenda-labeltext"
                                    >
                                      {' '}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="relative">
                                  {formikProps?.values?.lineItems?.map((l, indx) => {
                                    const isDeletedLine = l?.rowAction === ClaimAndReturnRowAction.Delete;
                                    return (
                                      <tr data-autoid={`rowClaimLine${indx + 1}`} key={indx} className={`h-10`}>
                                        <td align="left" width="7%" className={clsx({'opacity-25': isDeletedLine})}>
                                          <p
                                            data-autoid={`txtCode`}
                                            className={clsx(
                                              'min-w-[150px] p-2.5 font-poppins text-xs font-semibold leading-normal text-black-800',
                                            )}
                                          >
                                            {l?.code || '-'}
                                          </p>
                                        </td>
                                        <td
                                          className={clsx('bg-[#FAFAFA]', {'opacity-25': isDeletedLine})}
                                          align="left"
                                          width="20%"
                                        >
                                          <p
                                            data-autoid={`txtDescription`}
                                            className={clsx(
                                              'p-2.5 font-poppins text-xs font-semibold leading-normal text-black-800',
                                            )}
                                          >
                                            {l?.description}
                                          </p>
                                        </td>
                                        <td className={clsx({'opacity-25': isDeletedLine})} align="right" width="5%">
                                          <p
                                            data-autoid={`txtUoM`}
                                            className={clsx(
                                              'p-2.5 font-poppins text-xs font-semibold leading-normal text-black-800',
                                            )}
                                          >
                                            {l?.uoM}
                                          </p>
                                        </td>
                                        <td
                                          className={clsx('bg-[#FAFAFA]', {'opacity-25': isDeletedLine})}
                                          align="right"
                                          width="10%"
                                        >
                                          <Field
                                            rowAction={l?.rowAction}
                                            index={indx}
                                            price={l?.price}
                                            name={`lineItems[${indx}].quantity`}
                                            component={quantityInput}
                                          />
                                        </td>
                                        <td className={clsx({'opacity-25': isDeletedLine})} align="right" width="10%">
                                          <Field
                                            rowAction={l?.rowAction}
                                            index={indx}
                                            quantity={l?.quantity}
                                            name={`lineItems[${indx}].price`}
                                            getCurrencyStrFromDecimal={getCurrencyStrFromDecimal}
                                            component={currencyInput}
                                          />
                                        </td>
                                        <td
                                          className={clsx('bg-[#FAFAFA]', {'opacity-25': isDeletedLine})}
                                          align="right"
                                          width="10%"
                                        >
                                          <p
                                            data-autoid={`txtLineTotalEx`}
                                            className={clsx(
                                              'p-2.5 font-poppins text-xs font-semibold leading-normal text-black-800',
                                            )}
                                          >
                                            {String(Math.trunc(l?.lineTotalEx * 100) / 100)}
                                          </p>
                                        </td>
                                        {claimAndReturnsV272602 && (
                                          <td
                                            className={clsx('bg-[#FFF]', {'opacity-25': isDeletedLine})}
                                            align="center"
                                            width="10%"
                                          >
                                            <div className="ml-3 w-[38px]">
                                              <SCheckbox checked={l?.isTaxExempt} disabled label={''} />
                                            </div>
                                          </td>
                                        )}
                                        <td className={clsx({'opacity-25': isDeletedLine})} align="left" width="15%">
                                          <Field
                                            rowAction={l?.rowAction}
                                            index={indx}
                                            name={`lineItems[${indx}].reason`}
                                            claimAndReturnsV272602={claimAndReturnsV272602}
                                            reasonDropdownOptions={reasonDropdownOptions}
                                            component={selectReasonInput}
                                          />
                                        </td>
                                        <td
                                          className={clsx('bg-[#FAFAFA]', {'opacity-25': isDeletedLine})}
                                          align="left"
                                          width="15%"
                                        >
                                          <Field
                                            rowAction={l?.rowAction}
                                            index={indx}
                                            name={`lineItems[${indx}].action`}
                                            component={selectActionInput}
                                          />
                                        </td>
                                        <td
                                          align="center"
                                          className="px-2.5 font-poppins text-xs font-semibold leading-normal text-black-800 !opacity-100"
                                        >
                                          <div className="flex flex-row items-center justify-between gap-3">
                                            {claimAndReturnsV272602 && (
                                              <div className="flex flex-row items-center justify-between gap-3">
                                                <button
                                                  disabled={isDeletedLine}
                                                  type="button"
                                                  data-autoid={`btnAddnote`}
                                                  onClick={() => {
                                                    setClaimLineItemNote({
                                                      name: `lineItems[${indx}].notes`,
                                                      value: l?.notes,
                                                    });
                                                  }}
                                                >
                                                  <IconChatClaims
                                                    color={l?.notes && '#1C78AD'}
                                                    className={clsx('cursor-pointer', {
                                                      '!cursor-not-allowed opacity-25': isDeletedLine,
                                                    })}
                                                  />
                                                </button>
                                                <button
                                                  type="button"
                                                  disabled={isDeletedLine}
                                                  data-autoid={`btnEditEvidence`}
                                                  onClick={() =>
                                                    isDeletedLine ? undefined : setClaimUploadAttachmentsData(l)
                                                  }
                                                >
                                                  <IconCamera
                                                    color={l?.isAttachmentUpload ? '#1C78AD' : ''}
                                                    className={clsx('cursor-pointer', {
                                                      '!cursor-not-allowed opacity-25': isDeletedLine,
                                                    })}
                                                  />
                                                </button>
                                              </div>
                                            )}
                                            {isDeletedLine ? (
                                              <UndoClaimIcon
                                                dataAutoId={`btnUndoLine`}
                                                className={clsx(
                                                  `cursor-pointer ${!claimAndReturnsV272602 && 'mx-auto'}`,
                                                )}
                                                onClick={() => {
                                                  formikProps?.setFieldValue(
                                                    `lineItems[${indx}].rowAction`,
                                                    l?.claimRequestLineID
                                                      ? ClaimAndReturnRowAction.Update
                                                      : ClaimAndReturnRowAction.Create,
                                                  );
                                                }}
                                              />
                                            ) : (
                                              <Delete
                                                data-autoid={`btnDeleteLine`}
                                                className={clsx(
                                                  `cursor-pointer text-[#CCCCCC] ${!claimAndReturnsV272602 && 'mx-auto'}`,
                                                )}
                                                onClick={() =>
                                                  formikProps?.setFieldValue(
                                                    `lineItems[${indx}].rowAction`,
                                                    ClaimAndReturnRowAction.Delete,
                                                  )
                                                }
                                              />
                                            )}
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                  {claimAndReturnsV272602 && (
                                    <ClaimsAddAdhocLine
                                      isSubmitting={isSubmitting}
                                      claimLineItemReason={claimLineItemReason}
                                      getCurrencyStrFromDecimal={getCurrencyStrFromDecimal}
                                      handleSubmit={handleSubmit}
                                      reasonDropdownOptions={reasonDropdownOptions}
                                      supplierId={_supplierId}
                                      updateFormValue={updateFormValue}
                                    />
                                  )}
                                </tbody>
                              </table>

                              <div className="mt-4 flex min-w-[220px] flex-row justify-between self-end bg-[#F1F1F1] p-[6px]">
                                <p className="font-poppins text-xs font-medium text-black-800">Claim total(ex tax):</p>
                                <p
                                  className="font-poppins text-xs font-medium text-black-800"
                                  data-autoid={`txtClaimTotalEx`}
                                >
                                  {PriceFormat(getClaimTotalExTax(formikProps?.values?.lineItems), undefined, 2)}
                                </p>
                              </div>
                              {claimAndReturnsV272602 && (
                                <>
                                  <div className="flex min-w-[220px] flex-row justify-between self-end bg-[#F1F1F1] p-[6px]">
                                    <p className="font-poppins text-xs font-medium text-black-800">Tax</p>
                                    <p
                                      className="font-poppins text-xs font-medium text-black-800"
                                      data-autoid={`txtClaimTotalTax`}
                                    >
                                      {PriceFormat(getClaimTotalTax(formikProps?.values?.lineItems), undefined, 2)}
                                    </p>
                                  </div>
                                  <div className="mb-2 flex min-w-[220px] flex-row justify-between self-end bg-[#F1F1F1] p-[6px]">
                                    <p className="font-poppins text-xs font-bold text-black-800">
                                      Claim total(inc tax):
                                    </p>
                                    <p
                                      className="font-poppins text-xs font-bold text-black-800"
                                      data-autoid={`txtClaimTotalInc`}
                                    >
                                      {PriceFormat(getClaimTotalIncTax(formikProps?.values?.lineItems), undefined, 2)}
                                    </p>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                          <ClaimLineItemNoteDialog
                            note={claimLineItemNote?.value}
                            isOpen={claimLineItemNote?.name ? true : false}
                            onClose={() => setClaimLineItemNote(undefined)}
                            onConfirm={(note: string) => {
                              if (claimLineItemNote) {
                                formikProps?.setFieldValue(claimLineItemNote?.name, note);
                                setClaimLineItemNote(undefined);
                              }
                            }}
                            isSubmitting={false}
                          />

                          {claimUploadAttachmentData && (
                            <EditEvidenceDialog
                              key={claimUploadAttachmentData?.claimRequestLineID}
                              canUploadAttachment
                              claimRequestDetails={claimRequestDetails}
                              handleSaveForAttachmentAsDraft={async () => {
                                const err = await formikProps?.validateForm();
                                if (!isEmpty(err)) {
                                  formikProps?.handleSubmit();
                                  setClaimUploadAttachmentsData(undefined);
                                  return;
                                }
                                return await handleSubmit(formikProps?.values, true);
                              }}
                              linkedSupplierId={_supplierId}
                              accountCustomerId={_accountCustomerId}
                              lineItemData={claimUploadAttachmentData}
                              showModal={claimUploadAttachmentData ? true : false}
                              onSuccess={claimRequestResponse => updateFormValue(claimRequestResponse)}
                              handleClose={() => setClaimUploadAttachmentsData(undefined)}
                            />
                          )}
                          <CreateAndEditClaimRequestFooter
                            redirectIfStatus={redirectIfStatus}
                            claimAndReturnsV272602={claimAndReturnsV272602}
                            isSubmitting={isSubmitting}
                            updateClaimsStatus={updateClaimsStatus}
                          />
                        </div>
                      )}
                    </div>
                  )}
                />
              </>
            </div>
          </Form>
        )}
      </Formik>
      <div className="absolute !bottom-0 !right-0 z-50 w-[400px] shadow-lg">
        <ConversationChatBox scope="CR_CreateAndEdit" />
      </div>
    </div>
  );

  return (
    <div className={`h-full overflow-hidden bg-spenda-newbg`}>
      {isInBuyerContext && (
        <>
          <MarketplaceLayout leftPanel={leftPanel} splitWidthType={4} />
          <PSBLFooter />
        </>
      )}
      {isInSupplierContext && <Layout leftPanel={leftPanel} splitWidthType={4} />}
    </div>
  );
};
export default CreateAndEditClaimRequest;

//----------------- INPUTS--------------------

function quantityInput({field, form, price, index, rowAction}: any) {
  const isInValid = form?.touched?.lineItems?.[index]?.quantity && form?.errors?.lineItems?.[index]?.quantity;
  const currentRow = form?.values?.lineItems?.[index];

  const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    await field?.onChange(e);
    const total = Number(e.target.value) * price;
    const rowAction = currentRow?.claimRequestLineID ? ClaimAndReturnRowAction.Update : ClaimAndReturnRowAction.Create;
    await form?.setFieldValue(`lineItems[${index}].lineTotalEx`, total);
    await form?.setFieldValue(`lineItems[${index}].rowAction`, rowAction);
  };

  return (
    <div className="relative flex items-center justify-end">
      <input
        title={currentRow?.availableQty !== null ? `Available Quantity: ${currentRow?.availableQty}` : ''}
        id={`floating_outlined${field?.name}`}
        disabled={rowAction === ClaimAndReturnRowAction.Delete}
        type="number"
        placeholder="Quantity"
        data-autoid={`txtQuantity`}
        className={clsx(
          'm-0 h-[38px] !rounded-[3px] !p-2.5 text-right font-poppins text-xs font-semibold leading-normal text-black-800 placeholder:text-sm placeholder:text-[#ccc] focus:border-[1px] focus:border-solid focus:border-[#777]',
          {'border-none': !isInValid},
          {'border !border-red-700 !pr-6 focus:border-red-700': isInValid},
        )}
        value={field?.value}
        name={field?.name}
        onChange={onChange}
        onBlur={field?.onBlur}
      />
      {isInValid && (
        <InvalidError
          availableQty={currentRow?.availableQty}
          dataAutoId="Quantity"
          error={form?.errors?.lineItems?.[index]?.quantity}
          indx={index}
        />
      )}
    </div>
  );
}

function currencyInput({field, form, quantity, index, rowAction, getCurrencyStrFromDecimal}: any) {
  const currentRow = form?.values?.lineItems?.[index];
  const isInValid = form?.touched?.lineItems?.[index]?.price && form?.errors?.lineItems?.[index]?.price;
  const isActiveInput = form?.values?.activeInput === field?.name;
  const value = isActiveInput ? field?.value : getCurrencyStrFromDecimal(String(Math.trunc(field?.value * 100) / 100));

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isMoreThan4DigitAterDecimal = e?.target?.value?.split('.')[1]?.length > 4;
    if (isMoreThan4DigitAterDecimal) return;
    field?.onChange(e);
    const total = Number(e.target.value) * quantity;
    const rowAction = currentRow?.claimRequestLineID ? ClaimAndReturnRowAction.Update : ClaimAndReturnRowAction.Create;
    form?.setFieldValue(`lineItems[${index}].lineTotalEx`, total);
    form?.setFieldValue(`lineItems[${index}].rowAction`, rowAction);
  };

  return (
    <div className="relative flex items-center justify-end">
      <input
        disabled={rowAction === ClaimAndReturnRowAction.Delete}
        id="Price"
        type={isActiveInput ? 'number' : 'text'}
        placeholder="Price"
        data-autoid={`txtPrice`}
        className={clsx(
          'm-0 h-[38px] !rounded-[3px] !p-2.5 text-right font-poppins text-xs font-semibold leading-normal text-black-800 placeholder:text-sm placeholder:text-[#ccc] focus:border-[1px] focus:border-solid focus:border-[#777]',
          {'border-none': !isInValid},
          {'border !border-red-700 !pr-6 focus:border-red-700': isInValid},
        )}
        name={field?.name}
        value={value}
        onChange={onChange}
        onFocus={() => form?.setFieldValue(`activeInput`, field?.name)}
        onBlur={e => {
          field?.onBlur(e);
          form?.setFieldValue(`activeInput`, undefined);
        }}
      />
      {isInValid && <InvalidError dataAutoId="Price" error={form?.errors?.lineItems?.[index]?.price} indx={index} />}
    </div>
  );
}

function selectReasonInput({field, form, index, rowAction, reasonDropdownOptions, claimAndReturnsV272602}: any) {
  const currentRow = form?.values?.lineItems?.[index];

  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const rowAction = currentRow?.claimRequestLineID ? ClaimAndReturnRowAction.Update : ClaimAndReturnRowAction.Create;
    field?.onChange(e);
    form?.setFieldValue(`lineItems[${index}].rowAction`, rowAction);
  };

  return (
    <select
      data-autoid={`ddlReason`}
      disabled={rowAction === ClaimAndReturnRowAction.Delete}
      className={clsx(
        'm-0 h-[38px] w-full rounded-[3px] border-none border-[#777] px-2 font-poppins text-xs font-semibold outline-none focus:border-[1px] focus:border-solid focus:border-[#777]',
        {'cursor-pointer': rowAction !== ClaimAndReturnRowAction.Delete},
      )}
      value={field?.value}
      name={field?.name}
      onChange={onChange}
      onBlur={field?.onBlur}
    >
      {claimAndReturnsV272602
        ? reasonDropdownOptions?.map((r: any, reasonIndex: number) => {
            return (
              <React.Fragment key={`lblSelectClaimAction-${reasonIndex}`}>
                {r?.subReasons?.length ? (
                  <optgroup label={r?.parentReason?.reason}>
                    {r?.subReasons?.map((sr: any) => (
                      <option data-autoid={`lblReasonOption-${reasonIndex}`} key={sr?.reason} value={sr?.reason}>
                        {sr?.reason}
                      </option>
                    ))}
                  </optgroup>
                ) : (
                  <option data-autoid={`lblReasonOption-${reasonIndex}`} value={r?.parentReason?.reason}>
                    {r?.parentReason?.reason}
                  </option>
                )}
              </React.Fragment>
            );
          })
        : (Object.keys(ClaimReasons) as Array<keyof typeof ClaimReasons>).map((key, index) => {
            return (
              <option
                key={'lblReasonOption' + index}
                data-autoid={`lblReasonOption-${index}`}
                value={ClaimReasons[key]}
              >
                {key?.replace(/([A-Z])/g, ' $1').trim()}
              </option>
            );
          })}
    </select>
  );
}

function selectActionInput({field, form, index, rowAction}: any) {
  const currentRow = form?.values?.lineItems?.[index];

  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const rowAction = currentRow?.claimRequestLineID ? ClaimAndReturnRowAction.Update : ClaimAndReturnRowAction.Create;
    field?.onChange(e);
    form?.setFieldValue(`lineItems[${index}].rowAction`, rowAction);
  };

  return (
    <select
      disabled={rowAction === ClaimAndReturnRowAction.Delete}
      data-autoid={`ddlAction`}
      className={clsx(
        {'cursor-pointer': rowAction !== ClaimAndReturnRowAction.Delete},
        'border-none focus:border-[1px] focus:border-solid focus:border-[#777]',
        'm-0 h-[38px] w-full rounded-[3px] border-[#777] px-2 font-poppins text-xs font-semibold outline-none',
      )}
      value={field?.value}
      name={field?.name}
      onChange={onChange}
      onBlur={field?.onBlur}
    >
      <option key={`lblActionOption${index}`} data-autoid={`lblActionOption-${index}`} value={ClaimActions.Credit}>
        {ClaimActions.Credit}
      </option>
    </select>
  );
}

const InvalidError = (props: {dataAutoId: string; indx: number; error: string; availableQty?: number | null}) => {
  const {availableQty, error, dataAutoId} = props;

  return (
    <Tooltip
      arrow
      placement="bottom"
      className={clsx({
        'rounded-[4px] bg-white py-2 pl-3 pr-5 text-spenda-primarytext shadow-md': tooltip,
      })}
      content={
        <React.Fragment>
          <div className="font-medium text-gray-900">
            <Typography variant="xsmall" data-autoid={`txt${dataAutoId}Error`} className="mb-2 text-red-700">
              {error}
            </Typography>
            {typeof availableQty !== 'undefined' && availableQty !== null && (
              <Typography variant="xsmall" data-autoid={`txtAvailable${dataAutoId}`}>
                {`Available Quantity:${availableQty}`}
              </Typography>
            )}
          </div>
        </React.Fragment>
      }
    >
      <div data-autoid={`icon${dataAutoId}Error`} className="absolute px-1">
        <ErrorIcon />
      </div>
    </Tooltip>
  );
};
