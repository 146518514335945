import React from 'react';
import {APOrPsblModuleInfo} from './APOrPsblModuleInfo';
import {isWebsiteId17} from '../../config';
import {Redirect} from 'react-router-dom';
import {useFeatureFlags} from '../../hooks/useFeatureFlags';

const PSBLModuleInfo = () => {
  const {psblV2189963} = useFeatureFlags().supplierDriven();

  if (!isWebsiteId17) {
    return <Redirect to={'/menu'} />;
  }

  return <APOrPsblModuleInfo isPsblModuleInfo psblV2189963={psblV2189963} />;
};

export default PSBLModuleInfo;
