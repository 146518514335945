import React, {
  useState,
  useEffect,
  FunctionComponent,
  ChangeEvent,
  useContext,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import clsx from 'clsx';
import {
  ClickAwayListener,
  InputAdornment,
  InputBase,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
  withStyles,
} from '@material-ui/core';
import {Button, Checkbox, Menu, MenuHandler, MenuList, SearchInput, IconButton} from 'spenda-ui-react';
import {uniqueId, debounce, omit, isEmpty, camelCase} from 'lodash';
import {usePopupState} from 'material-ui-popup-state/hooks';
import {FormikProps} from 'formik';

import AppContext from '../../context/app/appContext';
import {SALES_ORDER_STATUS, DISCOUNT_MODE, DeliveryMethods} from '../../model/constants/Constants';
import {Inventory} from '../../model/inventory/Inventory';
import {ICustomer} from '../../model/customer/Customer';
import {ISalesOrderLine, SalesOrderReservationStatus} from '../../model/sales-order/SalesOrder';
import {InventoryLineAutocomplete} from '../inventory/InventoryAutocomplete';
import {CreateSalesOrderValues} from '../form/SalesOrderForm';
import {IHeaderData} from '../tables/STable';
import LoadingIndicator from '../ui/LoadingIndicator';
import {PriceFormat} from '../../utils/formatter';
import PriceCalculator from '../../utils/priceCalculator';
import {useSalesOrderAPI} from '../../services/useSalesOrderAPI';

import IconDocumentEdit from '../../assets/svg/IconDocumentEdit';
import {WidgetDeleteLogo} from '../../assets/svg/WidgetDeleteLogo';

const useOrderDetailsStyles = makeStyles(() => ({
  root: {
    boxShadow: '0 0 6px 0 #D3E5EF',
  },
  table: {
    fontFamily: 'Poppins, sans-serif',
    borderCollapse: 'separate',
    borderSpacing: 0,
    '& .MuiTableRow-root': {
      height: 40,
      '& .MuiTableCell-head': {
        fontFamily: 'Poppins, sans-serif',
        color: '#999999',
        fontWeight: '600',
        fontSize: '14px',
        padding: '0px 8px',
        backgroundColor: '#F1F1F1',
        borderBottom: 'none',
        '&:first-child': {
          borderTopLeftRadius: '2px',
        },
        '&:last-child': {
          borderTopRightRadius: '2px',
        },
      },
      '& .MuiTableCell-body': {
        fontFamily: 'Poppins, sans-serif',
        fontSize: '12px',
        color: '#333333',
        padding: '0px 8px',
        borderBottom: '1px solid #EAEAEA50',
        border: '1px solid transparent',
        '&.active': {
          border: '1px solid hsl(var(--primary))',
        },
        '&:nth-of-type(even)': {
          backgroundColor: '#FAFAFA',
        },
        '&.cell-input': {
          cursor: 'text',
        },
        '& .input-bordered.Mui-focused': {
          border: '1px solid hsl(var(--primary)) !important',
        },
      },
    },
  },
}));

const StyledInputBase = withStyles({
  root: {
    fontFamily: 'Poppins, sans-serif',
    '& .MuiInputAdornment-root': {
      '& p': {
        color: '#333333',
        fontSize: '12px',
        fontFamily: 'Poppins, sans-serif',
      },
    },
    '& input': {
      fontSize: '12px',
      color: '#333333',
      textAlign: 'right',
      backgroundColor: 'transparent !important',
    },
  },
})(InputBase);

const columnArray = ['SKU', 'Cost Price', 'Weight', 'Volume', 'UoM', 'Sell Price Inc', 'Type'];

const getColumns = (
  Lines: ISalesOrderLine[],
  handleClearClick: (id: any) => void,
  saveLineInventory: (selected: Partial<Inventory> | null, selection: any) => void,
  handleQuantityChange: (e: any) => void,
  handleSellPriceExChange: (e: any) => void,
  setActiveIndex: (x: number) => void,
  handleKeyDownLastField: (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>, x: ISalesOrderLine) => void,
  selectedColumns: string[],
): (IHeaderData & {
  width?: string;
  editable: boolean;
  formatter: (rowData: any) => React.ReactNode;
  editor?: (rowData: any, index: number, inputRefs: React.MutableRefObject<HTMLInputElement[]>) => React.ReactNode;
})[] => {
  const columns = [
    {
      title: 'Code',
      field: 'Code',
      width: '175px',
      editable: true,
      formatter: (rowData: any) => rowData.Code,
      editor: (rowData: any, index: number, inputRefs: React.MutableRefObject<HTMLInputElement[]>) => {
        return (
          <InventoryLineAutocomplete
            index={index}
            inputRefs={inputRefs}
            id={`Code-${rowData.InventoryID}`}
            searchCodeOnly={true}
            alreadyFilled={Lines.map(a => a.InventoryID)}
            clearClick={() => handleClearClick(rowData.InventoryID)}
            onFocus={() => setActiveIndex(index)}
            selected={
              rowData?.InventoryID && rowData?.ShortDescription
                ? {
                    InventoryCode: rowData?.Code || '',
                    ShortDescription: rowData?.ShortDescription,
                  }
                : null
            }
            onSuggestionSelected={(_e, selected) => saveLineInventory(selected.suggestion, selected)}
          />
        );
      },
    },
    {
      title: 'Products',
      field: 'ShortDescription',
      editable: true,
      formatter: (rowData: any) => rowData.ShortDescription,
      editor: (rowData: any, index: number, inputRefs: React.MutableRefObject<HTMLInputElement[]>) => {
        return (
          <InventoryLineAutocomplete
            index={index}
            inputRefs={inputRefs}
            id={`ShortDescription-${rowData.InventoryID}`}
            alreadyFilled={Lines.map(a => a.InventoryID)}
            clearClick={() => handleClearClick(rowData.InventoryID)}
            onFocus={() => setActiveIndex(index)}
            selected={
              rowData?.InventoryID && rowData?.ShortDescription
                ? {
                    InventoryCode: rowData?.Code || '',
                    ShortDescription: rowData?.ShortDescription,
                  }
                : null
            }
            onSuggestionSelected={(e, selected) => saveLineInventory(selected.suggestion, selected)}
          />
        );
      },
    },
    {
      title: 'SKU',
      field: 'LineTotalExString',
      align: 'right',
      editable: false,
      formatter: (rowData: any) => rowData.SKU || '',
    },
    {
      title: 'Cost Price',
      field: 'LineTotalExString',
      align: 'right',
      editable: false,
      formatter: (rowData: any) => rowData.CostPriceEx,
    },
    {
      title: 'UoM',
      field: 'LineTotalExString',
      align: 'right',
      editable: false,
      formatter: (rowData: any) => rowData.UoM,
    },
    {
      title: 'Volume',
      field: 'LineTotalExString',
      align: 'right',
      editable: false,
      formatter: (rowData: any) => rowData.Volume,
    },
    {
      title: 'Weight',
      field: 'LineTotalExString',
      align: 'right',
      editable: false,
      formatter: (rowData: any) => rowData.Weight_kg,
    },
    {
      title: 'Type',
      field: 'LineTotalExString',
      align: 'right',
      editable: false,
      formatter: (rowData: any) => rowData.Type,
    },
    {
      title: 'Sell Price Inc',
      field: 'Sell Price Inc',
      align: 'right',
      editable: false,
      formatter: (rowData: any) => rowData.StandardSellPriceInc,
    },
    {
      title: 'Qty',
      field: 'Quantity',
      align: 'center',
      width: '163px',
      editable: true,
      formatter: (rowData: any) => rowData.Quantity || '0',
      editor: (rowData: any, index: number, inputRefs: React.MutableRefObject<HTMLInputElement[]>) => {
        return (
          <StyledInputBase
            fullWidth
            id={`Quantity-${rowData.InventoryID}`}
            // disabled={isLoading}
            placeholder="0"
            type="number"
            inputRef={el => (inputRefs.current[index] = el)}
            onFocus={() => setActiveIndex(index)}
            value={rowData.Quantity}
            onChange={handleQuantityChange}
            inputProps={{'data-autoid': `txtQuantity${index}`, style: {textAlign: 'center'}}}
          />
        );
      },
    },
    {
      title: 'Price',
      field: 'SellPriceEx',
      align: 'right',
      width: '163px',
      editable: true,
      formatter: (rowData: any) => rowData.SellPriceExString,
      editor: (rowData: any, index: number, inputRefs: React.MutableRefObject<HTMLInputElement[]>) => {
        return (
          <StyledInputBase
            fullWidth
            id={`SellPriceEx-${rowData.InventoryID}`}
            // disabled={isLoading}
            type="number"
            placeholder="0"
            inputRef={el => (inputRefs.current[index] = el)}
            onFocus={() => setActiveIndex(index)}
            value={PriceCalculator.roundNumber(rowData.SellPriceEx || 0)}
            onKeyDown={e => handleKeyDownLastField(e, rowData)}
            onChange={e => {
              if (Number(e.target.value) >= 0) handleSellPriceExChange(e);
            }}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            inputProps={{'data-autoid': `txtSellPriceEx${index}`}}
          />
        );
      },
    },
    {
      title: 'Line Total',
      field: 'LineTotalExString',
      align: 'right',
      width: '163px',
      editable: false,
      formatter: (rowData: any) => rowData.LineTotalExString || '$0.00',
    },
  ] as (IHeaderData & {
    width?: string;
    editable: boolean;
    formatter: (rowData: any) => React.ReactNode;
    editor: (rowData: any) => React.ReactNode;
  })[];
  const selectedFixColumns = [...selectedColumns, 'Code', 'Products', 'Qty', 'Price', 'Line Total'];

  return columns.filter(column => selectedFixColumns.includes(column.title));
};

export const SalesOrderDetailLines: FunctionComponent<
  FormikProps<CreateSalesOrderValues> & {
    lineItemsError?: boolean;
    customerDetails?: ICustomer | undefined;
    setEditMode: any;
    editMode: boolean;
    handleSetAddress?: (addressID: string, addressString: string, addressType: string) => void;
  }
> = props => {
  const classes = useOrderDetailsStyles();
  const {tenantInfo} = useContext(AppContext);
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const {values, setFieldValue, setValues, editMode, setEditMode} = props;

  const {addSalesOrderLineItem, isLoading} = useSalesOrderAPI();
  const searchInputPopup = usePopupState({variant: 'popover', popupId: 'searchItemsInputPopup'});

  const [searchText, setSearchText] = useState<string>('');
  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
  const [activeIndex, setActiveIndex] = useState<number>(-1);
  const [isNavigating, setIsNavigating] = useState<boolean>(true);
  const [isEditing, setIsEditing] = useState<any>(null);

  const debounceCalculateLineTotal = useCallback(
    debounce(
      (Lines: any, keyUpdated?: string, discount?: number, discountMode?: string, values?: CreateSalesOrderValues) => {
        calculateLineTotal(Lines, keyUpdated, discount, discountMode, values);
      },
      500,
    ),
    [],
  );

  useEffect(() => {
    if (
      (!isEmpty(values) && !values.Lines) ||
      (Array.isArray(values.Lines) &&
        values.Lines?.filter(
          _line =>
            _line.InventoryID !== tenantInfo?.TenantUserDetails?.DefaultDiscountLineItemInventoryId &&
            _line.InventoryID !== tenantInfo?.TenantUserDetails?.DefaultShippingLineItemInventoryId,
        ).length === 0)
    ) {
      addLineInventory();
    }
  }, [values.Lines]);

  useEffect(() => {
    if (editMode && !isEditing && values.Lines && values.Lines.length) {
      const linesSize = values.Lines.length;
      if (
        values.Lines[linesSize - 1]?.InventoryID !==
          tenantInfo?.TenantUserDetails?.DefaultDiscountLineItemInventoryId &&
        values.Lines[linesSize - 1]?.InventoryID !== tenantInfo?.TenantUserDetails?.DefaultShippingLineItemInventoryId
      )
        setIsEditing(values.Lines[linesSize - 1].InventoryID);
    }
  }, [editMode]);

  const handleSearchText = (searchTextInput: string) => {
    setSearchText(searchTextInput.toLocaleLowerCase());
  };

  const handleDelete = (id: number) => {
    const data = [...values.Lines];
    const index = data.findIndex(t => t.InventoryID === id);
    data[index].IsActive = false;
    setFieldValue('Lines', data);
    debounceCalculateLineTotal(data, '', Number(values.Discount), values.DiscountMode, values);
    if (data.length === 0) {
      addLineInventory();
    }
  };

  const calculateLineTotal = async (
    _Lines: Partial<ISalesOrderLine>[],
    keyUpdated?: string,
    discount: number = 0,
    discountMode: string = DISCOUNT_MODE.PERCENT,
    _values?: any,
  ) => {
    if (!Array.isArray(_Lines)) {
      return;
    }
    const Lines = _Lines
      .filter(
        inventory =>
          Boolean(inventory.Code) ||
          inventory.InventoryID === tenantInfo?.TenantUserDetails?.DefaultShippingLineItemInventoryId,
      )
      .map(
        inventory =>
          ({
            DeliveryMethod: DeliveryMethods.Deliver,
            InventoryID: inventory.InventoryID,
            Quantity: inventory.Quantity,
            ID: inventory.ID || 0,
            IsActive: inventory.IsActive,
            ...(inventory.HasPriceBeenEdited &&
            inventory.InventoryID !== tenantInfo?.TenantUserDetails?.DefaultShippingLineItemInventoryId
              ? {SellPriceEx: inventory.SellPriceEx, HasPriceBeenEdited: true}
              : {}),
            ...(inventory.HasPriceBeenEdited &&
            inventory.InventoryID === tenantInfo?.TenantUserDetails?.DefaultShippingLineItemInventoryId
              ? {SellPriceInc: inventory.SellPriceInc, HasPriceBeenEdited: true}
              : {}),
          }) as any,
      );

    const {Transaction, IsSuccess} = await addSalesOrderLineItem(0, {
      CustomerID: _values.CustomerID || tenantInfo?.TenantUserDetails?.DefaultCashSaleCustomerId,
      Lines: Lines,
      Discount:
        discountMode === DISCOUNT_MODE.PERCENT && discount && discount >= 1 ? discount / 100 || 0 : discount || 0,
      DiscountMode: discount ? discountMode : 'None',
    });
    if (IsSuccess && Transaction) {
      const newLines = Transaction?.Lines?.map(line1 => ({
        ...(Lines.find(line2 => line2.InventoryID === line1.InventoryID) || {}),
        ...omit(line1, keyUpdated || '', 'ID', 'LineID'),
      }));

      setValues({
        ..._values,
        Discount: discountMode === DISCOUNT_MODE.PERCENT ? Number(Transaction.DiscountString) : Number(discount) || '',
        DiscountString: Transaction.DiscountString,
        DiscountMode: discountMode,
        Lines: newLines as any,
        TotalEx: Transaction.TotalEx,
        TotalExString: Transaction.TotalExString,
        TotalInc: Transaction.TotalInc,
        TotalIncString: Transaction.TotalIncString,
        TotalTax: Transaction.TotalTax,
        TotalTaxString: Transaction.TotalTaxString,
      });
    }
  };

  const handleClearClick = (id: string) => {
    if (id) {
      const index = values.Lines.findIndex(li => li.InventoryID === id);
      const Lines = [...values.Lines];
      Lines[index].ShortDescription = '';
      Lines[index].SKU = '';
      Lines[index].Code = '';
      Lines[index].InventoryID = '';
      Lines[index].QuantityPicked = 0;
      Lines[index].LineTotalEx = 0;
      Lines[index].SellPriceEx = 0;
      Lines[index].SellPriceExString = PriceFormat(Lines[index].SellPriceEx);
      Lines[index].LineTotalExString = PriceFormat(Lines[index].LineTotalEx);
      Lines[index].Quantity = 0;
      if (
        Lines[index]?.InventoryID !== tenantInfo?.TenantUserDetails?.DefaultDiscountLineItemInventoryId &&
        Lines[index]?.InventoryID !== tenantInfo?.TenantUserDetails?.DefaultShippingLineItemInventoryId
      )
        setIsEditing(Lines[index].InventoryID);
      setFieldValue('Lines', Lines);
      debounceCalculateLineTotal(Lines, '', Number(values.Discount), values.DiscountMode, values);
    }
  };

  const addLineInventory = () => {
    if (!props?.errors?.Lines) {
      const newLineItem = {
        InventoryID: uniqueId('NewLineItem_'),
        Code: '',
        ShortDescription: '',
        SellPriceEx: 0,
        SellPriceExString: PriceFormat(0),
        Quantity: 0,
        SOH: 0,
        LineTotalEx: 0,
        LineTotalExString: PriceFormat(0),
        CostPriceEx: '',
        Weight_kg: '',
        Volume: '',
        UoM: '',
        Type: '',
      };
      const valuesLength = values?.Lines?.length || 0;
      const _activeIndex = valuesLength * (selectedColumns.length + 5);
      if (Array.isArray(values.Lines)) {
        setFieldValue('Lines', [...values.Lines, newLineItem]);
      } else {
        setFieldValue('Lines', [newLineItem]);
      }
      setEditMode(true);
      if (
        Number(newLineItem?.InventoryID) !== tenantInfo?.TenantUserDetails?.DefaultDiscountLineItemInventoryId &&
        Number(newLineItem?.InventoryID) !== tenantInfo?.TenantUserDetails?.DefaultShippingLineItemInventoryId
      ) {
        setIsEditing(newLineItem.InventoryID);
        setIsNavigating(true);
        setActiveIndex(_activeIndex);
      }
    }
  };

  const saveLineInventory = async (selected: Partial<Inventory> | null) => {
    const inventoryItem = {
      InventoryID: selected?.ID,
      Code: selected?.InventoryCode,
      ShortDescription: selected?.ShortDescription,
      Quantity: 1,
      DeliveryMethod: DeliveryMethods.Deliver,
    };

    if (Array.isArray(values.Lines)) {
      const index = values.Lines.findIndex(li => li.InventoryID === isEditing);
      const Lines = [...values.Lines];
      Lines[index] = inventoryItem;
      setFieldValue('Lines', Lines);
      const valuesLength = values?.Lines?.length || 0;
      const _activeIndex = (valuesLength - 1) * (selectedColumns.length + 5) + (selectedColumns.length + 2);
      debounceCalculateLineTotal(Lines, '', Number(values.Discount), values.DiscountMode, values);
      setEditMode(true);
      if (
        inventoryItem?.InventoryID !== tenantInfo?.TenantUserDetails?.DefaultDiscountLineItemInventoryId &&
        inventoryItem?.InventoryID !== tenantInfo?.TenantUserDetails?.DefaultShippingLineItemInventoryId
      ) {
        setIsEditing(inventoryItem.InventoryID);
        setIsNavigating(true);
        setActiveIndex(_activeIndex);
      }
    }
  };

  const handleSellPriceExChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (Array.isArray(values.Lines) && /^\d+(\.\d{0,2})?$|^$/.test(e.target.value)) {
      const index = values.Lines.findIndex(li => String(li.InventoryID) === String(isEditing));
      const Lines = [...values.Lines];
      Lines[index].SellPriceEx = PriceCalculator.roundNumber(parseFloat(e.target.value));
      Lines[index].HasPriceBeenEdited = true;
      setFieldValue('Lines', Lines);
      debounceCalculateLineTotal(Lines, 'SellPriceEx', Number(values.Discount), values.DiscountMode, values);
    }
  };

  const handleQuantityChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (Array.isArray(values.Lines)) {
      const index = values.Lines.findIndex(li => String(li.InventoryID) === String(isEditing));
      const Lines = [...values.Lines];
      const quantity = parseFloat(e.target.value) < 0 ? ('' as any) : parseFloat(e.target.value);
      Lines[index].Quantity = quantity;
      setFieldValue('Lines', Lines);
      debounceCalculateLineTotal(Lines, 'Quantity', Number(values.Discount), values.DiscountMode, values);
    }
  };

  const handleFreightChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (Array.isArray(values.Lines)) {
      const freightLineItemIndex = values.Lines.findIndex(
        li => li.InventoryID === tenantInfo?.TenantUserDetails?.DefaultShippingLineItemInventoryId,
      );
      const Lines = [...values.Lines];
      if (freightLineItemIndex >= 0 && Number(e.target.value)) {
        Lines[freightLineItemIndex].HasPriceBeenEdited = true;
        Lines[freightLineItemIndex].SellPriceInc = Number(e.target.value);
      } else if (freightLineItemIndex >= 0 && !Number(e.target.value)) {
        Lines[freightLineItemIndex].IsActive = false;
      } else if (freightLineItemIndex < 0 && Number(e.target.value)) {
        Lines.push({
          DeliveryMethod: DeliveryMethods.Deliver,
          InventoryID: tenantInfo?.TenantUserDetails?.DefaultShippingLineItemInventoryId,
          Quantity: 1,
          SellPriceInc: Number(e.target.value),
          HasPriceBeenEdited: true,
        });
      }

      setFieldValue('Lines', Lines);
      debounceCalculateLineTotal(Lines, '', Number(values.Discount), values.DiscountMode, values);
    }
  };

  const handleDiscountChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, type: DISCOUNT_MODE) => {
    const Lines = [...values.Lines].filter(
      inventory => inventory.InventoryID !== tenantInfo?.TenantUserDetails?.DefaultDiscountLineItemInventoryId,
    );
    if (type === DISCOUNT_MODE.PERCENT) {
      setValues({
        ...values,
        Lines: Lines,
        DiscountMode: DISCOUNT_MODE.PERCENT,
        Discount: e.target.value,
        DiscountString: e.target.value,
      });
      debounceCalculateLineTotal(Lines, '', Number(e.target.value), DISCOUNT_MODE.PERCENT, values);
    } else if (type === DISCOUNT_MODE.DOLLAR) {
      setValues({
        ...values,
        Lines: Lines,
        DiscountMode: DISCOUNT_MODE.DOLLAR,
        Discount: e.target.value,
      });
      debounceCalculateLineTotal(Lines, '', Number(e.target.value), DISCOUNT_MODE.DOLLAR, values);
    }
  };

  const handleColumnCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentSelected = [...selectedColumns];
    if (event.target.checked) {
      currentSelected.push(event.target.name);
    } else {
      const index = currentSelected.indexOf(event.target.name);
      if (index !== -1) {
        currentSelected.splice(index, 1);
      }
    }
    setSelectedColumns(currentSelected);
  };

  const handleGlobalClickAway = () => {
    setActiveIndex(-1);
    setIsNavigating(false);
    setIsEditing(null);
  };

  const handleClickAway = () => {
    handleSearchText('');
    searchInputPopup.close();
  };

  const handleSetActiveIndex = (x: number) => {
    setActiveIndex(x);
  };

  const handleKeyDownLastField = (
    e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
    rowData: ISalesOrderLine,
  ) => {
    if (e.key === 'Tab') {
      const {Lines} = values;
      const nextIndex = Lines.findIndex((item: any) => item.InventoryID === rowData.InventoryID) + 1;
      if (nextIndex >= Lines.length) {
        // If pressed enter on last line
        addLineInventory();
      } else {
        const nextItem = Lines[nextIndex];
        const _activeIndex = nextIndex * (selectedColumns.length + 5);
        setIsEditing(nextItem.InventoryID);
        setIsNavigating(true);
        setActiveIndex(_activeIndex);
      }
    }
  };

  const columns = useMemo(
    () =>
      getColumns(
        values.Lines,
        handleClearClick,
        saveLineInventory,
        handleQuantityChange,
        handleSellPriceExChange,
        handleSetActiveIndex,
        handleKeyDownLastField,
        selectedColumns,
      ),
    [values.Lines, selectedColumns, isEditing],
  );

  const rows = useMemo(
    () =>
      values.Lines?.filter(
        a =>
          a?.InventoryID !== tenantInfo?.TenantUserDetails?.DefaultDiscountLineItemInventoryId &&
          a?.InventoryID !== tenantInfo?.TenantUserDetails?.DefaultShippingLineItemInventoryId &&
          (a?.ShortDescription?.toLocaleLowerCase().includes(searchText) ||
            a?.Code?.toLocaleLowerCase()?.includes(searchText)),
      ) || [],
    [values.Lines, searchText],
  );

  const [numRows, numCols] = [rows.length, columns.length];

  const handleMouseDown = useCallback(
    // @ts-ignore
    e => {
      if (tableContainerRef.current && tableContainerRef.current.contains(e.target)) {
        if (e.target.className.includes && e.target.className.includes('cell-input') && editMode) {
          setActiveIndex(Number(e.target.dataset.index));
          setIsNavigating(true);
          setIsEditing(e.target.dataset.id);
        }
      } else {
        setIsNavigating(false);
      }
    },
    [tableContainerRef, setIsNavigating, editMode],
  );

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (isNavigating) {
        const {key} = e;
        switch (key) {
          case 'ArrowUp':
            // Move up a row, subtract num cols from index
            if (!isEditing && activeIndex >= numRows) setActiveIndex(activeIndex - numCols);
            break;
          case 'ArrowDown':
            // Move down a row, add num cols to index
            if (!isEditing && activeIndex < numRows * numCols - numCols) setActiveIndex(activeIndex + numCols);
            break;
          case 'ArrowRight':
            // Move one col right, add one
            if (!isEditing && activeIndex < numRows * numCols - 1) setActiveIndex(activeIndex + 1);
            break;
          case 'ArrowLeft':
            // Move one col left, subtract one
            if (!isEditing && activeIndex > 0) setActiveIndex(activeIndex - 1);
            break;
          case 'Enter':
            if (isEditing) setIsEditing(null);
            // @ts-ignore
            else if (isNavigating) setIsEditing(e.target.dataset.id);
            else if (!isEditing) setIsNavigating(true);
            break;
          case 'Escape':
            // Stop navigating
            if (isEditing) setIsEditing(false);
            else if (isNavigating) setIsNavigating(false);
            break;
          default:
            break;
        }
      }
    },
    [activeIndex, isNavigating, isEditing, numRows, numCols],
  );

  useEffect(() => {
    window.addEventListener('mousedown', handleMouseDown);
    // @ts-ignore
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('mousedown', handleMouseDown);
      // @ts-ignore
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleMouseDown, handleKeyDown]);

  const onIndexChange = useCallback(() => {
    if (activeIndex >= 0 && activeIndex < numRows * numCols) {
      const inputRef = inputRefs.current[activeIndex];

      if (inputRef) {
        if (isEditing) {
          inputRef?.focus && inputRef.focus();
          inputRef?.select && inputRef.select();
        } else {
          inputRef?.blur && inputRef.blur();
        }
      }
    }
  }, [activeIndex, isEditing, inputRefs, numRows, numCols]);

  useEffect(onIndexChange, [activeIndex, onIndexChange]);

  const onIsNavigatingChange = useCallback(() => {
    if (!isNavigating) {
      // setActiveIndex(-1);
    } else if (activeIndex < 0 && editMode) {
      // setActiveIndex(0);
    }
  }, [isNavigating, setActiveIndex, activeIndex]);

  useEffect(onIsNavigatingChange, [isNavigating, onIsNavigatingChange]);

  const subTotal = useMemo(
    () =>
      values.Lines?.reduce((acc, _line) => {
        if (
          _line?.InventoryID !== tenantInfo?.TenantUserDetails?.DefaultDiscountLineItemInventoryId &&
          _line?.InventoryID !== tenantInfo?.TenantUserDetails?.DefaultShippingLineItemInventoryId
        ) {
          return acc + (_line?.LineTotalEx || 0);
        }
        return acc;
      }, 0),
    [values.Lines],
  );

  const taxAmount = values.TotalTax;

  const shippingInc = useMemo(
    () =>
      values.Lines?.find(
        _line => _line?.InventoryID === tenantInfo?.TenantUserDetails?.DefaultShippingLineItemInventoryId,
      )?.SellPriceInc || 0,
    [values.Lines],
  );

  return (
    <div
      className={`${classes.root} relative mt-2 h-full w-full overflow-y-hidden rounded-lg bg-white px-2.5 pt-4 font-poppins `}
    >
      <div className={`relative h-full w-full flex-1 overflow-y-auto pb-14`} id="create-sales-orders-details">
        <LoadingIndicator isLoading={isLoading} size="sm" />
        <div className="flex-column mb-2 flex items-center justify-between">
          <p className="my-1 text-lg font-medium text-spenda-primarytext">Order Details</p>
          {!values.Status ||
          (values?.Status === SALES_ORDER_STATUS.OPEN &&
            values?.Reservation?.Status !== SalesOrderReservationStatus.Active) ? (
            <div className="flex-column flex">
              {!editMode ? (
                <IconButton
                  name="EditOrderLines"
                  className="mr-1 focus:ring-0"
                  variant="outlined"
                  onClick={() => {
                    setEditMode(true);
                    if (
                      values.Lines &&
                      Array.isArray(values.Lines) &&
                      values.Lines.length &&
                      values.Lines[0]?.InventoryID !==
                        tenantInfo?.TenantUserDetails?.DefaultDiscountLineItemInventoryId &&
                      values.Lines[0]?.InventoryID !== tenantInfo?.TenantUserDetails?.DefaultShippingLineItemInventoryId
                    ) {
                      setIsEditing(values.Lines[0].InventoryID);
                    }
                  }}
                >
                  <IconDocumentEdit />
                </IconButton>
              ) : null}
              <ClickAwayListener onClickAway={handleClickAway}>
                <div className="mr-1">
                  <SearchInput
                    placeholder="Search Items, code"
                    value={searchText}
                    onChange={e => handleSearchText(e.target.value)}
                    autoFocus
                    reverse
                  />
                </div>
              </ClickAwayListener>
              <Menu
                placement="bottom-end"
                dismiss={{
                  itemPress: false,
                }}
              >
                <MenuHandler>
                  <Button variant="outlined" className="mr-1 focus:ring-0">
                    Columns Settings
                  </Button>
                </MenuHandler>
                <MenuList className="flex flex-col border border-primary p-0">
                  {Object.entries(columnArray).map(([key, value]: [string, any]) => {
                    return (
                      <div
                        key={key}
                        className="flex items-center gap-x-2 px-2.5 py-0 outline-none first:pt-1 last:pb-1 hover:!bg-primary/10 active:!bg-primary/10"
                      >
                        <Checkbox
                          id={camelCase(value)}
                          name={value}
                          ripple={false}
                          color="primary"
                          checked={selectedColumns.includes(value)}
                          onChange={handleColumnCheckbox}
                          containerProps={{className: 'p-0'}}
                          disabled={Boolean(value.disabled)}
                          className="border-primary bg-white before:h-5 before:w-5 checked:border-none checked:bg-primary hover:before:opacity-0"
                          label={value}
                          labelProps={{className: 'px-3 py-2 text-black-800 font-semibold text-base'}}
                        />
                      </div>
                    );
                  })}
                </MenuList>
              </Menu>
            </div>
          ) : null}
        </div>
        <ClickAwayListener onClickAway={handleGlobalClickAway}>
          <div className="mb-5 flex flex-col" ref={tableContainerRef}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  {columns.map(column => (
                    <TableCell key={column.title} align={column.align} width={column.width || ''}>
                      {column.title}
                    </TableCell>
                  ))}
                  {editMode ? <TableCell key="Delete" align="center" width={10} /> : null}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, rIdx) => {
                  return (
                    <TableRow tabIndex={-1} key={row.InventoryID}>
                      {columns.map((column, cIdx) => {
                        let value = column.formatter(row);
                        const index = rIdx * numCols + cIdx;
                        const editing = String(isEditing) === String(row.InventoryID) && column.editable;
                        if (editing && typeof column.editor === 'function') {
                          value = column.editor(row, index, inputRefs);
                        }
                        return (
                          <TableCell
                            key={column.title}
                            data-index={index}
                            data-id={row.InventoryID}
                            className={clsx({
                              active: activeIndex === index,
                              'cell-input': column.editable,
                            })}
                            align={column.align}
                            style={{color: !value && !editing ? '#999999' : '#333333'}}
                          >
                            {value || column.title}
                          </TableCell>
                        );
                      })}
                      {editMode &&
                      row?.InventoryID !== tenantInfo?.TenantUserDetails?.DefaultDiscountLineItemInventoryId &&
                      row?.InventoryID !== tenantInfo?.TenantUserDetails?.DefaultShippingLineItemInventoryId ? (
                        <TableCell align="center">
                          <IconButton
                            variant="text"
                            className="active:bg-transparent"
                            onClick={() => handleDelete(row.InventoryID)}
                            data-autoid={`btnDeleteOrderLine${rIdx}`}
                          >
                            <WidgetDeleteLogo />
                          </IconButton>
                        </TableCell>
                      ) : null}
                    </TableRow>
                  );
                })}
                {(!values.Status || values?.Status === SALES_ORDER_STATUS.OPEN) && editMode ? (
                  <TableRow id="edit">
                    <TableCell align="left" style={{borderBottom: 'none'}}>
                      <Button
                        variant="text"
                        ripple={false}
                        disabled={isLoading}
                        onClick={addLineInventory}
                        className="hover:no-underline focus:bg-transparent active:bg-transparent"
                      >
                        Add New Line
                      </Button>
                    </TableCell>
                    {Array(columns.length).fill(
                      <TableCell style={{backgroundColor: 'transparent', borderBottom: 'none'}} />,
                    )}
                  </TableRow>
                ) : null}
                <TableRow id="subtotal">
                  {Array(columns.length - 2).fill(
                    <TableCell style={{backgroundColor: 'transparent', borderBottom: 'none'}} />,
                  )}
                  <TableCell align="right">Subtotal</TableCell>
                  <TableCell align="right">{PriceFormat(subTotal)}</TableCell>
                  {editMode ? <TableCell /> : null}
                </TableRow>
                <TableRow id="discount">
                  {Array(columns.length - 2).fill(
                    <TableCell style={{backgroundColor: 'transparent', borderBottom: 'none'}} />,
                  )}
                  <TableCell align="right">Discount (Inc)</TableCell>
                  <TableCell align="right" style={{padding: values.ID && !editMode ? '0 8px' : 0, height: 'inherit'}}>
                    <div className="flex h-full flex-row">
                      {editMode ? (
                        <StyledInputBase
                          fullWidth
                          type="number"
                          id="Discount"
                          name="Discount"
                          value={
                            values.DiscountMode === DISCOUNT_MODE.PERCENT
                              ? values.DiscountString
                              : PriceCalculator.roundNumber((Number(values.Discount) / (subTotal + taxAmount)) * 100)
                          }
                          onChange={e => {
                            if (
                              !isLoading &&
                              Number(e.target.value) >= 0 &&
                              Number(e.target.value) <= 100 &&
                              /^\d+(\.\d{0,2})?$|^$/.test(e.target.value)
                            ) {
                              handleDiscountChange(e, DISCOUNT_MODE.PERCENT);
                            }
                          }}
                          placeholder="0"
                          inputProps={{
                            pattern: '[0-9]',
                            step: 1,
                            'data-autoid': 'txtDiscountPercent',
                            style: {textAlign: 'center'},
                          }}
                          endAdornment={<InputAdornment position="end">%</InputAdornment>}
                          style={{border: '1px solid #707070', paddingRight: '4px', marginRight: '2px'}}
                          className="input-bordered"
                        />
                      ) : values.DiscountMode === DISCOUNT_MODE.PERCENT ? (
                        <span className="my-auto w-full">{values.DiscountString || 0}%</span>
                      ) : (
                        <span className="my-auto w-full">
                          {PriceCalculator.roundNumber((Number(values.Discount) / (subTotal + taxAmount)) * 100)}%
                        </span>
                      )}
                      {editMode ? (
                        <StyledInputBase
                          fullWidth
                          type="number"
                          id="Discount"
                          name="Discount"
                          value={
                            values.DiscountMode === DISCOUNT_MODE.DOLLAR
                              ? values.Discount
                              : PriceCalculator.roundNumber(
                                  (Number(values.DiscountString) * (subTotal + taxAmount)) / 100,
                                )
                          }
                          onChange={e => {
                            if (
                              !isLoading &&
                              Number(e.target.value) >= 0 &&
                              Number(e.target.value) <= subTotal &&
                              /^\d+(\.\d{0,2})?$|^$/.test(e.target.value)
                            ) {
                              handleDiscountChange(e, DISCOUNT_MODE.DOLLAR);
                            }
                          }}
                          placeholder="0"
                          inputProps={{
                            pattern: '[0-9]',
                            step: 1,
                            'data-autoid': 'txtDiscountAmount',
                            style: {textAlign: 'center'},
                          }}
                          startAdornment={<InputAdornment position="start">$</InputAdornment>}
                          style={{border: '1px solid #707070', paddingLeft: '4px'}}
                          className="input-bordered"
                          onBlur={e => {
                            const num = parseFloat(e.target.value) || 0;
                            e.target.value = num.toFixed(2);
                            handleDiscountChange(e, DISCOUNT_MODE.DOLLAR);
                          }}
                        />
                      ) : values.DiscountMode === DISCOUNT_MODE.DOLLAR ? (
                        <span className="my-auto w-full">{PriceFormat(Number(values.Discount) ?? 0)}</span>
                      ) : (
                        <span className="my-auto w-full">
                          {PriceFormat((Number(values.DiscountString) * (subTotal + taxAmount)) / 100)}
                        </span>
                      )}
                    </div>
                  </TableCell>
                  {editMode ? <TableCell /> : null}
                </TableRow>
                <TableRow id="shipping">
                  {Array(columns.length - 2).fill(
                    <TableCell style={{backgroundColor: 'transparent', borderBottom: 'none'}} />,
                  )}
                  <TableCell align="right">Shipping (Inc)</TableCell>
                  <TableCell align="right" style={{padding: '0', height: 'inherit'}}>
                    <div className="flex h-full flex-row">
                      <div
                        className="w-full"
                        style={{paddingLeft: '4px', border: '1px solid transparent', marginRight: '2px'}}
                      />
                      {editMode ? (
                        <StyledInputBase
                          fullWidth
                          type="number"
                          id="FreightTotalEx"
                          name="FreightTotalEx"
                          value={shippingInc || ''}
                          onChange={e => {
                            if (!isLoading && Number(e.target.value) >= 0 && !/[^0-9]+/.test(e.target.value))
                              handleFreightChange(e);
                          }}
                          placeholder="0"
                          inputProps={{
                            pattern: '[0-9]',
                            step: 1,
                            'data-autoid': 'txtFreightTotalEx',
                            style: {textAlign: 'center'},
                          }}
                          startAdornment={<InputAdornment position="start">$</InputAdornment>}
                          style={{
                            border: '1px solid #707070',
                            paddingLeft: '4px',
                          }}
                          className="input-bordered"
                          onBlur={e => {
                            const num = parseFloat(e.target.value) || 0;
                            e.target.value = num.toFixed(2);
                            handleFreightChange(e);
                          }}
                        />
                      ) : (
                        <span className="my-auto" style={{paddingRight: '8px'}}>
                          {PriceFormat(shippingInc || 0)}
                        </span>
                      )}
                    </div>
                  </TableCell>
                  {editMode ? <TableCell /> : null}
                </TableRow>
                <TableRow id="tax">
                  {Array(columns.length - 2).fill(
                    <TableCell style={{backgroundColor: 'transparent', borderBottom: 'none'}} />,
                  )}
                  <TableCell align="right">Tax</TableCell>
                  <TableCell align="right">{PriceFormat(taxAmount)}</TableCell>
                  {editMode ? <TableCell /> : null}
                </TableRow>
                <TableRow id="total">
                  {Array(columns.length - 2).fill(
                    <TableCell style={{backgroundColor: 'transparent', borderBottom: 'none'}} />,
                  )}
                  <TableCell align="right">Total</TableCell>
                  <TableCell align="right">{PriceFormat(values.TotalInc)}</TableCell>
                  {editMode ? <TableCell /> : null}
                </TableRow>
              </TableBody>
            </Table>
            {props?.lineItemsError && values?.Lines?.[0]?.Code === '' ? (
              <div className="w-max text-sm text-error">
                <p>Add at least one product</p>
              </div>
            ) : null}
            {props?.errors?.Lines ? (
              <div className="w-max text-sm text-error">
                <p>Please provide all order details</p>
              </div>
            ) : null}
          </div>
        </ClickAwayListener>
      </div>
    </div>
  );
};
