import { IUser } from '../model/user/User';
import { StorageKeys, TwoStepAuthType } from "../model/constants/Constants";
import useStorage from "../hooks/useStorage";
import useHttp from '../hooks/useHttp';
import { useAuth0 } from "@auth0/auth0-react";


export const useAuth = () => {

    const {
        logout: logoutAuth0
      } = useAuth0();

    const { POST } = useHttp();
    const { setItem, } = useStorage();
    
    const setToken = (user: any) => {
        return setItem(StorageKeys.User, user);
    }
    
    const managePreferences = (autoLogin: boolean) => {
        setItem(StorageKeys.AutoLogin, autoLogin);
    }
    
    const fixBoolsOnObject = (obj: any) => {
        for (let prop in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, prop)) {
                if (obj[prop] === "True" || obj[prop] === "False") {
                    obj[prop] = (obj[prop] === "True")
                }
            }
        }
    }
    
    const fixNumbersOnUser = (user: IUser) => {
        // These fields come in as strings, want to type them as numbers
        user.UserID = parseInt(user.UserID as any)
        user.WebsiteID = parseInt(user.WebsiteID as any)
        user.TenantID = parseInt(user.TenantID as any)
    }
    
    const stringToOptionsArray = (value: string | undefined): TwoStepAuthType[] => {
        if (!value) {
            return []
        }
        let split = value.split(",");
        // Assume the number we've been given is in our enum. +item will convert string to number
        return split.map(item => +item);
    }
    
    const login = (username: string, password: string, autoLogin: boolean, code?: string): Promise<any> => {
        let result: any = {};
    
        let payload = "grant_type=password&username=" + encodeURIComponent(username) +
            "&password=" + encodeURIComponent(password) +
            "&client_id=13";
        
        if (code) {
            payload += "&code=" + code;
        }

        managePreferences(autoLogin);
    
        return POST("login", payload, false).then((response: any) => {
            result.IsSuccess = true;
            fixBoolsOnObject(response);
            fixNumbersOnUser(response);
            response.OptionsFor2SA = stringToOptionsArray(response.OptionsFor2SA)
    
            if (response && response.access_token) {
                result.Value = response;
            }
            return result;
        }).catch((error: any) => {
            result.IsSuccess = false;
            result.Messages = [error];
            return result;
        });
    }
    
    const logout = () => {
        logoutAuth0({
            returnTo: window.location.origin,
        })
        return Promise.resolve('success')
    };

    return {
        login,
        setToken,
        logout
    }
}


