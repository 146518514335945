import React, {useState} from 'react';
import {RouteProps} from 'react-router-dom';
import {HeaderDialogs} from '../menu/HeaderDialogs';
import {useFeatureFlags} from '../../hooks/useFeatureFlags';
import {ITenant, LogoTypeEnum} from '../../model/Tenant';
import {Header} from '../menu/MenuHeader';
import {IUser} from '../../model/user/User';

export interface IModuleInfoHearProps extends RouteProps {
  tenant: ITenant;
  user: IUser;
  isPsblOnboarding?: boolean;
}

export const ModuleInfoHeader = (props: IModuleInfoHearProps) => {
  const {tenant, user, isPsblOnboarding} = props;
  const [userAnchorEl, setUserAnchorEl] = useState<null | HTMLElement>(null);
  const [settingsAnchorEl, setSettingsAnchorEl] = useState<null | HTMLElement>(null);
  const [notificationsAnchorEl, setNotificationsAnchorEl] = useState<null | HTMLElement>(null);
  const supplierIcon = tenant?.Logos?.find(sI => sI.LogoTypeID.toString() === LogoTypeEnum.Logo);
  const {paymentAuth72488} = useFeatureFlags().tenantOwned();

  return (
    <>
      <HeaderDialogs
        userAnchorEl={userAnchorEl}
        settingsAnchorEl={settingsAnchorEl}
        notificationsAnchorEl={notificationsAnchorEl}
        setUserAnchorEl={setUserAnchorEl}
        setSettingsAnchorEl={setSettingsAnchorEl}
        setNotificationsAnchorEl={setNotificationsAnchorEl}
        paymentAuth72488={paymentAuth72488}
      />
      <Header
        supplierIcon={supplierIcon}
        user={user}
        tenant={tenant}
        setUserAnchorEl={setUserAnchorEl}
        setSettingsAnchorEl={setSettingsAnchorEl}
        setNotificationsAnchorEl={setNotificationsAnchorEl}
        paymentAuth72488={paymentAuth72488}
        isAPOnboardingV1Header
        isPsblOnboarding={isPsblOnboarding}
      />
    </>
  );
};
