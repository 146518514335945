import React, {useEffect} from 'react';
import ThemeContext from './ThemeContext';
// import ThemeContextReducer from './ThemeContextReducer';
// import {IThemeColors, IThemeLogo} from '../../model/theme/Theme';
// import ThemeContextActions from './ThemeContextActions';
import environment from '../../utils/environment';

interface IThemeContextProviderProps {
  children: React.ReactNode;
}
export const ThemeContextProvider = (props: IThemeContextProviderProps) => {
  // const initialState: IThemeContextState = {
  //   logo: undefined,
  //   rootClassName: '',
  // };

  const {THEME: theme} = environment;

  useEffect(() => {
    if (environment.THEME?.rootClassName) {
      document.body.className = environment.THEME?.rootClassName;
    }
  }, [environment.THEME]);

  // const [state, dispatch] = useReducer(ThemeContextReducer, initialState);

  // const updateCssVaribles = (colors: IThemeColors | undefined) => {
  //   const root = document.documentElement;
  //   const variables = {
  //     '--primary': colors?.primary || '202 72% 39%',
  //     '--primary-hover': colors?.primaryHover || '208 72% 32%',
  //     '--success': '156 45% 43%',
  //     '--success-hover': '159 69% 24%',
  //     '--error': '12 53% 52%',
  //     '--error-hover': '12 49% 41%',
  //     '--warning': '39 78% 44%',
  //     '--cool-purple': '246 48% 64%',
  //   };

  //   Object.entries(variables).forEach(([key, value]) => {
  //     root?.style.setProperty(key, value);
  //   });
  // };

  // const setLogos = (logos: IThemeLogo[]) => {
  //   dispatch({
  //     type: ThemeContextActions.SET_LOGOS,
  //     payload: logos,
  //   });
  // };

  // const setColors = (colors: IThemeColors | undefined) => {
  //   updateCssVaribles(colors);
  //   dispatch({
  //     type: ThemeContextActions.SET_COLORS,
  //     payload: colors,
  //   });
  // };

  return (
    <ThemeContext.Provider
      value={{
        rootClassName: theme?.rootClassName || '',
        logo: theme?.logo || undefined,
      }}
    >
      {props.children}
    </ThemeContext.Provider>
  );
};
