import React, {useEffect, useMemo, useState} from 'react';
import {debounce} from 'lodash';
import {Option, SearchDropdownInput, SearchInput, Spinner} from 'spenda-ui-react';

export interface IARSearchBarSearchCaterogies {
  label: string;
  value: string;
}

export interface IARSearchBarSearch {
  searchString: string;
  searchCategory?: string;
}

interface ISearchBarProps {
  searchString: string;
  searchCategory?: string;
  isSearching: boolean;
  dataAutoIdSuffix: string;
  searchCategories?: IARSearchBarSearchCaterogies[];
  placeholder?: string;
  reverse?: boolean;
  type?: 'simple' | 'advance';
  onSubmit: (values: IARSearchBarSearch) => void;
}

export const ARSearchBar = (props: ISearchBarProps) => {
  // Props
  const {searchString, searchCategory, isSearching, dataAutoIdSuffix, searchCategories, onSubmit, reverse, type} =
    props;

  // States
  const [searchKey, setSearchKey] = useState('');
  const [searchCat, setSearchCat] = useState<string | undefined>('');

  useEffect(() => {
    setSearchCat(searchCategory);
    setSearchKey(searchString);
  }, [searchString, searchCategory]);

  useEffect(() => {
    return () => {
      debounceSearch.cancel();
    };
  }, []);

  // Functions

  const onSearchCategoryChange = (sc: string) => {
    setSearchCat(sc);
    if (!searchKey) return;
    onSubmit({searchString: searchKey, searchCategory: sc});
  };

  const handleSearch = (searchString: string) => onSubmit({searchString: searchString, searchCategory: searchCat});
  const debounceSearch = useMemo(() => debounce(handleSearch, 750), [searchCat]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = e?.target;
    debounceSearch(value);
    setSearchKey(value);
  };

  const options = searchCategories?.map(s => (
    <Option key={s?.value} value={s?.value}>
      {s?.label}
    </Option>
  ));

  return (
    <div className="search-container !relative">
      {type === 'advance' ? (
        <SearchDropdownInput
          reverse={reverse ? reverse : false}
          value={searchKey}
          type="text"
          name="searchString"
          id="search-text-input"
          onChange={handleSearchChange}
          autoComplete="off"
          options={options}
          data-autoid={`txtSearchInput${dataAutoIdSuffix}`}
          inputProps={{
            InputProp: {endAdornment: isSearching ? <Spinner color="primary" /> : ''},
            adornmentContainerClass: 'border-none',
          }}
          placeholder={
            searchCategories?.length ? `Search ${searchCat?.replace(/([a-z0-9])([A-Z])/g, '$1 $2')}` : 'Search'
          }
          selectProps={{
            className: 'min-w-[100px] border-none',
            children: '',
            onChange: e => onSearchCategoryChange(e as string),
            name: `searchInput${dataAutoIdSuffix}`,
            value: searchCat,
          }}
          iconButtonProps={{'data-autoid': 'btnSearchInput'}}
        />
      ) : (
        <SearchInput
          reverse={reverse ? reverse : false}
          value={searchKey}
          type="text"
          name="searchString"
          id="search-text-input"
          onChange={handleSearchChange}
          autoComplete="off"
          data-autoid={`txtSearchInput${dataAutoIdSuffix}`}
          placeholder={
            searchCategories?.length ? `Search ${searchCategory?.replace(/([a-z0-9])([A-Z])/g, '$1 $2')}` : 'Search'
          }
          iconButtonProps={{
            'data-autoid': `btnSearchInput`,
          }}
        />
      )}
    </div>
  );
};
