import React, { useState } from 'react'
import { AlertDialogSlideV2 } from '../dialog/AlertDialogSlideV2';
import { Button, Textarea, Typography } from 'spenda-ui-react';
import { IconPencil } from '../../assets/svg/quotes-management/IconPencil';

interface IClaimsInternalNotesDialog {
    handleClose: () => void;
    handleDone: () => void
    externalNotes?: boolean;
    isLoading?: boolean
}

export const ClaimsInternalNotesDialog = (props: IClaimsInternalNotesDialog) => {
    const { handleClose, handleDone, externalNotes, isLoading } = props;

    const [isEditNote, setIsEditNote] = useState<boolean>(false)
    return (
        <AlertDialogSlideV2
            title={'Notes history'}
            headingTextSize="h2"
            headingClassess="text-black justify-center"
            footerClass="justify-between sm:w-auto w-full"
            dialogClassess="!min-w-[20%] !max-w-[940px] !w-full sm:h-auto h-full sm:block flex justify-center items-center flex-col sm:p-0 p-8"
            dialogBodyClassess="flex justify-center px-0"
            data-autoid={'dlgAddInternalNote'}
            actions={[
                {
                    label: 'Cancel',
                    variant: 'outlined',
                    action: handleClose,
                },
                {
                    label: 'Done',
                    loading: isLoading,
                    action: handleDone,
                },
            ]}
        ><div className='w-full'>
                <div className="gap-5 flex justify-between items-end w-full pt-3 px-10 mb-6">
                    <div className='w-full'>
                        <Typography variant='h3' className='text-spenda-greytext mb-4'>Add internal note</Typography>
                        <Textarea
                            label="Add new note"
                            displayLength
                            maxLength={500}
                            data-autoid={'txtInternalNote'}
                        />
                    </div>
                    <div><Button className='min-w-[112px] px-2 mb-[7px]' variant='outlined'>Save Note</Button></div>
                </div>
                <hr className='mb-7 border-[#ececec]' />
                <div className="gap-5 flex justify-between items-start w-full px-10 max-h-[300px] overflow-auto">
                    <div className='w-full'>
                        <Typography variant='h3' className='text-spenda-greytext mb-4'>Note history</Typography>
                        {[1].map((index) => {
                            return (
                                <div key={index} className={`${isEditNote ? 'pt-3.5 pb-5' : 'py-3.5'} w-full flex justify-between items-start px-5 bg-primary/5 rounded-lg mb-4`}>
                                    <div className='text-black-800 w-3/4 gap-y-2 flex flex-col'>
                                        {isEditNote ? <Textarea
                                            label="Edit internal note"
                                            displayLength
                                            maxLength={500}
                                            data-autoid={'txtInternalNote'}
                                        /> : <>
                                            {externalNotes && <Typography variant='small'><span className='font-medium'>Line item:</span>  Part 0982</Typography>}
                                            <Typography variant='small'><span className='font-medium'>{externalNotes ? ' External note:' : 'Internal note:'}</span> This is my sample note text 1 and this is my sample note text 2</Typography>
                                            <div className='flex items-center'>
                                                <Typography variant='small'><span className='font-medium'>Date created:</span> 12 oct 2024</Typography>
                                                <div className='w-[2px] h-3 bg-black-800 mx-10'></div>
                                                <Typography variant='small'><span className='font-medium'>Created by:</span> Abhishek Kapoor</Typography>
                                            </div>
                                            <Typography variant='xsmall' className='text-spenda-labeltext'>Modified by <span className='font-medium'>Shweta Arora</span> on 15 oct 2024</Typography></>
                                        }
                                    </div>
                                    <div className='cursor-pointer'>
                                        <Button ripple={false} color="primary" variant="text" className='flex justify-start items-start px-0 text-sm' onClick={() => isEditNote ? setIsEditNote(false) : setIsEditNote(true)}>
                                            {isEditNote ? 'Save' : <div className='flex justify-start items-center gap-1'>
                                                <IconPencil />
                                                <Typography variant='small' className='font-semibold text-primary'>Edit</Typography>
                                            </div>}
                                        </Button>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className='min-w-[112px]'></div>
                </div>
            </div>
        </AlertDialogSlideV2>
    )
}

export default ClaimsInternalNotesDialog