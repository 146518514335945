import React, {useCallback, useState} from 'react';
import {useFormikContext} from 'formik';
import {Typography} from 'spenda-ui-react';
import {debounce} from 'lodash';

import QuoteSmartSearch from './QuoteSmartSearch';
import {useInventoryAPI} from '../../services/useInventoryAPI';
import {IPagedActionResults} from '../../model/ActionResults';
import {Inventory} from '../../model/inventory/Inventory';
import {IQuoteLines, IQuotes} from '../../model/quotes/quotes';
import GreenCheckIcon from '../../assets/svg/accounts-payable/GreenCheckIcon';
import {DISCOUNT_MODE} from '../../model/constants/Constants';
import {IServiceJob, IServiceJobLines} from '../../model/service-management/serviceJob';

interface QuoteInventoryAutocompleteProps<T> {
  by: 'product' | 'code';
  index: number;
  alreadyFilled: number[];
  calculateLineTotal: (
    Lines: Partial<IQuoteLines | IServiceJobLines>[],
    _values: T,
    shipping?: number | string,
    rowIndex?: number,
    discount?: string,
    discountMode?: DISCOUNT_MODE,
  ) => void;
  disabled?: boolean;
}

const QuoteInventoryAutocomplete = <T extends IQuotes | IServiceJob>(
  props: QuoteInventoryAutocompleteProps<T>,
): JSX.Element => {
  const [results, setResults] = useState<Partial<Inventory>[]>();
  const {listInventory, listInventoryByCode, isLoading} = useInventoryAPI();

  const {values, setFieldValue} = useFormikContext<T>();

  const debouncedSave = useCallback(
    debounce((newValue: string) => {
      getInventoryList(newValue);
    }, 750),
    [],
  );

  const saveLineInventory = async (selected: Partial<Inventory> | null, _selection: any) => {
    if (Array.isArray(values.lines)) {
      const _lines = values.lines.map((_line, _index) => {
        if (_index === props.index) {
          return {
            ..._line,
            inventoryID: selected?.ID,
            code: selected?.InventoryCode,
            shortDescription: selected?.ShortDescription,
            quantity: 1,
            uoM: selected?.UoM,
            description: selected?.Description,
            sellPriceEx: +selected?.StandardSellPriceEx?.toFixed(2)!,
            lineTotalEx: +selected?.StandardSellPriceEx?.toFixed(2)!,
            costPriceEx: +selected?.CostPriceEx?.toFixed(2)!,
          };
        }
        return _line;
      });
      setFieldValue('lines', _lines);
      props.calculateLineTotal(
        _lines,
        values,
        values.shipping,
        props.index,
        values.discountString,
        values.discountMode,
      );
    }
  };

  const handleChange: ChangeEventHandler = e => {
    if (!e.target.value.trim()) return setResults([]);
    if (props.by === 'product') setFieldValue(`lines[${props.index}].product`, e.target.value);
    else setFieldValue(`lines[${props.index}].code`, e.target.value);
    if (e.target.value.length < 3) {
      return;
    }
    debouncedSave(e.target.value);
  };

  const getInventoryList = (query?: string) => {
    if (props.by === 'product') {
      listInventory({
        MaxResults: 100,
        ViewMode: 'Sales',
        IsSystem: false,
        ...(query ? {Search: query} : {}),
      }).then((res: IPagedActionResults<Inventory[]>) => {
        setResults(res?.Value ?? []);
      });
    } else {
      listInventoryByCode({
        MaxResults: 100,
        StartRow: 1,
        IsSystem: false,
        ...(query ? {SearchString: query} : {}),
      }).then((res: IPagedActionResults<Inventory[]>) => {
        setResults(res?.Value ?? []);
      });
    }
  };

  const handleSuggestionSelected = (item: Partial<Inventory>) => {
    setResults([item]);
    const selectedSuggestion = {
      suggestion: item,
      suggestionValue: `${item.InventoryCode} ${item.ShortDescription}`,
      suggestionIndex: props.index,
      sectionIndex: 0,
    };
    if (props.alreadyFilled && props.alreadyFilled.length) {
      if (!props.alreadyFilled.some((_m: number) => _m === item.ID)) {
        saveLineInventory(item, selectedSuggestion);
      }else{
        return false;
      }
    } else {
      saveLineInventory(item, selectedSuggestion);
    }
    return true;
  };

  const resultShow = (item: Partial<Inventory>) => {
    const isSelected = props.alreadyFilled && props.alreadyFilled.some((_m: number) => _m === item.ID);
    return (
      <Typography variant="xsmall" data-autoid={`ddlItem-${item.InventoryCode}`}>
        <span className="flex flex-row items-center justify-between">
          <span>
            <b>{item?.InventoryCode}</b> {item?.ShortDescription}
          </span>
          {isSelected && <GreenCheckIcon className="w-min-[18px] ml-1" width="18px" height={'18px'} />}
        </span>
      </Typography>
    );
  };

  return (
    <QuoteSmartSearch
      key={props.by + props.index}
      results={results}
      placeholder={props.by === 'product' ? 'Search by name or code' : 'Search by code'}
      value={props.by === 'product' ? values?.lines[props.index]?.shortDescription : values?.lines[props.index]?.code}
      renderItem={resultShow}
      byCode={props.by === 'code'}
      onChange={handleChange}
      isLoading={isLoading}
      onSelect={handleSuggestionSelected}
      getInventoryList={getInventoryList}
      index={props.index}
      disabled={props.disabled}
    />
  );
};
export default QuoteInventoryAutocomplete;
