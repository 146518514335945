import * as React from 'react';
const BackIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="12" fill="none" {...props}>
    <path
      fill="#1C78AD"
      fillRule="evenodd"
      d="M12.17 5.084H3.003l3.016-3.016A.917.917 0 1 0 4.726.767L.794 4.708a1.833 1.833 0 0 0-.541 1.293c.004.482.199.943.54 1.283l3.933 3.942A.917.917 0 1 0 6.02 9.924L3.003 6.917h9.167a.917.917 0 1 0 0-1.833Z"
      clipRule="evenodd"
    />
  </svg>
);
export default BackIcon;
