import React, {useContext, useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import {ArRefreshBtn} from '../arRefresh/ArRefresh';
import {ConversationType, DatTypes, StartConvoType} from '../../model/constants/Constants';
import {IAddress} from '../../model/address/Address';
import {PriceFormat, toFixedWithoutRounding, NameInitials} from '../../utils/formatter';
import {useTenantInfo} from '../../hooks/useTenantInfo';
import {AUTH_SELLING_COLLECT_ROUTE} from '../../routes/AuthenticatedRoutes';
import StartConvo from '../conversationChatBox/StartConvo';
import PurchasingContext from '../../context/purchasing/purchasingContext';
import {useConversationAPI} from '../../services/useConversationAPI';
import {ICustomerLevelConvoData} from '../../model/conversation/Conversation';
import GoBackIcon from '../../assets/svg/GoBackIcon';
import {ResolvedConvoDownload} from '../conversationChatBox/ResolvedConvoDownload';
import ARCreditStoppedIcon from '../../assets/svg/ARCreditStoppedIcon';
import ARCreditHoldIcon from '../../assets/svg/ARCreditHoldIcon';
import {LinkedOperationalCustomerDialog} from './LinkedOperationalCustomerDialog';
import {Edit} from '@material-ui/icons';
import {CustomerDialog} from '../dialog/CustomerDialog';
import {Button, IconButton, Typography} from 'spenda-ui-react';
interface IARHeaderProps {
  headerData?: IHeaderDetails;
  isLoading: boolean;
}

export interface IHeaderDetails {
  supplierTenantName?: string;
  userEmail?: string;
  userMobile?: string;
  suppName?: string;
  suppEmail?: string;
  suppMobile?: string;
  customerName?: string;
  customerEmail?: string;
  customerMobile?: string;
  customerAddress?: IAddress;
  IsOnCreditHold?: boolean;
  IsOnCreditStop?: boolean;
  creditLimit?: number;
  accountBalance?: number;
  creditAvailable?: number;
}

export const ARHeader = (props: IARHeaderProps) => {
  const {headerData, isLoading} = props;
  const history = useHistory();
  const {isInBuyerContext, isInSupplierContext} = useTenantInfo();
  const {conversations} = useContext(PurchasingContext);
  const {supplierId, customerId} = useParams<{supplierId?: string; customerId?: string}>();
  const [openCustomerClass, setOpenCustomerClass] = useState<boolean>(false);
  const [openCustomer, setOpenCustomer] = useState<boolean>(false);
  const [customerLevelConvo, setCustomerLevelConvo] = useState<ICustomerLevelConvoData>();

  const operationConvoUniqueId =
    isInBuyerContext && supplierId ? supplierId : isInSupplierContext && customerId ? customerId : undefined;

  const {getBuyerCustomerConvo, getSupplierConversations, isLoading: isConvoLoading} = useConversationAPI();

  useEffect(() => {
    if (supplierId) {
      getBuyerCustomerLevelConvo();
    } else if (customerId) {
      getSupplierCustomerLevelConvo(customerId);
    }
  }, [supplierId, customerId]);

  const getSupplierCustomerLevelConvo = async (custId: string) => {
    try {
      const suppCustomerConvo = await getSupplierConversations(custId);
      if (suppCustomerConvo?.supplierConversationList?.length) {
        const customerLevelConvoData = {
          customerLevelConversationMessageSummary:
            suppCustomerConvo.supplierConversationList[0].customerLevelConversationMessageSummary,
          customerLevelResolvedConversations:
            suppCustomerConvo.supplierConversationList[0].customerLevelResolvedConversations,
        };
        setCustomerLevelConvo(customerLevelConvoData);
      } else {
        setCustomerLevelConvo(undefined);
      }
    } catch {}
  };

  const getBuyerCustomerLevelConvo = async () => {
    try {
      const buyerCustomerConvos = await getBuyerCustomerConvo();
      if (buyerCustomerConvos) {
        setCustomerLevelConvo(buyerCustomerConvos);
      }
    } catch {}
  };

  const getAddress = () => {
    return `${
      (headerData?.customerAddress?.StreetAddress?.replace('\\n', ' ') || '') +
      ' ' +
      (headerData?.customerAddress?.City || '') +
      ' ' +
      (headerData?.customerAddress?.State || '') +
      ' ' +
      (headerData?.customerAddress?.PostCode || '') +
      ' ' +
      (headerData?.customerAddress?.Country || '')
    }`;
  };

  const handleBackClick = () => {
    history.push(`${AUTH_SELLING_COLLECT_ROUTE}`);
  };

  return (
    <>
      {isInSupplierContext && (
        <>
          {customerId && (
            <LinkedOperationalCustomerDialog
              open={openCustomerClass}
              onClose={() => setOpenCustomerClass(false)}
              customerID={parseInt(customerId || '')}
            />
          )}
          <CustomerDialog
            customerId={customerId}
            open={openCustomer}
            onClose={() => setOpenCustomer(false)}
            setOpenAccountCustomer={() => null}
            setCustomer={() => null}
          />
        </>
      )}
      <div
        className={`flex flex-row rounded bg-white pl-1 ${isInBuyerContext ? '' : 'mb-2'}`}
        style={{boxShadow: '0px 0px 6px 0px #D3E5EF'}}
      >
        {isInSupplierContext && (
          <div
            className={`my-1 flex h-[140px] w-[68px] cursor-pointer flex-col items-center justify-center rounded-[6px] bg-primary-header`}
            onClick={handleBackClick}
            data-autoid={`btnARBackButton`}
          >
            <GoBackIcon />
            <Typography variant="paragraph" className="mt-4 font-semibold text-primary">
              Back
            </Typography>
          </div>
        )}

        <div className={`flex w-[50%] flex-col justify-between p-2.5`}>
          {/* {isInSupplierContext && (
            <p className="font-poppins text-xs font-medium text-spenda-dashbg mb-2">
              Statement-<span className="text-black-800">Activity</span>
            </p>
          )} */}
          <div className=" flex flex-row items-center justify-between">
            {isLoading ? (
              <div className="flex flex-row items-center justify-between">
                <Skeleton circle={true} width={30} height={30} className="mr-2" />
                <Skeleton width={120} height={20} />
              </div>
            ) : (
              <div className="flex h-12 flex-row items-center justify-between">
                <div
                  className={`mr-2 flex h-[32px] w-[32px] items-center justify-center rounded-full bg-green-200 font-poppins text-xs font-normal text-green-600`}
                  data-autoid={`lblARHeaderNameIntials`}
                >
                  {isInBuyerContext
                    ? NameInitials(headerData?.supplierTenantName)
                    : NameInitials(headerData?.customerName)}
                </div>
                <Typography data-autoid={`lblARHeaderName`} variant="paragraph" className="font-medium text-black-800">
                  {isInBuyerContext ? headerData?.supplierTenantName : headerData?.customerName}
                </Typography>
                <div
                  className={`ml-2 flex items-center justify-between ${customerLevelConvo?.customerLevelConversationMessageSummary?.unreadMessages ? 'gap-3.5' : 'gap-2.5'}`}
                >
                  {operationConvoUniqueId && (
                    <StartConvo
                      operationUniqueId={operationConvoUniqueId}
                      conversationWith={
                        (isInBuyerContext ? headerData?.supplierTenantName : headerData?.customerName) || ''
                      }
                      supplierID={isInBuyerContext && supplierId && parseInt(supplierId) ? parseInt(supplierId) : null}
                      customerID={
                        isInSupplierContext && customerId && parseInt(customerId) ? parseInt(customerId) : null
                      }
                      messageCount={customerLevelConvo?.customerLevelConversationMessageSummary?.messageCount}
                      unreadMessages={customerLevelConvo?.customerLevelConversationMessageSummary?.unreadMessages}
                      conversationId={customerLevelConvo?.customerLevelConversationMessageSummary?.conversationID}
                      contextConvos={conversations}
                      convoType={ConversationType.CUSTOMER}
                      startConvoType={StartConvoType.ICON}
                    />
                  )}
                  <div>
                    {!!customerLevelConvo?.customerLevelResolvedConversations?.length && !isConvoLoading && (
                      <ResolvedConvoDownload resolvedConvos={customerLevelConvo?.customerLevelResolvedConversations} />
                    )}
                  </div>
                </div>
              </div>
            )}
            {isInBuyerContext &&
              (isLoading ? (
                <div className={`flex flex-col`}>
                  <Skeleton width={120} height={10} className="-mb-1" />
                  <Skeleton width={120} height={10} />
                </div>
              ) : (
                <div className="mx-2 flex flex-col">
                  <Typography placeholder="" data-autoid={`lblARHeaderBuyerMobile`} variant="xsmall" color="black">
                    M: {headerData?.userMobile}
                  </Typography>
                  <Typography data-autoid={`lblARHeaderBuyerEmail`} variant="xsmall" className="text-black-800">
                    E: {headerData?.userEmail}
                  </Typography>
                </div>
              ))}
            {isInSupplierContext && (
              <div className="flex items-center gap-2.5 self-end">
                <IconButton variant="outlined" data-autoid={`btnEditARCustomer`} onClick={() => setOpenCustomer(true)}>
                  <Edit className="!text-primary" />
                </IconButton>
                <Button
                  className="bg-white"
                  data-autoid={`btnEditClass`}
                  onClick={() => setOpenCustomerClass(true)}
                  variant="outlined"
                  color="primary"
                >
                  Edit Class
                </Button>
              </div>
            )}
          </div>
          <div
            className={`relative flex flex-row border-[lightgray] border-t-default ${
              isInBuyerContext ? 'mr-2 pb-2' : 'justify-between'
            } border-t-default`}
          >
            {isLoading ? (
              <div className={`flex flex-col`}>
                <Skeleton width={150} height={10} className="mb-1" />
                <Skeleton width={120} height={10} className="mb-1" />
                <Skeleton width={120} height={10} />
              </div>
            ) : (
              <div className={`${isInSupplierContext && 'pb-2'} mt-2 flex flex-col`}>
                <Typography
                  data-autoid={`${isInBuyerContext ? 'lblSupplierName' : 'lblBuyerAddress'}`}
                  variant="xsmall"
                  className="font-semibold text-black-800"
                >
                  {isInBuyerContext ? headerData?.suppName : getAddress()}
                </Typography>
                <Typography
                  data-autoid={`${isInBuyerContext ? 'lblSupplierMobile' : 'lblBuyerMobile'}`}
                  variant="xsmall"
                  className="text-black-800"
                >
                  M: {isInBuyerContext ? headerData?.suppMobile : headerData?.customerMobile}
                </Typography>
                <Typography
                  data-autoid={`${isInBuyerContext ? 'lblSupplierEmail' : 'lblBuyerEmail'}`}
                  variant="xsmall"
                  className="text-black-800"
                >
                  E: {isInBuyerContext ? headerData?.suppEmail : headerData?.customerEmail}
                </Typography>
              </div>
            )}
            {isInSupplierContext && (
              <div>
                {headerData?.IsOnCreditStop && (
                  <div
                    data-autoid={`btnStopCredit`}
                    className={`flex h-full w-[58px] flex-col items-center justify-center bg-[rgba(197,93,68,.1)] p-1`}
                  >
                    <ARCreditStoppedIcon />
                    <Typography
                      variant="xsmall"
                      className="pt-[6px] text-center font-medium leading-[11px] text-[#C55D44]"
                    >
                      Credit stopped
                    </Typography>
                  </div>
                )}
                {headerData?.IsOnCreditHold && (
                  <div
                    data-autoid={`btnCreditHold`}
                    className={`flex h-full w-[58px] flex-col items-center justify-center bg-[rgba(198,138,25,0.125)] p-1`}
                  >
                    <ARCreditHoldIcon />
                    <Typography
                      variant="xsmall"
                      className="pt-[6px] text-center font-medium leading-[11px] text-[#C55D44]"
                    >
                      Credit Hold
                    </Typography>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div className={`flex flex-col bg-[rgb(250,250,250)] ${isInBuyerContext ? 'w-[50%]' : 'w-[45%]'} pl-5 pr-3`}>
          <div className="flex flex-row items-center justify-between pt-3">
            <Typography variant="paragraph" className="mb-3 font-medium text-black-800">
              Account details
            </Typography>
            {isInSupplierContext && <ArRefreshBtn datTypeId={DatTypes.Customers} />}
          </div>
          <div className={` flex h-full flex-row items-center justify-between border-[lightgray] border-t-default`}>
            <div className="flex flex-col items-center justify-center px-3">
              <Typography variant="xsmall" className="font-medium text-black-800">
                Limit
              </Typography>
              <Typography
                data-autoid={`lblCreditLimitAmt`}
                variant="paragraph"
                className="font-semibold text-black-800"
              >
                {headerData?.creditLimit
                  ? PriceFormat(parseFloat(toFixedWithoutRounding(headerData?.creditLimit)))
                  : '$0.00'}
              </Typography>
            </div>
            <div className="flex flex-col items-center justify-center px-3">
              <Typography variant="xsmall" className="font-medium text-black-800">
                {(headerData?.accountBalance || 0) > 0 ? 'Unpaid' : 'Credit'}
              </Typography>
              <Typography
                data-autoid={`lblAccountBalanceAmt`}
                variant="paragraph"
                className="font-semibold text-spenda-error"
              >
                {headerData?.accountBalance
                  ? PriceFormat(parseFloat(toFixedWithoutRounding(Math.abs(headerData?.accountBalance))))
                  : '$0.00'}
              </Typography>
            </div>
            <div className="flex flex-col items-center justify-center px-3">
              <Typography variant="xsmall" className="font-medium text-black-800">
                Available
              </Typography>
              <Typography
                data-autoid={`lblAvailableAmt`}
                variant="paragraph"
                className="font-semibold text-spenda-error"
              >
                {headerData?.creditAvailable
                  ? PriceFormat(parseFloat(toFixedWithoutRounding(headerData?.creditAvailable)))
                  : '$0.00'}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
