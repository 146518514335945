import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from 'react';
import {Button, Checkbox, Input, Typography} from 'spenda-ui-react';
import {PriceFormat} from '../../utils/formatter';
import {SpendaNoTagLogo} from '../../assets/svg/SpendaNoTagLogo';
import QuoteContext from '../../context/quote-context/QuoteContext';
import moment from 'moment';
import AppContext from '../../context/app/appContext';
import useQuotesAPI from '../../services/useQuotesAPI';
import {IQuotePackageSummaryLines} from '../../model/quotes/quotes';
import LoadingIndicator from '../ui/LoadingOverlay';
import MergedIcon from '../../assets/svg/quotes-management/MergedIcon';
import APTooltip from '../data-display/APTootip';

export const QuotePreviewMainPanel = (props: {
  isDisabledMode: boolean;
  quoteID: number;
  isResendingQuote: boolean;
  quotePckgSummaryLines: IQuotePackageSummaryLines[];
  setQuotePckgSummaryLines: Dispatch<SetStateAction<IQuotePackageSummaryLines[]>>;
  quoteIndex: number;
  setEditedTabs: (quoteId: number, quoteIndex: number) => void;
  isShowQuantityInQuote?: boolean;
}) => {
  const {
    isDisabledMode,
    isResendingQuote,
    quoteID,
    setQuotePckgSummaryLines,
    quotePckgSummaryLines,
    quoteIndex,
    setEditedTabs,
    isShowQuantityInQuote,
  } = props;
  const {quotePackage, customerDetails} = useContext(QuoteContext);
  const {tenantInfo, tenant} = useContext(AppContext);
  const {getSummaryLines, createUpdateSummaryLines, revertSummaryLines} = useQuotesAPI();
  const quoteDetails = quotePackage?.quotes?.find(quote => quote.quoteID === props.quoteID);
  const [selectedSummaryLines, setSelectedSummaryLines] = useState<number[]>([]);
  const [allSummaryLinesSelected, setAllSummaryLinesSelected] = useState<boolean>(false);
  const [showSummarizeButton, setShowSummarizeButton] = useState<boolean>(false);
  const [showRevertButton, setShowRevertButton] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchSummaryLines = async () => {
    const lines = await getSummaryLines(props.quoteID);
    const pkgLines = [...quotePckgSummaryLines];
    pkgLines[quoteIndex].lines = lines;
    setQuotePckgSummaryLines(pkgLines);
    setShowSummarizeButton(false);
    setShowRevertButton(false);
    setSelectedSummaryLines([]);
    setAllSummaryLinesSelected(false);
    setIsLoading(false);
    // set edited tabs
    setEditedTabs(props.quoteID, quoteIndex);
  };

  useEffect(() => {
    const summaryLines = quotePckgSummaryLines?.[quoteIndex]?.lines;
    // conditions for enabling buttons
    if (selectedSummaryLines.length > 1) {
      const selectedLines = summaryLines?.filter((_line, index) => selectedSummaryLines.includes(index));
      if ((selectedLines?.filter(line => line.quoteLineSummaryID)?.length || 0) <= 1) {
        // all lines selected to be summarised - enable summarise button
        setShowSummarizeButton(true);
        setShowRevertButton(false);
      } else {
        setShowSummarizeButton(false);
        setShowRevertButton(false);
      }
    } else if (
      selectedSummaryLines.length === 1 &&
      summaryLines?.[selectedSummaryLines[0]]?.quoteLineSummaryID !== null
    ) {
      // check if already summarized line and show revert button
      setShowRevertButton(true);
      setShowSummarizeButton(false);
    } else {
      setShowSummarizeButton(false);
      setShowRevertButton(false);
    }
  }, [selectedSummaryLines]);

  const CustomTableData = ({
    tableHead,
    tableCell,
    className,
  }: {
    tableHead: string;
    tableCell: string | React.ReactNode;
    className?: string;
  }) => {
    return (
      <>
        <td
          width="15%"
          align="right"
          className={`${className} ${
            isDisabledMode ? 'text-[6px]' : 'text-xs'
          } border-b-2 border-[#E1E1E1] px-2 py-1 font-medium text-black-800`}
        >
          {tableHead}
        </td>
        <td
          width="15%"
          align="right"
          data-autoid={`txt${tableHead.replace(' ', '')}${isDisabledMode ? 'Disable' : ''}-${props.quoteID}`}
          className={`${className} ${
            isDisabledMode ? 'text-[6px]' : 'text-xs'
          } border-b-2 border-[#E1E1E1]  px-2 py-1 font-medium text-black-800`}
        >
          {tableCell}
        </td>
      </>
    );
  };

  const summariseSelectedLines = async () => {
    setIsLoading(true);
    const summaryLines = quotePckgSummaryLines?.[quoteIndex].lines;
    const quoteIDs = summaryLines
      ?.filter((item, index) => selectedSummaryLines.includes(index) && !item.quoteLineSummaryID)
      .map(item => item.quoteLineID);
    const quoteLineSummary = summaryLines?.find(
      (item, index) => selectedSummaryLines.includes(index) && item.quoteLineSummaryID,
    );
    await createUpdateSummaryLines(
      props.quoteID,
      quoteIDs || [],
      quoteLineSummary?.description || '',
      quoteLineSummary?.quoteLineSummaryID,
    );
    await fetchSummaryLines();
  };

  const revertSummarizedLines = async () => {
    setIsLoading(true);
    const selectedLine = quotePckgSummaryLines?.[quoteIndex].lines?.[selectedSummaryLines[0]];
    await revertSummaryLines(selectedLine?.quoteLineSummaryID!, props.quoteID);
    await fetchSummaryLines();
  };

  return (
    <div
      className={`${
        isDisabledMode ? 'mt-10 h-[450px] min-w-[350px]' : 'mt-4 h-[806px] w-[670px]'
      } bg-white !text-[#333333]`}
    >
      <header
        className={`after:content-[' '] relative flex h-full flex-col rounded-[4px] rounded-tr-[40px] bg-white shadow-[0_3px_10px_rgb(227,238,245,1)]
        after:absolute after:right-0 after:top-0 ${
          !isDisabledMode
            ? 'after:border-l-[40px] after:border-t-[38px]'
            : 'after:border-l-[25px] after:border-t-[27px]'
        } after:block; after:rounded-tl-0 
       after:rounded-tr-0 after:rounded-br-0 after:z-0 after:w-[0px] after:overflow-y-auto after:rounded-bl-xl after:border-b-[0px] after:border-r-[0px]
       after:border-solid after:border-b-[#e1e1e1] after:border-l-[#e1e1e1] after:border-r-[#fff] after:border-t-[#fff] after:bg-[#E1E1E1]`}
      >
        <div className={`mb-5 h-full overflow-y-auto px-3 ${isDisabledMode ? 'mx-2' : 'mx-5 pt-1'}`}>
          <div className={`mt-8 flex items-center justify-between`}>
            {tenant?.Logos?.[0]?.URI ? (
              <img
                src={tenant?.Logos?.[0]?.URI}
                alt="Preview Logo"
                className={`${isDisabledMode ? 'h-[29px] w-[103px]' : 'h-[64px] w-[236px]'}`}
              />
            ) : (
              <SpendaNoTagLogo height={isDisabledMode ? '40px' : '80px'} width={isDisabledMode ? '100px' : '200px'} />
            )}
            <Typography className="font-bold" variant={isDisabledMode ? 'small' : 'h2'}>
              Sales Quotation
            </Typography>
          </div>
          <div className="flex justify-between">
            <div className={`mb-1 flex items-end text-xl`}>
              <Typography
                data-autoid={`${isDisabledMode ? `txtCompanyNameDisable-${props.quoteID}` : `txtCompanyName-${props.quoteID}`}`}
                variant={isDisabledMode ? 'small' : 'h2'}
              >
                {customerDetails?.CompanyName}
              </Typography>
            </div>
            <div className="flex justify-between">
              <div
                className={`flex flex-col items-center border-[#E1E1E1] ${
                  isDisabledMode
                    ? 'mt-2.5 h-[27px] w-[79px] rounded-[5px] border-[1.2px] pt-[2px]'
                    : '-mt-2.5 h-[56px] w-[161px] rounded-xl border-4 p-1'
                } `}
              >
                <Typography variant="xsmall" className={`${isDisabledMode && '!text-[6px]'} font-medium`}>
                  Amount Quoted:
                </Typography>
                <Typography
                  datatype={`${isDisabledMode ? `txtTotalIncDisabled-${quoteID}` : `txtTotalInc-${quoteID}`}`}
                  variant={isDisabledMode ? 'xsmall' : 'h3'}
                  className={`${isDisabledMode && 'font-semibold'}`}
                >
                  {PriceFormat(quoteDetails?.totalInc || 0)}
                </Typography>
              </div>
            </div>
          </div>
          <div className="mb-1 flex justify-between">
            <div className="mb-2 flex flex-col items-start md:mb-1">
              <Typography
                variant={isDisabledMode ? 'xsmall' : 'small'}
                className={`${isDisabledMode && '!text-[6px]'}`}
                data-autoid={`${isDisabledMode ? `txtABNDisable-${quoteID}` : `txtABN-${quoteID}`}`}
              >
                ABN: {customerDetails?.ABN}
              </Typography>
              <Typography
                variant={isDisabledMode ? 'xsmall' : 'small'}
                className={`${isDisabledMode && '!text-[6px]'}`}
                data-autoid={`${isDisabledMode ? `txtPhoneDisable-${quoteID}` : `txtPhone-${quoteID}`}`}
              >
                phone: {customerDetails?.Phone1}
              </Typography>
              <Typography
                variant={isDisabledMode ? 'xsmall' : 'small'}
                className={`${isDisabledMode && '!text-[6px]'}`}
                data-autoid={`${isDisabledMode ? `txtEmailDisable-${quoteID}` : `txtEmail-${quoteID}`}`}
              >
                email: {customerDetails?.PrimaryContactEmailAddress}
              </Typography>
              <Typography
                variant={isDisabledMode ? 'xsmall' : 'small'}
                className={`${isDisabledMode && '!text-[6px]'}`}
              >
                web: -
              </Typography>
            </div>
            <div>
              <div className="mb-0 mt-4 flex flex-col items-end justify-end">
                <Typography
                  variant={isDisabledMode ? 'xsmall' : 'small'}
                  className={`${isDisabledMode && '!text-[6px]'}`}
                >
                  Date: {moment(new Date()).format('DD MMM YYYY')}
                </Typography>
                <Typography
                  variant={isDisabledMode ? 'xsmall' : 'small'}
                  className={`${isDisabledMode && '!text-[6px]'}`}
                  data-autoid={`${isDisabledMode ? `txtRefNumberDisable-${quoteID}` : `txtRefNumber-${quoteID}`}`}
                >
                  Ref #: {quotePackage?.refNumber}
                </Typography>
              </div>
              <div className={`mb-2 flex items-start justify-end md:mb-1`}></div>
            </div>
          </div>
          <hr />
          <div className="my-2 flex flex-col">
            <Typography variant={isDisabledMode ? 'xsmall' : 'h3'} className="mb-1">
              Quote from
            </Typography>
            <Typography variant={isDisabledMode ? 'xsmall' : 'small'} className={`${isDisabledMode && '!text-[6px]'}`}>
              {tenantInfo?.TenantUserDetails?.TenantName}
            </Typography>
            <Typography variant={isDisabledMode ? 'xsmall' : 'small'} className={`${isDisabledMode && '!text-[6px]'}`}>
              {tenantInfo?.TenantUserDetails?.UserName}
            </Typography>
            <Typography variant={isDisabledMode ? 'xsmall' : 'small'} className={`${isDisabledMode && '!text-[6px]'}`}>
              {tenant?.Locations?.[0]?.Addresses?.[0]?.FullAddress || ''}
            </Typography>
          </div>
          <LoadingIndicator
            isAPOnboardingV1={true}
            isLoading={isLoading && !isDisabledMode}
            overlayBgColor={'rgba(0, 0, 0, 0.1)'}
            className="!h-fit"
          >
            <table className="w-full">
              <thead className={`bg-[#E1E1E1] py-1`}>
                <tr>
                  <th
                    align="left"
                    className={`${isDisabledMode ? 'pl-5 !text-[6px]' : 'pl-1 text-xs'} py-[2px] pr-2 font-medium`}
                  >
                    <div className={`${isDisabledMode ? 'gap-2' : 'gap-2.5'} flex items-center`}>
                      {!isDisabledMode && !isResendingQuote && (
                        <Checkbox
                          data-autoid={`chkAll-${quoteID}`}
                          containerProps={{className: 'p-0'}}
                          disabled={isLoading}
                          ripple={false}
                          checked={allSummaryLinesSelected}
                          onChange={e => {
                            if (e.target.checked) {
                              setSelectedSummaryLines(
                                quotePckgSummaryLines?.[quoteIndex]?.lines.map((_line, index) => index) || [],
                              );
                            } else {
                              setSelectedSummaryLines([]);
                            }
                            setAllSummaryLinesSelected(e.target.checked);
                          }}
                          className="h-5 w-5 rounded-[4px] border-primary bg-transparent transition-all checked:border-primary checked:bg-primary hover:before:opacity-0"
                        />
                      )}
                      Description
                    </div>
                  </th>
                  {isShowQuantityInQuote && (
                    <>
                      <th
                        align="center"
                        className={`${isDisabledMode ? '!text-[6px]' : 'text-xs'} px-2 py-[2px]  font-medium`}
                      >
                        Qty
                      </th>
                      <th
                        align="right"
                        className={`${isDisabledMode ? '!text-[6px]' : 'text-xs'} px-2 py-[2px]  font-medium`}
                      >
                        Unit Price
                      </th>
                    </>
                  )}
                  <th
                    align="right"
                    className={`${isDisabledMode ? '!text-[6px]' : 'text-xs'} px-2 py-[2px]  font-medium`}
                  >
                    {isDisabledMode ? 'Total' : 'Line Total'}
                  </th>
                </tr>
              </thead>
              <tbody>
                {isDisabledMode
                  ? quoteDetails?.lines?.map((line, index) => {
                      return (
                        <tr key={`isDisabledMode-${index}`}>
                          <td width={'60%'} className={`border-b-2 border-[#E1E1E1] py-1 pl-2 pr-2 sm:pl-1 `}>
                            <div className="flex gap-2">
                              <Typography className="min-w-[8px] text-[6px] font-medium">{index + 1}.</Typography>
                              <Typography
                                variant="xsmall"
                                data-autoid={`txtShortDescriptionDisable-${index}-${quoteID}`}
                                className={`${isDisabledMode && '!text-[6px]'} font-medium`}
                              >
                                {line.shortDescription}
                              </Typography>
                            </div>
                          </td>
                          <td
                            width={'10%'}
                            align="center"
                            data-autoid={`txtQuantityDisable-${index}-${quoteID}`}
                            className={`${
                              isDisabledMode ? '!text-[6px]' : 'text-xs'
                            } border-b-2 border-[#E1E1E1] px-2 py-1 font-medium`}
                          >
                            {line.quantity}
                          </td>
                          <td
                            width={'15%'}
                            align="right"
                            data-autoid={`txtSellPriceExDisable-${index}-${quoteID}`}
                            className={`${
                              isDisabledMode ? '!text-[6px]' : 'text-xs'
                            } border-b-2 border-[#E1E1E1] px-2 py-1 font-medium`}
                          >
                            {PriceFormat(line.sellPriceEx)}
                          </td>
                          <td
                            width={'15%'}
                            align="right"
                            data-autoid={`txtLineTotalExDisable-${index}-${quoteID}`}
                            className={`${
                              isDisabledMode ? '!text-[6px]' : 'text-xs'
                            } border-b-2 border-[#E1E1E1] px-2 py-1 font-medium`}
                          >
                            {PriceFormat(line.lineTotalEx)}
                          </td>
                        </tr>
                      );
                    })
                  : quotePckgSummaryLines?.[quoteIndex]?.lines?.map((item, index) => {
                      isLoading && <LoadingIndicator />;
                      return (
                        <tr key={index}>
                          <td
                            width={isShowQuantityInQuote ? '60%' : '85%'}
                            className={`border-b-2 border-[#E1E1E1] py-1 pl-2 pr-2 sm:pl-1 `}
                          >
                            <div className="flex gap-2.5">
                              {!isResendingQuote && (
                                <Checkbox
                                  data-autoid={`chkLine-${index}-${quoteID}`}
                                  containerProps={{className: 'p-0'}}
                                  ripple={false}
                                  checked={selectedSummaryLines?.includes(index) || allSummaryLinesSelected}
                                  disabled={isLoading}
                                  onChange={() => {
                                    const data = [...selectedSummaryLines];
                                    if (data?.includes(index)) {
                                      const rowIndex = data.findIndex(line => line === index);
                                      data.splice(rowIndex, 1);
                                      setSelectedSummaryLines(data);
                                      setAllSummaryLinesSelected(false);
                                    } else {
                                      data?.push(index);
                                      setSelectedSummaryLines(data);
                                      if (quotePckgSummaryLines?.[quoteIndex]?.lines.length === data.length) {
                                        setAllSummaryLinesSelected(true);
                                      }
                                    }
                                  }}
                                  className="h-5 w-5 rounded-[4px] border-primary bg-transparent transition-all before:w-auto checked:border-primary checked:bg-primary hover:before:opacity-0"
                                />
                              )}
                              {isResendingQuote ? (
                                <Typography
                                  variant="xsmall"
                                  data-autoid={`txtDescription-${index}-${quoteID}`}
                                  className={`w-[306px] text-sm font-medium`}
                                >
                                  {item?.description}
                                </Typography>
                              ) : (
                                <Input
                                  name="description"
                                  data-autoid={`txtDescription-${index}-${quoteID}`}
                                  containerProps={{
                                    className: 'w-[306px] h-5',
                                  }}
                                  autoFocus={index === 0 && item?.description.length <= 0}
                                  className={`${
                                    item?.description.length > 0 ? '!border-[#333]' : '!border-primary'
                                  } rounded-sm border-[.5px] !px-1 !py-[2px] text-xs font-medium`}
                                  placeholder="Please write a summary e.g. materials"
                                  value={item?.description}
                                  onChange={e => {
                                    const pkgLines = [...quotePckgSummaryLines];
                                    const data = pkgLines[quoteIndex].lines;
                                    data[index].description = e.target.value;
                                    pkgLines[quoteIndex].lines = data;
                                    setQuotePckgSummaryLines(pkgLines);
                                  }}
                                  onBlur={async () => {
                                    // update description for summary lines
                                    if (item.quoteLineSummaryID) {
                                      await createUpdateSummaryLines(
                                        props.quoteID,
                                        [],
                                        item.description,
                                        item.quoteLineSummaryID,
                                      );
                                      setEditedTabs(props.quoteID, quoteIndex);
                                    } else {
                                      const id = await createUpdateSummaryLines(
                                        props.quoteID,
                                        [item.quoteLineID],
                                        item.description,
                                      );
                                      const pkgLines = [...quotePckgSummaryLines];
                                      const data = pkgLines[quoteIndex].lines;
                                      data[index].quoteLineSummaryID = id;
                                      pkgLines[quoteIndex].lines = data;
                                      setQuotePckgSummaryLines(pkgLines);
                                      setEditedTabs(props.quoteID, quoteIndex);
                                    }
                                  }}
                                />
                              )}
                              {item.quoteLineSummaryID && (
                                <APTooltip title="Summarised Line" arrow placement="bottom">
                                  <span
                                    data-autoid={`IconMerge-${index}-${quoteID}`}
                                    className="flex cursor-pointer items-center justify-center"
                                  >
                                    <MergedIcon />
                                  </span>
                                </APTooltip>
                              )}
                            </div>
                          </td>
                          {isShowQuantityInQuote && (
                            <>
                              <td
                                width={'10%'}
                                align="center"
                                className={`${
                                  isDisabledMode ? '!text-[6px]' : 'text-xs'
                                } border-b-2 border-[#E1E1E1] px-2 py-1 font-medium`}
                                data-autoid={`txtQuantity-${index}-${quoteID}`}
                              >
                                {item.quantity}
                              </td>
                              <td
                                width={'15%'}
                                align="right"
                                data-autoid={`txtSellPriceEx-${index}-${quoteID}`}
                                className={`${
                                  isDisabledMode ? '!text-[6px]' : 'text-xs'
                                } border-b-2 border-[#E1E1E1] px-2 py-1 font-medium`}
                              >
                                {PriceFormat(Number(item.sellPriceEx.toFixed(2)))}
                              </td>
                            </>
                          )}
                          <td
                            width={'15%'}
                            align="right"
                            data-autoid={`txtLineTotalEx-${index}-${quoteID}`}
                            className={`${
                              isDisabledMode ? '!text-[6px]' : 'text-xs'
                            } border-b-2 border-[#E1E1E1] px-2 py-1 font-medium`}
                          >
                            {PriceFormat(item.lineTotalEx)}
                          </td>
                        </tr>
                      );
                    })}
              </tbody>
            </table>
            <table className="w-full">
              <tbody>
                <tr>
                  <td rowSpan={4} width="70%" className="align-text-top">
                    {!isDisabledMode && !isResendingQuote && (
                      <>
                        {showSummarizeButton && !showRevertButton ? (
                          <Button
                            variant={'outlined'}
                            data-autoid={`btnSummariseSelectedLines-${quoteID}`}
                            className="mt-2"
                            onClick={summariseSelectedLines}
                          >
                            Summarise Selected Lines
                          </Button>
                        ) : (
                          !showRevertButton && (
                            <Button
                              variant={'text'}
                              data-autoid={`btnSummariseSelectedLines-${quoteID}`}
                              disabled={true}
                              className="mt-2"
                            >
                              Summarise Selected Lines
                            </Button>
                          )
                        )}
                        {showRevertButton && (
                          <Button
                            variant="outlined"
                            className="mt-2"
                            onClick={revertSummarizedLines}
                            data-autoid={`btnRevertOriginalLines-${quoteID}`}
                          >
                            Revert Original Lines
                          </Button>
                        )}
                      </>
                    )}
                  </td>
                  <CustomTableData tableHead="Subtotal" tableCell={PriceFormat(quoteDetails?.totalEx)} />
                </tr>
                <tr>
                  <CustomTableData tableHead="GST" tableCell={PriceFormat(quoteDetails?.totalTax)} />
                </tr>
                <tr>
                  <CustomTableData
                    tableHead="Total"
                    tableCell={PriceFormat(quoteDetails?.totalInc)}
                    className="bg-[#E1E1E1] !font-bold"
                  />
                </tr>
              </tbody>
            </table>
          </LoadingIndicator>
        </div>
        <div
          className={`${
            isDisabledMode ? 'h-[22px]' : 'h-9 min-h-9'
          } flex w-full items-center justify-center bg-[#F3F3F3]`}
        >
          <SpendaNoTagLogo width={isDisabledMode ? '31px' : '60px'} height={isDisabledMode ? '15px' : '25px'} />
        </div>
      </header>
    </div>
  );
};
