import React, {SetStateAction} from 'react';
import LoadingIndicator from '../../components/ui/LoadingIndicator';
import {Badge, IconButton, Progress, Upload} from 'spenda-ui-react';
import {WidgetDeleteLogo} from '../../assets/svg/WidgetDeleteLogo';
import {IQuoteAttachment} from '../../model/quotes/quotes';
import {Toast} from '../../utils/Toast';
import {AttachmentType} from '../../model/FileDetails';
import {Visibility} from '@material-ui/icons';
import {DownloadIcon} from '../../assets/svg/DownloadIcon';
import CrossIcon from '../../assets/svg/Cross';
import {useFeatureFlags} from '../../hooks/useFeatureFlags';
import {PDFIcon, WordIcon} from '../../assets/svg';
import ImagePreview from './ImagePreview';

const QuoteAttachment = (props: {
  addedFilesToUpload?: File[];
  invalidFiles?: File[];
  setAddedFilesToUpload?: React.Dispatch<SetStateAction<File[]>>;
  setInvalidFiles?: React.Dispatch<SetStateAction<File[]>>;
  deleteFile?: (index: number, isInvalid: boolean) => void;
  isUploading?: boolean;
  uploadedAttachments?: IQuoteAttachment[];
  deleteQuoteAttachment?: (guid: string) => void;
  customerView?: boolean;
  isServiceJobView?: boolean;
  disabled?: boolean;
}) => {
  const {
    addedFilesToUpload,
    invalidFiles,
    setAddedFilesToUpload,
    setInvalidFiles,
    deleteFile,
    isUploading,
    uploadedAttachments,
    deleteQuoteAttachment,
    customerView,
    isServiceJobView,
    disabled,
  } = props;

  const allowedTypes = Object.values(AttachmentType);
  const {QuoteV289367} = useFeatureFlags().tenantOwned();

  //Function
  const handleOnChange = (files: FileList) => {
    if (files && files.length > 0) {
      const newInvalidFiles: File[] = [];
      const validFiles: File[] = [];
      let invalidFilename: string = '';

      Array.from(files).forEach(file => {
        if (allowedTypes.includes(file.type as AttachmentType)) {
          validFiles.push(file);
        } else {
          newInvalidFiles.push(file);
          invalidFilename = invalidFilename.concat(file.name + ',');
        }
      });

      if (invalidFilename.length > 0) {
        Toast.error(`invalid file type:${invalidFilename.slice(0, -1)}`);
      }

      setInvalidFiles?.(prevState => [...prevState, ...newInvalidFiles]);

      setAddedFilesToUpload?.(prevState => {
        const currentFiles = prevState || [];
        return [...currentFiles, ...validFiles];
      });
    }
  };

  const getFileIcon = (fileName: string) => {
    const fileTypeToIconMap: {[key: string]: JSX.Element} = {
      pdf: <PDFIcon className="h-[60px] w-[60px] text-gray-600" />,
      doc: <WordIcon className="h-[60px] w-[60px] text-gray-600" />,
      docx: <WordIcon className="h-[60px] w-[60px] text-gray-600" />,
    };

    const extension = fileName.split('.').pop()?.toLowerCase() || '';
    return fileTypeToIconMap[extension] || null;
  };

  const FileItem = ({name, onDelete, isInvalid}: {name: string; onDelete: () => void; isInvalid: boolean}) => (
    <>
      <div
        className={`flex ${isServiceJobView ? 'max-w-[435px]' : 'max-w-[377px]'} w-full flex-col gap-y-2 rounded-lg border border-[#ECECEC] bg-white px-2.5 py-2 shadow-[0_0_6px_0_rgba(211,229,239,1)]`}
      >
        <div className="flex w-full items-center justify-between">
          <span className="w-3/4 truncate font-normal text-black-800" data-autoid={`txt${name}`}>
            {name}
          </span>
          <IconButton
            disabled={disabled}
            className="cursor-pointer active:bg-transparent"
            ripple={false}
            variant="text"
            onClick={onDelete}
            name={`Delete${name}`}
          >
            <WidgetDeleteLogo />
          </IconButton>
        </div>
        <Progress value={100} color={isInvalid ? 'error' : 'success'} />
      </div>
    </>
  );

  const FileItemV2 = ({file, onDelete, caption}: {file: File; caption: string; onDelete: () => void}) => {
    const fileURL = URL.createObjectURL(file);

    const handleViewImage = (fileURL: string) => {
      window.open(fileURL, '_blank');
    };

    const icon = getFileIcon(file.name);
    return (
      <>
        <div
          className={`flex flex-col ${isServiceJobView ? 'w-[15%] flex-wrap justify-start' : 'w-1/4'}  items-center`}
        >
          <div className="relative flex h-[110px] w-full flex-col justify-center">
            <div className="absolute inset-0 flex items-center justify-center rounded-[6px] bg-[#00000040] opacity-0 hover:opacity-100">
              <div className="flex space-x-3">
                <Visibility
                  data-autoid="btnViewEvidence"
                  className="cursor-pointer !fill-[#fff]"
                  onClick={() => {
                    handleViewImage(fileURL);
                  }}
                />
                <a data-autoid="btnDownloadEvidence" href={fileURL} download>
                  <DownloadIcon data-autoid="EditEvidenceDialogDownloadIcon" className="cursor-pointer !fill-[#fff]" />
                </a>
              </div>
              <div
                className="absolute -right-2 -top-2 flex h-[20px] w-[20px] cursor-pointer items-center justify-center"
                onClick={onDelete}
                data-autoid="btnRemoveEvidence"
              >
                <Badge
                  className="h-[20px] w-[20px] bg-[#ffcabd]"
                  color="white"
                  placement="top-end"
                  content={<CrossIcon data-autoid="CrossIcon" className="h-[8px] w-[8px] fill-deep-orange-900" />}
                />
              </div>
            </div>
            {icon ? (
              <div className="flex h-full w-full items-center justify-center bg-gray-200">{icon}</div>
            ) : (
              <img
                loading="lazy"
                src={fileURL}
                alt={file.name}
                className="h-[110px] w-full rounded-[6px] border border-[#ccc] bg-cover bg-clip-border bg-center bg-no-repeat bg-origin-border object-scale-down p-1"
              />
            )}
          </div>
          <div className="mt-2 flex items-center justify-center">
            <span className="text-center text-sm text-gray-700">{caption}</span>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {isUploading && <LoadingIndicator isLoading={isUploading} position={{position: 'relative'}} size="md" />}
      {!customerView && (
        <Upload
          data-autoid="uploadAttachment"
          label="Drop any additional documents here or click to upload"
          className={`${isServiceJobView ? 'max-w-[435px]' : 'max-w-[377px]'} h-full min-h-[114px] text-sm font-normal text-black-800`}
          onChange={handleOnChange}
          disabled={disabled}
          multiple
        />
      )}

      {QuoteV289367 ? (
        <div className={`flex w-full ${isServiceJobView ? '' : 'mt-2'}`}>
          <div
            className={`flex w-full flex-wrap ${isServiceJobView ? 'justify-start gap-x-5 gap-y-5' : 'justify-center gap-x-3 gap-y-3'}  `}
          >
            {uploadedAttachments &&
              uploadedAttachments.map((item, index) => {
                const icon = getFileIcon(item.caption);

                return (
                  <div
                    key={index}
                    className={`flex flex-col flex-wrap items-center ${isServiceJobView ? 'w-[15%] justify-start' : 'w-1/4'}`}
                  >
                    <div className={`flex w-full flex-col flex-wrap items-center`}>
                      <div className="relative flex max-h-[110px] w-full flex-col items-center justify-center">
                        <div className="absolute left-0 top-0 flex h-[110px] max-h-[110px] w-full flex-col items-center justify-center rounded-[6px] bg-[#00000040] opacity-0 hover:opacity-100">
                          <div className="flex gap-x-5">
                            <Visibility
                              data-autoid="btnViewEvidence"
                              className="cursor-pointer !fill-[#fff]"
                              onClick={() => ImagePreview(item.uri)}
                            />
                            <a data-autoid="btnDownloadEvidence" href={item.uri} download={item.caption}>
                              <DownloadIcon data-autoid="EditEvidenceDialogDownloadIcon" className="cursor-pointer" />
                            </a>
                          </div>
                          {!customerView && (
                            <div
                              className="absolute -right-[14px] -top-[6px] flex h-[20px] w-[20px] cursor-pointer items-center gap-4 rounded-full"
                              onClick={() => deleteQuoteAttachment?.(item.attachmentGuid)}
                              data-autoid="btnRemoveEvidence"
                            >
                              <Badge
                                className="h-[20px] w-[20px] bg-[#ffcabd]"
                                color="white"
                                placement="top-end"
                                content={
                                  <CrossIcon data-autoid="CrossIcon" className="h-[8px] w-[8px] fill-deep-orange-900" />
                                }
                              />
                            </div>
                          )}
                        </div>
                        {icon ? (
                          <div className="flex h-[110px] w-full items-center justify-center bg-gray-200">
                            {icon}</div>
                        ) : (
                          <img
                            loading="lazy"
                            src={item.thumbnailURI}
                            alt={item.attachmentGuid}
                            className="h-[110px] w-full rounded-[6px] border border-[#ccc] bg-cover bg-clip-border bg-center bg-no-repeat bg-origin-border object-scale-down p-1"
                          />
                        )}
                      </div>
                    </div>
                    <div className="mt-2 flex items-center justify-center">
                      <span className="text-center text-sm text-gray-700">{item.caption}</span>
                    </div>
                  </div>
                );
              })}

            {addedFilesToUpload &&
              addedFilesToUpload.map((file, index) => (
                <FileItemV2
                  caption={file.name}
                  file={file}
                  key={`added-${index}`}
                  onDelete={() => deleteFile?.(index, false)}
                />
              ))}

            {invalidFiles &&
              invalidFiles.map((file, index) => (
                <FileItemV2
                  caption={file.name}
                  file={file}
                  key={`invalid-${index}`}
                  onDelete={() => deleteFile?.(index, true)}
                />
              ))}
          </div>
        </div>
      ) : (
        <div className={`flex w-full flex-col gap-y-1.5 ${isServiceJobView ? '' : 'items-center justify-center'}`}>
          {uploadedAttachments &&
            uploadedAttachments?.map((item, index) => (
              <div
                key={index}
                className={`flex ${isServiceJobView ? 'max-w-[435px]' : 'max-w-[377px]'} w-full flex-col gap-y-2 rounded-lg border border-[#ECECEC] bg-white px-2.5 py-2 shadow-[0_0_6px_0_rgba(211,229,239,1)]`}
              >
                <div className="flex w-full items-center justify-between">
                  <span className="w-3/4 truncate font-normal text-black-800" data-autoid={`txt${item.caption}`}>
                    <a href={item.uri} download={item.caption}>
                      {item?.caption}
                    </a>
                  </span>
                  {!customerView && (
                    <IconButton
                      disabled={disabled}
                      className="cursor-pointer active:bg-transparent"
                      ripple={false}
                      variant="text"
                      onClick={() => {
                        deleteQuoteAttachment?.(item.attachmentGuid);
                      }}
                      name={`Delete${item.caption}`}
                    >
                      <WidgetDeleteLogo />
                    </IconButton>
                  )}
                </div>
                {!customerView && <Progress value={100} color="success" />}
              </div>
            ))}

          {addedFilesToUpload?.map((file, index) => (
            <FileItem key={index} name={file.name} onDelete={() => deleteFile?.(index, false)} isInvalid={false} />
          ))}

          {invalidFiles?.map((file, index) => (
            <FileItem key={index} name={file.name} onDelete={() => deleteFile?.(index, true)} isInvalid={true} />
          ))}
        </div>
      )}
    </>
  );
};

export default QuoteAttachment;
