import React, {useContext, useEffect, useState} from 'react';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import {makeStyles} from '@material-ui/core/styles';
import {FormLabel} from '@material-ui/core';
import {cloneDeep} from 'lodash';

import {PrimaryButton, SecondaryButton} from '../../../components/buttons/DefaultButtons';
import {PaymentWidgetContext, PaymentWidgetPaymentSteps, PaymentWidgetStep} from './PaymentWidget';
import './scss/PaymentWidgetStepPaymentAuthority.css';
import {SCheckbox} from '../../../components/inputs/SCheckbox';
import {STextField} from '../../../components/inputs/STextField';
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';
import ArrowDropUpOutlinedIcon from '@material-ui/icons/ArrowDropUpOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import Autocomplete from '@material-ui/lab/Autocomplete';
import lock from '../../../assets/svg/lock.svg';
import {
  ARModals,
  AuthorisationRequestMode,
  AuthorisationStatus,
  PaymentWidgetScope,
} from '../../../model/constants/Constants';
import {AuthorisationRulesRequest, Authorisers} from '../../../model/payment/PaymentAuthority';
import {usePaymentUtilities} from '../../../hooks/usePaymentUtilities';
import infoIcon from '../../../assets/svg/accounts-payable/blue-info-icon.svg';
import {EnterSecurityCode} from './pw-components/EnterSecurityCode';
import {Toast} from '../../../utils/Toast';
import {ZohoContext} from '../../../context/app/ZohoContext';
import {ITenantSupplierPaymentMethod} from '../../../model/payment/PaymentMethod';
import {WidgetTooltip} from '../../../components/data-display/WidgetTooltip';
import usePaymentAuthorityAPI from '../../../services/usePaymentAuthorityAPI';
import ARContext from '../../../context/ar-context/ARContext';
enum PaymentAuthoritySteps {
  AUTHENTICATION_SETTINGS,
  AUTHORISATION_SETTING,
  SELECT_PEOPLE_TO_AUTHORIZE,
  ENTER_OTP,
  CHANGE_REQUEST_MSG,
}

const useStyles = makeStyles({
  radioButton: {
    '& .MuiFormGroup-row': {
      flexWrap: 'nowrap',
    },
    '& .MuiRadio-colorPrimary.Mui-checked': {
      color: '#1C78AD',
    },
  },
  label: {
    color: '#000 !important',
    fontFamily: 'poppins',
    fontSize: '12px',
    justifyContent: 'center',
    '& .MuiTypography-body1': {
      fontSize: '12px',
      fontFamily: 'poppins',
    },
  },
  label2: {
    color: '#000 !important',
    fontFamily: 'poppins',
    fontSize: '12px',
    height: '30px',
    paddingTop: '20px',
    '& .MuiTypography-body1': {
      fontSize: '12px',
      fontFamily: 'poppins',
    },
  },
  inputField: {
    position: 'relative',
    '& .MuiOutlinedInput-input:-webkit-autofill': {
      '-webkit-box-shadow': '0 0 0 100px #fff inset !important',
    },
    '& .MuiInputAdornment-root': {
      display: 'none',
    },
    '& .MuiOutlinedInput-root': {
      padding: '8px 14px',
      height: '100%',
      borderRadius: '0px',
      '&.Mui-focused': {
        '& .MuiInputAdornment-root': {
          display: 'contents',
        },
      },
    },
    '& textarea, input': {
      padding: '0px !important',
      paddingLeft: '22px!important',
      fontFamily: 'Poppins !important',
      fontSize: '12px',
      height: '24px',
      position: 'relative',
      '&::placeholder': {
        position: 'absolute',
        left: '0px',
        zIndex: '99999',
        top: '5px',
      },
    },
  },
  icon: {
    borderRadius: 6,
    width: 24,
    height: 24,
    margin: '2px',
    border: '1.5px solid #1C78AD',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedicon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#1C78AD',
    height: 24,
    width: 24,
    borderRadius: '6px',
    paddingBottom: '3px',
    marginRight: '4px',
    '& .check': {
      display: 'inline-block',
      transform: 'rotate(45deg)',
      height: 16,
      width: 8,
      borderBottom: '3px solid #FFF',
      borderRight: '3px solid #FFF',
    },
  },
  root: {
    border: '1px solid #1C78AD',
    borderTop: '0px',
    borderRadius: '0px 0px 6px 6px',
    marginTop: '0px',
    boxShadow: 'none',
    '& .MuiAutocomplete-listbox': {
      padding: '0px',
    },
  },
  option: {
    fontSize: 12,
    fontFamily: 'poppins',
    background: '',
    padding: '10px 35px 10px 20px',
    borderBottom: '1px solid #D8D8D8',
    '&:last-child': {
      borderBottom: '0px',
    },
    '&:hover': {
      background: '#D3E5EF50',
    },
  },
});

export const PaymentWidgetStepSetPaymentAuthorisationRules = () => {
  const [step, setStep] = useState<PaymentAuthoritySteps | undefined>();
  const [isAuthorisationRequired, setIsAuthorisationRequired] = useState<boolean>(false);
  const [isApprovalCodeRequired, setIsApprovalCodeRequired] = useState<boolean>(false);
  const [requiredApprovalCount, setRequiredApprovalCount] = useState<number>(1);
  const [authorisationRequestMode, setAuthorisationRequestMode] = useState<AuthorisationRequestMode>(
    AuthorisationRequestMode.SEQUENTIAL,
  );
  const [selectedAuthorisers, setSelectedAuthorisers] = useState<Authorisers[]>([]);
  const [authorisersList, setAuthorisersList] = useState<Authorisers[]>([]);
  const [activeUsersList, setActiveUsersList] = useState<Authorisers[]>([]);
  const [securityCode, setSecurityCode] = useState<string>('');
  const [securityCodeErrorMsg, setSecuityCodeErrorMsg] = useState('');

  const handleChangeSecurityCode = (code: any) => {
    if (securityCodeErrorMsg) setSecuityCodeErrorMsg('');
    return setSecurityCode(code);
  };

  const {
    cardToEdit,
    setStep: widgetStep,
    getPaymentMethodAuthorisationRules,
    savePaymentAuthorisationRules: savePaymentAuthorisationRulesAPI,
    setIsRefreshPaymentMethods,
    userID,
    widgetScope,
    onDone,
    onCancel: onCancelContext,
    setPaymentAuthorisationRules,
    paymentAuthRules,
    resetAuthenticationCode,
    setPaymentAuthorisationDetails,
    approvePaymentAuthorisation,
    paymentAuthorisationDetails,
    getPaymentMethodAuthorisationDetails,
    marketplaceSupplier,
    storedPaymentOptions,
    setSelectedPaymentMethod,
    scheduledPaymentsV2,
    isAPOnboardingV1,
  } = useContext(PaymentWidgetContext);
  const zohoChatContext = useContext(ZohoContext)!;
  const {setArModalToShow} = useContext(ARContext);
  const {showChatWindow} = zohoChatContext;
  const classes = useStyles();
  const {transformPaymentAuthorisationUserInfo, getAuthenticationCodeErrorMsg} = usePaymentUtilities();
  const {paymentAuthorityChangeNotification} = usePaymentAuthorityAPI();

  const zeroNewCode = paymentAuthorisationDetails?.newCodesRemainingCount === 0;
  const zeroCodeAttempts = paymentAuthorisationDetails?.attemptsRemainingCount === 0;
  const isAtemptsExhausted = zeroNewCode && zeroCodeAttempts;

  useEffect(() => {
    getPaymentAuthorityRules();
  }, []);

  const getPaymentAuthorityRules = async () => {
    if (cardToEdit?.PaymentAccountGUID && getPaymentMethodAuthorisationRules) {
      const result = await getPaymentMethodAuthorisationRules(cardToEdit.PaymentAccountGUID);
      setPaymentAuthorisationRules?.(result);
      const {users, activeRules, pendingRules} = result;
      if (pendingRules === null) {
        setStep(PaymentAuthoritySteps.AUTHORISATION_SETTING);
      } else {
        widgetStep && widgetStep(PaymentWidgetPaymentSteps.APPROVE_AUTHORISATION_RULES);
      }

      const {
        isApprovalCodeRequired: defaultIsApprovalCodeRequired,
        requiredApprovalCount: defaultRequiredApprovalCount,
        isAuthorisationRequired: defaultIsAuthorisationRequired,
        authorisationRequestMode: defaultAuthorisationRequestMode,
        authoriserUserIDs,
      } = activeRules;

      const updatedUsers = transformPaymentAuthorisationUserInfo(users, userID);
      setAuthorisersList(updatedUsers);
      if (authoriserUserIDs?.length) {
        const authorisedUsers = authoriserUserIDs
          .map(a => users.find(b => a === b.userID))
          .filter(a => a !== undefined);
        const updatedAuthorisedUsers = authorisedUsers.length
          ? transformPaymentAuthorisationUserInfo(authorisedUsers as Authorisers[], userID)
          : [];
        setActiveUsersList(updatedAuthorisedUsers);
        setSelectedAuthorisers(updatedAuthorisedUsers);
      } else {
        if (userID) {
          // added self as default user, if authorisation ID array is empty
          const defaultUser = users.find(a => a.userID === userID);
          setActiveUsersList([defaultUser!]);
          setSelectedAuthorisers([defaultUser!]);
        }
      }
      setIsApprovalCodeRequired(defaultIsApprovalCodeRequired);
      setRequiredApprovalCount(defaultRequiredApprovalCount);
      setIsAuthorisationRequired(defaultIsAuthorisationRequired);
      setAuthorisationRequestMode(defaultAuthorisationRequestMode);
    }
  };

  const modulesWidgetScopes =
    widgetScope &&
    [
      PaymentWidgetScope.PIBL,
      PaymentWidgetScope.ACCOUNT_PAYABLE,
      PaymentWidgetScope.PSBL,
      PaymentWidgetScope.PREPAYMENT,
      PaymentWidgetScope.SPENDA_FINANCE,
    ].includes(widgetScope);

  const isAutoPayEnabled = Boolean(storedPaymentOptions?.find(pm => pm.IsInvigoDefault));

  const gotoInvigoInfo = () => {
    setSelectedPaymentMethod && setSelectedPaymentMethod(cardToEdit as ITenantSupplierPaymentMethod);
    widgetStep && widgetStep(PaymentWidgetPaymentSteps.INVIGO_CREDIT_INFO);
  };

  const onSaveAuthorisation = () => {
    if (setIsRefreshPaymentMethods) {
      setIsRefreshPaymentMethods(true);
    }
    if (!isAutoPayEnabled && marketplaceSupplier?.IsLendingProvider && marketplaceSupplier.CreditLimit) {
      gotoInvigoInfo();
    } else if (widgetStep && modulesWidgetScopes) {
      widgetStep(PaymentWidgetPaymentSteps.NEW);
    } else if (widgetScope === PaymentWidgetScope.CONFIGURE_PAYMENT_OPTIONS) {
      onDone && onDone();
    } else if (
      widgetScope === PaymentWidgetScope.ADD_CREDIT_CARD ||
      widgetScope === PaymentWidgetScope.ADD_BANK_ACCOUNT ||
      widgetScope === PaymentWidgetScope.ADD_AIRPLUS ||
      widgetScope === PaymentWidgetScope.VERIFY_PAYMENT_METHOD
    ) {
      onCancelContext && onCancelContext();
    }
  };

  const savePaymentAuthorisationRules = async () => {
    const payload = {
      friendlyName: cardToEdit?.FriendlyName,
      isInvigoDefault: cardToEdit?.IsInvigoDefault,
      authorisationRules: {
        isAuthorisationRequired,
        isApprovalCodeRequired: isApprovalCodeRequired,
        requiredApprovalCount,
        authorisationRequestMode,
        authoriserUserIDs: selectedAuthorisers.map(user => user.userID),
      },
    } as AuthorisationRulesRequest;

    if (savePaymentAuthorisationRulesAPI && cardToEdit?.PaymentAccountGUID) {
      await savePaymentAuthorisationRulesAPI(payload, cardToEdit?.PaymentAccountGUID);
      if (scheduledPaymentsV2) {
        const authorityChangeResponse = await paymentAuthorityChangeNotification(cardToEdit?.PaymentAccountGUID);
        if (authorityChangeResponse?.length) {
          setArModalToShow({modal: ARModals.PaymentAuthorityChangeAlertModal, data: authorityChangeResponse});
        }
      }
      const getRules = await getPaymentMethodAuthorisationRules?.(cardToEdit?.PaymentAccountGUID);
      getRules && setPaymentAuthorisationRules?.(getRules);
      if (getRules?.authorisation?.status === AuthorisationStatus.AwaitingCode) {
        const authDetails = await getPaymentMethodAuthorisationDetails?.(getRules?.authorisation.authorisationID!);
        setPaymentAuthorisationDetails?.(authDetails?.authorisation!);
        setStep(PaymentAuthoritySteps.ENTER_OTP);
      } else if (getRules?.pendingRules !== null) {
        setStep(PaymentAuthoritySteps.CHANGE_REQUEST_MSG);
      } else {
        onSaveAuthorisation();
      }
    }
  };

  const handleResetSecurityCode = async () => {
    if (paymentAuthRules?.authorisation.authorisationID && resetAuthenticationCode) {
      const resetAuthenticationCodeResponse = await resetAuthenticationCode(
        paymentAuthRules.authorisation.authorisationID,
      );
      Toast.info(`Successfully sent a new security code.`);
      setPaymentAuthorisationDetails?.(resetAuthenticationCodeResponse?.authorisation);
      handleChangeSecurityCode('');
    }
  };

  const verifySecurityCode = async () => {
    if (isAtemptsExhausted) {
      showChatWindow();
      return;
    }
    if (approvePaymentAuthorisation && paymentAuthRules?.authorisation.authorisationID) {
      const authorisePaymentResponse = await approvePaymentAuthorisation(
        {approvalCode: securityCode!},
        paymentAuthRules?.authorisation?.authorisationID,
      );
      setPaymentAuthorisationDetails?.(authorisePaymentResponse.authorisation);
      if (authorisePaymentResponse?.authorisation?.status === AuthorisationStatus.Complete) {
        onSaveAuthorisation();
      } else if (authorisePaymentResponse?.authorisation?.status === AuthorisationStatus.Approved) {
        setStep(PaymentAuthoritySteps.CHANGE_REQUEST_MSG);
      }
      const errors = authorisePaymentResponse?.error.errors;
      if (errors.length) {
        const erroMsg = getAuthenticationCodeErrorMsg(authorisePaymentResponse);
        setSecuityCodeErrorMsg(erroMsg);
      }
    }
  };

  const cancelHandler = () => {
    if (setIsRefreshPaymentMethods) setIsRefreshPaymentMethods(true);
    if (!isAutoPayEnabled && marketplaceSupplier?.IsLendingProvider && marketplaceSupplier.CreditLimit) {
      gotoInvigoInfo();
    } else if (widgetStep && modulesWidgetScopes) {
      widgetStep(PaymentWidgetPaymentSteps.NEW);
    } else {
      onCancelContext && onCancelContext();
    }
  };

  const handleRequiredApprovalCount = (e: React.ChangeEvent<HTMLInputElement>) => {
    const count = parseInt(e.target.value);
    if (count == 1) {
      setAuthorisationRequestMode(AuthorisationRequestMode.PARALLEL);
    }
    setRequiredApprovalCount(count);
  };

  const handleAuthorisationRequestMode = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAuthorisationRequestMode(e.target.value as AuthorisationRequestMode);
  };

  const hanldleAuthorisers = (a: Authorisers) => {
    if (typeof a === 'object') {
      setSelectedAuthorisers(prev => [...prev, a]);
    }
  };

  const hanldeUserSequenceChangeUp = (index: number) => {
    if (index == 0) return;
    const toIndex = index - 1;
    const user = selectedAuthorisers[index];

    const authorisers = cloneDeep(selectedAuthorisers);
    authorisers.splice(index, 1);
    authorisers.splice(toIndex, 0, user);

    setSelectedAuthorisers(authorisers);
  };

  const hanldeUserSequenceChangeDown = (index: number) => {
    if (index === selectedAuthorisers.length - 1) return;

    const toIndex = index + 1;
    const user = selectedAuthorisers[index];

    const authorisers = cloneDeep(selectedAuthorisers);
    authorisers.splice(index, 1);
    authorisers.splice(toIndex, 0, user);

    setSelectedAuthorisers(authorisers);
  };

  const disableSubmitButton = () => {
    switch (true) {
      case selectedAuthorisers.length <= 0:
        return true;

      case requiredApprovalCount === 2 && selectedAuthorisers.length < 2:
        return true;

      default:
        return false;
    }
  };

  const AuthenticationSettings = () => (
    <div className="payment-authority-container">
      <div className="mb-10 mt-16 flex justify-center">
        <img className="h-18 w-16" src={lock} alt="lockicon" />
      </div>

      <h6 className="header-text pt-2">Authentication settings</h6>

      <p className="authentication-desc">
        When this option is enabled, Spenda will add an additional layer of security around your payment workflow. We’ll
        send a six-digit code that must be entered in order to authorise a payment.
      </p>

      <div>
        <SCheckbox
          label="Yes please, enable authentication on this payment option"
          checked={isApprovalCodeRequired}
          onChange={e => setIsApprovalCodeRequired(e.target.checked)}
          dat-autoid="chkAuthenticationSettings"
          className="text-spenda-primarytext"
          fontFamily="Poppins"
          lableFontSize="0.75rem"
          lableFontWeight={500}
          labelAlign="start"
          icon={<span className={classes.icon} />}
          checkedIcon={
            <span className={classes.checkedicon}>
              <span className="check" />
            </span>
          }
        />
      </div>
    </div>
  );

  const AuthorisationSettings = () => (
    <div className="pl-5 pr-4">
      <h6 className="header-text pb-16 pt-12">Authorisation settings</h6>

      <FormControl component="fieldset" className={classes.radioButton}>
        <FormLabel className={classes.label}>
          {isAPOnboardingV1 ? 'How many people need to authorise payments?' : 'How many people need to authorise?'}
        </FormLabel>
        <RadioGroup
          row
          aria-label="position"
          name="position"
          defaultValue="top"
          value={requiredApprovalCount}
          onChange={handleRequiredApprovalCount}
          className="justify-around"
        >
          <FormControlLabel
            className={classes.label}
            value={1}
            control={<Radio color="primary" />}
            label="One"
            labelPlacement="end"
            data-autoid="chkOne"
          />
          <FormControlLabel
            className={classes.label}
            value={2}
            control={<Radio color="primary" />}
            label="Two"
            labelPlacement="end"
            data-autoid="chkTwo"
          />
        </RadioGroup>
      </FormControl>

      <div className="my-px flex h-8 items-center px-6 pt-3">
        {authorisersList?.length && authorisersList.length <= 1 ? (
          <>
            <img src={infoIcon} className="mr-2" width="15px" height="15px" />
            <p className="text-left font-poppins font-medium text-spenda-primarytext" style={{fontSize: '7px'}}>
              If you would like to include more than one authoriser, please add more users through the manage user
              settings.{' '}
            </p>
          </>
        ) : null}
      </div>

      <div className={requiredApprovalCount === 1 ? 'opacity-50' : ''}>
        <p className="px-2 pb-16 pt-5 text-xs">
          Decide whether authorisation must be sequential according to the listed authorisers, or if it can happen in
          any order.
        </p>

        <FormControl component="fieldset" className={classes.radioButton}>
          <FormLabel className={classes.label}>What is the order of the authorisation process?</FormLabel>
          <RadioGroup
            aria-label="position"
            name="position"
            defaultValue="top"
            value={authorisationRequestMode}
            onChange={requiredApprovalCount === 1 ? undefined : handleAuthorisationRequestMode}
          >
            <FormControlLabel
              className={classes.label2}
              value={AuthorisationRequestMode.SEQUENTIAL}
              control={<Radio color="primary" />}
              label="Authorisation must be sequential"
              labelPlacement="end"
              data-autoid="chkSequentialAuthorisation"
            />
            <FormControlLabel
              className={classes.label2}
              value={AuthorisationRequestMode.PARALLEL}
              control={<Radio color="primary" />}
              label="Authorisation can happen in any order"
              labelPlacement="end"
              data-autoid="chkAnyOrderAuthorisation"
            />
          </RadioGroup>
        </FormControl>
      </div>
    </div>
  );

  const SelectPeopleToAuthorise = () => {
    const filteredAuthors = authorisersList.filter(
      authoriser => !selectedAuthorisers.find(user => authoriser?.userID == user?.userID),
    );

    const showAutoSelect = () => {
      switch (true) {
        case !filteredAuthors.length:
          return false;

        case authorisationRequestMode === AuthorisationRequestMode.SEQUENTIAL &&
          selectedAuthorisers.length >= requiredApprovalCount:
          return false;

        default:
          return true;
      }
    };

    return (
      <div className="px-5">
        <h6 className="header-text pb-1 pt-12">Authorisation settings</h6>

        <p className="authentication-settings-desc">
          People on this list have authority to charge or authorise payments from this payment option. Others can still
          submit payment requests.
        </p>

        <p className="pb-4 text-left text-xs">People who are allowed to authorise: </p>

        <div className="selected-authorisers-container">
          <div>
            {selectedAuthorisers?.map((a, i) => (
              <div key={i} className="flex h-10 items-center border-spenda-scream bg-spenda-cream px-2 ">
                <span className="mx-2 w-2 font-poppins text-xs font-normal">{`${i + 1}.`}</span>
                <span className="mx-1 flex w-full justify-start font-poppins text-xs font-normal">{a?.name}</span>
                {authorisationRequestMode === AuthorisationRequestMode.SEQUENTIAL && (
                  <>
                    <span
                      className=" flex h-5 w-5 cursor-pointer items-center justify-center rounded-md border-spenda-dashbg"
                      onClick={() => hanldeUserSequenceChangeUp(i)}
                    >
                      <ArrowDropUpOutlinedIcon style={{color: '#D8D8D8'}} />
                    </span>
                    <span
                      className=" mx-1 flex h-5 w-5 cursor-pointer items-center justify-center rounded-md border-spenda-dashbg"
                      onClick={() => hanldeUserSequenceChangeDown(i)}
                    >
                      <ArrowDropDownOutlinedIcon style={{color: '#D8D8D8'}} />
                    </span>
                  </>
                )}

                <span
                  className="flex h-5 w-5 cursor-pointer items-center justify-center"
                  data-autoid={`btnDeleteAuthoriser-${i + 1}`}
                  onClick={() => {
                    setSelectedAuthorisers((a: Authorisers[]) =>
                      selectedAuthorisers.filter(b => b?.userID !== a[i]?.userID),
                    );
                  }}
                >
                  <DeleteIcon style={{color: '#D8D8D8'}} />
                </span>
              </div>
            ))}
          </div>

          {showAutoSelect() && (
            <Autocomplete
              freeSolo
              disableClearable={true}
              options={filteredAuthors}
              getOptionLabel={(a: Authorisers) => a?.name!}
              onChange={(_, a) => hanldleAuthorisers(a as Authorisers)}
              renderInput={params => (
                <div className={`${classes.inputField}`}>
                  <STextField
                    {...params}
                    placeholder={`${selectedAuthorisers.length + 1}.    Type user`}
                    variant="outlined"
                    borderColor="#EAEAEA"
                    backgroundColor="#FAFAFA"
                    data-autoid="txtTypeUser"
                  />
                </div>
              )}
              classes={{
                option: classes.option,
                paper: classes.root,
              }}
            />
          )}
        </div>
      </div>
    );
  };

  const body = (
    <div className="font-poppins">
      {step === PaymentAuthoritySteps.AUTHENTICATION_SETTINGS && <AuthenticationSettings />}
      {step === PaymentAuthoritySteps.AUTHORISATION_SETTING && <AuthorisationSettings />}
      {step === PaymentAuthoritySteps.SELECT_PEOPLE_TO_AUTHORIZE && <SelectPeopleToAuthorise />}
      {step === PaymentAuthoritySteps.ENTER_OTP && (
        <EnterSecurityCode
          securityCode={securityCode}
          errorMsg={securityCodeErrorMsg}
          setSecurityCode={code => handleChangeSecurityCode(code)}
          handleResetSecurityCode={handleResetSecurityCode}
          paymentAuthorisationDetails={paymentAuthorisationDetails!}
          onEnterVerificationCode={verifySecurityCode}
        />
      )}
      {step === PaymentAuthoritySteps.CHANGE_REQUEST_MSG && (
        <ChangeRequestMsg nextAuthorisers={activeUsersList.filter(a => a.userID !== userID)} />
      )}
    </div>
  );

  const footer = (
    <>
      {step === PaymentAuthoritySteps.AUTHENTICATION_SETTINGS && (
        <>
          <SecondaryButton width="100px" label="Cancel" onClick={cancelHandler} />
          <PrimaryButton width="100px" label="Save" onClick={savePaymentAuthorisationRules} />
        </>
      )}

      {step === PaymentAuthoritySteps.AUTHORISATION_SETTING && (
        <>
          <SecondaryButton width="100px" label="Cancel" onClick={cancelHandler} />
          <PrimaryButton
            width="100px"
            label="Next"
            onClick={() => setStep(PaymentAuthoritySteps.SELECT_PEOPLE_TO_AUTHORIZE)}
          />
        </>
      )}

      {step === PaymentAuthoritySteps.SELECT_PEOPLE_TO_AUTHORIZE && (
        <>
          <SecondaryButton width="100px" label="Cancel" onClick={cancelHandler} />
          <WidgetTooltip
            disableHoverListener={selectedAuthorisers.length > 0}
            title={'Please add at least one authoriser'}
            placement="top"
          >
            <div>
              <PrimaryButton
                disabled={disableSubmitButton()}
                width="100px"
                label="Next"
                onClick={() => setStep(PaymentAuthoritySteps.AUTHENTICATION_SETTINGS)}
              />
            </div>
          </WidgetTooltip>
        </>
      )}
      {step === PaymentAuthoritySteps.ENTER_OTP && (
        <>
          <SecondaryButton label="Cancel" onClick={onSaveAuthorisation} />
          <PrimaryButton
            label={isAtemptsExhausted ? 'Support' : 'Verify'}
            onClick={verifySecurityCode}
            disabled={securityCode.trim().length < 6 || (!zeroNewCode && zeroCodeAttempts)}
          />
        </>
      )}
      {step === PaymentAuthoritySteps.CHANGE_REQUEST_MSG && (
        <div className="w-full justify-center">
          <PrimaryButton width="100px" label="Done" onClick={() => onSaveAuthorisation()} />
        </div>
      )}
    </>
  );

  return <PaymentWidgetStep body={body} footer={footer} />;
};

const ChangeRequestMsg = (props: {nextAuthorisers: Authorisers[]}) => {
  const {nextAuthorisers} = props;
  return (
    <div className="font-poppins ">
      <h1 className="text-xxl mb-16 mt-12 flex justify-center font-light">Change request</h1>

      <p className="flex justify-center px-12 py-7 text-xs font-normal ">
        As a security measure we require secondary authorisation to any changes made to the payment option.
      </p>
      <p className="mt-1 flex flex-col justify-center px-12 text-xs font-normal">
        <span className="pb-3">We have sent a notification to the following to authorise these changes:</span>
        {nextAuthorisers.map(a => (
          <p key={a.userID} >{a.name}</p>
        ))}
      </p>
    </div>
  );
};
