import React, {FC, useEffect, useState} from 'react';
import {AlertDialogSlideV2, IDialogActionV2} from './AlertDialogSlideV2';
import QuoteAttachment from '../../screens/quote-management/QuoteAttachment';
import {useAttachmentsAPI} from '../../services/useAttachmentsAPI';
import {AttachmentTypeEnum} from '../../model/constants/Constants';
import {IQuoteAttachment} from '../../model/quotes/quotes';

type QuoteAttachmentDialogProps = {
  open: boolean;
  handleOpenAndClose: React.Dispatch<React.SetStateAction<boolean>>;
  quoteID?: number;
  uploadedAttachments?: IQuoteAttachment[];
  deleteQuoteAttachment?: (guid: string) => void;
  fetchAttachments?: () => void;
  customerView?: boolean;
};

export const QuoteAttachmentDialog: FC<QuoteAttachmentDialogProps> = props => {
  const {handleOpenAndClose, uploadedAttachments, deleteQuoteAttachment, fetchAttachments, customerView} = props;
  const {uploadAttachments} = useAttachmentsAPI();
  const [isUploading, setIsUploading] = useState(false);
  const [addedFilesToUpload, setAddedFilesToUpload] = useState<File[]>([]);

  const deleteFile = (index: number) => {
    let data = [...addedFilesToUpload];
    if (index >= 0) {
      data.splice(index, 1);
      setAddedFilesToUpload(data);
    }
  };

  const onSubmit = async () => {
    try {
      setIsUploading(true);

      if (!props.quoteID) return;

      if (addedFilesToUpload?.length) {
        const formData = new FormData();

        addedFilesToUpload?.forEach((attachment: File) => {
          formData.append('Files', attachment, attachment.name);
        });

        const attachments = addedFilesToUpload?.map((n, i) => {
          return {
            Caption: addedFilesToUpload?.[i]?.name,
            Note: '',
            Action: 0,
          };
        });

        const jsonRequest = {
          QuoteID: props.quoteID,
          Attachments: attachments || [],
        };

        formData.append('AttachmentType', JSON.stringify(AttachmentTypeEnum.Quotes));
        formData.append('JsonRequest', JSON.stringify(jsonRequest));

        await uploadAttachments(formData, {'content-type': 'multipart/form-data'});
        setIsUploading(false);
      }
    } catch {
      setIsUploading(false);
    }
  };

  useEffect(() => {}, [customerView]);

  const actions: IDialogActionV2[] = [
    {
      label: customerView ? 'Close' : 'Save & Close',
      action: async () => {
        if (customerView) {
          handleOpenAndClose(false);
        } else {
          await onSubmit();
          fetchAttachments?.();
          handleOpenAndClose(false);
        }
      },
    },
  ];

  if (!customerView) {
    actions.unshift({
      label: 'Cancel',
      variant: 'outlined',
      action: () => {
        handleOpenAndClose(false);
      },
    });
  }

  return (
    <AlertDialogSlideV2
      title={customerView ? 'View attachment' : 'Add attachment'}
      headingTextSize="h2"
      data-autoid={customerView ? 'dlgViewAttachment' : 'dlgAddAttachment'}
      dialogActionsAlignment={customerView ? 'justify-center' : 'justify-between'}
      dialogBodyClassess="flex flex-col first:gap-y-5  gap-y-2 items-center h-[330px] pt-7 overflow-y-auto max-h-[18rem]"
      actions={actions}
    >
      <QuoteAttachment
        addedFilesToUpload={addedFilesToUpload}
        setAddedFilesToUpload={setAddedFilesToUpload}
        deleteFile={deleteFile}
        isUploading={isUploading}
        uploadedAttachments={uploadedAttachments}
        deleteQuoteAttachment={deleteQuoteAttachment}
        customerView={customerView}
      />
    </AlertDialogSlideV2>
  );
};
