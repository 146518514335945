import React, {useEffect, useState} from 'react';
import {ARDialogTemplate} from '../ARDialogTemplate';
import {STextField} from '../../inputs/STextField';
import {Button, Typography} from 'spenda-ui-react';
interface IClaimLineItemNoteDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (reason: string) => void;
  isSubmitting: boolean;
  note?: string;
}

export const ClaimLineItemNoteDialog = (props: IClaimLineItemNoteDialogProps) => {
  //Props
  const {isOpen, isSubmitting, note, onClose, onConfirm} = props;

  // State
  const [lineItemNote, setLineItemNote] = useState('');

  useEffect(() => {
    setLineItemNote(note || '');
  }, [note]);

  const handleSubmit = () => {
    onConfirm(lineItemNote);
  };

  return (
    <ARDialogTemplate
      dialogProps={{size: 'xs', open: isOpen, className: '!min-w-[460px] !max-w-[460px]'}}
      isFullScreen
      header={<p className=" font-poppins text-xl font-light text-[#333333]">Add note</p>}
      body={
        <div className=" mb-12 mt-[14px] flex w-full flex-col items-start p-3">
          <Typography variant="paragraph" className="mb-12 text-center font-light text-black-800">
            Please specify the note below
          </Typography>
          <div className="h-16 w-full">
            <STextField
              v2
              fullWidth
              maxLength={500}
              label="Add note"
              multiline
              height="300px"
              name="AddClaimLineItemNote"
              value={lineItemNote}
              onChange={e => setLineItemNote(e?.target?.value)}
              placeholder="Note description"
              minRows={4}
              maxRows={4}
            />
          </div>
        </div>
      }
      footer={
        <div className="flex w-full flex-row items-center justify-between">
          <Button
            data-autoid={`btnCancel`}
            className="bg-white"
            onClick={() => onClose()}
            variant="outlined"
            color="primary"
          >
            Cancel
          </Button>
          <Button
            disabled={isSubmitting}
            loading={isSubmitting}
            variant="filled"
            color="primary"
            data-autoid={`btnSubmit`}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </div>
      }
      handleClose={() => onClose()}
    />
  );
};
