import useHttp from '../hooks/useHttp';
import {IResponseWrapper} from '../model/ActionResults';

export interface IPrefixSuffix {
  prefix: string | null;
  suffix: string | null;
}

export const useAPConfigs = () => {
  const {GET, POST, isLoading} = useHttp();

  const getPrefixSuffix = async (): Promise<IPrefixSuffix> => {
    return GET(`Spenda/Payments/AccountsPayable/Config/RefNumberPrefixSuffix`).then((res: IResponseWrapper<IPrefixSuffix>) => res.value);
  };

  const savePrefixSuffix = (payload: IPrefixSuffix): Promise<IPrefixSuffix> => {
    return POST('Spenda/Payments/AccountsPayable/Config/RefNumberPrefixSuffix', payload).then((data: IResponseWrapper<IPrefixSuffix>) => data.value);
  };

  return {
    getPrefixSuffix,
    savePrefixSuffix,
    isLoading,
  };
};
