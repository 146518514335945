import React from 'react';
import {Button, Dialog, DialogBody, DialogFooter, Typography} from 'spenda-ui-react';
import {IconQuoteAccepted} from '../../assets/svg/quotes-management/IconQuoteAccepted';
import {IconQuoteRejected} from '../../assets/svg/quotes-management/IconQuoteRejected';

interface IQuoteAcceptedRejectedDialog {
  open: boolean;
  isAccepted?: boolean;
  handleClose: () => void;
}

export const QuoteAcceptedRejectedDialog = (props: IQuoteAcceptedRejectedDialog) => {
  const {open, isAccepted, handleClose} = props;

  return (
    <Dialog
      open={open}
      handler={() => {}}
      className={`flex h-full !w-full !min-w-[20%] !max-w-full flex-col items-center justify-center p-8 sm:block sm:h-auto sm:!max-w-[339px] sm:p-0`}
    >
      <DialogBody className="my-[4.5rem] flex flex-col items-center justify-center gap-10">
        {isAccepted ? <IconQuoteAccepted /> : <IconQuoteRejected />}
        <Typography className={`${isAccepted ? 'text-primary' : 'text-[#C55D44]'}`} variant="h2">
          {isAccepted ? 'Quote accepted' : 'Quote rejected'}
        </Typography>
        <div className="flex w-[234px] flex-col items-center justify-center gap-5 text-center">
          {isAccepted ? (
            <Typography variant="small">You have successfully accepted quote.</Typography>
          ) : (
            <Typography variant="small">Your quote has been rejected.</Typography>
          )}
        </div>
      </DialogBody>
      <DialogFooter className="p-2.5">
        <div className="flex w-full items-center justify-center rounded-lg p-2 sm:bg-[#f1f1f1]">
          <div className="flex gap-2">
            <Button variant="filled" color="primary" onClick={handleClose} data-autoid="btnCloseQuote">
              Close
            </Button>
          </div>
        </div>
      </DialogFooter>
    </Dialog>
  );
};
