import React from 'react';
import SVGIcon from '../ui/SVGIcon';
import {Button, Typography} from 'spenda-ui-react';
import {colors} from 'spenda-ui-react/types/generic';
import {variant} from 'spenda-ui-react/types/components/button';

export interface IOptionProps {
  svg?: any;
  label?: string;
  description?: string;
  color?: colors;
  variant?: variant;
  isButton?: boolean;
  action: () => void;
  border?: string;
}

export const Dropdown = (props: {options: IOptionProps[]; title?: string}) => {
  let hasIconSettings = props.options.find(opt => opt.isButton);

  return (
    <ul>
      <li className={`cursor-default mb-2 ${!hasIconSettings ? 'border-b border-spenda-dashbg' : ''}`}>
        <Typography variant="h3" className="relative text-start">
          {props.title}
        </Typography>
      </li>
      {props.options.map((opt: IOptionProps, id: number) => (
        <li key={id}>
          {opt.isButton ? (
            <Button
              data-autoid={`btn${opt?.label?.replace(/ /g, '')}Setting`}
              color={opt.color}
              onClick={opt.action}
              variant={opt.variant}
              fullWidth
            >
              {opt.label}
            </Button>
          ) : (
            <div
              data-autoid={`btn${opt?.label?.replace(/ /g, '')}Setting`}
              onClick={opt.action}
              className="flex p-2 cursor-pointer float-left mb-2"
            >
              <div className="pt-3">{opt.svg && <SVGIcon name={opt.svg} />}</div>
              <div className="flex flex-col text-left ml-2">
                <Typography variant="h3" className="text-primary">
                  {opt.label}
                </Typography>
                <Typography>{opt.description}</Typography>
              </div>
            </div>
          )}
        </li>
      ))}
    </ul>
  );
};
