import React, { useState } from 'react';
import { Button, IconButton, Menu, MenuHandler, MenuList, Typography } from 'spenda-ui-react';

import { Toast } from '../../utils/Toast';
import { KebabMenu } from '../../assets/svg';
import { PriceFormat } from '../../utils/formatter';
import { DeleteQuotePackageDialog } from '../dialog';
import useQuotesAPI from '../../services/useQuotesAPI';
import { DISCOUNT_MODE } from '../../model/constants/Constants';
import { IQuoteLines, IQuotes } from '../../model/quotes/quotes';
import { useQuoteContext } from '../../context/quote-context/QuoteContext';
import PlusButton from '../../assets/svg/service-management/PlusButton';

interface IQuoteVariantV2 {
  isQuoteSent?: boolean;
  isSaving?: boolean;
  handleSelectedQuoteVariant?: (quoteID: number) => Promise<void>;
}
const QuoteVariantButton = (props:  {
  index: number;
  active: boolean;
  quote?: IQuotes;
  isQuoteSent?: boolean;
  isSaving?: boolean;
  handleSelectedQuoteVariant?: (quoteID: number) => Promise<void>;
}) => {
  const { active, index, quote, isQuoteSent, isSaving } = props;

  const {
    isLoading,
    quotePackage,
    setQuotePackage,
    selectedQuoteVariantID,
    selectedVariantTotalAmount,
    setSelectedQuoteVariantID,
    setSelectedVariantTotalAmount,
    handleDeletePackage,
    handleDeleteQuote,
  } = useQuoteContext();

  const { createQuote } = useQuotesAPI();

  const [showDeleteQuotePackage, setShowDeleteQuotePackage] = useState<boolean>(false);

  const bgColor = active ? 'bg-primary' : 'bg-[#ECECEC]';
  const textColor = active ? 'text-white hover:text-white' : 'text-black hover:text-black';
  const containerClass = `flex items-center justify-between cursor-pointer ${bgColor} rounded-md py-2.5 pr-2.5 pl-3.5 ${isSaving && !active ? 'opacity-40' : null}}`;

  const duplicateVariant = async (quoteId: number) => {
    if (quotePackage?.quotes && quotePackage?.quotes?.length > 4) {
      Toast.error('Cannot create more than 5 variants.');
      return;
    }
    await props.handleSelectedQuoteVariant?.(-1);
    const existingQuoteDetails = quotePackage?.quotes?.find(quote => quote.quoteID === quoteId);
    const newVariantPayload: IQuotes = {
      quotePackageID: quotePackage?.quotePackageID!,
      name: `Quote ${(quotePackage?.quotes?.length || 0) + 1}`,
      description: ``,
      discount: 0,
      discountMode: DISCOUNT_MODE.NONE,
      isActive: true,
      lines: [],
      shipping: 0,
    };
    newVariantPayload.lines =
      existingQuoteDetails?.lines?.map((line: IQuoteLines) => {
        return {
          code: line.code,
          shortDescription: line.shortDescription,
          inventoryID: line.inventoryID,
          quantity: line.quantity,
          sellPriceEx: line.sellPriceEx,
          isActive: true,
        };
      }) || [];
    const createdQuote = await createQuote(newVariantPayload);
    const quotePkg = quotePackage && { ...quotePackage };
    quotePkg && quotePkg.quotes?.push(createdQuote);
    setQuotePackage?.(quotePkg!);
    setSelectedQuoteVariantID?.(createdQuote?.quoteID!);
    setSelectedVariantTotalAmount?.(createdQuote?.totalInc || 0);
  };

  const handleClick = async () => {
    if ((isQuoteSent && isLoading) || selectedQuoteVariantID === quote?.quoteID || isSaving) return;
    await props.handleSelectedQuoteVariant?.(quote?.quoteID!);
  };

  return (
    <div data-autoid={`btnQuoteVariant-${quote?.quoteID}`} className={containerClass} onClick={handleClick}>
      <Button
        ripple={false}
        variant="text"
        data-autoid={`txtQuoteName-${quote?.quoteID}`}
        title={quote?.name || `Quote ${index + 1}`}
        className={`${textColor} truncate cursor-pointer p-0 text-lg font-medium hover:no-underline focus:bg-transparent active:bg-transparent`}
      >
        {quote?.name || `Quote ${index + 1}`}
      </Button>
      <div className="flex items-center gap-x-2">
        <Typography
          data-autoid={`txtTotal-${quote?.quoteID}`}
          variant="small"
          className={`${!active ? 'text-black-800' : 'text-white'} ml-2.5`}
        >
          {PriceFormat(
            selectedQuoteVariantID === quote?.quoteID && !isQuoteSent
              ? selectedVariantTotalAmount
              : quote?.totalInc || 0,
          )}
        </Typography>
        {quote && active && !isQuoteSent ? (
          <Menu offset={-5}>
            <MenuHandler>
              <IconButton
                variant="text"
                ripple={false}
                color={active ? 'white' : 'blue-gray'}
                name={`Menu-${quote?.quoteID}`}
                className={`hover:bg-transparent active:bg-transparent`}
              >
                <KebabMenu />
              </IconButton>
            </MenuHandler>
            <MenuList className="flex flex-col p-0">
              <button
                data-autoid={`btnDeleteQuoteVariant-${quote?.quoteID}`}
                className="min-w-[180px] rounded-none border-b border-[#ECECEC] px-3 py-2.5 text-sm font-normal text-black-900 hover:!bg-primary/10 hover:text-black-900 active:!bg-primary/10"
                onClick={() => {
                  setShowDeleteQuotePackage(true);
                }}
              >
                Discard quote variant
              </button>
              <button
                data-autoid={`btnDuplicateQuoteVariant-${quote?.quoteID}`}
                className="min-w-[180px] rounded-none border-b border-[#ECECEC] px-3 py-2.5 text-sm font-normal text-black-900 hover:!bg-primary/10 hover:text-black-900 active:!bg-primary/10"
                onClick={() => duplicateVariant(quote?.quoteID!)}
              >
                Duplicate quote variant
              </button>
            </MenuList>
          </Menu>
        ) : (
          <div className="py-5"></div>
        )}
      </div>
      {showDeleteQuotePackage && (
        <DeleteQuotePackageDialog
          isSubmitting={isLoading}
          deleteQuotePackage={async () => {
            await handleDeletePackage?.();
            setShowDeleteQuotePackage(false);
          }}
          deleteQuote={async () => {
            await handleDeleteQuote?.(selectedQuoteVariantID);
            setShowDeleteQuotePackage(false);
          }}
          onCancel={() => setShowDeleteQuotePackage(false)}
          showQuoteButton={quotePackage?.quotes! && quotePackage?.quotes?.length > 1}
        />
      )}
    </div>
  );
};

const QuoteVariantV2 = (props: IQuoteVariantV2) => {
  const { isQuoteSent, isSaving, handleSelectedQuoteVariant } = props;

  const { quotePackage, selectedQuoteVariantID, setQuotePackage, setSelectedQuoteVariantID } = useQuoteContext();

  const { createQuote } = useQuotesAPI();

  const createNewVariant = async () => {
    await handleSelectedQuoteVariant?.(-1);
    const payload: IQuotes = {
      quotePackageID: quotePackage?.quotePackageID!,
      name: `Quote ${(quotePackage?.quotes?.length || 0) + 1}`,
      description: ``,
      discount: 0,
      discountMode: DISCOUNT_MODE.NONE,
      isActive: true,
      lines: [],
      shipping: 0,
    };
    const createdQuote = await createQuote(payload);
    const quotePkg = quotePackage && { ...quotePackage };
    quotePkg && quotePkg.quotes?.push(createdQuote);
    setQuotePackage?.(quotePkg!);
    setSelectedQuoteVariantID?.(createdQuote?.quoteID!);
  };

  return (<>
    <div className="relative z-[9999] flex items-center justify-between bg-white mb-2.5">
      <Typography className="text-lg font-medium text-black-800">Variants</Typography>
      {!isQuoteSent && (
        <IconButton
          variant="outlined"
          className="border-none p-0 shadow-none"
          ripple={false}
          disabled={quotePackage?.quotes && quotePackage?.quotes?.length > 4}
        >
          <PlusButton
            className="cursor-pointer"
            data-autoid="btnAddNewVariant"
            onClick={() => {
              createNewVariant();
            }}
          />
        </IconButton>
      )}
    </div>
    <div
      className={`${quotePackage && quotePackage?.quotes && quotePackage?.quotes?.length > 5 ? 'pr-1' : ''
        } flex flex-col gap-y-2.5`}
    >
      {quotePackage?.quotes?.map((quote, index) => {
        return (
          <QuoteVariantButton
            active={selectedQuoteVariantID === quote?.quoteID}
            quote={quote}
            index={index}
            key={index}
            isQuoteSent={isQuoteSent}
            handleSelectedQuoteVariant={handleSelectedQuoteVariant}
            isSaving={isSaving}
          />
        );
      })}
    </div>
  </>
  );
};

export default QuoteVariantV2;
