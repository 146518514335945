import React from 'react';
import {Switch} from 'react-router-dom';
import {PrivateRoute} from './PrivateRoute';
import {MenuTypes, ModuleTypes} from '../model/constants/Constants';
import {QuotesDashboard} from '../screens/quote-management/QuotesDashboard';
import CreateQuote from '../screens/quote-management/CreateQuote';
import {QuotesPreview} from '../screens/quote-management/QuotesPreview';
import QuotesDetail from '../screens/quote-management/quotes-detail/QuotesDetail';
import {useFeatureFlags} from '../hooks/useFeatureFlags';
import {CreateQuotePackageV2} from '../screens/quote-management/CreateQuoteV2';
import { QuoteDetailPackageV2 } from '../screens/quote-management/quotes-detail/QuotesDetailV2';

export const AUTH_SELLING_QUOTES_LIST = '/manage-quotes';
export const AUTH_SELLING_QUOTES_CREATE = `${AUTH_SELLING_QUOTES_LIST}/quote/`;
export const AUTH_SELLING_QUOTES_DETAILS = `${AUTH_SELLING_QUOTES_LIST}/quote-details/`;
export const AUTH_SELLING_QUOTES_PREVIEW = `${AUTH_SELLING_QUOTES_LIST}/preview/`;

export const QuoteManagementRoutes = () => {
  const {QuoteV289367} = useFeatureFlags().tenantOwned();

  return (
    <Switch>
      <PrivateRoute
        path={AUTH_SELLING_QUOTES_LIST}
        exact
        moduleIds={[ModuleTypes.Quotes]}
        Component={QuotesDashboard}
        MenuFilters={{
          MenuType: MenuTypes.Selling,
          ModuleID: ModuleTypes.Quotes,
          ModuleDependency: [ModuleTypes.Quotes],
        }}
      />
      <PrivateRoute
        path={`${AUTH_SELLING_QUOTES_CREATE}:packageId`}
        exact
        moduleIds={[ModuleTypes.Quotes]}
        Component={QuoteV289367 ? CreateQuotePackageV2 : CreateQuote}
        MenuFilters={{
          MenuType: MenuTypes.Selling,
          ModuleID: ModuleTypes.Quotes,
          ModuleDependency: [ModuleTypes.Quotes],
        }}
      />
      <PrivateRoute
        path={`${AUTH_SELLING_QUOTES_PREVIEW}:packageId`}
        exact
        moduleIds={[ModuleTypes.Quotes]}
        Component={QuotesPreview}
        MenuFilters={{
          MenuType: MenuTypes.Selling,
          ModuleID: ModuleTypes.Quotes,
          ModuleDependency: [ModuleTypes.Quotes],
        }}
      />
      <PrivateRoute
        path={`${AUTH_SELLING_QUOTES_DETAILS}:packageId`}
        exact
        moduleIds={[ModuleTypes.Quotes]}
        Component={QuoteV289367 ? QuoteDetailPackageV2 :QuotesDetail}
        MenuFilters={{
          MenuType: MenuTypes.Selling,
          ModuleID: ModuleTypes.Quotes,
          ModuleDependency: [ModuleTypes.Quotes],
        }}
      />
    </Switch>
  );
};
