import React, {useState, useEffect, useContext, Dispatch, SetStateAction} from 'react';
import {makeStyles} from '@material-ui/core';
import AppContext from '../../context/app/appContext';
import {ZohoContext} from '../../context/app/ZohoContext';
import {ITenant} from '../../model/Tenant';
import {IUser} from '../../model/user/User';
import {useAuth} from '../../services/useAuth';
import {CompanyDetailsDialog} from '../dialog/CompanyDetailsDialog';
import {CompanyLocationsDialog} from '../dialog/CompanyLocationsDialog';
import {FeatureFlagsDialog} from '../dialog/BetaFeaturesDialog';
import UsersDialog from '../dialog/UsersDialog';
import {Dropdown, IOptionProps} from '../form/Dropdown';
import {SPopover} from '../modals/modalSpendaMeterialUI';
import {Notifications} from './Notifications';

const MenuPopover = (props: {options: IOptionProps[]; title?: string}) => {
  return (
    <div className={`speech-bubble text-center`}>
      <div className="flex p-2">
        <Dropdown options={props.options} title={props.title} />
      </div>
    </div>
  );
};

interface CustomMenuItem {
  child: any;
}

const useStyles = makeStyles({
  notificationPopover: {
    backgroundColor: 'transparent',
    '& .MuiPopover-paper': {
      borderRadius: '4px',
      top: '60px !important',
    },
  },
});

export interface IMenuProps {
  userAnchorEl: null | HTMLElement;
  setUserAnchorEl: Dispatch<SetStateAction<null | HTMLElement>>;
  settingsAnchorEl: null | HTMLElement;
  setSettingsAnchorEl: Dispatch<SetStateAction<null | HTMLElement>>;
  notificationsAnchorEl: null | HTMLElement;
  setNotificationsAnchorEl: Dispatch<SetStateAction<null | HTMLElement>>;
  paymentAuth72488?: boolean;
  afterUserSettingDialogClose?: () => void;
}

export const HeaderDialogs = (props: IMenuProps) => {
  const {
    userAnchorEl,
    settingsAnchorEl,
    setUserAnchorEl,
    setSettingsAnchorEl,
    notificationsAnchorEl,
    setNotificationsAnchorEl,
    paymentAuth72488,
    afterUserSettingDialogClose,
  } = props;

  const classes = useStyles();

  const appContext = useContext(AppContext);
  // We only use the header in authenticated app, so we will have a user
  let user = appContext.user as IUser;
  let tenant = appContext.tenant as ITenant;

  const [showUsersDialog, setShowUsersDialog] = useState<boolean>(false);
  const [showCompanyDetailsDialog, setShowCompanyDetailsDialog] = useState<boolean>(false);
  const [showCompanyLocationsDialog, setShowCompanyLocationsDialog] = useState<boolean>(false);
  const [showFeatureFlagsDialog, setFeatureFlagsDialog] = useState<boolean>(false);
  const zohoContext = useContext(ZohoContext)!;

  const {logout} = useAuth();

  const [twoSaOptions, setTwoSaOptions] = useState<IOptionProps[]>([]);

  useEffect(() => {
    const options: IOptionProps[] = [];

    options.push({
      label: 'Logout',
      variant: 'outlined',
      isButton: true,
      action: logOut,
    });

    setTwoSaOptions(options);
  }, [user]);

  const logOut = () => {
    logout().finally(() => {
      appContext.logout();
      // reset zoho chat window
      if (zohoContext) {
        zohoContext.resetChatWindow();
      }
    });
  };

  const toggleUsersDialog = () => {
    setShowUsersDialog(!showUsersDialog);
    if (showUsersDialog) {
      afterUserSettingDialogClose?.();
    }
  };

  const toggleCompanyDetailsDialog = () => {
    setShowCompanyDetailsDialog(!showCompanyDetailsDialog);
  };

  const toggleFeatureFlagsDialog = () => {
    setFeatureFlagsDialog(!showFeatureFlagsDialog);
  };

  const toggleCompanyLocationsDialog = () => {
    setShowCompanyLocationsDialog(!showCompanyLocationsDialog);
  };

  const onCloseCompanyDetails = () => {
    setShowCompanyDetailsDialog(false);
  };

  const onCloseCompanyLocations = () => {
    setShowCompanyLocationsDialog(false);
  };

  const settings: IOptionProps[] = [];

  if (!tenant?.IsIndividual) {
    settings.push({
      svg: 'companyDetails',
      label: 'Business Details',
      description: `Manage your business details, your Spenda custom URL and upload business logos.`,
      action: toggleCompanyDetailsDialog,
    });
  }

  settings.push({
    svg: 'companyLocations',
    label: 'Business Locations',
    description: 'Manage your business and warehouse locations here.',
    action: toggleCompanyLocationsDialog,
  });

  settings.push({
    svg: 'manageUsers',
    label: 'Manage Users',
    description: 'Manage your existing users, invite new users and manager user roles.',
    action: toggleUsersDialog,
  });

  settings.push({
    svg: 'experiment',
    label: 'Beta Features',
    description: 'Try some of our newest functionality.',
    action: toggleFeatureFlagsDialog,
  });

  const [userPopover, setUserPopover] = React.useState<CustomMenuItem>({
    child: null,
  });

  useEffect(() => {
    setUserPopover(prevState => {
      return {
        ...prevState,
        child: <MenuPopover options={twoSaOptions} title={user && user.UserName} />,
      };
    });
  }, [twoSaOptions]);

  const [settingsPopover] = React.useState<CustomMenuItem>({
    child: (
      <MenuPopover
        options={settings}
        title={user && user.TenantName && `${user.FirstName + ' ' + user.LastName} Settings`}
      />
    ),
  });

  const updateUser = (newUser: Partial<IUser>) => {
    appContext.setUser({...user, ...newUser});
  };

  return (
    <>
      <SPopover
        id="menuUserPopover"
        open={Boolean(userAnchorEl)}
        onClose={() => setUserAnchorEl(null)}
        anchorEl={userAnchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        className={classes.notificationPopover}
      >
        {userPopover.child}
      </SPopover>

      <SPopover
        id="menuSettingsPopover"
        open={Boolean(settingsAnchorEl)}
        onClose={() => setSettingsAnchorEl(null)}
        anchorEl={settingsAnchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        className={classes.notificationPopover}
      >
        {settingsPopover.child}
      </SPopover>

      {paymentAuth72488 && (
        <SPopover
          id="menuNotificationsPopover"
          open={Boolean(notificationsAnchorEl)}
          onClose={() => setNotificationsAnchorEl(null)}
          anchorEl={notificationsAnchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          className={classes.notificationPopover}
        >
          <Notifications />
        </SPopover>
      )}

      {showCompanyDetailsDialog && <CompanyDetailsDialog onClose={onCloseCompanyDetails}></CompanyDetailsDialog>}
      {showUsersDialog && (
        <UsersDialog
          updateLoggedInUser={updateUser}
          title="User Management"
          actions={[
            {
              label: 'Close',
              variant: 'outlined',
              action: toggleUsersDialog,
            },
          ]}
          logOut={logOut}
          currentUser={user}
          paymentAuth72488={paymentAuth72488}
        ></UsersDialog>
      )}
      {showCompanyLocationsDialog && (
        <CompanyLocationsDialog
          title="Company Locations"
          onClose={onCloseCompanyLocations}
          isAdmin={user.IsAdministrator}
          isSupport={user.IsSupport}
        />
      )}
      {showFeatureFlagsDialog && (
        <FeatureFlagsDialog
          open={true}
          onClose={toggleFeatureFlagsDialog}
          supplierMarket={appContext.marketplaceSupplier}
          updateFeatureFlags={appContext.setFeatureFlags}
          flags={appContext.featureFlags}
        />
      )}
    </>
  );
};
