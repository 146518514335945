import React, {useContext, useEffect, useState} from 'react';
import {Menu as MenuComponent} from '../../components/menu/Menu';
import AppContext from '../../context/app/appContext';
import {IMenuModule} from '../../model/menu/Menu';
import {ITenant} from '../../model/Tenant';
import {IUser} from '../../model/user/User';
import useConnectedSupplierAPI from '../../services/useConnectedSuppliersAPI';
import useHomePageAPI from '../../services/useHomePageAPI';
import {NotificationContext} from '../../context/NotificationContext';
import {isMobile} from 'react-device-detect';
import DisclaimerModal from '../../components/accountsPayableOnboarding/DisclaimerModal';
import {useFeatureFlags} from '../../hooks/useFeatureFlags';
import TechnicianDisclaimerModal from '../../components/service-management/TechnicianDisclaimerModal';
import {useAuth} from '../../services/useAuth';

export const Menu = () => {
  // Context
  const appContext = useContext(AppContext);
  const {notifications, refreshNotifications} = useContext(NotificationContext);

  const {connectedSuppliersSearch} = useConnectedSupplierAPI();
  const {GetModules} = useHomePageAPI();

  const tenant = appContext.tenant as ITenant;
  const user = appContext.user as IUser;
  const modules = appContext.modules as IMenuModule[];
  const supplierId = appContext?.marketplaceSupplier?.SupplierID?.toString();
  const connectedSuppliers = appContext.suppliers || [];
  const [showMobileDisclaimer, setShowMobileDisclaimer] = useState<boolean>(false);
  const [showTechnicianDisclaimer, setShowTechnicianDisclaimer] = useState<boolean>(false);
  const {serviceManagementV1} = useFeatureFlags().tenantOwned();
  const {logout} = useAuth();

  const isServiceTechnician = Boolean(appContext?.tenantInfo?.TenantUserDetails?.IsServiceTechnician);

  const logOut = () => {
    logout().finally(() => {
      appContext.logout();
    });
  };

  useEffect(() => {
    if (isMobile && !appContext.isMobileDisclaimerShown) {
      setShowMobileDisclaimer(true);
    }

    if (serviceManagementV1 && isServiceTechnician) {
      setShowTechnicianDisclaimer(true);
    }

    const fetchData = async () => {
      let mounted = true;

      let promises: Promise<any>[] = [];

      if (!modules.length) {
        const p1 = GetModules();
        promises.push(p1);
      }

      if (!connectedSuppliers.length) {
        const p2 = connectedSuppliersSearch({});
        promises.push(p2);
      }

      if (promises.length === 0) return Promise.resolve(true);

      const res = await Promise.allSettled(promises);
      const modulesResponse = res[0];
      const suppliersResponse = res[1];

      if (mounted) {
        if (modulesResponse?.status === 'fulfilled' && modulesResponse.value.length) {
          appContext.setModules(modulesResponse.value);
        }

        if (suppliersResponse?.status === 'fulfilled' && suppliersResponse.value.length) {
          appContext.setConnectedSuppliers(suppliersResponse.value);
        }

        if (!notifications.length) {
          refreshNotifications();
        }
      }

      return () => {
        mounted = false;
      };
    };

    fetchData();
  }, []);

  return (
    <>
      {showMobileDisclaimer && (
        <DisclaimerModal
          handleClose={() => {
            setShowMobileDisclaimer(!showMobileDisclaimer);
            appContext.setIsMobileDisclaimerShown(true);
          }}
        />
      )}
      {showTechnicianDisclaimer && (
        <TechnicianDisclaimerModal
          handleClose={() => {
            logOut();
          }}
        />
      )}
      <MenuComponent
        user={user}
        tenant={tenant}
        supplierId={supplierId}
        modules={modules}
        connectedSuppliers={connectedSuppliers}
      />
    </>
  );
};
