import React, {useContext, useEffect} from 'react';
import {Route, RouteComponentProps, Redirect, RouteProps} from 'react-router-dom';
import {ModuleStatus, ModuleTypes} from '../model/constants/Constants';
import {FeatureFlagKeys, useFeatureFlags} from '../hooks/useFeatureFlags';
import {ParamentersToSelectMenu} from '../model/tenant/TenantModuleDetails';
import AppContext from '../context/app/appContext';
import {AUTH_TENANT_SETUP} from './AuthenticatedRoutes';
import {AUTH_PURCHASING_ONBOARDING} from './PurchasingRoutes';
import {defaultMarketPlacePath, defaultWebsiteId, isWebsiteId17, websiteId} from '../config';
import {CapNonPilotMemberNotAllowedDialog} from '../components/AccountsReceivable/modals/CapNonPilotMemberNotAllowedDialog';

export interface IPrivateRouteProps extends RouteProps {
  Component?: React.FC<RouteComponentProps>;
  path?: string;
  exact?: boolean;
  moduleIds?: ModuleTypes[];
  featureFlag?: FeatureFlagKeys;
  ComponentName?: string;
  MenuFilters?: ParamentersToSelectMenu;
  websiteIds?: number[];
}

export const PrivateRoute = ({
  path,
  moduleIds,
  featureFlag,
  Component,
  exact = false,
  MenuFilters,
  websiteIds,
  ...rest
}: IPrivateRouteProps) => {
  const {user: currentUser, tenantInfo, statementInvitationId, setMenu} = useContext(AppContext);

  const {isFlagEnabled, isFlagEnabledInSupplier} = useFeatureFlags();

  useEffect(() => {
    if (MenuFilters) {
      setMenu(MenuFilters);
    }
  }, [path]);

  const {IsAuthorisedToAccessWebsite, AgreedTsAndCs} = tenantInfo?.TenantUserDetails || {};

  if (isWebsiteId17 && IsAuthorisedToAccessWebsite === false && isFlagEnabledInSupplier(FeatureFlagKeys.PSBLSTAGING)) {
    return <CapNonPilotMemberNotAllowedDialog />;
  }

  return (
    <Route
      {...rest}
      exact={exact}
      render={(props: RouteComponentProps<{marketPlacePath?: string}>) => {
        if (!currentUser || !currentUser.Modules) {
          return;
        }

        const webIds = websiteIds?.length ? websiteIds : [defaultWebsiteId];
        if (isWebsiteId17 && !webIds?.includes(websiteId)) {
          return <Redirect to={`/${defaultMarketPlacePath}`} />;
        }

        const userModuleIds = currentUser.Modules?.split(',').map(m => parseInt(m));
        if (Component) {
          const userHasRequiredModule = moduleIds && moduleIds.some(m => userModuleIds.indexOf(m) >= 0);

          const hasSynkdModule = currentUser.Modules?.split(',').indexOf(ModuleTypes.Synkd.toString()) >= 0;

          if (
            !userHasRequiredModule &&
            !hasSynkdModule &&
            path !== '/module-info/accounts-payable' &&
            path !== '/onboarding/:onboardingAccount?' &&
            path !== '/pay/onboarding/:onboardingAccount?' &&
            path !== '/pay/onboardingv1/:onboardingAccount?' &&
            path !== AUTH_PURCHASING_ONBOARDING &&
            path !== '/menu' &&
            path !== '/tenant/setup'
          ) {
            return <Redirect to="/401" />;
          }

          const tenantModules = tenantInfo?.Modules?.filter(module => moduleIds?.includes(module.ModuleID));
          const hasSubscriptionCancelled = tenantModules?.find(
            module => module.Status?.toLowerCase() === ModuleStatus.SubscriptionCancelled?.toLowerCase(),
          );
          if (hasSubscriptionCancelled) {
            return <Redirect to="/401" />;
          }

          // Feature Flag is not enabled for this Route
          if (featureFlag && !isFlagEnabled(featureFlag)) {
            return <Redirect to="/menu" />;
          }

          // authorised so return component
          return <Component {...props} />;
        } else {
          if (statementInvitationId) {
            return <Redirect to={`/statement?statementid=${statementInvitationId}`} />;
          }

          // If website ID 17 and tenant setup is not completed then show PSBL module info
          if (featureFlag && isFlagEnabledInSupplier(featureFlag) && isWebsiteId17 && !AgreedTsAndCs) {
            return <Redirect to={'/supplier/module-info/psbl'} />;
          }

          if (!AgreedTsAndCs) {
            return <Redirect to={AUTH_TENANT_SETUP} />;
          }

          return <Redirect to="/menu" />;
        }
      }}
    />
  );
};
