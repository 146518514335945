import React, {SetStateAction} from 'react';
import Skeleton from 'react-loading-skeleton';
import {IInvoice} from '../../../model/invoice/Invoice';
import {IPaymentT} from '../../../model/payment/PaymentT';
import {ISupplierWhoRaisedInvoice} from '../../../model/supplier/SupplierWhoRaisedInvoice';
import {LogoTypeEnum} from '../../../model/Tenant';
import {NameInitials} from '../../../utils/formatter';
import {PriceFormat} from '../../../utils/formatter';
import {ResolvedConvoFetchAndDownload} from '../../conversationChatBox/ResolvedConvoDownload';
import moment from 'moment';
import {DatTypes, InvoicePaymentAllocationStatus} from '../../../model/constants/Constants';
import {TransactionAttachments} from '../../ui/InvoiceDetailsComponent/TransactionAttachments';
import {Typography} from 'spenda-ui-react';
import {Tab, TabPanel, Tabs, TabsBody, TabsHeader} from 'spenda-ui-react';

const tabs = [
  {label: 'Detailed view', value: 0},
  {label: 'Customer view', value: 1},
];

interface ITransactionTemplateProps {
  tx?: IInvoice;
  supplier?: ISupplierWhoRaisedInvoice;
  isLoading: boolean;
  QuoteV2?: boolean;
  hideQuantity?: boolean;
  selectedViewTab?: string;
  setSelectedViewTab?: React.Dispatch<SetStateAction<number>>;
  isInBuyerContext?: boolean;
}

export const InvoiceTemplate = (props: ITransactionTemplateProps) => {
  const {tx, supplier, isLoading, QuoteV2, hideQuantity, selectedViewTab, setSelectedViewTab, isInBuyerContext} = props;
  const LogoURI = supplier?.Logos.find(x => x.LogoTypeID.toString() === LogoTypeEnum.Logo)?.URI;

  const aRContactEmailAddress =
    tx && tx.ARContactDetails
      ? tx.ARContactDetails
      : supplier
        ? supplier.ARContactEmailAddress || supplier.PrimaryContactEmail || ''
        : '';

  const getShippingAndDeliveryAddress = () => {
    switch (tx?.DeliveryMethod) {
      case 'Deliver':
        return (
          <>
            <Typography variant="paragraph" className="text-black-800">
              Deliver To:{' '}
            </Typography>
            <Typography variant="paragraph" className="text-black-800">
              {tx?.CustomerName}
            </Typography>
            <Typography variant="paragraph" className="text-black-800">
              {tx?.ShippingAddress?.StreetAddress}
            </Typography>
            <Typography variant="paragraph" className="text-black-800">
              {tx?.ShippingAddress?.City}
            </Typography>
            <Typography
              variant="paragraph"
              className="text-black-800"
            >{`${tx?.ShippingAddress?.State} ${tx?.ShippingAddress?.PostCode}`}</Typography>
          </>
        );
      case 'WareHousePickup':
        return (
          <>
            <Typography variant="paragraph" className="text-black-800">
              Warehouse Pickup:{' '}
            </Typography>
            <Typography variant="paragraph" className="text-black-800">
              Company Name
            </Typography>
            <Typography variant="paragraph" className="text-black-800">
              Street
            </Typography>
            <Typography variant="paragraph" className="text-black-800">
              City
            </Typography>
            <Typography variant="paragraph" className="text-black-800">
              Country, Pincode
            </Typography>
          </>
        );
      default:
        return '';
    }
  };

  const renderInvoiceDetailedView = (showSummaryLines: boolean = false) => {
    const lines = showSummaryLines ? tx?.SummaryLines : tx?.Lines;
    return (
      <>
        <div className="my-5 flex h-auto w-full flex-row justify-between">
          {LogoURI ? (
            <img data-autoid={'imgLogoURIInvoiceTemplate'} src={LogoURI} className="h-[100px] w-[100px]" />
          ) : supplier?.Name && !isLoading ? (
            <div className="flex h-12 flex-row items-center justify-center rounded-full bg-[#b8b046] p-4">
              <Typography
                variant="paragraph"
                data-autoid={'lblSupplierNameInitialsInvoiceTemplate'}
                className="text-5xl text-black-800"
              >
                {NameInitials(supplier?.Name)?.toUpperCase()}
              </Typography>
            </div>
          ) : (
            <Skeleton circle={true} height={'6rem'} width={'6rem'} />
          )}
          <div className="flex flex-row items-center justify-between gap-4">
            <ResolvedConvoFetchAndDownload txDatType={tx?.DatTypeID} txGuid={tx?.GUIDstring} />
            <div>
              {!!tx?.Attachments?.length && (
                <TransactionAttachments Counter={tx?.AttachmentCount} Attachments={tx?.Attachments} />
              )}
            </div>
            <Typography variant="paragraph" className="self-center text-3xl font-semibold text-black-800">
              Tax Invoice
            </Typography>
          </div>
        </div>
        <div className="flex h-auto w-full flex-row justify-between pb-3">
          <div className="flex flex-col">
            <Typography variant="paragraph" data-autoid={'lblSupplierNameInvoiceTemplate'} className="text-black-800">
              {supplier && !isLoading ? supplier?.Name : <Skeleton width={`7rem`} />}
            </Typography>
            <Typography variant="paragraph" data-autoid={'lblSupplierABNInvoiceTemplate'} className="text-black-800">
              {supplier && !isLoading ? `ABN: ${supplier?.ABN || ''}` : <Skeleton width={`4rem`} />}
            </Typography>
            <Typography variant="paragraph" data-autoid={'lblPhoneInvoiceTemplate'} className="text-black-800">
              {supplier && !isLoading ? `Phone: ${supplier?.BusinessPhone || ''}` : <Skeleton width={`7rem`} />}
            </Typography>
            <Typography variant="paragraph" className="text-black-800">
              {supplier && !isLoading ? supplier?.Address?.StreetAddress || '' : <Skeleton width={`8rem`} />}
            </Typography>
            <Typography variant="paragraph" className="text-black-800">
              {supplier && !isLoading ? (
                `${supplier?.Address?.City || ''} ${supplier?.Address?.State || ''} ${supplier?.Address?.PostCode || ''}`
              ) : (
                <Skeleton width={`8rem`} />
              )}
            </Typography>
            <Typography
              variant="paragraph"
              data-autoid={'lblPrimaryContactEmailInvoiceTemplate'}
              className="text-black-800"
            >
              {!isLoading ? aRContactEmailAddress : <Skeleton width={`8rem`} />}
            </Typography>
          </div>
          <div className="flex flex-row gap-5">
            <div className="flex flex-col">
              <Typography variant="paragraph" className="text-black-800">
                Invoice Number
              </Typography>
              {tx && tx.VendorName && (
                <Typography variant="paragraph" className="text-black-800">
                  Vendor
                </Typography>
              )}
              {tx && tx.VendorTransRefNumber && (
                <Typography variant="paragraph" className="text-black-800">
                  Vendor Invoice #
                </Typography>
              )}
              <Typography variant="paragraph" className="text-black-800">
                Invoice Date
              </Typography>
              <Typography variant="paragraph" className="text-black-800">
                Due Date
              </Typography>
              <Typography variant="paragraph" className="text-black-800">
                Customer Account
              </Typography>
              <Typography variant="paragraph" className="text-black-800">
                Sales Order
              </Typography>
              <Typography variant="paragraph" className="text-black-800">
                PO Ref Number
              </Typography>
            </div>
            <div className="flex flex-col">
              <Typography variant="paragraph" data-autoid={'lblRefNumberInvoiceTemplate'} className="text-black-800">
                {tx && !isLoading ? tx.RefNumber : <Skeleton width={`4rem`} />}
              </Typography>
              {tx && tx.VendorName && (
                <Typography
                  variant="paragraph"
                  data-autoid={'lblRefNumberInvoiceTemplate'}
                  className="max-w-[350px] overflow-ellipsis whitespace-nowrap text-black-800"
                >
                  {tx && !isLoading ? tx.VendorName : <Skeleton width={`4rem`} />}
                </Typography>
              )}
              {tx && tx.VendorTransRefNumber && (
                <Typography variant="paragraph" data-autoid={'lblRefNumberInvoiceTemplate'} className="text-black-800">
                  {tx && !isLoading ? tx.VendorTransRefNumber : <Skeleton width={`4rem`} />}
                </Typography>
              )}
              <Typography
                variant="paragraph"
                data-autoid={'lblTransDateStringInvoiceTemplate'}
                className="text-black-800"
              >
                {tx && !isLoading ? tx?.TransDateString : <Skeleton width={`6rem`} />}
              </Typography>
              <Typography
                variant="paragraph"
                data-autoid={'lblDueDateStringInvoiceTemplate'}
                className="text-black-800"
              >
                {tx && !isLoading ? tx?.DueDateString : <Skeleton width={`6rem`} />}
              </Typography>
              <Typography
                variant="paragraph"
                data-autoid={'lblAccountCustomerIDInvoiceTemplate'}
                className="text-black-800"
              >
                {tx && !isLoading ? tx?.CustomerRefNumber : <Skeleton width={`4rem`} />}
              </Typography>
              <Typography
                variant="paragraph"
                data-autoid={'lblRefTransRefNumberInvoiceTemplate'}
                className="text-black-800"
              >
                {tx && !isLoading ? tx?.RefTransRefNumber : <Skeleton width={`4rem`} />}
              </Typography>
              <Typography
                variant="paragraph"
                data-autoid={'lblVendorTransRefNumberInvoiceTemplate'}
                className="text-black-800"
              >
                {tx && !isLoading ? tx?.CustomerRefTransRefNumber : <Skeleton width={`4rem`} />}
              </Typography>
            </div>
          </div>
        </div>
        <div className="mb-3 flex h-auto w-full flex-row justify-between gap-5 border-b-2 border-t-2 border-[#333] px-[40px] py-[16px]">
          <div className="flex h-auto w-[50%] flex-col border-[3px] p-[20px]">
            <Typography variant="paragraph" className="text-black-800">
              Bill To:
            </Typography>
            <Typography
              variant="paragraph"
              data-autoid={'lblContactFirstNameInvoiceTemplate'}
              className="text-black-800"
            >
              {tx && !isLoading ? tx?.CustomerName : <Skeleton width={`7rem`} />}
            </Typography>
            <Typography variant="paragraph" className="text-black-800">
              {tx && !isLoading ? tx?.BillingAddress?.StreetAddress : <Skeleton width={`8rem`} />}
            </Typography>
            <Typography variant="paragraph" className="text-black-800">
              {tx && !isLoading ? tx?.BillingAddress?.City : <Skeleton width={`4rem`} />}
            </Typography>
            <Typography variant="paragraph" className="text-black-800">
              {tx && !isLoading ? (
                `${tx?.BillingAddress?.State} ${tx?.BillingAddress?.PostCode}`
              ) : (
                <Skeleton width={`8rem`} />
              )}
            </Typography>
          </div>
          <div className="flex h-auto w-[50%] flex-col border-[3px] p-[20px]">
            {tx && !isLoading ? (
              getShippingAndDeliveryAddress()
            ) : (
              <>
                <Skeleton width={`4rem`} />
                <Skeleton width={`7rem`} />
                <Skeleton width={`4rem`} />
                <Skeleton width={`8rem`} />
              </>
            )}
          </div>
        </div>

        <div className="mb-[40px] flex h-auto w-full flex-col">
          <table className="mb-[40px]">
            <thead>
              <tr className="border-b-2">
                {!showSummaryLines && (
                  <th className="text-left font-poppins text-base font-semibold leading-relaxed text-black-800">
                    Code
                  </th>
                )}
                <th
                  className="text-left font-poppins text-base font-semibold leading-relaxed text-black-800"
                  colSpan={showSummaryLines ? (hideQuantity ? 4 : 2) : 1}
                >
                  Description
                </th>
                {!(showSummaryLines && hideQuantity) && (
                  <th className="text-center font-poppins text-base font-semibold leading-relaxed text-black-800">
                    Qty
                  </th>
                )}
                {!(showSummaryLines && hideQuantity) && (
                  <th className="text-right font-poppins text-base font-semibold leading-relaxed text-black-800">
                    Unit Price
                  </th>
                )}
                <th className="text-right font-poppins text-base font-semibold leading-relaxed text-black-800">
                  Line Total
                </th>
              </tr>
            </thead>
            <tbody>
              {tx && !isLoading
                ? lines?.map((tl, index) => (
                    <tr key={tl?.ID}>
                      {!showSummaryLines && (
                        <td
                          data-autoid={`lblCode${index}InvoiceTemplate`}
                          className="text-left font-poppins text-base font-normal text-black-800"
                        >
                          {tl?.Code}
                        </td>
                      )}
                      <td
                        colSpan={showSummaryLines ? (hideQuantity ? 4 : 2) : 1}
                        data-autoid={`lblDescription${index}InvoiceTemplate`}
                        className="text-left font-poppins text-base font-normal text-black-800"
                      >
                        {tl?.Description}
                      </td>
                      {!(showSummaryLines && hideQuantity) && (
                        <td
                          data-autoid={`lblQuantity${index}InvoiceTemplate`}
                          className="text-center font-poppins text-base font-normal text-black-800"
                        >
                          {tl?.Quantity}
                        </td>
                      )}
                      {!(showSummaryLines && hideQuantity) && (
                        <td
                          data-autoid={`lblSellPriceEx${index}InvoiceTemplate`}
                          className="text-right font-poppins text-base font-normal text-black-800"
                        >
                          {PriceFormat(tl?.SellPriceEx)}
                        </td>
                      )}
                      <td
                        data-autoid={`lblLineTotalEx${index}InvoiceTemplate`}
                        className="text-right font-poppins text-base font-normal text-black-800"
                      >
                        {PriceFormat(tl?.LineTotalEx)}
                      </td>
                    </tr>
                  ))
                : [0, 1, 2, 3]?.map(tl => (
                    <tr key={tl}>
                      {Array(5)
                        .fill(1)
                        .map((_, indx) => (
                          <td key={indx} className="text-left font-poppins text-base font-normal text-black-800">
                            <Skeleton width={'90%'} />
                          </td>
                        ))}
                    </tr>
                  ))}
              <tr>
                <td colSpan={4} className="pt-8 text-right font-poppins text-base font-normal text-black-800">
                  Subtotal
                </td>
                <td
                  data-autoid={'lblTotalExInvoiceTemplate'}
                  className="pt-8 text-right font-poppins text-base font-normal text-black-800"
                >
                  {tx && !isLoading ? PriceFormat(tx?.TotalEx) : <Skeleton width={`3rem`} />}
                </td>
              </tr>
              <tr className="border-b-2">
                <td
                  colSpan={4}
                  className="pt-2 text-right font-poppins text-base font-normal leading-relaxed text-black-800"
                >
                  GST
                </td>
                <td
                  data-autoid={'lblTotalTaxInvoiceTemplate'}
                  className="pt-2 text-right font-poppins text-base font-normal leading-relaxed text-black-800"
                >
                  {tx && !isLoading ? PriceFormat(tx?.TotalTax) : <Skeleton width={`3rem`} />}
                </td>
              </tr>
              <tr>
                <td colSpan={3} className="pt-2 text-left font-poppins text-base font-semibold text-black-800">
                  *Backorder items have not been included in this invoice total.
                </td>
                <td className="pt-2 text-right font-poppins text-base font-semibold text-black-800">Total</td>
                <td
                  data-autoid={'lblTotalIncInvoiceTemplate'}
                  className="pt-2 text-right font-poppins text-base font-semibold text-black-800"
                >
                  {tx && !isLoading ? PriceFormat(tx?.TotalInc) : <Skeleton width={`3rem`} />}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <p className="mb-4 font-poppins text-xl font-semibold text-black-800">Payments</p>
        <div className="mb-[20px] flex h-auto w-full flex-col">
          <table className="mb-[20px]">
            <thead>
              <tr className="border-b-2">
                <th className="text-left font-poppins text-base font-semibold leading-relaxed text-black-800">
                  Payment Number
                </th>
                <th className="text-left font-poppins text-base font-semibold leading-relaxed text-black-800">
                  Method
                </th>
                <th className="text-left font-poppins text-base font-semibold leading-relaxed text-black-800">
                  Date/Time
                </th>
                <th className="text-right font-poppins text-base font-semibold leading-relaxed text-black-800">
                  Amount
                </th>
              </tr>
            </thead>
            <tbody>
              {tx && !isLoading ? (
                <>
                  {tx?.Payments?.filter(
                    p =>
                      p.Status === InvoicePaymentAllocationStatus.Paid ||
                      p.Status === InvoicePaymentAllocationStatus.Cleared,
                  )?.map((payment: IPaymentT, id: number) => (
                    <tr key={payment?.ID}>
                      <td
                        data-autoid={`lblRefNumber${id}InvoiceTemplate`}
                        className="text-left font-poppins text-base font-normal text-black-800"
                      >
                        {payment?.RefNumber}
                      </td>
                      <td
                        data-autoid={`lblMethod${id}InvoiceTemplate`}
                        className="text-left font-poppins text-base font-normal text-black-800"
                      >
                        {payment?.Method}
                      </td>
                      <td
                        data-autoid={`lblDateTime${id}InvoiceTemplate`}
                        className="text-left font-poppins text-base font-normal text-black-800"
                      >
                        {moment(payment?.DateTime).format('DD MMM YYYY HH:mm A')}
                      </td>
                      <td
                        data-autoid={`lblAppliedAmount${id}InvoiceTemplate`}
                        className="text-right font-poppins text-base font-normal text-black-800"
                      >
                        {PriceFormat(payment?.AppliedAmount)}
                      </td>
                    </tr>
                  ))}
                  {tx?.RefBusTrans?.filter(f => f?.TransTypeID === DatTypes.CreditNote)?.map(crediNote => (
                    <tr key={crediNote?.ID}>
                      <td
                        data-autoid={`lblRefNumber${crediNote?.ID}InvoiceTemplate`}
                        className="text-left font-poppins text-base font-normal text-black-800"
                      >
                        {crediNote?.RefNumber}
                      </td>
                      <td
                        data-autoid={`lblMethod${crediNote?.ID}InvoiceTemplate`}
                        className="text-left font-poppins text-base font-normal text-black-800"
                      >
                        {DatTypes?.[crediNote?.TransTypeID]}
                      </td>
                      <td
                        data-autoid={`lblDateTime${crediNote?.ID}InvoiceTemplate`}
                        className="text-left font-poppins text-base font-normal text-black-800"
                      >
                        {moment(crediNote?.AppliedDate).format('DD MMM YYYY HH:mm A')}
                      </td>
                      <td
                        data-autoid={`lblAppliedAmount${crediNote?.ID}InvoiceTemplate`}
                        className="text-right font-poppins text-base font-normal text-black-800"
                      >
                        {PriceFormat(crediNote?.AppliedAmount)}
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                [0, 1, 2, 3]?.map(tl => (
                  <tr key={tl}>
                    {Array(4)
                      .fill(1)
                      .map((_, indx) => (
                        <td key={indx} className="text-left font-poppins text-base font-normal text-black-800">
                          <Skeleton width={'90%'} />
                        </td>
                      ))}
                  </tr>
                ))
              )}
              <tr className="border-t-2 border-t-black-800">
                <td colSpan={3} className="pt-2 text-right font-poppins text-base font-semibold text-black-800">
                  Total Paid
                </td>
                <td className="pt-2 text-right font-poppins text-base font-semibold text-black-800">
                  {tx && !isLoading ? PriceFormat(tx?.TotalPaid) : <Skeleton width={`3rem`} />}
                </td>
              </tr>
              <tr>
                <td colSpan={3} className="pt-2 text-right font-poppins text-base font-semibold text-black-800">
                  Balance
                </td>
                <td
                  data-autoid={'lblBalanceInvoiceTemplate'}
                  className="pt-2 text-right font-poppins text-base font-semibold text-black-800"
                >
                  {tx && !isLoading ? PriceFormat(tx?.Balance) : <Skeleton width={`3rem`} />}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="mb-4 flex h-auto w-full flex-col">
          <Typography variant="paragraph" className="mb-4 font-semibold text-black-800">
            Sales Person:{' '}
            {tx && !isLoading ? `${tx?.RepFirstName || ''} ${tx?.RepLastName || ''}` : <Skeleton width={`3rem`} />}
          </Typography>
          {/* <p className="font-poppins text-base font-normal text-black-800">
          All goods and services supplied remain the property of Acme Auto Repairs until all monies outstanding are
          received.
        </p> */}
          {/* <p className="font-poppins text-base font-normal text-black-800">All EFT Payments To: Acme Auto Repairs</p>
        <p className="font-poppins text-base font-normal text-black-800">Bank Name: Commonwealth Bank</p>
        <p className="font-poppins text-base font-normal text-black-800">BSB: 111-222</p>
        <p className="font-poppins text-base font-normal text-black-800">Acct No: 123456789</p> */}
        </div>
        <div className="flex h-auto w-full flex-col items-center justify-center">
          <Typography
            variant="paragraph"
            className="mb-[20px] border-b-2 text-xl font-semibold leading-[80px] text-black-800"
          >
            Powered by
          </Typography>
          <a className="font-poppins text-xl font-semibold text-primary underline" href="https://spenda.co/">
            www.spenda.co
          </a>
        </div>
      </>
    );
  };

  return (
    <div className="flex h-max w-full flex-col bg-white px-[20px] pb-[150px] pt-[20]">
      {QuoteV2 && tx?.SummaryLines?.length && selectedViewTab && setSelectedViewTab && !isInBuyerContext ? (
        <Tabs value={parseInt(selectedViewTab)} className="mt-2 flex w-full flex-col items-center">
          <TabsHeader
            className="h-[40px] w-[335px] bg-[#ECECEC]"
            indicatorProps={{
              className: 'bg-spenda-sBlue shadow-none !text-white',
            }}
          >
            {tabs?.map(({label, value}) => (
              <Tab
                key={value}
                value={value}
                className={`${parseInt(selectedViewTab) === value ? 'font-medium text-white' : 'font-medium'}`}
                onClick={() => setSelectedViewTab(value)}
                data-autoid={`tab-${label}`.replace(/ /g, '')}
                title={label}
              >
                {label}
              </Tab>
            ))}
          </TabsHeader>
          <TabsBody>
            {tabs?.map(({value}) => (
              <TabPanel key={value} value={value} className="w-full !p-0">
                {renderInvoiceDetailedView(value === 1)}
              </TabPanel>
            ))}
          </TabsBody>
        </Tabs>
      ) : (
        renderInvoiceDetailedView(tx?.SummaryLines?.length && QuoteV2 && isInBuyerContext ? true : false)
      )}
    </div>
  );
};
