import {createContext} from 'react';

export interface IThemeContextState {
  rootClassName: string;
  logo?: (props: SVGProps) => JSX.Element;
  // colors: IThemeColors | undefined;
}

export interface IThemeContextType extends IThemeContextState {
  // setLogos: (logos: IThemeLogo[]) => void;
  // setColors: (colors: IThemeColors | undefined) => void;
}

const ThemeContext = createContext<IThemeContextType>({
  logo: undefined,
  rootClassName: '',
  // colors: undefined,
  // setLogos: () => undefined,
  // setColors: () => undefined,
});

export default ThemeContext;
