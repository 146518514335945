import React, {useContext, useEffect, useState} from 'react';
import Skeleton from 'react-loading-skeleton';
import {RouteProps, useHistory} from 'react-router-dom';
import GoBackIcon from '../../assets/svg/GoBackIcon';
import AppContext from '../../context/app/appContext';
import PurchasingContext from '../../context/purchasing/purchasingContext';
import {useTenantInfo} from '../../hooks/useTenantInfo';
import {IAlertInfo, IAlertRequestRequest} from '../../model/alert/AlertInfo';
import {
  ConversationType,
  DatTypes,
  IAlertTypes,
  InvoicePaymentAllocationStatus,
  StartConvoType,
} from '../../model/constants/Constants';
import {IInvoice} from '../../model/invoice/Invoice';
import {IInvoicePaymentAllocation} from '../../model/invoice/InvoicePaymentAllocation';
import {useCommonTransactionAPI} from '../../services/useCommonTransactionAPI';
import {useCustomerStatementsAPI} from '../../services/useCustomerStatementsAPI';
import {useSupplierTransactionsAPI} from '../../services/useSupplierTransactionsAPI';
import StartConvo from '../conversationChatBox/StartConvo';
import {PaymentReminderDialog} from '../dialog/PaymentReminderDialog';
import {InvoiceAllocationTemplate} from './templates/InvoiceAllocationTemplate';
import {InvoiceTemplate} from './templates/InvoiceTemplate';
import {IconButton} from '@material-ui/core';
import {ArrowBack, ArrowForward} from '@material-ui/icons';
import Close from '@material-ui/icons/Close';
import {usePsblBatchHook, useTransactionViewHook} from '../../hooks/useARHook';
import {useFeatureFlags} from '../../hooks/useFeatureFlags';
import {RetryDialog} from '../transactions/Transaction';
import {CreditNoteTemplate} from './templates/CreditNoteTemplate';
import {PaymentTemplate} from './templates/PaymentTemplate';
import {Toast} from '../../utils/Toast';
import useSpendaPaymentServicesAPI from '../../services/useSpendaPaymentServicesAPI';
import {ARDialogTemplate} from './ARDialogTemplate';
import {AUTH_SELLING_AR_CREATE_CLAIM_REQUESTS_ROUTE} from '../../routes/AuthenticatedRoutes';
import clsx from 'clsx';
import {SelectEmailTemplateDialog, SelectEmailTemplateScope} from './SelectEmailTemplateDialog';
import {Button, Typography} from 'spenda-ui-react';
import {ConfirmCancelBatchPaymentModal} from './modals/ConfirmCancelBatchPaymentModal';

export interface ITxList {
  txId: number;
  txGuid: string;
  txDatType: number;
}

export interface ITxData {
  txId?: number;
  txGuid?: string;
  txDatType: number;
  mcId?: number;
}

interface ITransactionViewProps extends RouteProps {
  customerId?: number;
  hideFooter?: boolean;
  isShowInPdfView?: boolean;
  merchantCustomerID?: number;
  scope?: 'ARPaymentHistory' | 'CustomerFocusView' | 'PSBlView' | 'CustomerGroupView' | 'PaidInvoices';
  supplierId?: number;
  transactionId?: number;
  txDatType: number;
  txGuid?: string;
  txList?: ITxList[];
  handleOnClosePdfView: (action?: string) => void;
  onClickCancelScheduleBatch?: () => void;
}

export const TransactionView = (props: ITransactionViewProps) => {
  // Props
  const {
    supplierId,
    customerId,
    hideFooter = false,
    isShowInPdfView,
    merchantCustomerID,
    scope,
    txList,
    handleOnClosePdfView,
    transactionId: txId,
    txGuid,
    txDatType,
  } = props;

  // Hooks
  const {isInSupplierContext, isInBuyerContext} = useTenantInfo();
  const {retryPSBLBatch} = usePsblBatchHook(supplierId);
  const {handleViewTx, getSupplierWhoRaisedInvoice} = useTransactionViewHook();
  const history = useHistory();

  // Feature Flag
  const {
    claimAndReturnsV272602: claimAndReturnsV2Supplier,
    statementsAndAutomatedStatement83193,
    QuoteV289367: QuoteV2,
  } = useFeatureFlags().tenantOwned();
  const {
    claimAndReturnsV272602: claimAndReturnsV2Buyer,
    scheduledPaymentsV2,
    QuoteV289367: QuoteV2Buyer,
  } = useFeatureFlags().supplierDriven();
  const claimAndReturnsV272602 = isInBuyerContext
    ? claimAndReturnsV2Buyer && claimAndReturnsV2Supplier
    : claimAndReturnsV2Supplier;

  // Context
  const {conversations} = useContext(PurchasingContext);
  const {isPSBLPIBLEnable, setIsShowConfigureSettingsDialog, tenantInfo} = useContext(AppContext);

  // APIS
  const {getInvoicePaymentAllocation} = useCommonTransactionAPI();
  const customerStatementsAPI = useCustomerStatementsAPI();
  const BuyerStatementAPI = useSupplierTransactionsAPI();
  const {approveLoan, isLoading: isApprovingLoan} = useSpendaPaymentServicesAPI();

  // States
  const [tx, setTx] = useState<IInvoice>();
  const [paymentAllocationTx, setPaymentAllocationTx] = useState<IInvoicePaymentAllocation>();
  const [isShowPdfView, setIsShowPdfView] = useState(isShowInPdfView);
  const [isShowSendInvoiceDialog, setIsShowSendInvoiceDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [retryAlertDialog, setRetryAlertDialog] = useState(false);
  const [isShowApproveLoanDialog, setIsShowApproveLoanDialog] = useState(false);
  const [isShowSelectEmailTemplateDialog, setIsShowSelectEmailTemplateDialog] = useState(false);
  const [isShowCancelBatchPaymentDialog, setIsShowCancelBatchPaymentDialog] = useState(false);
  const [selectedViewTab, setSelectedViewTab] = useState<number>(0);

  // Constants
  const allowedTx = txList?.filter(t =>
    [DatTypes.Invoice, DatTypes.CreditNote, DatTypes.InvoicePaymentAllocation, DatTypes.Payment].includes(t.txDatType),
  );
  const supplierTenant = getSupplierWhoRaisedInvoice();

  // UseEffects
  useEffect(() => {
    if (!txDatType) return;
    setIsLoading(true);
    let fetchTransaction: () => Promise<IInvoice | IInvoicePaymentAllocation | undefined>;

    if (isInSupplierContext) {
      fetchTransaction = () => {
        if (DatTypes.Invoice === txDatType) {
          if (txGuid) {
            return customerStatementsAPI.getInvoiceByGuid({transactionGuid: txGuid});
          }

          if (merchantCustomerID && txId) {
            return customerStatementsAPI.getInvoiceByGuid({merchantCustomerID, transId: txId});
          }
        }

        if (DatTypes.InvoicePaymentAllocation === txDatType && txId) return getInvoicePaymentAllocation(txId);

        if (!txId || !txGuid) return new Promise(resolve => resolve(undefined));

        const custRequest = {
          TransID: txId,
          TransactionGuid: txGuid,
        };

        switch (txDatType) {
          case DatTypes.CreditNote:
            return customerStatementsAPI.getCreditNote(custRequest);
          case DatTypes.Payment:
            return customerStatementsAPI.getPayment(custRequest);
          default:
            return new Promise(resolve => resolve(undefined));
        }
      };
    } else {
      fetchTransaction = () => {
        if (DatTypes.InvoicePaymentAllocation === txDatType && txId) return getInvoicePaymentAllocation(txId);

        if (!txId || !txGuid) return new Promise(resolve => resolve(undefined));

        const request = {
          LinkedSupplierID: props?.supplierId,
          TransactionGuid: txGuid,
          IsIncludePayments: true,
        };

        switch (txDatType) {
          case DatTypes.Invoice:
            return BuyerStatementAPI.getInvoice(request);
          case DatTypes.CreditNote:
            return BuyerStatementAPI.getCreditNote(request);
          case DatTypes.Payment:
            return BuyerStatementAPI.getPayment(request);
          default:
            return new Promise(resolve => resolve(undefined));
        }
      };
    }

    fetchTransaction()
      .then(async data => {
        if (data) {
          if (txDatType === DatTypes.InvoicePaymentAllocation) {
            setPaymentAllocationTx(data as IInvoicePaymentAllocation);
            setIsLoading(false);
          } else {
            setTx(data as IInvoice);
            setIsLoading(false);
          }
        }
      })
      .catch(() => {});
  }, [supplierId]);

  // Functions
  const onClickTransactionArrow = (action: 'NEXT' | 'PREVIOUS') => {
    try {
      if (!allowedTx?.length) return;
      let index = allowedTx?.findIndex(t => t.txId === txId);
      if (
        index === -1 ||
        (action === 'NEXT' && index === allowedTx?.length - 1) ||
        (action === 'PREVIOUS' && index === 0)
      )
        return;
      const txObj = allowedTx[index + (action === 'NEXT' ? 1 : -1)];
      handleViewTx({
        txDatType: txObj.txDatType,
        txGuid: DatTypes.InvoicePaymentAllocation === txObj.txDatType ? undefined : txObj.txGuid,
        txId: txObj.txId,
      });
    } catch {}
  };

  const isArrowsAreDisabled = (action: 'NEXT' | 'PREVIOUS') => {
    try {
      if (!allowedTx?.length) return true;
      let index = allowedTx?.findIndex(t => t.txId === txId);
      return action === 'NEXT' ? index < 0 || index === allowedTx?.length - 1 : index <= 0;
    } catch {}
  };

  const handlePdfViewClose = () => {
    if (isShowInPdfView) handleOnClosePdfView();
    else setIsShowPdfView(false);
  };

  const getTransactionType = (datType: number) => {
    switch (datType) {
      case DatTypes.Invoice:
        return 'Invoice';
      case DatTypes.CreditNote:
        return 'Credit Note';
      default:
        return '';
    }
  };

  const onDownloadPDF = async () => {
    try {
      if (!txId) return;

      if (DatTypes.InvoicePaymentAllocation === txDatType) {
        const alertReqInvoiceAllocation = {
          DatTypeID: DatTypes.InvoicePaymentAllocationReceipt,
          DocIDs: [txId],
          AlertType: IAlertTypes.Pdf,
          WebsiteID: 13,
        };
        return customerStatementsAPI.getBatchPaymentHstoryPrintOut(alertReqInvoiceAllocation).then(data => {
          if (data) {
            data.map(pdf => {
              window.open(pdf);
            });
          }
        });
      }

      let fetchTransaction: () => Promise<IAlertInfo | undefined>;

      if (isInBuyerContext) {
        fetchTransaction = () => {
          let alertReq: Partial<IAlertRequestRequest> = {
            LinkedSupplierID: supplierId,
            TransactionGuid: tx && tx.GUID,
            DocID: tx && tx.ID,
          };
          switch (txDatType) {
            case DatTypes.CreditNote:
              return BuyerStatementAPI.getCreditNotePrintout(alertReq);
            case DatTypes.Invoice:
              return BuyerStatementAPI.getInvoicePrintout({
                ...alertReq,
                IsShowSummaryLines: QuoteV2Buyer,
              });
            case DatTypes.Payment:
              return BuyerStatementAPI.getPaymentAlert(alertReq);
            default:
              return new Promise(resolve => resolve(undefined));
          }
        };
      } else {
        fetchTransaction = () => {
          let alertReq: Partial<IAlertRequestRequest> = {
            TransID: txId,
            TransactionGuid: tx && tx.GUID,
          };
          switch (txDatType) {
            case DatTypes.CreditNote:
              return customerStatementsAPI.getCreditNotePrintOut(alertReq);
            case DatTypes.Invoice:
              return customerStatementsAPI.getInvoicePrintOut({
                ...alertReq,
                IsShowSummaryLines: QuoteV2 && selectedViewTab === 1,
              });
            case DatTypes.Payment:
              return customerStatementsAPI.getPaymentAlert(alertReq);
            default:
              return new Promise(resolve => resolve(undefined));
          }
        };
      }
      const aletResponse = await fetchTransaction();

      if (aletResponse?.AlertMsg) {
        const pdf = aletResponse?.AlertMsg?.AttachmentFileNames[0];
        window.open(pdf);
      }
    } catch {}
  };

  const nextButton = (
    <IconButton
      onClick={() => onClickTransactionArrow('NEXT')}
      disabled={isArrowsAreDisabled('NEXT')}
      data-autoid="btnPDFViewNext"
      className="flex flex-row items-center justify-center"
      style={{
        padding: 0,
        color: '#fff',
        position: 'absolute',
        right: isShowPdfView ? '-25px' : '30px',
        top: isShowPdfView ? '50%' : '55%',
        zIndex: 10,
      }}
    >
      <div className="flex h-[48px] w-[48px] flex-col items-center justify-center rounded-full bg-[#E2E2E2] hover:bg-primary">
        <ArrowForward style={{color: 'inherit', fontSize: '40px', zIndex: 10}} />
      </div>
    </IconButton>
  );

  const RedirectToClaimCreatePage = () => {
    if (isInSupplierContext && tx?.AccountCustomerID) {
      const pathName = AUTH_SELLING_AR_CREATE_CLAIM_REQUESTS_ROUTE?.replace(
        ':accountCustomerId',
        String(tx?.AccountCustomerID),
      );
      history.push(`${pathName}?txId=${tx?.ID}&txGuid=${tx?.GUID}`);
    } else if (isInBuyerContext) {
      history.push(`/supplier/${supplierId}/claim-request/create?txId=${tx?.ID}&txGuid=${tx?.GUID}`);
    }
  };

  const previousButton = (
    <IconButton
      onClick={() => onClickTransactionArrow('PREVIOUS')}
      disabled={isArrowsAreDisabled('PREVIOUS')}
      data-autoid="btnPDFViewPrev"
      className="flex flex-row items-center justify-center"
      style={{
        padding: 0,
        color: '#fff',
        position: 'absolute',
        left: isShowPdfView ? '-25px' : '25px',
        top: isShowPdfView ? '50%' : '55%',
        zIndex: 10,
      }}
    >
      <div
        onClick={() => {}}
        className="flex h-[48px] w-[48px] flex-col items-center justify-center rounded-full bg-[#E2E2E2] hover:bg-primary"
      >
        <ArrowBack style={{color: 'inherit', fontSize: '40px', zIndex: 10}} />
      </div>
    </IconButton>
  );

  const handleToggleRetryDialog = () => {
    setRetryAlertDialog(!retryAlertDialog);
  };

  const handleRetryBatch = () => {
    if (paymentAllocationTx?.invoicePaymentAllocation?.invoicePaymentAllocationID) {
      retryPSBLBatch(paymentAllocationTx.invoicePaymentAllocation?.invoicePaymentAllocationID);
      history.push(`/supplier/${supplierId}/bills`);
      handleToggleRetryDialog();
    }
  };

  const onLoanApproval = async (loanId?: number) => {
    if (!tx?.Loan?.ID) {
      Toast.info('Invalid Load ID');
      return;
    }

    await approveLoan(loanId!);
    Toast.info('Funding has been approved');
    setIsShowApproveLoanDialog(false);
    const t = {...tx};
    if (!t.Loan) return;
    t.Loan.IsPending = false;
    setTx(t);
  };

  const handleSendInvoiceClick = () => {
    if (isPSBLPIBLEnable) {
      statementsAndAutomatedStatement83193
        ? setIsShowSelectEmailTemplateDialog(true)
        : setIsShowSendInvoiceDialog(true);
    } else setIsShowConfigureSettingsDialog(true);
  };

  const isCreditNote = DatTypes.CreditNote === txDatType;
  const isInvoice = DatTypes.Invoice === txDatType;
  const isPayment = DatTypes.Payment === txDatType;
  const isInvoiceAllocation = DatTypes.InvoicePaymentAllocation === txDatType;

  const retryInvoiceCount = paymentAllocationTx?.invoicePaymentAllocation.live_Invoice_Count ?? 0;

  const isShowRetryPaymentBtn =
    isInBuyerContext &&
    paymentAllocationTx &&
    retryInvoiceCount > 0 &&
    [
      InvoicePaymentAllocationStatus.Cancelled,
      InvoicePaymentAllocationStatus.Failed,
      InvoicePaymentAllocationStatus.Declined,
    ].includes(paymentAllocationTx.invoicePaymentAllocation.status);

  const isShowCancelBatchBtn =
    scheduledPaymentsV2 &&
    isInBuyerContext &&
    paymentAllocationTx?.invoicePaymentAllocation?.scheduledDateTime_utc &&
    paymentAllocationTx?.invoicePaymentAllocation?.isUpcomingPayment &&
    paymentAllocationTx?.invoicePaymentAllocation?.status === InvoicePaymentAllocationStatus.Scheduled;

  const isShowStartConvoBtn =
    isInvoice && (scope === 'PSBlView' || scope === 'CustomerFocusView') && (supplierId || customerId);
  const isShowCreateClaimBtn = claimAndReturnsV272602 && isInvoice && !tx?.IsEarlyClaimShortPayment;
  const isShowSendInvoiceBtn = isInSupplierContext && isInvoice && txId;
  const isShowApproveLoanBtn = isInSupplierContext && isInvoice && tx?.Loan && tx.Loan.IsPending;

  return (
    <>
      {isShowSendInvoiceDialog && isInSupplierContext && txId && (
        <PaymentReminderDialog
          customerId={customerId?.toString()}
          txId={txId?.toString()}
          isSendByLink={true}
          closeDialog={() => setIsShowSendInvoiceDialog(false)}
        />
      )}

      {isShowCancelBatchPaymentDialog && supplierId && paymentAllocationTx && isShowCancelBatchBtn && (
        <ConfirmCancelBatchPaymentModal
          isOpen={isShowCancelBatchPaymentDialog}
          onClose={() => setIsShowCancelBatchPaymentDialog(false)}
          onSuccess={async () => {
            setIsShowCancelBatchPaymentDialog(false);
            handleOnClosePdfView('RefetchData');
          }}
          isScheduled={
            paymentAllocationTx?.invoicePaymentAllocation?.status === InvoicePaymentAllocationStatus.Scheduled
          }
          supplierId={supplierId}
          transactionID={paymentAllocationTx?.invoicePaymentAllocation?.invoicePaymentAllocationID}
          datTypeID={txDatType}
          schedulePaymentData={paymentAllocationTx?.invoicePaymentAllocation?.scheduledDateTime_utc}
        />
      )}

      {isShowApproveLoanDialog && (
        <ARDialogTemplate
          dialogProps={{size: 'sm', open: isShowApproveLoanDialog, className: '!min-w-[460px] !max-w-[460px]'}}
          isFullScreen
          header={<p className=" font-poppins text-xl font-light text-[#333333]">Confirm Funding Approval</p>}
          body={
            <div className="my-12 flex flex-col">
              <Typography variant="paragraph" className="text-center font-light text-black-800">
                Are you sure that you want to approve the loan?
              </Typography>
            </div>
          }
          footer={
            <div className="flex w-full flex-row items-center justify-between">
              <Button
                data-autoid={`btnCancel`}
                className="bg-white"
                onClick={() => setIsShowApproveLoanDialog(false)}
                variant="outlined"
                color="primary"
              >
                Cancel
              </Button>
              <Button
                disabled={isApprovingLoan}
                loading={isApprovingLoan}
                variant="filled"
                color="primary"
                data-autoid={`btnApprove`}
                onClick={() => onLoanApproval(tx?.Loan?.ID)}
              >
                Approve
              </Button>
            </div>
          }
          handleClose={() => setIsShowApproveLoanDialog(false)}
        />
      )}

      {isShowSelectEmailTemplateDialog && statementsAndAutomatedStatement83193 && (
        <SelectEmailTemplateDialog
          isShowModal={isShowSelectEmailTemplateDialog}
          onClose={() => setIsShowSelectEmailTemplateDialog(false)}
          invoiceId={tx?.ID}
          accountCustomerId={tx?.AccountCustomerID}
          scope={SelectEmailTemplateScope.SendInvoice}
        />
      )}

      {isShowPdfView && (
        <div
          className={`pdf-transaction-view absolute w-full ${
            scope === 'PSBlView' || scope === 'CustomerFocusView' ? 'top-[6px]' : 'top-[0px]'
          } left-0 z-[1299] h-full bg-[rgba(0,0,0,0.1)] backdrop-blur-[8px] `}
        >
          <div className="relative h-full w-full">
            <div className={`relative flex h-full flex-col rounded-[6px] bg-transparent p-0 px-2.5 font-poppins`}>
              <div className="invoiceBody mt-[40px] flex h-full flex-col">
                <div
                  data-autoid={'btnClose'}
                  onClick={handlePdfViewClose}
                  className="absolute right-[32px] z-10 flex cursor-pointer flex-row items-center justify-center"
                >
                  <Close className=" text-primary" />
                  <Typography variant="h2" className="ml-1 font-light text-black-800">
                    Close
                  </Typography>
                </div>
                <div className="flex h-full flex-col">
                  <div className=" h-full">
                    <div
                      className={`flex h-full flex-col ${
                        [DatTypes.Invoice, DatTypes.CreditNote].includes(txDatType) ? 'w-[750px]' : 'w-[800px]'
                      } relative mx-auto my-0`}
                    >
                      <div className="flex h-full flex-row overflow-auto">
                        <div className="flex">{previousButton}</div>
                        {isInvoice && (
                          <InvoiceTemplate
                            isLoading={isLoading}
                            supplier={supplierTenant}
                            tx={tx}
                            QuoteV2={isInBuyerContext ? QuoteV2Buyer : QuoteV2}
                            selectedViewTab={selectedViewTab.toString()}
                            setSelectedViewTab={setSelectedViewTab}
                            hideQuantity={!tenantInfo?.TenantUserDetails.IsShowQuantityInQuote}
                            isInBuyerContext={isInBuyerContext}
                          />
                        )}
                        {isCreditNote && <CreditNoteTemplate isLoading={isLoading} supplier={supplierTenant} tx={tx} />}
                        {isPayment && <PaymentTemplate isLoading={isLoading} tx={tx} />}
                        {isInvoiceAllocation && (
                          <InvoiceAllocationTemplate
                            isLoading={isLoading}
                            supplier={supplierTenant}
                            tx={paymentAllocationTx}
                          />
                        )}
                        <div className="flex">{nextButton}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {[DatTypes.Invoice, DatTypes.CreditNote, DatTypes.Payment].includes(txDatType) && !hideFooter && (
              <div className="absolute bottom-[40px] left-0 right-0 mx-auto flex h-[60px] w-[690px] flex-row items-center justify-between rounded-[8px] bg-[#f0f0f0] p-2.5">
                <div className="flex flex-row items-center justify-start gap-4">
                  {isShowStartConvoBtn &&
                    (isLoading ? (
                      <Skeleton width={'10rem'} height={'2rem'} />
                    ) : (
                      tx && (
                        <StartConvo
                          operationUniqueId={tx?.GUID}
                          conversationWith={(isInSupplierContext ? tx?.CustomerName : supplierTenant?.Name) || ''}
                          refNumber={tx?.RefNumber}
                          supplierID={isInBuyerContext ? supplierId : null}
                          customerID={isInSupplierContext ? customerId : null}
                          datTypeID={tx?.DatTypeID}
                          guid={tx?.GUID}
                          contextConvos={conversations}
                          startConvoType={StartConvoType.BUTTON}
                          convoType={ConversationType.DOCUMENT}
                          transactionId={tx?.ID}
                        />
                      )
                    ))}

                  {isShowCreateClaimBtn && (
                    <div className="flex flex-row items-center justify-start">
                      {isLoading ? (
                        <Skeleton width={'8rem'} height={'2rem'} />
                      ) : (
                        <Button
                          variant="filled"
                          color="primary"
                          data-autoid={`btnCreateClaim`}
                          onClick={RedirectToClaimCreatePage}
                        >
                          Create Claim
                        </Button>
                      )}
                    </div>
                  )}
                </div>

                <div className="flex flex-row items-center justify-end gap-2.5">
                  {txId && (
                    <>
                      {isLoading ? (
                        <Skeleton width={'4rem'} height={'2rem'} />
                      ) : (
                        <Button
                          loading={BuyerStatementAPI?.isLoading || customerStatementsAPI?.isLoading}
                          data-autoid={`btnPDF`}
                          className="bg-white"
                          onClick={() => onDownloadPDF()}
                          variant="outlined"
                          color="primary"
                        >
                          PDF
                        </Button>
                      )}
                    </>
                  )}
                  {isShowSendInvoiceBtn && (
                    <>
                      {isLoading ? (
                        <Skeleton width={'10rem'} height={'2rem'} />
                      ) : (
                        <Button
                          disabled={isShowSendInvoiceDialog}
                          variant="filled"
                          color="primary"
                          data-autoid={`btnSendInvoice`}
                          onClick={handleSendInvoiceClick}
                        >
                          Send Invoice
                        </Button>
                      )}
                    </>
                  )}
                </div>
              </div>
            )}
            {isInvoiceAllocation && !hideFooter && (
              <div className="absolute bottom-[40px] left-0 right-0 z-10 mx-auto flex h-[60px] w-[690px] flex-row items-center justify-between rounded-[8px] bg-[#f0f0f0] p-2.5">
                <div>
                  {isShowRetryPaymentBtn && (
                    <>
                      {retryAlertDialog && (
                        <RetryDialog
                          handleToggleAlertDialog={handleToggleRetryDialog}
                          handleRetryBatch={handleRetryBatch}
                        />
                      )}
                      <Button variant="filled" color="primary" onClick={handleToggleRetryDialog}>
                        {`Retry ${retryInvoiceCount} ${retryInvoiceCount > 1 ? 'invoices' : 'invoice'}`}
                      </Button>
                    </>
                  )}
                </div>
                <div
                  className={clsx(
                    {'justify-between': scheduledPaymentsV2},
                    {'justify-end': !scheduledPaymentsV2},
                    `m-3 flex w-full flex-row items-center gap-2.5`,
                  )}
                >
                  {isShowCancelBatchBtn &&
                    (isLoading ? (
                      <Skeleton width={'4rem'} height={'2rem'} />
                    ) : (
                      <Button
                        data-autoid={`btnCancelBatch`}
                        className="bg-white"
                        onClick={() => setIsShowCancelBatchPaymentDialog(true)}
                        variant="outlined"
                        color="error"
                      >
                        Cancel Batch
                      </Button>
                    ))}
                  {isLoading ? (
                    <Skeleton width={'4rem'} height={'2rem'} />
                  ) : (
                    <Button
                      loading={BuyerStatementAPI?.isLoading || customerStatementsAPI?.isLoading}
                      data-autoid={`btnPDF`}
                      className="bg-white"
                      onClick={onDownloadPDF}
                      variant="outlined"
                      color="primary"
                    >
                      PDF
                    </Button>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {!isShowPdfView && [DatTypes.Invoice, DatTypes.CreditNote, DatTypes.Payment].includes(txDatType) && (
        <div className="h-full">
          <div className={`flex h-full w-full flex-col rounded-[6px] bg-white p-0 font-poppins`}>
            <div className="invoiceHeader flex flex-row items-center justify-between border-[#D8D8D8] border-b-default px-2.5 py-[14px]">
              <div className="flex flex-row items-center justify-start gap-2.5">
                <div
                  data-autoid={'btnGoBackTransactionView'}
                  className="flex h-10 w-10 cursor-pointer flex-col items-center justify-center rounded-[6px] border-[1px] border-primary"
                  onClick={() => handleOnClosePdfView()}
                >
                  <GoBackIcon />
                </div>
                <Typography
                  variant="h2"
                  data-autoid={`lblRefNumberDatTypeID${tx?.ID}`}
                  className="font-light text-black-800"
                >
                  {tx?.RefNumber} {getTransactionType(tx?.DatTypeID || 0)} Details
                </Typography>
              </div>
              <div className="flex flex-row items-center justify-end gap-2.5">
                {isInvoice &&
                  (supplierId || customerId) &&
                  (isLoading ? (
                    <Skeleton width={'10rem'} height={'2rem'} />
                  ) : (
                    tx && (
                      <StartConvo
                        operationUniqueId={tx?.GUID}
                        conversationWith={(isInSupplierContext ? tx?.CustomerName : supplierTenant?.Name) || ''}
                        refNumber={tx?.RefNumber}
                        supplierID={isInBuyerContext ? supplierId : null}
                        customerID={isInSupplierContext ? customerId : null}
                        datTypeID={tx?.DatTypeID}
                        guid={tx?.GUID}
                        contextConvos={conversations}
                        startConvoType={StartConvoType.BUTTON}
                        convoType={ConversationType.DOCUMENT}
                        transactionId={tx?.ID}
                      />
                    )
                  ))}

                {isShowCreateClaimBtn && (
                  <div className="flex flex-row items-center justify-start">
                    {isLoading ? (
                      <Skeleton width={'8rem'} height={'2rem'} />
                    ) : (
                      <Button
                        variant="filled"
                        color="primary"
                        data-autoid={`btnCreateClaim`}
                        onClick={RedirectToClaimCreatePage}
                      >
                        Create Claim
                      </Button>
                    )}
                  </div>
                )}

                {isLoading ? (
                  <Skeleton width={'6rem'} height={'2rem'} />
                ) : (
                  <Button
                    variant="outlined"
                    color="primary"
                    className="bg-white"
                    data-autoid={`btnViewPDF`}
                    onClick={() => setIsShowPdfView(true)}
                  >
                    View PDF
                  </Button>
                )}

                {isShowApproveLoanBtn && (
                  <>
                    {isLoading ? (
                      <Skeleton width={'10rem'} height={'2rem'} />
                    ) : (
                      <Button
                        variant="outlined"
                        color="primary"
                        className="bg-white"
                        data-autoid={`btnApproveLoan`}
                        onClick={() => setIsShowApproveLoanDialog(true)}
                      >
                        Approve Loan
                      </Button>
                    )}
                  </>
                )}

                {isShowSendInvoiceBtn && (
                  <>
                    {isLoading ? (
                      <Skeleton width={'10rem'} height={'2rem'} />
                    ) : (
                      <Button
                        variant="filled"
                        color="primary"
                        disabled={isShowSendInvoiceDialog}
                        data-autoid={`btnSendInvoice`}
                        onClick={handleSendInvoiceClick}
                      >
                        Send Invoice
                      </Button>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="h-full overflow-auto px-[30px]">
              {isInvoice && isInBuyerContext ? (
                <InvoiceTemplate
                  isLoading={isLoading}
                  supplier={supplierTenant}
                  tx={tx}
                  QuoteV2={QuoteV2Buyer}
                  hideQuantity={!tenantInfo?.TenantUserDetails.IsShowQuantityInQuote}
                  isInBuyerContext={isInBuyerContext}
                />
              ) : (
                <InvoiceTemplate isLoading={isLoading} supplier={supplierTenant} tx={tx} />
              )}
              {isCreditNote && <CreditNoteTemplate isLoading={isLoading} supplier={supplierTenant} tx={tx} />}
              {isPayment && <PaymentTemplate isLoading={isLoading} tx={tx} />}
              <div className="z-10">{previousButton}</div>
              <div className="z-10">{nextButton}</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
