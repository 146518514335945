import React, {FunctionComponent, PropsWithChildren} from 'react';
import {Button, Dialog, DialogBody, DialogFooter, DialogHeader, Typography} from 'spenda-ui-react';
import {variant} from 'spenda-ui-react/types/components/typography';

export interface IDialogActionV2 extends PropsWithChildren {
  label: string;
  type?: 'submit' | 'button';
  action: (value?: any) => void;
  variant?: 'outlined' | 'text' | 'filled' | 'gradient';
  color?: 'primary' | 'secondary' | 'error' | 'warning';
  disabled?: boolean;
  hidden?: boolean;
  loading?: boolean;
  className?: string;
}

export interface IDialogPropsV2 extends PropsWithChildren {
  dialogClassess?: string;
  svg?: any;
  title?: string | React.ReactNode;
  actions?: IDialogActionV2[];
  overflowY?: 'auto' | 'hidden' | 'scroll';
  dialogActionsAlignment?: 'justify-end' | 'justify-start' | 'justify-center' | 'justify-between';
  paddingX?: number;
  paddingTopHeader?: number;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
  footer?: React.ReactNode | undefined;
  header?: React.ReactNode | undefined;
  showCancel?: boolean;
  onCancel?: () => void;
  handler?: (value: any) => void;
  isAccountsPayable?: boolean;
  headerFontWeight?: number;
  alignContentText?: string;
  alignContentItems?: string;
  showTitleBottomBorder?: boolean;
  id?: string | undefined;
  headingTextSize?: 'h1' | 'h2' | 'h3' | 'small';
  headingClassess?: string;
  dialogBodyClassess?: string;
  headerChildren?: React.ReactNode;
  footerClass?: string;
}

export const AlertDialogSlideV2: FunctionComponent<IDialogPropsV2> = prop => {
  const {
    svg,
    headingTextSize,
    title,
    children,
    size,
    headingClassess,
    dialogBodyClassess,
    dialogActionsAlignment,
    footer,
    actions,
    dialogClassess,
    headerChildren,
    handler,
    footerClass,
    ...rest
  } = prop;
  // using spenda-ui-react Dialog
  const [, setOpen] = React.useState(true);

  const closeDialog = () => {
    setOpen(false);
  };

  return (
    <Dialog open handler={handler ?? closeDialog} size={size ?? 'md'} className={dialogClassess ?? ''} {...rest}>
      {title && (
        <DialogHeader className={`${headingClassess ?? 'justify-center'} border-b border-[#EAEAEA]`}>
          {svg && <img className="mr-2" src={svg} alt="TimeOut" />}
          <Typography variant={headingTextSize ?? 'h2'}>{title}</Typography>
          {headerChildren}
        </DialogHeader>
      )}
      <DialogBody className={dialogBodyClassess}>{children}</DialogBody>
      <DialogFooter
        className={`${dialogActionsAlignment} ${footerClass} mx-2.5 mb-2.5 rounded-lg bg-spenda-footerBg p-2.5`}
      >
        {footer}
        {!footer &&
          actions?.map((a: IDialogActionV2, index: number) => (
            <Button
              key={index}
              onClick={a.action}
              disabled={a.disabled}
              hidden={a.hidden}
              className={`${a.className} ${a.variant === 'outlined' ? ' bg-white focus:ring-0' : ''}`}
              variant={a.variant}
              color={a.color ?? 'primary'}
              loading={a.loading}
            >
              {a.label}
            </Button>
          ))}
      </DialogFooter>
    </Dialog>
  );
};

interface IAlertDialog extends IDialogPropsV2 {
  content?: string;
  body?: React.ReactNode;
  contentClass?: string;
  contextTextVariant?: variant;
}

export const AlertDialog: React.FC<IAlertDialog> = prop => {
  const {
    actions,
    title,
    content,
    size,
    id,
    body,
    dialogActionsAlignment,
    headingTextSize,
    contentClass,
    contextTextVariant,
    children,
    ...rest
  } = prop;
  return (
    <AlertDialogSlideV2
      id={id}
      title={title}
      headingTextSize={headingTextSize ?? 'h1'}
      headingClassess="justify-center"
      dialogActionsAlignment={dialogActionsAlignment ?? 'justify-between'}
      size={size}
      actions={actions}
      {...rest}
    >
      {content ? (
        <Typography
          variant={contextTextVariant ?? 'h3'}
          className={`py-8 text-center font-normal text-black-800 ${contentClass}`}
        >
          {content}
        </Typography>
      ) : body ? (
        body
      ) : (
        children
      )}
    </AlertDialogSlideV2>
  );
};
