import React, {useContext, useState} from 'react';
import {Formik, FormikProps} from 'formik';

import SVGIcon from '../../../components/ui/SVGIcon';
import Cards from '../../../components/creditCards';
import {PaymentMethodTypes, PaymentWidgetScope} from '../../../model/constants/Constants';
import {PaymentWidgetContext, PaymentWidgetPaymentSteps, PaymentWidgetStep} from './PaymentWidget';
import {ITenantSupplierPaymentMethod} from '../../../model/payment/PaymentMethod';
import {SButton} from '../../../components/buttons/Button';
import {SDialog} from '../../../components/modals/modalSpendaMeterialUI';
import {UpdateAccountRequest} from '../../../services/useSpendaPaymentServicesAPI';
import {STextField} from '../../../components/inputs/STextField';
import {css} from 'glamor';
import {
  Box,
  createStyles,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  makeStyles,
} from '@material-ui/core';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import {IOSSwitch} from '../../../components/form/SwitchSlider';
import {InfoRound} from '../../../assets/svg/InfoRound';
import {ARDialogTemplate} from '../../../components/AccountsReceivable/ARDialogTemplate';
import {DestructiveButton, SecondaryButton} from '../../../components/buttons/DefaultButtons';
import {SpendaFinanceCard} from '../../../components/creditCards/SpendaFinanceCard';
import {AirPlusCard} from '../../../components/creditCards/AirPlusCard';

const useStyles = makeStyles(
  createStyles({
    shape: {
      height: 150,
      minWidth: 280,
    },
    iconRoot: {
      color: '#BBB',
      fontSize: '1.25rem',
      '& .MuiSvgIcon-root': {
        color: '#BBB !important',
      },
    },
    crossButton: {
      width: 'auto !important',
    },
    inputField: {
      position: 'relative',
      '& .MuiOutlinedInput-input:-webkit-autofill': {
        '-webkit-box-shadow': '0 0 0 100px #fff inset !important',
      },
      '& .MuiOutlinedInput-root': {
        padding: '8px 14px',
        height: '100%',
      },
      '& .MuiInputLabel-outlined': {
        transform: 'translate(14px, 16px) scale(1)',
        fontFamily: 'Poppins !important',
        fontSize: '14px',
        fontWeight: 500,
        width: 'auto',
        color: '#bbbbbb !important',
      },
      '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        transform: 'translate(14px, -5px) scale(1)',
        fontSize: '10px ',
      },
      '& textarea, input': {
        padding: '0px',
        fontFamily: 'Poppins !important',
        fontSize: '14px',
      },
      '& .MuiFormHelperText-root.Mui-error': {
        fontFamily: 'Poppins !important',
        position: 'relative !important',
        top: '0px !important',
      },
      '& .MuiInputAdornment-positionEnd button': {
        padding: '0px !important',
      },
    },
  }),
);

const heading = css({
  '& .MuiTypography-root': {
    fontFamily: 'poppins',
  },
});

const tooltip = css({
  position: 'relative',
  display: 'flex',
  cursor: 'pointer',
  '& .tooltipStyles': {
    display: 'none',
    width: '308px',
    backgroundColor: '#fff',
    color: '#333',
    borderRadius: '4px',
    padding: '5px 20px',
    position: 'absolute',
    bottom: '192%',
    right: '-100%',
    zIndex: 1,
    boxShadow: '0px 0px 9px 6px rgba(0, 0, 0, 10%)',
    '&:after': {
      content: '""',
      position: 'absolute',
      top: '100%',
      right: '8%',
      borderWidth: '10px',
      borderStyle: 'solid',
      borderColor: '#fff transparent transparent transparent',
    },
  },
  '&:hover .tooltipStyles': {
    display: 'flex',
  },
});

export const PaymentWidgetStepUpdatePaymentDetails = () => {
  const classes = useStyles();
  const {
    cardToEdit,
    onCancel: onCancelContext,
    onDone,
    widgetScope,
    paymentAuth72488,
    setCardToEdit,
    setStep,
    setIsRefreshPaymentMethods,
    removeCreditCard,
    updateAccountDetails,
    updatePaymentMethodDetails,
    setIsPersistSelectedPaymentMethod,
    isLinkedToLendingProvider,
    tenantName,
    storedPaymentOptions,
  } = useContext(PaymentWidgetContext);

  const [isShowDeleteConfirmation, setIsShowDeleteConfirmation] = useState<boolean>(false);
  const [isShowUpdateAutoPayConfirmation, setIsShowUpdateAutoPayConfirmation] = useState<boolean>(false);
  const [showAutoPayError, setShowAutoPayError] = useState<boolean>(false);
  const isBankTransfer = cardToEdit?.PaymentMethod === PaymentMethodTypes.BankTransfer;
  const isSpendaFinanceCard = cardToEdit?.PaymentMethod === PaymentMethodTypes.LendingFacility;
  const isAirPlus = cardToEdit?.PaymentMethod === PaymentMethodTypes.Airplus;

  const onToggleRemoveCreditCardConfirmation = () => {
    if (cardToEdit?.IsInvigoDefault) {
      setShowAutoPayError(true);
      return;
    }
    if (setCardToEdit) setCardToEdit(cardToEdit);
    setIsShowDeleteConfirmation(true);
  };

  const onCancel = () => {
    if (setCardToEdit) setCardToEdit(undefined);

    if (
      [
        PaymentWidgetScope.CONFIGURE_PAYMENT_OPTIONS,
        PaymentWidgetScope.ADD_BANK_ACCOUNT,
        PaymentWidgetScope.ADD_CREDIT_CARD,
      ].includes(widgetScope!)
    ) {
      if (onCancelContext) onCancelContext();
      return;
    }

    if (setStep) setStep(PaymentWidgetPaymentSteps.NEW);
  };

  const onRemoveCreditCard = async () => {
    if (!removeCreditCard || !cardToEdit) return;
    const isDeleted = await removeCreditCard(cardToEdit);

    if (isDeleted || cardToEdit.PaymentAccountGUID) {
      if (setIsRefreshPaymentMethods) setIsRefreshPaymentMethods(true);
      if (setCardToEdit) setCardToEdit(undefined);
      setIsShowDeleteConfirmation(false);

      if (widgetScope === PaymentWidgetScope.CONFIGURE_PAYMENT_OPTIONS) {
        if (onCancelContext) onCancelContext();
        return;
      }

      if (setStep) setStep(PaymentWidgetPaymentSteps.NEW);
    }
  };

  const updateDetails = async (details: ITenantSupplierPaymentMethod) => {
    let updateReq: UpdateAccountRequest = {
      friendlyName: details.FriendlyName,
      isInvigoDefault: details?.IsInvigoDefault!,
    };
    //Alex: SPS CC & SPS BT
    if (details?.PaymentAccountGUID) {
      if (updateAccountDetails) {
        await updateAccountDetails(details.PaymentAccountGUID, updateReq);
      }
    } else {
      //Alex: fiserv one
      if (details?.SupplierPaymentOptionID && updatePaymentMethodDetails) {
        await updatePaymentMethodDetails(details.SupplierPaymentOptionID.toString(), updateReq);
      }
    }

    if (setIsPersistSelectedPaymentMethod) setIsPersistSelectedPaymentMethod(true);
    if (
      !paymentAuth72488 &&
      [
        PaymentWidgetScope.CONFIGURE_PAYMENT_OPTIONS,
        PaymentWidgetScope.ADD_BANK_ACCOUNT,
        PaymentWidgetScope.ADD_CREDIT_CARD,
      ].includes(widgetScope!)
    ) {
      if (onDone) onDone();
      return;
    }
    setTimeout(() => {
      if (setStep) {
        if (paymentAuth72488) {
          if (cardToEdit && setCardToEdit) {
            cardToEdit.FriendlyName = details.FriendlyName;
            cardToEdit.IsInvigoDefault = details.IsInvigoDefault;
            setCardToEdit(cardToEdit);
          }
          setStep(PaymentWidgetPaymentSteps.SET_PAYMENT_AUTHORISATION_RULES);
          return;
        }
        if (setIsRefreshPaymentMethods) setIsRefreshPaymentMethods(true);
        setStep(PaymentWidgetPaymentSteps.NEW);
      }
    }, 500);
  };

  const DetailsPreview = () => {
    return (
      <div>
        {isBankTransfer ? (
          <Box height="150px" className="flex flex-col justify-center">
            <Box display="flex" justifyContent="center" paddingTop="0.175rem">
              <SVGIcon width={'75px'} height={'75px'} bankAccount={PaymentMethodTypes.BankTransfer} />
            </Box>
            <Box lineHeight={1} paddingTop="2px" marginTop="22px">
              <Box display="flex" justifyContent="center" fontSize={'12px'} className={'pb-1 font-poppins'}>
                <Box paddingRight="4px">BSB</Box>
                <Box fontWeight={600}>{cardToEdit?.BankBSB}</Box>
              </Box>
              <Box display="flex" justifyContent="center" fontSize={'14px'} className={'font-poppins'}>
                <Box paddingRight="4px">ACC</Box>
                <Box fontWeight={600}>{cardToEdit?.BankAccountNumber}</Box>
              </Box>
            </Box>
          </Box>
        ) : isSpendaFinanceCard ? (
          <SpendaFinanceCard cardDetails={cardToEdit} />
        ) : isAirPlus ? (
          <AirPlusCard tenantName={tenantName} />
        ) : (
          <>
            <Cards
              cvc=""
              expiry={cardToEdit?.Expiry || ''}
              name={cardToEdit?.CardHolderName || ''}
              preview={true}
              issuer={cardToEdit?.PaymentMethod}
              number={`••••••••••••${cardToEdit?.Last4}`}
            />
            <p className="mt-1 font-poppins text-xs">{cardToEdit?.FriendlyName}</p>
          </>
        )}
      </div>
    );
  };

  const initialValues = {
    ...cardToEdit,
  };

  const title = `Edit your ${isBankTransfer ? 'Bank Account' : 'card'} details`;

  const body = (
    <Box mx="25px">
      <div className="font-poppins">
        <SDialog
          open={isShowDeleteConfirmation}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" className={`${heading} font-poppins`}>
            Delete Card
          </DialogTitle>
          <DialogContent className="font-poppins">
            <p>You wont be able to use this card in the future. Are you sure you want to delete it?</p>
          </DialogContent>
          <DialogActions>
            <SButton
              width="86px"
              height="40px"
              fontFamily="poppins"
              fontSize="14px"
              margin="0px 0px 10px 0px"
              color="blueShade"
              onClick={() => setIsShowDeleteConfirmation(false)}
            >
              Cancel
            </SButton>
            <SButton
              width="86px"
              height="40px"
              fontFamily="poppins"
              fontSize="14px"
              margin="0px 0px 10px 0px"
              onClick={onRemoveCreditCard}
              color="red"
              className="font-poppins"
            >
              Delete
            </SButton>
          </DialogActions>
        </SDialog>
      </div>
      <DetailsPreview />
      <Box mt={isBankTransfer ? '30px' : '40px'}>
        <Formik initialValues={initialValues as ITenantSupplierPaymentMethod} onSubmit={updateDetails}>
          {({values, setFieldValue, handleSubmit, handleChange}: FormikProps<ITenantSupplierPaymentMethod>) => (
            <Box className={`${classes.inputField}`}>
              {isShowUpdateAutoPayConfirmation && isLinkedToLendingProvider && (
                <ARDialogTemplate
                  dialogProps={{size: 'sm', open: isShowUpdateAutoPayConfirmation}}
                  isFullScreen
                  header={
                    <p className=" font-poppins text-xl font-light text-[#333333]">Update Spenda finance repayments</p>
                  }
                  body={
                    <div className="my-12">
                      <p className="text-center font-poppins text-base font-light text-black-800">
                        This payment option will be automatically charged for all future Spenda finance repayments.
                      </p>
                    </div>
                  }
                  footer={
                    <div className="flex w-full flex-row items-center justify-between">
                      <SecondaryButton
                        data-autoid="btnClearAdvanceFilter"
                        label="Cancel"
                        onClick={() => {
                          setFieldValue('IsInvigoDefault', false);
                          setIsShowUpdateAutoPayConfirmation(false);
                        }}
                      />
                      <DestructiveButton
                        data-autoid="btnDoneAdvanceFilter"
                        label="Update"
                        onClick={() => setIsShowUpdateAutoPayConfirmation(false)}
                      />
                    </div>
                  }
                />
              )}
              <form id="update-payment-form" onSubmit={handleSubmit}>
                <STextField
                  name="FriendlyName"
                  label="Nickname"
                  variant="outlined"
                  height="45px"
                  borderColor="#000"
                  backgroundColor="#FFF"
                  labelColor="#000"
                  value={values.FriendlyName}
                  onChange={handleChange}
                  InputProps={{
                    style: {fontFamily: 'Poppins', fontSize: '10px'},
                    endAdornment: values?.FriendlyName?.length ? (
                      <InputAdornment position="end">
                        <IconButton
                          className={classes.crossButton}
                          data-autoid="clearFriendlyName"
                          onClick={() => setFieldValue('FriendlyName', '')}
                        >
                          <ClearRoundedIcon className={`${classes.iconRoot} `} />
                        </IconButton>
                      </InputAdornment>
                    ) : (
                      ''
                    ),
                  }}
                />
                {/* { TODO: remove the toggle once we no longer use InvigoPaylater} */}
                {isLinkedToLendingProvider &&
                  storedPaymentOptions?.some(pm => pm.PaymentMethod === PaymentMethodTypes.Invigo) &&
                  cardToEdit?.PaymentMethod !== PaymentMethodTypes.Invigo && (
                    <div className="flex items-center justify-center">
                      <span className="text-start font-poppins text-xs">Enable Spenda finance repayments?</span>
                      <IOSSwitch
                        checked={values.IsInvigoDefault || false}
                        disabled={showAutoPayError}
                        name="IsInvigoDefault"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                          if (cardToEdit?.IsInvigoDefault) {
                            setShowAutoPayError(true);
                          } else {
                            setFieldValue('IsInvigoDefault', checked);
                            if (checked) {
                              setIsShowUpdateAutoPayConfirmation(true);
                            }
                          }
                        }}
                      />
                      <div className={`${tooltip} flex items-center`}>
                        <InfoRound
                          style={{
                            width: '24px',
                            height: '24px',
                            color: '#BBBBBB',
                          }}
                        />
                        <div className={'tooltipStyles flex flex-col'} style={{right: '-20px'}}>
                          <p className="py-1 font-poppins text-xs">
                            This payment option will be automatically charged when Spenda finance repayments become due.
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
              </form>
            </Box>
          )}
        </Formik>
        {showAutoPayError ? (
          <p className="mt-4 text-start font-poppins text-xs text-spenda-error">
            There are scheduled Spenda finance repayments on this payment option. Set up another card or account for
            Spenda finance repayments, or wait until the payments are processed.
          </p>
        ) : (
          <p className="mx-5 mt-8 font-poppins text-xs">
            {`If you would like to change other ${isBankTransfer ? 'bank' : 'card'} details please remove the ${
              isBankTransfer ? 'account' : 'card'
            } and add a new payment method.`}
          </p>
        )}
      </Box>
    </Box>
  );

  const footer = (
    <>
      <SButton
        width="85px"
        height="40px"
        borderRadius="6px"
        fontSize="0.875rem"
        fontFamily="Poppins"
        color="white"
        textColor="blueShade"
        border={'1px solid #1C78AD'}
        onClick={onCancel}
      >
        Cancel
      </SButton>
      <SButton
        width="90px"
        height="40px"
        borderRadius="6px"
        fontSize="0.875rem"
        fontFamily="Poppins"
        color="red"
        onClick={onToggleRemoveCreditCardConfirmation}
        disabled={showAutoPayError}
      >
        Remove
      </SButton>
      <SButton
        width="85px"
        height="40px"
        borderRadius="6px"
        fontSize="0.875rem"
        fontFamily="Poppins"
        type="submit"
        form="update-payment-form"
        color="blueShade"
      >
        {paymentAuth72488 ? 'Continue' : 'Save'}
      </SButton>
    </>
  );

  return <PaymentWidgetStep title={title} body={body} footer={footer} />;
};
