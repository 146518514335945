import React, {useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {FormikProps} from 'formik';

import {IQuotes} from '../../model/quotes/quotes';
import {Layout} from '../../components/layout/Layout';
import useQuotesAPI from '../../services/useQuotesAPI';
import QuoteCreateForm from '../../components/form/QuoteCreateForm';
import {useQuoteContext} from '../../context/quote-context/QuoteContext';
import QuoteCreateRightPanel from '../../components/form/QuoteCreateRightPanel';
import QuoteDetailsCardV2 from '../../components/quote-management/QuoteDetailsCardV2';
import QuoteContextProvider from '../../context/quote-context/QuoteContextProvider';
import {QuoteDetailsDialog} from '../../components/dialog';

export const CreateQuotePackageV2 = () => {
  return (
    <QuoteContextProvider>
      <CreateQuoteV2 />
    </QuoteContextProvider>
  );
};

const CreateQuoteV2 = () => {
  const {
    quotePackage,
    setQuotePackage,
    selectedQuoteVariantID,
    customerDetails,
    quoteCustomer,
    quotePackageDetails,
    setQuotePackageDetails,
    handleDeleteQuote,
    setSelectedVariantTotalAmount,
    setSelectedQuoteVariantID,
    duplicatePackage,
  } = useQuoteContext();

  const history = useHistory();
  const {updateQuotePackage, updateQuote, duplicateQuotePackage} = useQuotesAPI();

  const [showQuoteDetailsDialog, setShowQuoteDetailsDialog] = useState<boolean>(false);
  const [isUpdatingLines, setIsUpdatingLines] = useState<boolean>(false);

  const formRef = useRef<FormikProps<IQuotes>>(null);

  const updateVariantTotalAmount = (amount: number) => {
    setSelectedVariantTotalAmount?.(amount);
    setIsUpdatingLines(false);
  };

  const handleSelectedQuoteVariant = async (quoteId: number) => {
    if (formRef?.current?.dirty) {
      // save quote variant
      const updatedQuote: IQuotes = await updateQuote(formRef.current.values);
      const quotePkg = quotePackage && {...quotePackage};
      const index = quotePkg?.quotes?.findIndex(q => q.quoteID == selectedQuoteVariantID);
      if (index != -1 && quotePkg && quotePkg?.quotes?.length) {
        quotePkg.quotes[index!] = updatedQuote;
        setQuotePackage?.(quotePkg);
      }
      setSelectedQuoteVariantID?.(quoteId);
    } else {
      setSelectedQuoteVariantID?.(quoteId);
    }
    const amount = quotePackage?.quotes?.find(q => q.quoteID == quoteId)?.totalInc || 0;
    setSelectedVariantTotalAmount?.(amount);
  };

  const handleContinueClick = async (expiryDate: string, assignedToUserID: number, days: number) => {
    if (!duplicatePackage) {
      setQuotePackageDetails?.({
        validFor: expiryDate,
        assignQuoteToUserID: assignedToUserID,
        expireInDays: days,
      });
      const updatedPackage = await updateQuotePackage({
        expiryDate: days ? undefined : expiryDate,
        expiresInDays: expiryDate ? undefined : days,
        assignedToUserID,
        quotePackageID: quotePackage?.quotePackageID,
      });
      setQuotePackage?.(updatedPackage);
      setShowQuoteDetailsDialog(false);
    }
    if (quoteCustomer?.ID) {
      const duplicatedPackage = await duplicateQuotePackage({
        quotePackageID: quotePackage?.quotePackageID!,
        customerID: quoteCustomer.ID,
        assignedToUserID,
        expiryDate,
        expiresInDays: days,
      });
      setQuotePackage?.(duplicatedPackage);
    }
  };

  const handleNavigate = async (expiryDate: string, assignedToUserID: number, days: number) => {
    await handleContinueClick(expiryDate, assignedToUserID, days);
    if (duplicatePackage) {
      history.push(`/manage-quotes`);
    }
  };

  const _createQuote = selectedQuoteVariantID && (
    <>
      <QuoteDetailsCardV2 />
      <QuoteCreateForm
        formRef={formRef}
        quotePackage={quotePackage}
        setQuotesPackage={setQuotePackage!}
        selectedQuote={selectedQuoteVariantID}
        selectedQuoteCustomer={customerDetails!}
        expiryDate={quotePackageDetails?.validFor!}
        assignedToUserID={quotePackageDetails?.assignQuoteToUserID!}
        setShowQuoteDetailsDialog={setShowQuoteDetailsDialog}
        handleDeleteQuote={handleDeleteQuote!}
        updateVariantTotalAmount={updateVariantTotalAmount}
        setIsUpdatingLines={setIsUpdatingLines}
      />
    </>
  );

  const _rightPanelV2 = selectedQuoteVariantID && (
    <QuoteCreateRightPanel isSaving={isUpdatingLines} handleSelectedQuoteVariant={handleSelectedQuoteVariant} />
  );

  return (
    <div className={`relative h-full overflow-hidden bg-primary/5 font-poppins`}>
      <Layout leftPanel={_createQuote} mainPanel={_rightPanelV2} splitWidthType={'MP'} />

      {showQuoteDetailsDialog && (
        <QuoteDetailsDialog
          isEdit={true}
          open={showQuoteDetailsDialog}
          handleGoBack={() => {
            setShowQuoteDetailsDialog(!showQuoteDetailsDialog);
          }}
          quotePackageDetails={quotePackageDetails || {validFor: '', assignQuoteToUserID: 0}}
          handleContinueClick={handleNavigate}
        />
      )}
    </div>
  );
};

export default CreateQuoteV2;
