import moment from 'moment';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import {SameDataArrow} from '../../../assets/svg/SameDataArrow';
import {useTransactionViewHook} from '../../../hooks/useARHook';
import {useTenantInfo} from '../../../hooks/useTenantInfo';
import {LogoTypeEnum} from '../../../model/Tenant';
import {IBusTransLine, IInvoice, IRefBusTran} from '../../../model/invoice/Invoice';
import {ILinkedTran} from '../../../model/requisition/Requisition';
import {ISupplierWhoRaisedInvoice} from '../../../model/supplier/SupplierWhoRaisedInvoice';
import {NameInitials, PriceFormat} from '../../../utils/formatter';
import {TransactionAttachments} from '../../ui/InvoiceDetailsComponent/TransactionAttachments';
import {ARTable} from '../ARTable';
import {PaymentStatusBadge} from '../PaymentStatusBadge';
import {Typography} from 'spenda-ui-react';

interface ICreditNoteTemplateProps {
  tx?: IInvoice;
  isLoading: boolean;
  supplier?: ISupplierWhoRaisedInvoice;
}

export const CreditNoteTemplate = (props: ICreditNoteTemplateProps) => {
  const {tx, isLoading, supplier} = props;
  const {isInBuyerContext} = useTenantInfo();
  const LogoURI = supplier?.Logos.find(x => x.LogoTypeID.toString() === LogoTypeEnum.Logo)?.URI;
  const {handleViewTx} = useTransactionViewHook();

  const aRContactEmailAddress =
    tx && tx.ARContactDetails
      ? tx.ARContactDetails
      : supplier
        ? supplier.ARContactEmailAddress || supplier.PrimaryContactEmail || ''
        : '';

  const isVendorPresent = !!tx?.VendorName;

  const invoiceAttachedColumns = [
    {
      title: 'Date',
      key: 'creditNoteDate',
      columClassName: 'font-poppins leading-loose !text-[10px] font-medium !text-black-800 text-left py-0 pl-2 pr-1',
      rowClassName: 'p-0 pl-2 pr-1',
      align: 'left',
      isSortable: false,
      rowRenderer: (rowData: IRefBusTran | ILinkedTran, rowIndex: number) => (
        <Typography
          data-autoid={`lblCreditNoteDate`}
          variant="xsmall"
          className="inline text-left text-[10px] font-normal leading-loose text-black-800"
        >
          {rowIndex === 0 && moment(tx?.TransDate).format('DD/MM/YYYY')}
        </Typography>
      ),
    },
    {
      title: 'Ref Number',
      key: 'refNumber',
      columClassName: 'font-poppins leading-loose !text-[10px] font-medium !text-black-800 text-left pl-0 py-0 pr-1',
      rowClassName: 'p-0 pr-1',
      align: 'left',
      isSortable: false,
      rowRenderer: (rowData: IRefBusTran | ILinkedTran, rowIndex: number) => (
        <Typography
          data-autoid={`lblRefNumber`}
          variant="xsmall"
          className="inline cursor-pointer text-left text-[10px] font-normal leading-loose text-primary underline"
        >
          {rowIndex === 0 ? tx?.RefNumber : <SameDataArrow />}
        </Typography>
      ),
    },
    {
      title: 'Invoice Ref No.',
      key: 'invoice_RefNumber',
      columClassName: 'font-poppins leading-loose !text-[10px] font-medium !text-black-800 text-left pl-0 py-0 pr-1',
      rowClassName: 'p-0 pr-1',
      align: 'left',
      isSortable: false,
      rowRenderer: (rowData: any | ILinkedTran) => (
        <Typography
          data-autoid={`lblInvoiceRefNumber`}
          variant="xsmall"
          className="inline cursor-pointer text-left text-[10px] font-normal leading-loose text-primary underline"
          onClick={() =>
            handleViewTx(
              {
                txDatType: isInBuyerContext ? rowData?.TransTypeID : rowData?.DatTypeID,
                txGuid: rowData.GUIDstring,
                txId: rowData.ID,
              },
              true,
            )
          }
        >
          {rowData?.RefNumber}
        </Typography>
      ),
    },
    {
      title: 'Total',
      key: 'totalInc',
      columClassName: 'font-poppins !text-[10px] leading-loose font-medium !text-black-800 text-right py-0 pr-2',
      rowClassName: 'p-0 pr-2',
      align: 'right',
      isSortable: false,
      rowRenderer: (rowData: IRefBusTran | ILinkedTran) => (
        <Typography
          data-autoid={`lblAppliedAmount`}
          variant="xsmall"
          className="inline text-right text-[10px] font-normal leading-loose text-black-800"
        >
          {PriceFormat(rowData?.AppliedAmount)}
        </Typography>
      ),
    },
  ];

  if (isVendorPresent) {
    invoiceAttachedColumns?.splice(
      2,
      0,
      {
        title: 'Vendor',
        key: 'customer_Vendor',
        columClassName:
          'font-poppins leading-loose !text-[10px] font-medium !text-black-800 text-left py-0 pr-1 bg-[#ddd]',
        rowClassName: 'p-0 pl-3',
        align: 'left',
        isSortable: true,
        rowRenderer: (rowData: IRefBusTran | ILinkedTran, rowIndex: number) => (
          <Typography
            data-autoid={`lblVendorName`}
            variant="xsmall"
            className="inline text-left text-[10px] font-normal leading-loose text-black-800"
          >
            {rowIndex === 0 && tx?.VendorName}
          </Typography>
        ),
      },
      {
        title: 'Vendor Ref No.',
        key: 'vendor_RefNumber',
        columClassName:
          'font-poppins !text-[10px] leading-loose font-medium !text-black-800 text-right py-0 pr-1 bg-[#ddd]',
        rowClassName: 'p-0 pl-3',
        align: 'left',
        isSortable: false,
        rowRenderer: (rowData: IRefBusTran | ILinkedTran, rowIndex: number) => (
          <Typography
            data-autoid={`lblVendorTransRefNumber`}
            variant="xsmall"
            className="inline text-left text-[10px] font-normal leading-loose text-black-800"
          >
            {rowIndex === 0 && tx?.VendorTransRefNumber}
          </Typography>
        ),
      },
    );
  }

  const lineItemColumns = [
    {
      title: 'Code',
      key: 'Code',
      columClassName: 'font-poppins leading-loose !text-[10px] font-medium !text-black-800 text-left py-0 pl-2 pr-1',
      rowClassName: 'p-0 pl-2 pr-1',
      align: 'left',
      isSortable: false,
      rowRenderer: (r: IBusTransLine) => (
        <Typography
          data-autoid={`lblCode`}
          variant="xsmall"
          className="inline text-left text-[10px] font-normal leading-loose text-black-800"
        >
          {r?.Code}
        </Typography>
      ),
    },
    {
      title: 'Description',
      key: 'Description',
      columClassName: 'font-poppins leading-loose !text-[10px] font-medium !text-black-800 text-left py-0 pl-2 pr-1',
      rowClassName: 'p-0 pl-2 pr-1',
      align: 'left',
      isSortable: false,
      rowRenderer: (r: IBusTransLine) => (
        <Typography
          data-autoid={`lblDescription`}
          variant="xsmall"
          className="inline text-left text-[10px] font-normal leading-loose text-black-800"
        >
          {r?.Description}
        </Typography>
      ),
    },
    {
      title: 'Qty',
      key: 'Quantity',
      columClassName: 'font-poppins leading-loose !text-[10px] font-medium !text-black-800 text-left py-0 pl-2 pr-1',
      rowClassName: 'p-0 pl-2 pr-1',
      align: 'left',
      isSortable: false,
      rowRenderer: (r: IBusTransLine) => (
        <Typography
          data-autoid={`lblQuantity`}
          variant="xsmall"
          className="inline text-left text-[10px] font-normal leading-loose text-black-800"
        >
          {r?.Quantity}
        </Typography>
      ),
    },
    {
      title: 'Unit Price',
      key: 'SellPriceEx',
      columClassName: 'font-poppins leading-loose !text-[10px] font-medium !text-black-800 text-left py-0 pl-2 pr-1',
      rowClassName: 'p-0 pl-2 pr-1',
      align: 'left',
      isSortable: false,
      rowRenderer: (r: IBusTransLine) => (
        <Typography
          data-autoid={`lblSellPriceEx`}
          variant="xsmall"
          className="inline text-left text-[10px] font-normal leading-loose text-black-800"
        >
          {r?.SellPriceEx}
        </Typography>
      ),
    },
    {
      title: 'Line Total',
      key: 'LineTotalEx',
      columClassName: 'font-poppins leading-loose !text-[10px] font-medium !text-black-800 text-left py-0 pl-2 pr-1',
      rowClassName: 'p-0 pl-2 pr-1',
      align: 'right',
      isSortable: false,
      rowRenderer: (r: IBusTransLine) => (
        <Typography
          data-autoid={`lblLineTotalEx`}
          variant="xsmall"
          className="inline text-left text-[10px] font-normal leading-loose text-black-800"
        >
          {r?.LineTotalEx}
        </Typography>
      ),
    },
  ];

  const renderTableSkelton = (
    <table className="mb-[20px]">
      <thead>
        <tr className="border-t-spenda-dashbg bg-[#ddd]">
          <th className="text-left font-poppins text-base font-normal text-black-800">
            <Skeleton width={'90%'} />
          </th>
          <th className="text-left font-poppins text-base font-normal text-black-800">
            <Skeleton width={'90%'} />
          </th>
          <th className="text-center font-poppins text-base font-normal text-black-800">
            <Skeleton width={'90%'} />
          </th>
          <th className="text-right font-poppins text-base font-normal text-black-800">
            <Skeleton width={'90%'} />
          </th>
        </tr>
      </thead>
      <tbody className="border-b-spenda-dashbg">
        {[0, 1, 2, 3]?.map(tl => (
          <tr key={tl}>
            <td className="text-left font-poppins text-base font-normal text-black-800">
              <Skeleton width={'90%'} />
            </td>
            <td className="text-left font-poppins text-base font-normal text-black-800">
              <Skeleton width={'90%'} />
            </td>
            <td className="text-center font-poppins text-base font-normal text-black-800">
              <Skeleton width={'90%'} />
            </td>
            <td className="text-right font-poppins text-base font-normal text-black-800">
              <Skeleton width={'90%'} />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  return (
    <>
      <div className="flex h-auto w-full flex-col overflow-y-auto overflow-x-hidden bg-white px-[30px] pb-2.5 pt-[40px]">
        <div className="flex h-auto w-full flex-row items-start justify-between border-b-spenda-dashbg pb-3">
          <div className="flex w-[50%] flex-col items-start justify-start">
            {LogoURI ? (
              <img data-autoid={'imgLogoURIInvoiceTemplate'} src={LogoURI} className="h-[100px] w-[100px]" />
            ) : supplier?.Name && !isLoading ? (
              <div className="flex h-[100px] w-[100px] flex-row items-center justify-center rounded-full bg-[#b8b046] p-4">
                <Typography
                  data-autoid={'lblSupplierNameInitialsInvoiceTemplate'}
                  variant="paragraph"
                  className="text-5xl text-black-800"
                >
                  {NameInitials(supplier?.Name)?.toUpperCase()}
                </Typography>
              </div>
            ) : (
              <Skeleton circle={true} height={'6rem'} width={'6rem'} />
            )}
            <Typography variant="paragraph" className="pb-1 pt-3 text-xl font-light text-black-800">
              {supplier && !isLoading ? supplier?.Name : <Skeleton width={`7rem`} />}
            </Typography>
            <Typography variant="xsmall" className=" pb-1 text-black-800">
              {supplier && !isLoading ? `ABN: ${supplier?.ABN || ''}` : <Skeleton width={`4rem`} />}
            </Typography>
            <Typography variant="xsmall" className=" pb-1 text-black-800">
              {supplier && !isLoading ? `Phone: ${supplier?.BusinessPhone || ''}` : <Skeleton width={`7rem`} />}
            </Typography>
            <Typography variant="xsmall" className=" pb-1 text-black-800">
              {!isLoading ? `Email: ${aRContactEmailAddress}` : <Skeleton width={`8rem`} />}
            </Typography>
            <Typography variant="xsmall" className=" text-black-800">
              {!isLoading ? `Web: ${supplier?.Website || ''}` : <Skeleton width={`8rem`} />}
            </Typography>
          </div>
          <div className="flex w-[50%] flex-col items-end justify-end">
            <div className="flex flex-row items-center justify-between gap-4">
              <div>
                {!!tx?.Attachments?.length && (
                  <TransactionAttachments Counter={tx?.AttachmentCount} Attachments={tx?.Attachments} />
                )}
              </div>
              <Typography variant="paragraph" className="pb-1 text-xl font-bold text-black-800">
                Credit Note
              </Typography>
            </div>
            <PaymentStatusBadge
              isLoading={isLoading}
              status={tx?.InvoicePaymentAllocations?.[0]?.Status || tx?.Status}
            />
            <Typography variant="xsmall" className=" pb-1 text-black-800">
              Credit Note #: {tx && !isLoading ? tx.RefNumber : <Skeleton width={`4rem`} />}
            </Typography>
            <Typography variant="xsmall" className=" pb-1 text-black-800">
              Issue Date: {tx && !isLoading ? tx?.TransDateString : <Skeleton width={`6rem`} />}
            </Typography>
            <Typography variant="xsmall" className=" text-black-800">
              Date Applied:{' '}
              {tx && !isLoading ? moment(tx?.AppliedDate).format('DD MMM YYYY') : <Skeleton width={`6rem`} />}
            </Typography>
          </div>
        </div>
        <div className="mt-2.5 flex h-auto w-full flex-row items-start justify-between pb-[34px]">
          <div className="flex w-[50%] flex-col items-start justify-start">
            <Typography variant="paragraph" className="pb-1 font-medium text-black-800">
              Bill To: {tx && !isLoading ? tx?.CustomerName : <Skeleton width={`7rem`} />}
            </Typography>
            <Typography variant="xsmall" className=" pb-1 text-black-800">
              {tx && !isLoading ? (
                `Contact: ${tx?.Contact?.FirstName} ${tx?.Contact?.LastName}`
              ) : (
                <Skeleton width={`7rem`} />
              )}
            </Typography>
            <Typography variant="xsmall" className=" pb-1 text-black-800">
              {tx && !isLoading ? tx?.ContactEmailAddress : <Skeleton width={`7rem`} />}
            </Typography>
            <Typography variant="xsmall" className=" pb-1 text-black-800">
              {tx && !isLoading ? `Ref: ${tx?.RefNumber}` : <Skeleton width={`7rem`} />}
            </Typography>
          </div>
          <div className="flex w-[50%] flex-row items-start justify-end gap-6">
            <div className="flex flex-col items-start">
              <Typography variant="paragraph" className="pb-1 font-medium text-black-800">
                Shipping:
              </Typography>
              <Typography variant="xsmall" className=" pb-1 text-black-800">
                {tx && !isLoading ? tx?.ShippingAddress?.StreetAddress : <Skeleton width={`7rem`} />}
              </Typography>
              <Typography variant="xsmall" className=" pb-1 text-black-800">
                {tx && !isLoading ? (
                  `${tx?.ShippingAddress?.City} ${tx?.ShippingAddress?.State} ${tx?.ShippingAddress?.PostCode}`
                ) : (
                  <Skeleton width={`8rem`} />
                )}
              </Typography>
            </div>
            <div className="flex flex-col items-start">
              <Typography variant="paragraph" className="pb-1 font-medium text-black-800">
                Billing:
              </Typography>
              <Typography variant="xsmall" className=" pb-1 text-black-800">
                {tx && !isLoading ? tx?.BillingAddress?.StreetAddress : <Skeleton width={`7rem`} />}
              </Typography>
              <Typography variant="xsmall" className=" pb-1 text-black-800">
                {tx && !isLoading ? (
                  `${tx?.BillingAddress?.City} ${tx?.BillingAddress?.State} ${tx?.BillingAddress?.PostCode}`
                ) : (
                  <Skeleton width={`8rem`} />
                )}
              </Typography>
            </div>
          </div>
        </div>

        <div className="mb-[40px] flex h-auto w-full flex-col">
          {isLoading ? (
            <>
              {renderTableSkelton}
              <div className="flex flex-col justify-between self-end">
                {[1, 2, 3].map(i => (
                  <Skeleton
                    key={i}
                    className="mb-1 flex min-w-[180px] flex-row justify-between self-end p-[6px]"
                    width={'20%'}
                  />
                ))}
              </div>
            </>
          ) : (
            tx && (
              <>
                <ARTable
                  tableClass="!mx-0"
                  rows={tx?.Lines || []}
                  columns={lineItemColumns}
                  isLoading={isLoading}
                  dataAutoId="LineItem"
                  getRowDataAutoId={rowData => `rowLineItem${rowData?.code}`}
                />
                <div className="mb-1 flex min-w-[180px] flex-row justify-between self-end bg-[#E1E1E1] p-[6px]">
                  <Typography variant="xsmall" className=" font-bold text-black-800">
                    Subtotal
                  </Typography>
                  <Typography variant="xsmall" className=" font-bold text-black-800">
                    {PriceFormat(tx?.TotalEx)}
                  </Typography>
                </div>
                <div className="mb-1 flex min-w-[180px] flex-row justify-between self-end bg-[#E1E1E1] p-[6px] ">
                  <Typography variant="xsmall" className=" font-bold text-black-800">
                    GST
                  </Typography>
                  <Typography variant="xsmall" className=" font-bold text-black-800">
                    {PriceFormat(tx?.TotalTax)}
                  </Typography>
                </div>

                <div className="flex items-center justify-between border-t-[1px] border-solid pt-1">
                  <Typography variant="paragraph" className=" text-black-800">
                    *Backorder items have not been included in this invoice total.
                  </Typography>
                  <div className="flex min-w-[180px] flex-row justify-between self-end bg-[#E1E1E1] p-[6px]">
                    <Typography variant="xsmall" className=" font-bold text-black-800">
                      Total
                    </Typography>
                    <Typography variant="xsmall" className=" font-bold text-black-800">
                      {PriceFormat(tx?.TotalInc)}
                    </Typography>
                  </div>
                </div>
              </>
            )
          )}
        </div>

        <div className="mb-[20px] flex h-auto w-full flex-col">
          {tx && !isLoading ? (
            <>
              <ARTable
                tableClass="!mx-0"
                rows={isInBuyerContext ? tx?.RefBusTrans || [] : tx?.LinkedTrans || []}
                columns={invoiceAttachedColumns}
                isLoading={isLoading}
                dataAutoId="InvoiceAttached"
                getRowDataAutoId={rowData => `rowInvoiceAttached${rowData?.RefNumber}`}
              />
              {!tx?.RefBusTrans?.length && !tx?.LinkedTrans?.length && (
                <Typography variant="xsmall" className="m-2 justify-center pb-2 font-bold">
                  THERE ARE NO INVOICES ATTACHED TO THIS CREDIT NOTE
                </Typography>
              )}
            </>
          ) : (
            renderTableSkelton
          )}
        </div>

        {isLoading ? (
          <div className="flex justify-end">
            <Skeleton className="flex min-w-[180px] flex-row justify-between self-end p-[6px]" width={'20%'} />
          </div>
        ) : (
          <div className="flex min-w-[180px] flex-row justify-between self-end bg-[#E1E1E1] p-[6px]">
            <Typography variant="xsmall" className=" font-bold text-black-800">
              Balance
            </Typography>
            <Typography variant="xsmall" className=" font-bold text-black-800">
              {PriceFormat(tx?.Balance)}
            </Typography>
          </div>
        )}

        {/* <div className="flex flex-col justify-start">
          <p className="text-base font-medium font-poppins text-black-800">Notes:</p>
          <p className="text-xs font-normal font-poppins text-[#707070]">None</p>
        </div> */}
      </div>
    </>
  );
};
