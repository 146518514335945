import React, {FC, useState} from 'react';
import {useFormikContext} from 'formik';

import QuoteAttachment from '../../quote-management/QuoteAttachment';
import {IServiceJob, ServiceJobStatus} from '../../../model/service-management/serviceJob';
import useServiceJobAPI from '../../../services/useServiceJobAPI';
import {useAttachmentsAPI} from '../../../services/useAttachmentsAPI';
import {AttachmentTypeEnum} from '../../../model/constants/Constants';
import {useServiceContext} from '../../../context/serviceManagement/ServiceContext';

type ServiceAttachmentTabProps = {
  addedFilesToUpload: File[];
  setAddedFilesToUpload: React.Dispatch<React.SetStateAction<File[]>>;
  setIsSaving: React.Dispatch<React.SetStateAction<boolean>>;
};

const ServiceAttachmentTab: FC<ServiceAttachmentTabProps> = props => {
  const {addedFilesToUpload, setAddedFilesToUpload, setIsSaving} = props;
  const {values, setFieldValue} = useFormikContext<IServiceJob>();
  const {fetchServiceAttachments} = useServiceJobAPI();
  const {deleteAttachment} = useAttachmentsAPI();
  const {serviceJob} = useServiceContext();
  const [invalidFiles, setInvalidFiles] = useState<File[]>([]);


  const fetchServiceJobAttachments = async () => {
    const attachments = await fetchServiceAttachments(values?.serviceJobID!);
    setFieldValue('attachments', attachments);
    setIsSaving(false);
  };

  const deleteServiceAttachment = async (guid: string) => {
    setIsSaving(true);
    await deleteAttachment(AttachmentTypeEnum.ServiceJob, guid);
    fetchServiceJobAttachments();
  };

  const deleteFile = (index: number, isInvalid: boolean) => {
    if (isInvalid) {
      let invalidData = [...invalidFiles];
      if (index >= 0) {
        invalidData.splice(index, 1);
        setInvalidFiles(invalidData);
      }
    } else {
      let data = [...addedFilesToUpload];
      if (index >= 0) {
        data.splice(index, 1);
        setAddedFilesToUpload(data);
      }
    }
  };

  return (
    <QuoteAttachment
      addedFilesToUpload={addedFilesToUpload}
      invalidFiles={invalidFiles}
      setAddedFilesToUpload={setAddedFilesToUpload}
      setInvalidFiles={setInvalidFiles}
      deleteFile={deleteFile}
      uploadedAttachments={values?.attachments}
      deleteQuoteAttachment={deleteServiceAttachment}
      disabled={serviceJob?.status === ServiceJobStatus.Completed}
      isServiceJobView
    />
  );
};

export default ServiceAttachmentTab;
