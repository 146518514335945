import {IFlagTypes, ILDFeature} from './../model/feature-flags/FeatureFlags';
import {useContext} from 'react';
import AppContext from '../context/app/appContext';
import {LDFlagSet} from '../model/feature-flags/FeatureFlags';

export enum FeatureFlagKeys {
  purchaseInvoicingV1 = 'purchaseInvoicingV1',
  paymentAuth72488 = 'paymentAuth72488',
  purchaseRequisitionsV1 = 'purchaseRequisitionsV1',
  prepayments72511 = 'prepayments72511',
  claimAndReturnsV272602 = 'claimAndReturnsV272602',
  T2TPhase280729 = 'T2TPhase280729',
  onboardingUpgradeV1 = 'onboardingUpgradeV1',
  apQboIntegration = 'apQboIntegration',
  t2TV3 = 't2TV3',
  scheduledPayments83107 = 'scheduledPayments83107',
  statementsAndAutomatedStatement83193 = 'StatementsAndAutomatedStatement83193',
  scheduledPaymentsV2 = 'scheduledPaymentsV2',
  quoteManagementV1 = 'quoteManagementV1',
  serviceManagementV1 = 'serviceManagementV1',
  virtualCreditFacility88813 = 'virtualCreditFacility88813',
  paidInvoicesView89020 = 'paidInvoicesView89020',
  payfacStatementing83495 = 'payfacStatementing83495',
  payfacMerchantPortal88470 = 'payfacMerchantPortal88470',
  eCommerceAndTransactionsView89915 = 'eCommerceAndTransactionsView89915',
  payStatementByLinkV289938 = 'payStatementByLinkV289938',
  psblV2189963 = 'psblV2189963',
  QuoteV289367 = 'QuoteV289367',
  PSBLSTAGING = 'PSBLSTAGING',
}

const supplierDrivenKeysToCheckOnBothContext = [FeatureFlagKeys.claimAndReturnsV272602];

const camelize = (s: string) => s.replace(/-./g, x => x[1].toUpperCase());

export const useFeatureFlags = () => {
  const {featureFlags} = useContext(AppContext);

  const formatFeatureFlags = (flags: IFlagTypes | undefined) => {
    let fs: LDFlagSet = {};

    if (!flags) {
      return {};
    }

    Object.values(flags).forEach(value => {
      if (!Array.isArray(value)) return;

      value.forEach((featureFlag: ILDFeature) => {
        fs[camelize(featureFlag.key)] = Boolean(featureFlag.value);
      });
    });

    return fs;
  };

  const supplierDriven = () => {
    let fs: LDFlagSet = {};

    if (!Array.isArray(featureFlags?.linkedSupplierFeatureFlags)) return fs;

    featureFlags?.linkedSupplierFeatureFlags?.forEach(lsp => {
      const key = camelize(lsp.key);
      // Condition to check if the flag is enable in tenantOwned also
      if (supplierDrivenKeysToCheckOnBothContext?.includes(key as FeatureFlagKeys)) {
        fs[key] = !!lsp.value && isFlagEnabled(key as FeatureFlagKeys);
        return;
      }
      fs[key] = !!lsp.value;
    });
    return fs;
  };

  const tenantOwned = () => formatFeatureFlags(featureFlags?.featureFlags);

  const isFlagEnabled = (key: FeatureFlagKeys): boolean => {
    const flagStatus = formatFeatureFlags(featureFlags?.featureFlags);
    return flagStatus[key] || false;
  };

  const isFlagEnabledInSupplier = (key: FeatureFlagKeys): boolean => {
    return !!featureFlags?.linkedSupplierFeatureFlags?.find(f => camelize(f.key) === key)?.value;
  };

  return {
    formatFeatureFlags,
    supplierDriven,
    tenantOwned,
    isFlagEnabled,
    isFlagEnabledInSupplier,
  };
};
