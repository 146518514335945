import React from 'react';
import {Typography} from 'spenda-ui-react';
import {AlertDialogSlideV2, IDialogActionV2} from './AlertDialogSlideV2';

export const DeleteQuotePackageDialog = (props: {
  onCancel: () => void;
  disableQuoteButton?: boolean;
  deleteQuotePackage: () => void;
  deleteQuote: () => void;
  isSubmitting?: boolean;
  showQuoteButton?: boolean;
}) => {
  const actions: IDialogActionV2[] = [
    {
      label: 'Cancel',
      variant: 'outlined',
      action: () => {
        props.onCancel();
      },
      disabled: props.isSubmitting,
    },
  ];

  if (props.showQuoteButton) {
    actions.push({
      color: 'error',
      label: 'Delete this Quote',
      action: () => {
        props.deleteQuote();
      },
      disabled: props.isSubmitting || props.disableQuoteButton,
      loading: props.isSubmitting,
    });
  } else {
    actions.push({
      color: 'error',
      label: 'Yes, Delete Entire Package',
      action: () => {
        props.deleteQuotePackage();
      },
      disabled: props.isSubmitting,
      loading: props.isSubmitting,
      className: 'ml-[5.5rem]',
    });
  }

  return (
    <AlertDialogSlideV2
      title={'Delete entire quote'}
      headingTextSize="h2"
      data-autoid="dlgDeleteQuotePackage"
      headingClassess="justify-center"
      dialogActionsAlignment="justify-between"
      dialogClassess="!min-w-[20%] !max-w-[587px] !w-full"
      dialogBodyClassess="flex justify-center items-center"
      actions={actions}
    >
      <Typography
        variant="small"
        className="flex w-full max-w-[289px] items-center justify-center py-10 text-center font-normal text-black-800"
      >
        This is the last quote in this package. If you want to delete this quote it will delete the entire package.
      </Typography>
    </AlertDialogSlideV2>
  );
};
