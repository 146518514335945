import React, {useContext, useEffect, useState} from 'react';
import {Alert, Button, Dialog, DialogBody, DialogFooter, DialogHeader, Input, Option, Select} from 'spenda-ui-react';
import {QuoteDetailsValidDate} from '../../model/constants/Constants';
import {Form, Formik} from 'formik';
import useUsersAPI from '../../services/useUsersAPI';
import {IActionResultsList} from '../../model/ActionResults';
import {IUserAccountInfo, UserRoleEnum} from '../../model/user/UserAccountInfo';
import {IQuotePackageDetails} from '../../model/quotes/quotes';
import moment from 'moment';
import LoadingIndicator from '../ui/LoadingIndicator';
import _, {camelCase, upperFirst} from 'lodash';
import AppContext from '../../context/app/appContext';
import {useFeatureFlags} from '../../hooks/useFeatureFlags';

interface IQuoteDetailsDialog {
  open: boolean;
  isEdit?: boolean;
  handleGoBack: () => void;
  onChangeCustomer?: () => void;
  quotePackageDetails: IQuotePackageDetails;
  handleContinueClick: (expiryDate: string, assignedToUserID: number, days?: number) => void;
}

export const QuoteDetailsDialog = (props: IQuoteDetailsDialog) => {
  const {open, handleGoBack, handleContinueClick, quotePackageDetails, isEdit = false, onChangeCustomer} = props;
  const [selectedDays, setSelectedDays] = useState<QuoteDetailsValidDate | string>('');
  const dayKeys = Object.keys(QuoteDetailsValidDate) as (keyof typeof QuoteDetailsValidDate)[];
  const {get} = useUsersAPI();
  const [users, setUsers] = useState<IUserAccountInfo[]>([]);
  const [selectedUserId, setSelectedUserId] = useState<number>();
  const [selectedDate, setSelectedDate] = useState<string>();
  const [packageDetails, setPackageDetails] = useState<IQuotePackageDetails>(quotePackageDetails);
  const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [disableContinue, setDisableContinue] = useState<boolean>(false);
  const {user: loggedInUser} = useContext(AppContext);

  const {QuoteV289367} = useFeatureFlags().tenantOwned();

  useEffect(() => {
    if (!_.isEqual(packageDetails, quotePackageDetails) || !selectedDays) {
      quotePackageDetails.assignQuoteToUserID && setSelectedUserId(quotePackageDetails.assignQuoteToUserID);
      if (quotePackageDetails.expireInDays) {
        let days =
          7 === quotePackageDetails.expireInDays
            ? QuoteDetailsValidDate.DAY_7
            : 14 === quotePackageDetails.expireInDays
              ? QuoteDetailsValidDate.DAY_14
              : 30 === quotePackageDetails.expireInDays
                ? QuoteDetailsValidDate.DAY_30
                : '';
        setSelectedDays(days);
      } else if (quotePackageDetails.validFor) {
        if (moment(quotePackageDetails.validFor).isBefore(moment(new Date()), 'day')) {
          setShowErrorAlert(true);
        }
        setSelectedDays(QuoteDetailsValidDate.CUSTOMISE_DAYS);
        setSelectedDate(moment(quotePackageDetails.validFor).format('YYYY-MM-DD'));
      }
    }
  }, [packageDetails, quotePackageDetails]);

  useEffect(() => {
    setIsLoading(true);
    fetchUser();
  }, []);

  useEffect(() => {
    if (showErrorAlert) {
      setTimeout(() => {
        setShowErrorAlert(false);
      }, 2000);
    }
  }, [showErrorAlert]);

  const fetchUser = () => {
    get({}).then((data: IActionResultsList<IUserAccountInfo>) => {
      if (data.IsSuccess) {
        const filterTech = data?.Items.filter(
          user => !user.UserRoles.some(role => role.UserRoleEnum === UserRoleEnum.Technician),
        );
        setUsers(filterTech);
        setSelectedUserId(packageDetails.assignQuoteToUserID || loggedInUser?.UserID);
        setPackageDetails({
          ...packageDetails,
          assignQuoteToUserID: packageDetails.assignQuoteToUserID || loggedInUser?.UserID!,
        });
      }
      setIsLoading(false);
    });
  };
  const handleSelectChange = (value: string) => {
    setSelectedDays(value);
  };

  const onSubmit = (values: IQuotePackageDetails) => {
    if (!selectedDays.length || (selectedDays === QuoteDetailsValidDate.CUSTOMISE_DAYS && !selectedDate)) {
      setShowErrorAlert(true);
      setDisableContinue(false);
      return;
    }
    if (
      selectedDays === QuoteDetailsValidDate.CUSTOMISE_DAYS &&
      selectedDate &&
      moment(selectedDate).isBefore(moment(new Date()), 'day')
    ) {
      setShowErrorAlert(true);
      setDisableContinue(false);
      return;
    }
    // get expiry date from days selected
    let expiryDate;
    let days;
    if (selectedDays === QuoteDetailsValidDate.CUSTOMISE_DAYS) {
      expiryDate = selectedDate!;
    } else {
      days =
        selectedDays === QuoteDetailsValidDate.DAY_7
          ? 7
          : selectedDays === QuoteDetailsValidDate.DAY_14
            ? 14
            : selectedDays === QuoteDetailsValidDate.DAY_30
              ? 30
              : 0;
    }
    handleContinueClick?.(expiryDate!, values.assignQuoteToUserID, days);
  };

  return (
    <>
      <Dialog
        open={open}
        handler={() => {}}
        className={`!w-[500px] !min-w-[538px]`}
        data-autoid={isEdit ? 'dlgChangeQuotePackage' : 'dlgQuotePackageDetails'}
      >
        <DialogHeader className="justify-center border-b border-[#ccc] text-center text-xl font-light text-black-900">
          {isEdit ? 'Change quote package details' : 'Quote package details'}
        </DialogHeader>
        {isLoading || !users.length || !selectedUserId ? (
          <DialogBody>
            <div className="py-12">
              <LoadingIndicator isLoading={true} size="md" color="#1C78AD" />
            </div>
          </DialogBody>
        ) : (
          <Formik initialValues={packageDetails} onSubmit={onSubmit} enableReinitialize>
            {({setFieldValue, handleSubmit}) => (
              <Form>
                <DialogBody>
                  <div className="mr-2 py-12">
                    <div className="mb-3 flex flex-col items-center justify-center">
                      <div className="flex justify-between">
                        {selectedDays !== 'Customise days' ? (
                          <Select
                            size="lg"
                            variant="outlined"
                            containerProps={{className: 'min-w-[162px] mr-1'}}
                            label="Valid for"
                            onChange={handleSelectChange}
                            value={selectedDays as string}
                          >
                            {dayKeys.map(day => (
                              <Option
                                key={day}
                                data-autoid={`option${upperFirst(camelCase(day))}`}
                                value={QuoteDetailsValidDate[day]}
                              >
                                {QuoteDetailsValidDate[day]}
                              </Option>
                            ))}
                          </Select>
                        ) : (
                          <Input
                            type="date"
                            label="Valid for"
                            name="ValidFor"
                            onChange={e => setSelectedDate(e.target.value)}
                            containerProps={{className: 'min-w-[162px] mr-1'}}
                            value={selectedDate}
                          />
                        )}
                        <div>
                          <Select
                            size="lg"
                            variant="outlined"
                            label="Assign quote"
                            containerProps={{className: 'ml-2 min-w-[218px]'}}
                            value={selectedUserId?.toString()}
                            onChange={(value: string) => {
                              const user = users.find(user => user.UserID === parseInt(value));
                              setFieldValue('assignQuoteToUserID', user?.UserID!);
                              setSelectedUserId(user?.UserID);
                            }}
                          >
                            {users.map((user, index) => {
                              return (
                                <Option
                                  key={index}
                                  data-autoid={`option-${user.UserID}`}
                                  value={user?.UserID?.toString()}
                                >
                                  {user.DisplayName}
                                </Option>
                              );
                            })}
                          </Select>
                        </div>
                      </div>
                    </div>
                  </div>
                </DialogBody>
                <DialogFooter className="p-2.5">
                  <div className="flex w-full items-center justify-between rounded-lg bg-[#f1f1f1] p-2.5">
                    <div>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          setSelectedDays('');
                          setSelectedUserId(loggedInUser?.UserID);
                          handleGoBack();
                        }}
                        className="bg-white"
                        data-autoid="btnCancelQuote"
                      >
                        {isEdit ? 'Cancel' : 'Go Back'}
                      </Button>
                    </div>
                    <div className="flex gap-3">
                      {isEdit && !QuoteV289367 && <Button onClick={onChangeCustomer}>Change Customer</Button>}
                      <Button
                        variant="filled"
                        color="primary"
                        type="submit"
                        data-autoid="btnConfirmQuote"
                        disabled={disableContinue}
                        loading={disableContinue}
                        onClick={() => {
                          setDisableContinue(true);
                          handleSubmit();
                        }}
                      >
                        {isEdit ? 'Save & Close' : 'Continue'}
                      </Button>
                    </div>
                  </div>
                </DialogFooter>
              </Form>
            )}
          </Formik>
        )}
      </Dialog>

      {showErrorAlert && (
        <Alert
          open={showErrorAlert}
          onClose={() => setShowErrorAlert(false)}
          color="error"
          animate={{
            mount: {y: 0},
            unmount: {y: 50},
          }}
          className="absolute left-0 right-0 top-7 z-[99999] mx-auto w-full max-w-[350px] font-poppins"
        >
          Please select a valid expiry date. Expiry dates cannot be in the past.
        </Alert>
      )}
    </>
  );
};
