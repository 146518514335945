import React, {useMemo, useRef} from 'react';
import {useState} from 'react';
import {SButton} from '../../../components/buttons/Button';
import {useContext} from 'react';
import {ICreditCardT, ITenantSupplierPaymentMethod} from '../../../model/payment/PaymentMethod';
import {useEffect} from 'react';
import {
  BankAccounts,
  InvoicePaymentAllocationStatus,
  PaymentMethodTypes,
  PaymentWidgetScope,
  VerificationStatus,
  PaymentStatusType,
  PaymentServiceType,
  AuthorisationStatus,
  DatTypes,
  UserRoleTypes,
  AttentionRequiredReason,
  TradingTermName,
  PaymentMethodErrors,
} from '../../../model/constants/Constants';
import {MerchantSurcharge, IMerchantProcessingFee} from '../../../model/payment/PaymentT';
import {Toast} from '../../../utils/Toast';
import {PaymentWidgetContext, PaymentWidgetPaymentSteps, PaymentWidgetStep} from './PaymentWidget';
import {
  ErrorAnimation,
  ProcessingAnimation,
  SuccessAnimation,
  TimeoutAnimation,
} from '../../../assets/svg/animations/LottieAnimations';
import {PropsWithChildren} from 'react';
import {css} from 'glamor';
import {Carousel, CarouselItem} from '../Carousel';
import {IconEdit} from '../../../assets/svg/IconEdit';
import ErrorIcon from '@material-ui/icons/Error';
import {IconBankWithoutOutline} from '../../../assets/svg/payment-methods/IconBankWithoutOutline';
import {PriceFormat, getTradingTermDays, toFixedWithoutRounding} from '../../../utils/formatter';
import {IconCreditCard} from '../../../assets/svg/payment-methods/IconCreditCard';
import LoadingIndicator from '../../../components/ui/LoadingIndicator';
import {SpendaPayLater} from '../../../assets/svg/payment-methods/SpendaPayLater';
import {Visa} from '../../../assets/svg/payment-methods/Visa';
import {MasterCardNew} from '../../../assets/svg/payment-methods/MasterCardNew';
import useConnectedSupplier from '../../../hooks/useConnectedSupplier';
import {withStyles, Tooltip} from '@material-ui/core';
import {IconPaymentSubmit} from '../../../assets/svg/IconPaymentSubmit';
import {IconClock} from '../../../assets/svg/IconClock';
import {IconNAB} from '../../../assets/svg/bank-accounts/IconNAB';
import {IconWestpac} from '../../../assets/svg/bank-accounts/IconWestpac';
import {IconCommonWealth} from '../../../assets/svg/bank-accounts/IconCommonWealth';
import {IconANZ} from '../../../assets/svg/bank-accounts/IconANZ';
import {IActionResults} from '../../../model/ActionResults';
import {ISupplierPaymentResponse} from '../../../model/payment/SupplierPaymentResponse';
import {BankWhite} from '../../../assets/svg/payment-methods/BankWhite';
import {useCreditNotes} from '../../../hooks/useCreditNotesHooks';
import {usePaymentUtilities} from '../../../hooks/usePaymentUtilities';
import {IConnectedSupplierStatementSummary} from '../../../model/supplier/SupplierTransaction';
import FailedPayment from '../../../assets/svg/FailedPayment.svg';
import {IOSSwitch} from '../../../components/form/SwitchSlider';
import {Amex} from '../../../assets/svg/payment-methods/AMEX';
import SpendaFinanceBackground from '../../../assets/svg/SpendaFinanceBackground.svg';
import SpendaIcon from '../../../assets/svg/SpendaIcon.svg';
import './scss/PaymentWidgetStepHome.css';
import {PaymentAuthorisationResponse, Authorisers} from '../../../model/payment/PaymentAuthority';
import {PrimaryButton, SecondaryButton} from '../../../components/buttons/DefaultButtons';
import {EnterSecurityCode} from './pw-components/EnterSecurityCode';
import {ZohoContext} from '../../../context/app/ZohoContext';
import {WidgetTooltip} from '../../../components/data-display/WidgetTooltip';
import capricorn from '../../../assets/png/capricorn.png';
import {InfoRound} from '../../../assets/svg/InfoRound';
import IconPaymentScheduled from '../../../assets/svg/IconPaymentScheduled';
import IconAddPaymentOption from '../../../assets/svg/IconAddPaymentOption';
import moment from 'moment';
import AirPlusLogo from '../../../assets/svg/AirPlus_Logo.svg';
import AirPlusIcon from '../../../assets/svg/AirPlus_Icon.svg';
import clsx from 'clsx';
import ARTooltip from '../../../components/data-display/ARTootip';
import AirPlusThumbnailIcon from '../../../assets/svg/AirPlusThumbnail.svg';
import {AirPlusPortalLoginButton} from './pw-components/Buttons';
import {Button, Spinner} from 'spenda-ui-react';
import {PaymentWidgetStepCreditNotes} from './PaymentWidgetStepCreditNotes';

export const borderStyle = css({
  borderWidth: '1px',
  borderColor: '#2C95BC',
});
const dividerStyle = css({
  height: '1px',
  background: '#f1f1f1',
  width: '20rem',
});
const buttonStyle = css({
  borderWidth: '1px',
  // borderColor: '#1C78AD',
  width: '12.625rem',
  height: '2.5rem',
  lineHeight: '0.875rem',
  ':disabled': {
    opacity: '50%',
    cursor: 'default',
  },
});

const buttonContainerStyle = css({
  minHeight: '140px',
});

const BNPLCardCss = css({
  color: '#333333',
  position: 'absolute',
  top: '43px',
  left: '30px',
});

const PayLaterCss = css({
  position: 'absolute',
  left: '10px',
});
const CapricornPayLaterCss = css({
  position: 'absolute',
  left: '5px',
  top: '7px',
});

export const cardIconbgCss = css({
  width: '71.78px',
  height: '42px',
  backgroundColor: '#ffffff15',
  borderRadius: ' 8px',
  display: 'flex',
  justifyContent: 'center !important',
  alignItems: 'center !important',
});

export const PaymentWidgetStepHome = () => {
  const widgetContext = useContext(PaymentWidgetContext);
  const {
    totalPayableAmount: invoiceAmount,
    isRefreshPaymentMethods,
    merchantSurcharge,
    step,
    storedPaymentOptions,
    selectedPaymentMethod,
    temporalCreditCards,
    userEmailAddress,
    widgetScope,
    isPersistSelectedPaymentMethod,
    marketplaceSupplier,
    scheduledPaymentDate,
    getSurcharge,
    loadPaymentOptions,
    onCancel,
    onLogout,
    onPaymentApproved,
    onPaymentFailed,
    setCardToEdit,
    setIsRefreshPaymentMethods,
    setMerchantSurcharge,
    setSelectedPaymentMethod,
    setStep,
    setPwStepsData,
    pwStepData,
    setStoredPaymentOptions,
    submitPayment: submitPaymentAPI,
    setBpspAccountDetails,
    fetchSPSAccount,
    creditStatementSummary,
    checkPaymentStatus,
    checkRepaymentStatus,
    onDone,
    setIsPersistSelectedPaymentMethod,
    paymentAuth72488,
    authorisePayment,
    getPaymentMethodAuthorisationDetails,
    userID,
    approvePaymentAuthorisation,
    resetAuthenticationCode,
    updatePaymentBatch,
    nonInvoicePaymentAmount,
    tenantUserDetails,
    capricornDemo77857,
    selectedBatchDetails,
    retryPSBLBatch,
    creditAndClaimsList,
    scheduledPayments83107,
    airplus79131,
    isLoyaltyPointsToggleOn,
    airPlusDbiData,
    fees88078,
  } = widgetContext;

  const {
    totalInvoiceAmount,
    totalCreditClaimsPrepaymentsSelectedAmount,
    loading: isPsblBatchLoading,
    selectedTxList,
  } = selectedBatchDetails ?? {};

  const totalPayableAmount = [PaymentWidgetScope.PREPAYMENT, PaymentWidgetScope.SPENDA_FINANCE].includes(
    widgetScope as PaymentWidgetScope,
  )
    ? nonInvoicePaymentAmount
    : invoiceAmount;

  const {transformPaymentAuthorisationUserInfo, arBatchPartialPaymentInProgress, getAuthenticationCodeErrorMsg} =
    usePaymentUtilities();

  const [isShowApplyCreditNoteDialog, setIsShowApplyCreditNoteDialog] = useState(false);
  const [paymentStatusMessage, setPaymentStatusMessage] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPaymentFeeLoading, setIsPaymentFeeLoadind] = useState(false);
  const [showPrepaymentsReminder, setShowPrepaymentsReminder] = useState<boolean>(true);
  const [batchPaymentresponse, setBatchPaymentResponse] = useState<
    IActionResults<ISupplierPaymentResponse> | undefined
  >();

  const zohoChatContext = useContext(ZohoContext)!;
  const {showChatWindow} = zohoChatContext;

  /*******payment method authority states********/
  const [paymentAuthorisationDetails, setPaymentAuthorisationDetails] = useState<
    PaymentAuthorisationResponse | undefined
  >();
  const [authorisersList, setAuthorisersList] = useState<Authorisers[] | undefined>([]);
  const [securityCode, setSecurityCode] = useState('');
  const [securityCodeErrorMsg, setSecuityCodeErrorMsg] = useState('');
  const [isSubmittingPayment, setIsSubmittingPayment] = useState(false);

  const zeroNewCode = paymentAuthorisationDetails?.authorisation.newCodesRemainingCount === 0;
  const zeroCodeAttempts = paymentAuthorisationDetails?.authorisation.attemptsRemainingCount === 0;
  const isAtemptsExhausted = zeroNewCode && zeroCodeAttempts;

  const handleChangeSecurityCode = (code: any) => {
    if (securityCodeErrorMsg) setSecuityCodeErrorMsg('');
    return setSecurityCode(code);
  };
  /*******payment method authority states********/

  const paymentStausRefCount = React.useRef(0);
  const isFinalAuthoriser =
    paymentAuth72488 && paymentAuthorisationDetails?.authorisation.status === AuthorisationStatus.Complete;
  const paymentStatusReattempts = paymentAuth72488
    ? isFinalAuthoriser // in case of final authorisation we can wait for 30 seconds
      ? 4
      : 0
    : 4;

  const isPrepaymentSelectedEqualsAvailable = useMemo(() => {
    const availablePayments = selectedTxList?.filter(a => a.transactionTypeID === DatTypes.Payment).length;
    const selectedPayments = creditAndClaimsList?.filter?.(a => a.DatTypeID === DatTypes.Payment).length;
    return availablePayments === selectedPayments;
  }, [selectedTxList?.length, creditAndClaimsList?.length]);

  const allBatchInvoices = selectedTxList?.filter(a => a.transactionTypeID === DatTypes.Invoice);
  const cppBatch = allBatchInvoices?.length && arBatchPartialPaymentInProgress(allBatchInvoices);
  const TotalAmount = fees88078 ? selectedPaymentMethod?.TotalAmount : merchantSurcharge?.TotalAmount;

  const handleShowPrepaymentPrompt = () => {
    return showPrepaymentsReminder && !isPaymentFeeLoading && !isLoading && !isPrepaymentSelectedEqualsAvailable;
  };

  useEffect(() => {
    if (!batchPaymentresponse || !setStep) return;
    switch (batchPaymentresponse.Value.WorkflowStatus) {
      case InvoicePaymentAllocationStatus.Complete:
      case InvoicePaymentAllocationStatus.Paid:
      case InvoicePaymentAllocationStatus.Cleared:
        if (onPaymentApproved) {
          onPaymentApproved();
        }
        setPaymentStatusMessage('');
        if (
          paymentAuth72488 &&
          (paymentAuthorisationDetails?.authorisation.status === AuthorisationStatus.Approved ||
            paymentAuthorisationDetails?.authorisation.status === AuthorisationStatus.NotRequired)
        ) {
          setStep(PaymentWidgetPaymentSteps.SUBMITTED_TO_AUTHORISER);
        } else {
          setStep(PaymentWidgetPaymentSteps.APPROVED);
        }
        return;
      case InvoicePaymentAllocationStatus.Failed:
      case InvoicePaymentAllocationStatus.RolledBack:
        if (onPaymentFailed) {
          onPaymentFailed();
        }
        handlePaymentResponse(batchPaymentresponse);
        return;
      case InvoicePaymentAllocationStatus.Submitted:
        if (onPaymentApproved) {
          onPaymentApproved();
        }
        setPaymentStatusMessage('');
        setStep(PaymentWidgetPaymentSteps.SUBMITTED);
        return;
    }

    const interval = setInterval(() => {
      if (checkPaymentStatus) {
        paymentStausRefCount.current++;

        if (paymentStausRefCount.current <= paymentStatusReattempts) {
          if (widgetScope === PaymentWidgetScope.SPENDA_FINANCE) {
            checkRepaymentStatus &&
              checkRepaymentStatus(batchPaymentresponse.Value.paymentGUID!).then(res => {
                if (!res) return;
                setBatchPaymentResponse(res);
              });
          } else {
            checkPaymentStatus(batchPaymentresponse.Value.PaymentAllocationID!).then(res => {
              if (!res) return;
              setBatchPaymentResponse(res);
            });
          }
        } else {
          // Pooled during ~30secs, display a different view as payment could  be processing
          if (onPaymentApproved) {
            onPaymentApproved();
          }
          clearInterval(interval);
          setPaymentStatusMessage('');
          // if block only for payment method authorisation
          if (
            paymentAuth72488 &&
            (paymentAuthorisationDetails?.authorisation.status === AuthorisationStatus.Approved ||
              paymentAuthorisationDetails?.authorisation.status === AuthorisationStatus.NotRequired)
          ) {
            setStep(PaymentWidgetPaymentSteps.SUBMITTED_TO_AUTHORISER);
          } else {
            setStep(PaymentWidgetPaymentSteps.SUBMITTED);
          }
        }
      }
    }, 6000);

    return () => {
      clearInterval(interval);
    };
  }, [batchPaymentresponse]);

  useEffect(() => {
    if (
      step === PaymentWidgetPaymentSteps.NEW &&
      selectedPaymentMethod?.PaymentMethod === PaymentMethodTypes.Airplus &&
      pwStepData?.lastStep === PaymentWidgetPaymentSteps.AIRPLUS_DBI &&
      airPlusDbiData !== undefined &&
      airplus79131
    ) {
      setPwStepsData?.(undefined);
      makePayment();
    }
  }, [step]);

  useEffect(() => {
    if (!isRefreshPaymentMethods && widgetScope !== PaymentWidgetScope.PREPAYMENT) {
      return;
    }

    if (loadPaymentOptions) {
      setIsLoading(true);
      loadPaymentOptions()
        .then(res => {
          if (!res) return;
          if (setStoredPaymentOptions) {
            //remove bnpl cards for prepayments
            let cards = res;
            if (widgetScope === PaymentWidgetScope.PREPAYMENT) {
              cards = res.filter(
                a =>
                  a.PaymentMethod !== PaymentMethodTypes.Invigo &&
                  a.PaymentMethod !== PaymentMethodTypes.LendingFacility,
              );
            }

            let defaultCard;
            let sortedPermanentCards;
            const defaultPaymentMethodGuid = selectedBatchDetails?.paymentAccountGUID;
            cards.filter(a => a.PaymentAccountVerificationStatus !== VerificationStatus.VERIFIED);
            if (widgetScope === PaymentWidgetScope.PSBL) {
              defaultCard = cards.filter(a => a.PaymentAccountGUID === defaultPaymentMethodGuid);
              sortedPermanentCards = [
                ...defaultCard,
                ...cards.filter(a => a.PaymentAccountGUID !== defaultPaymentMethodGuid),
              ];
            } else {
              defaultCard = cards.filter(c => c.IsDefault);
              sortedPermanentCards = [...defaultCard, ...cards.filter(c => !c.IsDefault)];
            }

            const temporalPaymentOptions: ITenantSupplierPaymentMethod[] = (temporalCreditCards || [])?.map(tc => {
              return {
                CardHolderName: tc.CardHolderName!,
                Expiry: tc.ExpiryMMYY!,
                FriendlyName: tc.FriendlyName!,
                Last4: tc.CardNumber?.substring(tc.CardNumber.length - 4, tc.CardNumber.length)!,
                PaymentMethod: tc.CardType!,
                SupplierID: -1,
                CardNumber: tc.CardNumber,
                PaymentAccountGUID: tc.PaymentAccountGUID,
              };
            });

            // Temporal + Stored Cards
            const allCards = [...(temporalPaymentOptions || []), ...sortedPermanentCards];

            let indexBNPL: number | undefined = allCards?.findIndex(x => x.PaymentMethod == PaymentMethodTypes.Invigo);
            if (indexBNPL > -1 && setSelectedPaymentMethod && allCards) {
              setSelectedPaymentMethod(allCards?.[indexBNPL]);
            } else if (allCards.length && setSelectedPaymentMethod && !isPersistSelectedPaymentMethod) {
              setSelectedPaymentMethod(allCards[0]);
            } else if (fees88078 && allCards?.length && setSelectedPaymentMethod && isPersistSelectedPaymentMethod) {
              const alreadySelectedPaymentMethod = allCards?.find(
                c => c?.PaymentAccountGUID === selectedPaymentMethod?.PaymentAccountGUID,
              );
              alreadySelectedPaymentMethod
                ? setSelectedPaymentMethod(alreadySelectedPaymentMethod)
                : setSelectedPaymentMethod(allCards[0]);
            }

            setStoredPaymentOptions(allCards);
          }

          if (setIsRefreshPaymentMethods) setIsRefreshPaymentMethods(false);
          if (setIsPersistSelectedPaymentMethod) setIsPersistSelectedPaymentMethod(false);

          if (setCardToEdit) setCardToEdit(undefined);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [isRefreshPaymentMethods]);

  useEffect(() => {
    if (selectedPaymentMethod) onGetSurcharge();
  }, [selectedPaymentMethod]);

  useEffect(() => {
    if (!isPsblBatchLoading) onGetSurcharge();
  }, [selectedTxList?.length, isPsblBatchLoading]);

  useEffect(() => {
    fees88078 && setIsLoading(true);
  }, [fees88078, selectedTxList?.length]);

  useEffect(() => {
    if (fees88078) {
      setIsPersistSelectedPaymentMethod?.(true);
      setIsRefreshPaymentMethods?.(true);
    }
  }, [fees88078, selectedBatchDetails?.versionDateTimeUtc]);

  //handle auto pay redirection
  useEffect(() => {
    if (marketplaceSupplier?.CreditLimit) {
      if (storedPaymentOptions?.length && marketplaceSupplier?.IsLendingProvider && selectedPaymentMethod) {
        const isAutoPayEnabled = Boolean(storedPaymentOptions.find(pm => pm.IsInvigoDefault));

        // check if selected payment method is verified or not,
        // if not make a verified Payment Method as selected payment method
        if (!(selectedPaymentMethod.PaymentAccountVerificationStatus === VerificationStatus.VERIFIED)) {
          const verifiedPm = storedPaymentOptions.find(
            pm => pm.PaymentAccountVerificationStatus === VerificationStatus.VERIFIED,
          );

          if (verifiedPm) {
            setSelectedPaymentMethod && setSelectedPaymentMethod(verifiedPm);
          } else {
            return;
          }
        }

        if (!isAutoPayEnabled) {
          setStep && setStep(PaymentWidgetPaymentSteps.INVIGO_CREDIT_INFO);
        }
      }
    } else {
      // Code for Spenda Finance lending account (marketplaceSupplier?.CreditLimit = 0  for Spenda Finance)
    }
  }, [storedPaymentOptions, marketplaceSupplier?.IsLendingProvider, marketplaceSupplier?.CreditLimit]);

  const isCreditNotesAppliedGreaterThanInvoiceTotal = () => {
    if (widgetScope === PaymentWidgetScope.PSBL) {
      return (totalCreditClaimsPrepaymentsSelectedAmount ?? 0) >= (totalInvoiceAmount ?? 0);
    }
    if (!creditStatementSummary) {
      return false;
    }

    if ((creditStatementSummary.SubTotal || 0) > 0) {
      return false;
    }

    return true;
  };

  const onGetSurcharge = async () => {
    if (fees88078) return;
    if (!getSurcharge) return undefined;

    if (selectedPaymentMethod) {
      setIsPaymentFeeLoadind(true);
      const res = await getSurcharge({
        ccType: selectedPaymentMethod.PaymentMethod as PaymentMethodTypes,
        paymentAccountGUID: selectedPaymentMethod.PaymentAccountGUID,
        creditStatementSummary,
        isCreditNotesAppliedGreaterThanInvoiceTotal: isCreditNotesAppliedGreaterThanInvoiceTotal(),
        isPayerFeeApplicable: selectedPaymentMethod.IsPayerFeeApplicable,
        nonInvoicePaymentAmount,
        selectedPaymentMethod,
      });
      setIsPaymentFeeLoadind(false);
      if (setMerchantSurcharge) {
        setMerchantSurcharge(res);
      }
    } else {
      const res2: MerchantSurcharge = {
        AccountPaymentMethodID: 0,
        AccountPaymentMethodName: '',
        SurchargePercent: 0,
        SurchargeAmount: 0,
        PaymentAmount: totalPayableAmount || 0,
        TotalAmount: totalPayableAmount || 0,
        BusTrans: [],
      };

      if (setMerchantSurcharge) {
        setMerchantSurcharge(res2);
      }
    }
  };

  const handleResetSecurityCode = async () => {
    if (paymentAuthorisationDetails?.authorisation.authorisationID && resetAuthenticationCode) {
      const resetAuthenticationCodeResponse = await resetAuthenticationCode(
        paymentAuthorisationDetails.authorisation.authorisationID,
      );
      Toast.info(`Successfully sent a new security code.`);
      setPaymentAuthorisationDetails(resetAuthenticationCodeResponse);
      handleChangeSecurityCode('');
    }
  };

  const submitSecurityCode = async () => {
    const payload = {
      approvalCode: securityCode,
    };
    if (isAtemptsExhausted) {
      showChatWindow();
      return;
    }
    if (approvePaymentAuthorisation && paymentAuthorisationDetails?.authorisation) {
      const approvePaymentAuthorisationResponse = await approvePaymentAuthorisation(
        payload,
        paymentAuthorisationDetails.authorisation.authorisationID,
      );

      switch (approvePaymentAuthorisationResponse?.authorisation?.status) {
        case AuthorisationStatus.Complete:
          setPaymentAuthorisationDetails(approvePaymentAuthorisationResponse);
          submitPayment(approvePaymentAuthorisationResponse?.authorisation?.token);
          break;
        case AuthorisationStatus.Approved:
          const users = approvePaymentAuthorisationResponse?.authorisation?.authorisers?.filter(
            (a: Authorisers) => a.status === AuthorisationStatus.InProgress,
          );
          if (users?.length && userID) {
            const authorisersList = transformPaymentAuthorisationUserInfo(users, userID);
            setAuthorisersList(authorisersList);
          }
          setPaymentAuthorisationDetails(approvePaymentAuthorisationResponse);
          submitPayment(approvePaymentAuthorisationResponse?.authorisation?.token);
          break;
        case AuthorisationStatus.AwaitingCode:
        case AuthorisationStatus.Failed:
          setPaymentAuthorisationDetails(approvePaymentAuthorisationResponse);
          const errors = approvePaymentAuthorisationResponse.error.errors;
          if (errors.length) {
            const erroMsg = getAuthenticationCodeErrorMsg(approvePaymentAuthorisationResponse);
            setSecuityCodeErrorMsg(erroMsg);
          }
          break;
        case AuthorisationStatus.Failed:
          setPaymentAuthorisationDetails(approvePaymentAuthorisationResponse);
          break;
        default:
          break;
      }
    }
  };

  const handleFillDbiData = async () => {
    if (selectedPaymentMethod?.PaymentMethod === PaymentMethodTypes.Airplus && airplus79131 && setStep) {
      setStep(PaymentWidgetPaymentSteps.AIRPLUS_DBI);
    } else {
      return await makePayment();
    }
  };

  const submitPayment = async (authorisationToken?: string) => {
    if (!setStep) return;
    if (!submitPaymentAPI) return;
    if (!selectedPaymentMethod || !totalPayableAmount) return;
    setPaymentStatusMessage(
      'Please wait while your payment gets processed. Do not press the back button on your browser or leave this page',
    );
    setStep(PaymentWidgetPaymentSteps.PROCESSING);
    let res: IActionResults<ISupplierPaymentResponse> | undefined = undefined;
    try {
      let temporalCard: ICreditCardT | undefined = undefined;
      if (selectedPaymentMethod.SupplierID < 0 && !selectedPaymentMethod.SupplierPaymentOptionID) {
        temporalCard = (temporalCreditCards || []).find(
          c =>
            c.CardNumber === selectedPaymentMethod.CardNumber &&
            c.ExpiryMMYY === selectedPaymentMethod.Expiry &&
            c.CardType === selectedPaymentMethod.PaymentMethod,
        );
        if (!temporalCard) {
          Toast.info('Temporal card not available');
          return;
        }
      }
      res = await submitPaymentAPI({
        pm: selectedPaymentMethod,
        merchantSurcharge,
        temporalCard,
        isCreditNotesAppliedGreaterThanInvoiceTotal: isCreditNotesAppliedGreaterThanInvoiceTotal(),
        creditStatementSummary,
        isLoyaltyPointsToggleOn,
        authorisationToken,
        scheduledPaymentDate,
        airPlusDbiData,
      });
    } catch (e) {
      setStep(PaymentWidgetPaymentSteps.TIMEOUT);
      setPaymentStatusMessage(getPaymentExceptionMessage(e as PaymentExceptionError));
      return;
    } finally {
      // We do cleanup here
    }
    setBatchPaymentResponse(res);
  };

  //
  const makePayment = async () => {
    if (!setStep) return;
    if (!selectedPaymentMethod || !totalPayableAmount) return;
    setIsSubmittingPayment(true);
    let authorisePaymentResponse;
    if (paymentAuth72488 && authorisePayment && TotalAmount) {
      authorisePaymentResponse = await authorisePayment({
        paymentAccountGUID: selectedPaymentMethod.PaymentAccountGUID!,
        paymentAmount: String(TotalAmount),
        paymentMethod: selectedPaymentMethod.PaymentMethod,
        recipientName: marketplaceSupplier?.TenantName,
      });
    }
    // if block is for payment method authority
    if (paymentAuth72488 && TotalAmount) {
      const authorisationStatus = authorisePaymentResponse?.authorisationStatus;
      switch (authorisationStatus) {
        case AuthorisationStatus.AwaitingCode:
        case AuthorisationStatus.NotRequired:
        case AuthorisationStatus.Approved:
        case AuthorisationStatus.Complete:
          if (authorisePaymentResponse?.authorisationID) {
            const paymentAuthorisationDetails = await getPaymentMethodAuthorisationDetails?.(
              authorisePaymentResponse.authorisationID,
            );
            setPaymentAuthorisationDetails(paymentAuthorisationDetails);
            if (authorisationStatus === AuthorisationStatus.AwaitingCode) {
              setStep(PaymentWidgetPaymentSteps.ENTER_SECURITY_CODE);
            } else {
              const users = paymentAuthorisationDetails?.authorisation.authorisers?.filter(
                (a: Authorisers) => a.status === AuthorisationStatus.InProgress,
              );
              if (users?.length) {
                const authorisersList = transformPaymentAuthorisationUserInfo(users, userID);
                setAuthorisersList(authorisersList);
              }
              submitPayment(authorisePaymentResponse?.token);
            }
          }
          break;
        default:
          break;
      }
    } else {
      submitPayment();
    }
    setIsSubmittingPayment(false);
  };

  // This is when we click on the Pay button, after that we fill DBI metadata(here we navigates to AirPlus step) if needed and then do the authorisation process
  const onClickPay = async (_e?: Event) => {
    if (!selectedPaymentMethod || !totalPayableAmount) return;

    setIsSubmittingPayment(true);
    // Update the Payment Batch with the Selected Payment Method and Merchant Processing Fee (this is done before generating authorisation token from "authorisePayment API")
    if (widgetScope === PaymentWidgetScope.ACCOUNT_PAYABLE) {
      await updatePaymentBatch?.(
        selectedPaymentMethod.PaymentAccountGUID as string,
        merchantSurcharge as IMerchantProcessingFee,
        selectedPaymentMethod,
      );
    }
    setIsSubmittingPayment(false);

    handleFillDbiData();
  };

  const handleRetryBatch = () => {
    const ipaID = selectedBatchDetails?.invoicePaymentAllocationID;
    ipaID && retryPSBLBatch && retryPSBLBatch(ipaID);
  };

  const onClickCancel = (props: {isRetryBatch?: boolean}) => {
    if (widgetScope === PaymentWidgetScope.PREPAYMENT && setStep) {
      setStep(PaymentWidgetPaymentSteps.CREATE_PREPAYMENT);
      return;
    }

    // We are retrying the batch in case of clicking on cancel button too as we can discard the failed batch
    if (props.isRetryBatch && widgetScope === PaymentWidgetScope.PSBL) {
      handleRetryBatch();
    }

    if (onCancel) {
      onCancel();
    }
  };

  const handleTryAgain = async () => {
    if (widgetScope === PaymentWidgetScope.PSBL) {
      await handleRetryBatch();
    }
    if (setStep) {
      setStep(PaymentWidgetPaymentSteps.NEW);
    }
  };

  const onClickDone = () => {
    if (onDone) {
      onDone();
      return;
    }

    if (onCancel) {
      onCancel();
    }
  };

  const onClickLogout = () => {
    if (onLogout) {
      onLogout();
    }
  };

  const onVerifyPaymentAccount = async (spm: ITenantSupplierPaymentMethod) => {
    if (!spm.PaymentAccountGUID) return;

    if (!fetchSPSAccount) return;

    const bpspAccount = await fetchSPSAccount(spm.PaymentAccountGUID);

    if (setBpspAccountDetails) {
      setBpspAccountDetails(bpspAccount);
    }

    if (setStep) {
      setStep(PaymentWidgetPaymentSteps.VERIFY_CREDIT_CARD);
    }
  };

  const handlePaymentResponse = (res?: IActionResults<ISupplierPaymentResponse>, msg?: string) => {
    if (!setStep) return;

    if (!res) {
      setPaymentStatusMessage(msg ? msg : "Sorry, your payment couldn't be processed at this time");
      if (onPaymentFailed) {
        onPaymentFailed();
      }
      setStep(PaymentWidgetPaymentSteps.DECLINED); // TODO: Is Declines the bes to do here ??
      return;
    }

    if (!res.IsSuccess) {
      setPaymentStatusMessage(res.Messages.join(' - '));
      if (onPaymentFailed) {
        onPaymentFailed();
      }
      setStep(PaymentWidgetPaymentSteps.DECLINED);
      return;
    } else if (res.IsSuccess && res.Value?.PaymentStatus === PaymentStatusType.Paid) {
      setPaymentStatusMessage(undefined);
      setStep(PaymentWidgetPaymentSteps.APPROVED);

      if (onPaymentApproved) {
        onPaymentApproved();
      }

      return;
    }

    const payment = res.Value?.Payment;

    if (!payment) {
      setPaymentStatusMessage("Sorry, your payment couldn't be processed at this time");
      if (onPaymentFailed) {
        onPaymentFailed();
      }
      setStep(PaymentWidgetPaymentSteps.DECLINED);
      return;
    }

    if (!payment.PaymentLogs || !payment.PaymentLogs.length) {
      if (onPaymentFailed) {
        onPaymentFailed();
      }
      setStep(PaymentWidgetPaymentSteps.DECLINED);
      return;
    }

    const isInvoiceStatusPaid = payment.Status === 'Paid';
    const isInvoiceStatusInProgress = payment.Status === 'InProgress';

    const lastPaymentLog = payment.PaymentLogs.sort((a, b) => b.ID - a.ID)[0];
    const isLastPaymentLogApproved = lastPaymentLog?.Description?.toLowerCase() === 'approved';
    if (isInvoiceStatusPaid && isLastPaymentLogApproved) {
      setPaymentStatusMessage(undefined);
      setStep(PaymentWidgetPaymentSteps.APPROVED);

      if (onPaymentApproved) {
        onPaymentApproved();
      }

      return;
    }

    if (isInvoiceStatusInProgress) {
      // setPayment(payment);
      if (onPaymentApproved) {
        onPaymentApproved();
      }
      setStep(PaymentWidgetPaymentSteps.SUBMITTED);
      return;
    }

    setPaymentStatusMessage(lastPaymentLog.Message);
    if (onPaymentFailed) {
      onPaymentFailed();
    }
    setStep(PaymentWidgetPaymentSteps.DECLINED);
  };

  interface PaymentExceptionError extends Error {
    code: string;
  }

  const getPaymentExceptionMessage = (error: PaymentExceptionError) => {
    // Known issue where axios sets code to ECONNABORTED for timeouts https://github.com/axios/axios/issues/1543
    return error?.code === 'ECONNABORTED' && error?.message.includes('timeout') && error?.message.includes('exceeded')
      ? 'Your payment request is taking longer than expected, we will notify you once your payment has been completed. You can close this panel now.'
      : "Sorry, your payment couldn't be processed at this time";
  };

  const body = (
    <div>
      {step === PaymentWidgetPaymentSteps.NEW ? (
        <>
          {storedPaymentOptions?.length && (fees88078 ? !isLoading : true) ? (
            <PaymentWidgetCarousel
              isPaymentFeeLoading={isPaymentFeeLoading}
              setIsRefreshPaymentMethods={setIsRefreshPaymentMethods}
              onVerifyPaymentAccount={onVerifyPaymentAccount}
              fees88078={fees88078}
            />
          ) : (
            <div style={{height: '190px'}} className="relative flex flex-col items-center justify-center">
              <h3 className="mt-3 font-poppins text-xl">{isLoading ? 'Loading...' : 'Add a Payment Option'}</h3>
              <LoadingIndicator isLoading={isLoading} size={'sm'} position={{left: '28%', top: '49%'}} />
            </div>
          )}
          {widgetScope !== PaymentWidgetScope.ACCOUNT_PAYABLE ? (
            <PaymentWidgetAddPaymentButton
              isLoading={isLoading}
              isPaymentFeeLoading={isPaymentFeeLoading}
              isCreditNotesAppliedGreaterThanInvoiceTotal={isCreditNotesAppliedGreaterThanInvoiceTotal()}
              isSpendaFinance={[PaymentMethodTypes.LendingFacility].includes(
                selectedPaymentMethod?.PaymentMethod as PaymentMethodTypes,
              )}
              isBNPL={[PaymentMethodTypes.Invigo].includes(selectedPaymentMethod?.PaymentMethod as PaymentMethodTypes)}
              handleShowPrepaymentPrompt={handleShowPrepaymentPrompt}
              setShowPrepaymentsReminder={setShowPrepaymentsReminder}
              onClickApplyCreditNoteBtn={() => setIsShowApplyCreditNoteDialog(true)}
              fees88078={fees88078}
            />
          ) : // IN AP as of now we don't have Add payment method button, so we only have AirPlusPortalLoginButton
          selectedPaymentMethod?.PaymentMethod === PaymentMethodTypes.Airplus ? (
            <>
              <div className="mt-2 w-full px-2 font-poppins">
                <AirPlusPortalLoginButton isDisabled={isLoading} fullWidth={false} />
              </div>
              <div className="m-auto mb-1 mt-4 h-px w-[19rem] bg-[#f1f1f1]" />
            </>
          ) : (
            <div className="h-20" />
          )}
        </>
      ) : (
        step !== PaymentWidgetPaymentSteps.ENTER_SECURITY_CODE && (
          <PaymentWidgetPaymentFeedback
            message={step === PaymentWidgetPaymentSteps.SUBMITTED ? '' : paymentStatusMessage}
          />
        )
      )}

      {step === PaymentWidgetPaymentSteps.SUBMITTED ? (
        <div className="p-8 font-poppins">
          <p className="text-xs">
            Thanks for making a payment. We will let you know by email to <br />
            <span className="break-all font-bold">{userEmailAddress}</span> <br />
            when your payment has been processed.
          </p>
        </div>
      ) : step === PaymentWidgetPaymentSteps.SUBMITTED_TO_AUTHORISER ? (
        <div className="p-8 font-poppins">
          <p className="line-height-medium text-xs">
            Your payment has been submitted to:
            <br />
            <p className="leading-normal">
              {authorisersList?.map((a, i) => (
                <p className="break-all font-bold" key={i}>
                  {a.name}
                </p>
              ))}
            </p>
            for authorisation.
          </p>
        </div>
      ) : step === PaymentWidgetPaymentSteps.ENTER_SECURITY_CODE ? (
        <EnterSecurityCode
          securityCode={securityCode}
          setSecurityCode={code => handleChangeSecurityCode(code)}
          handleResetSecurityCode={handleResetSecurityCode}
          paymentAuthorisationDetails={paymentAuthorisationDetails?.authorisation!}
          errorMsg={securityCodeErrorMsg}
          onEnterVerificationCode={submitSecurityCode}
        />
      ) : (
        <PaymentWidgetPaymentBreakdown
          merchantSurcharge={merchantSurcharge}
          totalPayableAmount={totalPayableAmount}
          isPaymentFeeLoading={isPaymentFeeLoading}
          isPrepayment={nonInvoicePaymentAmount > 0}
          paymentMethod={selectedPaymentMethod?.PaymentMethod as PaymentMethodTypes}
          fees88078={fees88078}
          isPaymentMethodLoading={isLoading}
        />
      )}
      {scheduledPayments83107 && scheduledPaymentDate && (
        <div
          data-autoid="lblScheduledPaymentDatePaymentWidgetStepHome"
          className=" mt-5 inline-block w-full rounded-md bg-[#8178CF1A] px-3 py-1 text-center font-poppins text-xs font-medium text-[#8178CF]"
        >
          {`Scheduled Payment: ${moment(scheduledPaymentDate).format('DD MMM YYYY')}`}
        </div>
      )}
    </div>
  );

  const isBuyerAdmin = tenantUserDetails?.UserRoles.some(
    a =>
      a.Name?.toLowerCase() === UserRoleTypes.ADMIN.toLowerCase() ||
      a.Name?.toLowerCase() === UserRoleTypes.PRIMARY.toLowerCase(),
  );

  const fundingAmount =
    selectedPaymentMethod?.FundingRate && TotalAmount ? TotalAmount * 0.01 * selectedPaymentMethod?.FundingRate : 0;
  const availableCreditsLessThanAmountToPay = selectedPaymentMethod?.CreditAvailable! < TotalAmount! + fundingAmount;
  const repaymentConfigIssues =
    selectedPaymentMethod?.PaymentMethod === PaymentMethodTypes.LendingFacility &&
    (selectedPaymentMethod?.RepaymentAccountConfigIssues || availableCreditsLessThanAmountToPay);

  const disablePayButton = () => {
    if (fees88078 && isLoading) {
      return true;
    }
    if (isPsblBatchLoading) {
      return true;
    }
    if (isSubmittingPayment) {
      return true;
    }
    if (repaymentConfigIssues) {
      return true;
    }
    //  This condition to be removed when payment auth flag goes live along with widget tooltip on "Pay Now" button
    if (
      (widgetScope !== PaymentWidgetScope.ACCOUNT_PAYABLE && !isBuyerAdmin && !paymentAuth72488) ||
      (widgetScope !== PaymentWidgetScope.ACCOUNT_PAYABLE &&
        !isBuyerAdmin &&
        paymentAuth72488 &&
        selectedPaymentMethod?.AttentionRequiredReason === AttentionRequiredReason.NoAuthAttention)
    ) {
      return true;
    }
    if (widgetScope === PaymentWidgetScope.PSBL && handleShowPrepaymentPrompt() && !cppBatch) {
      return true;
    }
    return (
      step === PaymentWidgetPaymentSteps.PROCESSING ||
      isPaymentFeeLoading ||
      storedPaymentOptions?.length === 0 ||
      ((selectedPaymentMethod?.SupplierPaymentOptionID || 0) < 0 && !isCreditNotesAppliedGreaterThanInvoiceTotal?.())
    );
  };

  const footer = (
    <>
      {[PaymentWidgetPaymentSteps.NEW, PaymentWidgetPaymentSteps.PROCESSING].indexOf(
        step as PaymentWidgetPaymentSteps,
      ) >= 0 && (
        <>
          <Button
            variant="outlined"
            className="bg-white"
            onClick={onCancel}
            disabled={step === PaymentWidgetPaymentSteps.PROCESSING}
          >
            Cancel
          </Button>
          <WidgetTooltip
            title={
              selectedPaymentMethod?.RepaymentAccountConfigIssues ||
              (availableCreditsLessThanAmountToPay &&
                selectedPaymentMethod?.PaymentMethod === PaymentMethodTypes.LendingFacility &&
                PaymentMethodErrors.InsufficientFunds) ||
              'Please ask an Admin or Primary user to submit the payment for you.'
            }
            placement="top"
            disableHoverListener={
              widgetScope === PaymentWidgetScope.ACCOUNT_PAYABLE ||
              ((isBuyerAdmin ||
                (paymentAuth72488 &&
                  selectedPaymentMethod?.AttentionRequiredReason !== AttentionRequiredReason.NoAuthAttention)) &&
                !repaymentConfigIssues)
            }
            arrow
          >
            <div>
              <SButton
                className="text-base"
                color="green"
                fontFamily="Poppins"
                borderRadius="6px"
                height="40px"
                onClick={onClickPay}
                width="100px"
                disabled={disablePayButton()}
                isSubmitting={isSubmittingPayment}
              >
                {selectedPaymentMethod?.PaymentMethod === PaymentMethodTypes.Invigo
                  ? capricornDemo77857
                    ? 'Pay'
                    : 'Pay Later '
                  : scheduledPaymentDate
                    ? 'Schedule'
                    : 'Pay Now'}
              </SButton>
            </div>
          </WidgetTooltip>
        </>
      )}

      {step === PaymentWidgetPaymentSteps.APPROVED && (
        <>
          {widgetScope === PaymentWidgetScope.PIBL && (
            <>
              <SButton color="green" fontFamily="Poppins" height="40px" onClick={onClickCancel}>
                View Paid Invoice
              </SButton>
              <SButton color="blueShade" fontFamily="Poppins" height="40px" onClick={onClickLogout}>
                Log Out
              </SButton>
            </>
          )}
          {[
            PaymentWidgetScope.ACCOUNT_PAYABLE,
            PaymentWidgetScope.PREPAYMENT,
            PaymentWidgetScope.PSBL,
            PaymentWidgetScope.SPENDA_FINANCE,
          ].includes(widgetScope as PaymentWidgetScope) && (
            <div className="w-full text-center">
              <SButton color="blueShade" fontFamily="Poppins" height="40px" onClick={onClickDone}>
                Done
              </SButton>
            </div>
          )}
        </>
      )}

      {[PaymentWidgetPaymentSteps.DECLINED, PaymentWidgetPaymentSteps.TIMEOUT].includes(step!) && (
        <>
          {[
            PaymentWidgetScope.SPENDA_FINANCE,
            PaymentWidgetScope.PREPAYMENT,
            PaymentWidgetScope.PSBL,
            PaymentWidgetScope.PIBL,
          ].includes(widgetScope as PaymentWidgetScope) && (
            <>
              <SButton
                color="white"
                border={'1px solid #1C78AD'}
                textColor="blueShade"
                height="40px"
                fontFamily="Poppins"
                onClick={() => onClickCancel({isRetryBatch: true})}
                borderRadius="6px"
              >
                Cancel
              </SButton>
              <SButton color="blueShade" borderRadius="6px" onClick={handleTryAgain} fontFamily="Poppins">
                Try Again
              </SButton>
            </>
          )}
          {widgetScope === PaymentWidgetScope.ACCOUNT_PAYABLE && (
            <div className="w-full text-center">
              <SButton height="40px" color="blueShade" fontFamily="Poppins" onClick={onClickDone}>
                Done
              </SButton>
            </div>
          )}
        </>
      )}

      {step === PaymentWidgetPaymentSteps.SUBMITTED && (
        <>
          {widgetScope === PaymentWidgetScope.PIBL && (
            <>
              <SButton color="green" fontFamily="Poppins" height="40px" onClick={onClickCancel}>
                View Paid Invoice
              </SButton>
              <SButton color="blueShade" fontFamily="Poppins" height="40px" onClick={onClickLogout}>
                Log Out
              </SButton>
            </>
          )}
          {[
            PaymentWidgetScope.ACCOUNT_PAYABLE,
            PaymentWidgetScope.PREPAYMENT,
            PaymentWidgetScope.PSBL,
            PaymentWidgetScope.SPENDA_FINANCE,
          ].includes(widgetScope as PaymentWidgetScope) && (
            <div className="w-full text-center">
              <SButton
                height="40px"
                fontFamily="Poppins"
                color="blueShade"
                onClick={onClickDone}
                label="white"
                type="button"
              >
                Done
              </SButton>
            </div>
          )}
        </>
      )}
      {step === PaymentWidgetPaymentSteps.SUBMITTED_TO_AUTHORISER &&
        (widgetScope === PaymentWidgetScope.PIBL ? (
          <div className="flex w-full justify-between">
            <Button
              data-autoid={`btnLogout`}
              className="bg-white"
              onClick={onClickLogout}
              variant="outlined"
              color="primary"
            >
              Logout
            </Button>
            <Button data-autoid={`btnDone`} onClick={onCancel} color="primary">
              Done
            </Button>
          </div>
        ) : (
          <Button data-autoid={`btnDone`} onClick={onClickDone} color="primary" className="w-[92px]">
            Done
          </Button>
        ))}
      {step === PaymentWidgetPaymentSteps.ENTER_SECURITY_CODE && (
        <>
          <SecondaryButton label="Cancel" onClick={onClickCancel} width="100px" />

          <PrimaryButton
            label={isAtemptsExhausted ? 'Support' : 'Verify'}
            width="100px"
            onClick={submitSecurityCode}
            disabled={securityCode.trim().length < 6 || (!zeroNewCode && zeroCodeAttempts)}
          />
        </>
      )}
    </>
  );

  return (
    <>
      {isShowApplyCreditNoteDialog && (
        <PaymentWidgetStepCreditNotes
          isShowDialog={isShowApplyCreditNoteDialog}
          handleCloseDialog={() => setIsShowApplyCreditNoteDialog(false)}
        />
      )}
      <PaymentWidgetStep body={body} footer={footer} />
    </>
  );
};

interface IPaymentWidgetCarouselProps {
  // selectedPaymentMethod?: ITenantSupplierPaymentMethod;
  // setSelectedPaymentMethod?: (value: ITenantSupplierPaymentMethod) => void;
  setIsRefreshPaymentMethods?: (value: boolean) => void;
  onVerifyPaymentAccount: (pm: ITenantSupplierPaymentMethod) => void;
  isPaymentFeeLoading?: boolean;
  fees88078?: boolean;
}

const PaymentWidgetCarousel = (props: IPaymentWidgetCarouselProps) => {
  const {isPaymentFeeLoading, fees88078} = props;

  const {
    storedPaymentOptions,
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    setStep,
    setCardToEdit,
    capricornDemo77857,
  } = useContext(PaymentWidgetContext);

  const onCardChanged = (index: number) => {
    if (index < 0) return;

    const spm = storedPaymentOptions?.length ? storedPaymentOptions[index] : undefined;
    if (!spm || !setSelectedPaymentMethod) return;

    setSelectedPaymentMethod(spm);
  };

  const onEditCreditCard = (spm: ITenantSupplierPaymentMethod, setAuthorisation?: boolean) => {
    // Add card to edit to context
    if (setCardToEdit) setCardToEdit(spm);

    // Change to edit card view
    if (setStep) {
      if (setAuthorisation) {
        setStep(PaymentWidgetPaymentSteps.SET_PAYMENT_AUTHORISATION_RULES);
      } else setStep(PaymentWidgetPaymentSteps.CONFIGURE_PAYMENT_OPTIONS);
    }
  };
  let nickNameIndex = storedPaymentOptions?.findIndex(
    (pm: any) => pm.SupplierPaymentOptionID === selectedPaymentMethod?.SupplierPaymentOptionID,
  );
  return (
    <>
      <Carousel
        onSlideChanged={onCardChanged}
        isPaymentFeeLoading={isPaymentFeeLoading}
        activeIndex={storedPaymentOptions?.findIndex(
          pm => pm.SupplierPaymentOptionID === selectedPaymentMethod?.SupplierPaymentOptionID,
        )}
        nickName={
          storedPaymentOptions?.[nickNameIndex ? nickNameIndex : 0]?.PaymentMethod !== PaymentMethodTypes.Invigo
            ? storedPaymentOptions?.[nickNameIndex ? nickNameIndex : 0]?.FriendlyName ?? ''
            : capricornDemo77857
              ? 'Brisk Automotive'
              : ''
        }
      >
        {storedPaymentOptions?.map((spm: ITenantSupplierPaymentMethod, index: number) => (
          <CarouselItem key={`carousel-item-${index}`}>
            <PaymentMethodSlide
              paymentMethod={spm}
              onEditCreditCard={onEditCreditCard}
              onClickVerifyPaymentAccount={props.onVerifyPaymentAccount}
              fees88078={fees88078}
            />
          </CarouselItem>
        ))}
      </Carousel>
    </>
  );
};

interface IPaymentMethodSlideProps {
  paymentMethod: ITenantSupplierPaymentMethod;
  onEditCreditCard?: (spm: ITenantSupplierPaymentMethod, setAuthorisation?: boolean) => void;
  onClickVerifyPaymentAccount?: (spm: ITenantSupplierPaymentMethod) => void;
  fees88078?: boolean;
}

const PaymentMethodSlide = (props: IPaymentMethodSlideProps) => {
  const {paymentMethod: pm} = props;

  const {fees88078, selectedPaymentMethod, merchantSurcharge, paymentAuth72488, capricornDemo77857, tenantName} =
    useContext(PaymentWidgetContext);

  const TotalAmount = fees88078 ? selectedPaymentMethod?.TotalAmount : merchantSurcharge?.TotalAmount;

  const onClickEdit = (pm: ITenantSupplierPaymentMethod, setAuthorisation?: boolean) => {
    if (props.onEditCreditCard) props.onEditCreditCard(pm, setAuthorisation);
  };

  const [isVerifying, setIsVerifying] = useState<boolean>(false);

  const onClickVerifyPaymentAccount = async (pm: ITenantSupplierPaymentMethod) => {
    if (!props.onClickVerifyPaymentAccount) return;

    setIsVerifying(true);
    await props.onClickVerifyPaymentAccount(pm);
    setIsVerifying(false);
  };

  let bgGradientColor = '#dbdbdb';
  let fontColor = 'text-white';
  let txtShadow = '#343434 1px 1px 3px';
  let paymentMethodIcon = undefined;

  const isBNPL = pm.PaymentMethod.toUpperCase() === 'INVIGOPAYLATER';
  const isSpendaFinance = pm.PaymentMethod.toUpperCase() === 'LENDINGFACILITY';
  const isBankTransfer = pm.PaymentMethod.toUpperCase() === 'BANKTRANSFER';
  const isAirPlus = pm.PaymentMethod.toUpperCase() === 'AIRPLUS';

  const getBankTransferIconAndColor = () => {
    switch (pm.BankName) {
      case BankAccounts.NAB:
        bgGradientColor = 'linear-gradient(to right, #484C4F , #090F0F)';
        paymentMethodIcon = <IconNAB width="77px" height="47px" />;
        break;
      case BankAccounts.ANZ:
        bgGradientColor = 'linear-gradient(to right, #007DBA , #004165)';
        paymentMethodIcon = <IconANZ width="69px" height="45px" />;
        break;
      case BankAccounts.CBA:
        bgGradientColor = 'linear-gradient(to right, #216F86 , #1B3C41)';
        paymentMethodIcon = <IconCommonWealth width="66px" height="43px" />;
        break;
      case BankAccounts.WESTPAC:
        bgGradientColor = 'linear-gradient(to right, #DA1710 , #630202)';
        paymentMethodIcon = <IconWestpac width="65px" height="41px" />;
        break;
      default:
        bgGradientColor = 'linear-gradient(to right, #74B9D3 , #1F7290)';
        paymentMethodIcon = <BankWhite width="34px" height="40px" />;
        break;
    }
  };

  switch (pm.PaymentMethod.toUpperCase()) {
    case 'VISA':
      bgGradientColor = '#125a99';
      paymentMethodIcon = <Visa width="60px" height="42px" />;
      break;
    case 'MASTERCARD':
      bgGradientColor = 'linear-gradient(145deg, rgba(37,55,69,1) 0%, rgba(66,81,95,1) 67%)';
      paymentMethodIcon = <MasterCardNew width="54px" height="43px" />;
      break;
    case 'INVIGOPAYLATER':
      bgGradientColor = capricornDemo77857 ? '#481B5E' : '#E7E7E7';
      paymentMethodIcon = capricornDemo77857 ? null : <SpendaPayLater width="24.3px" height="37px" />;
      break;
    case 'LENDINGFACILITY':
      bgGradientColor = `url(${SpendaFinanceBackground}), linear-gradient(to right, #3E3F5A, #252637)`;
      paymentMethodIcon = <img src={SpendaIcon} width="27.77px" />;
      break;
    case 'AMERICANEXPRESS':
      bgGradientColor = 'linear-gradient(90deg, rgba(83,119,168,1) 0%, rgba(23,34,59,1) 100%)';
      paymentMethodIcon = <Amex width="40px" height="40px" />;
      break;
    case 'AIRPLUS':
      bgGradientColor = '#160F5B';
      paymentMethodIcon = <img src={AirPlusLogo} className="!w-[80px]" />;
      break;
    case 'BANKTRANSFER':
      getBankTransferIconAndColor();
      break;
  }

  const TradingTermDays = selectedPaymentMethod ? selectedPaymentMethod?.TradingTermDays : 0;
  const fundingAmount =
    selectedPaymentMethod?.FundingRate && TotalAmount ? (TotalAmount / 100) * selectedPaymentMethod?.FundingRate : 0;
  const TotalAmountToPay = (TotalAmount || 0) + fundingAmount;

  const isCardVerificationError = [
    VerificationStatus.FAILED,
    VerificationStatus.RETRY_REQUIRED,
    VerificationStatus.ATTEMPTS_EXCEEDED,
  ].includes(pm.PaymentAccountVerificationStatus!);

  const facilityRepaymentMethodError =
    selectedPaymentMethod?.RepaymentAccountConfigIssues == PaymentMethodErrors.NoFacilityRepaymentMethod;

  return (
    <div
      data-autoid={`paymentMethod-${pm.PaymentAccountGUID || pm.PaymentMethod}`}
      className={`relative flex flex-col justify-between p-2 ${fontColor} h-[150px] w-[270px] rounded-[10px] !bg-contain !bg-center !bg-no-repeat`}
      style={{background: bgGradientColor}}
    >
      <div className="flex items-center justify-end">
        {(pm.SupplierPaymentOptionID || 0) > 0 && !isBNPL && !isSpendaFinance ? (
          <>
            <div className="flex">
              {pm.IsDefault && (
                <span
                  style={{minHeight: '15px', height: '22px'}}
                  className={`${borderStyle} opactity-10 rounded border-blue-400 bg-blue-100 px-1 font-poppins text-base text-spenda-btnbg `}
                >
                  {'Default'}
                </span>
              )}

              {/* TODO: Jitu- using false condition to hide it for now, it will be usefull when Payment widget will be
              integrated in AR */}
              {pm.IsInvigoDefault! && false && (
                <span
                  style={{minHeight: '25px'}}
                  className={`ml-2 flex items-center rounded px-2 text-center text-white`}
                  {...css({backgroundColor: '#2F97BC'})}
                >
                  <IconClock />
                  Auto Pay
                </span>
              )}
            </div>
          </>
        ) : (
          <span style={{minHeight: '34px'}}>&nbsp;</span>
        )}
        {[VerificationStatus.NOT_STARTED, VerificationStatus.NOT_VERIFIED, VerificationStatus.PENDING].indexOf(
          pm.PaymentAccountVerificationStatus!,
        ) >= 0 && (
          <span
            style={{minHeight: '15px', height: '22px', padding: '0px 2px'}}
            className={`opactity-10 relative z-10 cursor-pointer rounded border border-orange-400 bg-orange-200 px-2 text-orange-400 ${
              pm.IsDefault ? '' : 'cursor-pointer hover:bg-spenda-warning hover:text-white'
            }`}
            onClick={() => onClickVerifyPaymentAccount(pm)}
          >
            {'Tap to Verify'}
            <LoadingIndicator isLoading={isVerifying} size="sm" color="#f6ad55" position={{right: '3px', top: '8px'}} />
          </span>
        )}

        {pm.SupplierPaymentOptionID && !isBNPL && pm.PaymentAccountVerificationStatus == 'Verified' ? (
          <i className="z-20 ml-2 cursor-pointer" onClick={() => onClickEdit(pm)}>
            <IconEdit iconColor="#FFFFFF" width="22" height="22" />
          </i>
        ) : (
          <i></i>
        )}
        {(isCardVerificationError ||
          // we do not need payment authorisation attention icon for Invoigo paylater #76929
          (pm.AttentionRequiredReason && paymentAuth72488 && pm.PaymentMethod !== PaymentMethodTypes.Invigo)) && (
          <ErrorIcon
            titleAccess={pm.AttentionRequiredReason || 'Verification error, tap to resolve'}
            className="z-10 ml-2 cursor-pointer text-orange-400"
            onClick={() => {
              if (pm.AttentionRequiredReason === AttentionRequiredReason.NoAuthAttention && !isCardVerificationError)
                onClickEdit(pm, true);
              else onClickVerifyPaymentAccount(pm);
            }}
          />
        )}
        {isSpendaFinance && facilityRepaymentMethodError ? (
          <ErrorIcon
            titleAccess={'Please set up a repayment method to use your Lending account'}
            className="z-10 ml-2 cursor-pointer text-orange-400"
          />
        ) : null}
        {isBNPL &&
          (!capricornDemo77857 ? (
            <p
              className={`flex items-center justify-start font-poppins text-sm font-semibold text-black-800 ${PayLaterCss}`}
            >
              {`PAY LATER`}
            </p>
          ) : (
            <p className={`${CapricornPayLaterCss}`}>
              <img src={capricorn} alt="img" />
            </p>
          ))}
        {isSpendaFinance && (
          <>
            <p className={`flex font-poppins text-base font-medium ${PayLaterCss}`}>{`Spenda Finance`}</p>
          </>
        )}
      </div>
      {isAirPlus ? (
        <div className="absolute flex w-full flex-col ">
          <div className="mt-3 flex justify-start">
            <img src={AirPlusIcon} className="mt-2 !w-[90px]" />
            <div className="absolute -top-2 right-2 h-[35px] w-[90px] rounded-bl-md rounded-tr-[10px] bg-[#8728D6]"></div>
            <div className="t-[9.5px] absolute right-2 top-3 h-[40px] w-[19px] rounded-l-md bg-[#78EF48]"></div>
          </div>

          <div className="absolute -bottom-8 right-2 flex w-full flex-row items-end justify-between p-4">
            <span className="font-poppins text-xs font-medium">{tenantName}</span>
            <img src={AirPlusLogo} className="!w-[80px]" />
          </div>
        </div>
      ) : null}
      <div className="flex items-end justify-between">
        <div className="w-full text-left">
          {isBNPL ? (
            capricornDemo77857 ? (
              <div className="mb-1 ml-3">
                <p className="font-courierNew text-xs font-bold">Member name: Brisk Automotive</p>
                <p className="font-courierNew text-xs font-bold">Member number: 880324</p>
              </div>
            ) : isSpendaFinance && facilityRepaymentMethodError ? (
              <p className={`text-center font-poppins text-sm font-normal ${BNPLCardCss} mr-6`}>
                Please set up a repayment method
                <br /> to use your Lending account
              </p>
            ) : (
              <p
                className={`mt-1 text-center font-poppins text-sm font-normal ${BNPLCardCss}`}
                data-autoid={isSpendaFinance ? 'lblSpendaFinanceDrawdownTerms' : 'lblInvigoPayLater'}
              >
                Your{' '}
                {[PaymentServiceType.BANK_ACCOUNT].includes(
                  selectedPaymentMethod?.DefaultRepaymentMethodType as PaymentServiceType,
                )
                  ? 'bank account'
                  : 'card'}{' '}
                ending in {selectedPaymentMethod?.DefaultRepaymentMethodLast4 || '0000'}
                <br /> will be charged on the <br />
                {getTradingTermDays(
                  TradingTermDays || 0,
                  selectedPaymentMethod?.TradingTermName || TradingTermName.DaysAfterInvoice,
                )}{' '}
                for {PriceFormat(parseFloat(TotalAmountToPay.toFixed(2)))}.
              </p>
            )
          ) : isSpendaFinance ? (
            <div className="row flex w-full items-end justify-between">
              <div className="flex flex-col">
                <div className="mb-1 flex flex-col">
                  <span className="font-poppins text-[8px] font-normal text-[#CBCBCB]">Available credit</span>
                  <div
                    data-autoid="lblAvailableCredit"
                    className="text-light font-poppins text-xl font-normal text-white"
                  >
                    {PriceFormat(parseFloat((pm.CreditAvailable ?? 0).toFixed(2)))}
                  </div>
                </div>
                <div className="flex flex-col">
                  <span className="font-poppins text-[8px] font-normal text-[#CBCBCB] ">Due on</span>
                  <div className="flex flex-row">
                    <span
                      data-autoid="lblTradingTermDays"
                      className="text-medium font-poppins text-sm font-normal text-white"
                    >
                      {getTradingTermDays(
                        TradingTermDays || 0,
                        selectedPaymentMethod?.TradingTermName || TradingTermName.DaysAfterInvoice,
                      )}
                    </span>
                    <WidgetTooltip
                      title={`Your ${
                        PaymentServiceType.BANK_ACCOUNT === pm?.DefaultRepaymentMethodType ? 'bank account' : 'card'
                      } ending in ${
                        pm?.DefaultRepaymentMethodLast4 || '0000'
                      } will be automatically charged on the due date.`}
                      backgroundColor="#53546A"
                      textColor="#fff"
                      arrow={false}
                      placement="right"
                      maxWidth="220px"
                      width="100%"
                      fontSize="10px"
                      fontWeight={400}
                      margin="0 0 25px 5px"
                      textAlign="left"
                    >
                      <div className="mb-1 ml-2">
                        <InfoRound style={{width: '13px', height: '13px', color: '#999999'}} />
                      </div>
                    </WidgetTooltip>
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-end">
                <span
                  data-autoid="lblFacilityID"
                  className="mb-2 mr-[2px] font-poppins text-[8px] font-normal text-white [writing-mode:vertical-lr]"
                >
                  {String(pm.FacilityID).padStart(5, '0')}
                </span>
                <img className="!h-[42.21px] !w-[27.77px]" src={SpendaIcon} alt="spenda" />
              </div>
            </div>
          ) : isBankTransfer ? (
            <>
              <p className="font-courierNew text-base font-bold">BSB: {pm.BankBSB}</p>
              <p className="font-courierNew text-base font-bold">ACC: {pm.BankAccountNumber}</p>
            </>
          ) : (
            ''
          )}
        </div>
        {!(isSpendaFinance || isAirPlus) ? (
          <div className="flex items-end justify-between">
            <div style={{textShadow: txtShadow, position: 'absolute', left: '1px', bottom: '2px'}}>
              {!isBNPL && !isBankTransfer && (
                <p className="font-courierNew text-xl font-bold tracking-widest" style={{padding: '4px 19px'}}>
                  •••• {`${pm.Last4}`}
                </p>
              )}
            </div>
            <div className={`${isBNPL ? '' : cardIconbgCss}`}>
              <span>{paymentMethodIcon}</span>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

const PaymentWidgetAddPaymentButton = (props: {
  isPaymentFeeLoading: boolean;
  isLoading: boolean;
  isCreditNotesAppliedGreaterThanInvoiceTotal: boolean;
  isSpendaFinance?: boolean;
  isBNPL?: boolean;
  handleShowPrepaymentPrompt: () => boolean | undefined;
  setShowPrepaymentsReminder: (showPrepaymentsReminder: boolean) => void;
  onClickApplyCreditNoteBtn: () => void;
  fees88078?: boolean;
}) => {
  const {
    setStep,
    storedPaymentOptions,
    creditsToApply,
    marketplaceSupplier,
    getSupplierStatementSummary: search,
    suppliers,
    getCreditClaimsAPI: getClaims,
    invoicesToPay,
    widgetScope,
    isLoyaltyPointsToggleOn,
    setIsLoyaltyPointsToggleOn,
    featLoyaltyPoints,
    selectedPaymentMethod,
    creditAndClaimsList,
    selectedBatchDetails,
    setCreditAndClaimsList,
    scheduledPayments83107,
    scheduledPaymentDate,
    merchantSurcharge,
    setScheduledPaymentDate,
  } = useContext(PaymentWidgetContext);

  const TotalAmount = props?.fees88078 ? selectedPaymentMethod?.TotalAmount : merchantSurcharge?.TotalAmount;
  const isShowSchedulePayment = TotalAmount ? TotalAmount > 0 : false;

  useEffect(() => {
    if (!isShowSchedulePayment) {
      setScheduledPaymentDate?.(undefined);
    }
  }, [isShowSchedulePayment]);

  const {availableCredit} = useCreditNotes({
    marketplaceSupplier,
    search,
    suppliers,
    getClaims,
    isSingleInvoice: widgetScope === PaymentWidgetScope.PIBL,
    creditAndClaimsList,
    setCreditAndClaimsList,
  });

  // PSBL batch invoices
  const {selectedTxList} = selectedBatchDetails ?? {};
  const allBatchInvoices = selectedTxList?.filter(a => a.transactionTypeID === DatTypes.Invoice);
  const allBatchCreditsList = selectedTxList?.filter(a => a.transactionTypeID !== DatTypes.Invoice);

  const [isOpen, setIsOpen] = React.useState(!props.isLoading && (storedPaymentOptions?.length as number) === 0);

  const AvailableCreditCss = css({
    borderRadius: '6px',
    width: '257px',
    height: '32px',
    backgroundColor: 'RGBA(211, 229, 239, 0.5)',
    fontSize: '12px',
    fontWeight: '400',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  });

  useEffect(() => {
    setIsOpen(!props.isLoading && storedPaymentOptions?.length === 0);
  }, [storedPaymentOptions, props.isLoading]);

  const _pu = usePaymentUtilities();
  //for PIBL
  const cpp = invoicesToPay && _pu.partialPaymentInProgress(invoicesToPay as IConnectedSupplierStatementSummary[]);
  // for PSBL
  const cppBatch = allBatchInvoices?.length && _pu.arBatchPartialPaymentInProgress(allBatchInvoices);

  const handleClick = () => {
    // if (storedPaymentOptions?.length === 0) return;
    setIsOpen(value => !value);
  };

  const hideCreditNotesButton = () => {
    if (widgetScope === PaymentWidgetScope.PSBL) {
      return !cppBatch;
    } else if (
      [PaymentWidgetScope.PREPAYMENT, PaymentWidgetScope.SPENDA_FINANCE].includes(widgetScope as PaymentWidgetScope)
    ) {
      return false;
    } else {
      return !props?.isBNPL && !cpp;
    }
  };

  const isCreditApplied =
    widgetScope === PaymentWidgetScope.PSBL ? allBatchCreditsList?.length : creditsToApply?.length;
  const getCreditTotal = () => {
    if (widgetScope === PaymentWidgetScope.PSBL) {
      return parseFloat(allBatchCreditsList?.reduce((a, c) => a + (c.balance || 0), 0)?.toFixed(2) || '0');
    } else {
      return parseFloat(creditsToApply?.reduce((a, c) => a + (c.Balance || 0), 0)?.toFixed(2) || '0');
    }
  };

  const showLoyaltyPoints: boolean =
    (marketplaceSupplier?.IsLoyaltyEligible &&
      featLoyaltyPoints &&
      !props.isCreditNotesAppliedGreaterThanInvoiceTotal) ??
    false;

  const Prompt = withStyles({
    tooltip: {
      padding: '20px 10px 10px 10px',
      boxSizing: 'border-box',
      background: '#3A76A9',
      border: '1px solid #3A76A9',
      position: 'relative',
      zIndex: 99999999,
      boxShadow: '0px 0px 4px 0 rgb(0 0 0 / 20%)',
      borderRadius: '6px',
      fontFamily: 'poppins',
      '& .MuiTooltip-arrow': {
        color: '#fff',
        '&::before': {
          // boxShadow: '-2px -3px 4px 0px rgb(0 0 0 / 12%)',
          background: '#3A76A9',
        },
      },
    },
  })(Tooltip);

  return (
    <>
      <div className="relative">
        <div className={`flex flex-col items-center justify-evenly p-1 font-poppins ${buttonContainerStyle}`}>
          {props?.isBNPL && (
            <div>
              <p className={`${AvailableCreditCss} font-poppins`}>Available credit</p>
              <p data-autoid="lblAvailableCredit" className="my-2 font-poppins text-xs font-normal">
                {PriceFormat(selectedPaymentMethod?.CreditAvailable)}
              </p>
            </div>
          )}
          <div className={`flex ${scheduledPayments83107 ? 'flex-row' : 'flex-col'} justify-between gap-2`}>
            <div
              className={`${!scheduledPayments83107 ? 'order-1 flex flex-col-reverse gap-3' : 'flex flex-row gap-3'}`}
            >
              {hideCreditNotesButton() && (
                <Prompt
                  id="prepaymentPrompt"
                  open={props.handleShowPrepaymentPrompt()}
                  arrow
                  placement="left-start"
                  title={
                    <React.Fragment>
                      <div className="flex h-[66px] w-[200px] flex-col">
                        <p className="mb-1 text-center font-poppins text-[10px] font-medium text-white">
                          It appears that you have more unallocated payments available. To add them, click here
                        </p>
                        <div className="text-right">
                          <span
                            data-autoid="btnGotit"
                            onClick={() => {
                              props.setShowPrepaymentsReminder(false);
                            }}
                            className="cursor-pointer font-poppins text-[10px] font-semibold leading-8 text-white"
                          >
                            Got it
                          </span>
                        </div>
                      </div>
                    </React.Fragment>
                  }
                >
                  <Button
                    data-autoid="btnAvailableCredit"
                    disabled={props.isLoading}
                    onClick={() => {
                      if (widgetScope === PaymentWidgetScope.PSBL) {
                        props?.onClickApplyCreditNoteBtn?.();
                        return;
                      }
                      setStep && setStep(PaymentWidgetPaymentSteps.CREDIT_NOTES);
                    }}
                    className={`rounded-md px-2 ${
                      isCreditApplied ? 'text-white' : 'text-primary '
                    } text-base font-semibold ${buttonStyle}  ${props.isLoading ? 'cursor-wait' : 'cursor-pointer'}`}
                    style={{backgroundColor: isCreditApplied ? '#1C78AD' : '#fff'}}
                  >
                    <p className="text-xs font-medium">
                      {' '}
                      {PriceFormat(
                        isCreditApplied ? getCreditTotal() : parseFloat((availableCredit ?? 0)?.toFixed(2)),
                      )}{' '}
                      {isCreditApplied ? 'applied' : 'available'}
                    </p>
                    <p>{isCreditApplied ? 'Modify' : 'Request/Apply Credit'}</p>
                  </Button>
                </Prompt>
              )}
              {!(props?.isBNPL || props?.isSpendaFinance) && (
                <button
                  data-autoid="btnAddAPaymentOption"
                  disabled={props.isLoading || storedPaymentOptions?.length === 0}
                  onClick={handleClick}
                  className={`${props.isLoading ? 'cursor-wait' : 'cursor-pointer'} ${
                    !scheduledPayments83107 && buttonStyle
                  }  z-10 flex h-[40px] w-[40px] cursor-pointer flex-row items-center justify-center rounded-[6px] border-[1px] border-primary p-[6px]`}
                >
                  {scheduledPayments83107 ? (
                    <IconAddPaymentOption />
                  ) : (
                    <p className="text-base font-semibold text-primary">Add new Payment Option</p>
                  )}
                </button>
              )}
            </div>
            {scheduledPayments83107 && isShowSchedulePayment && (
              <ARTooltip
                title={
                  <p className="whitespace-nowrap text-center font-poppins text-[12px] font-medium text-spenda-primarytext">
                    {cppBatch ? 'Only full invoices can be scheduled' : 'Schedule Payment'}
                  </p>
                }
              >
                <button
                  disabled={Boolean(cppBatch)}
                  data-autoid="btnScheduledPaymentDate"
                  onClick={() => setStep && setStep(PaymentWidgetPaymentSteps.SCHEDULE_PAYMENT)}
                  className={clsx(
                    {'cursor-wait': props?.isLoading},
                    {'cursor-pointer': !props?.isLoading && !cppBatch},
                    {'!border-[#3C9F78] !bg-[#3C9F78]': Boolean(scheduledPaymentDate)},
                    `z-10 flex h-[40px] w-[40px] cursor-pointer flex-row items-center justify-center rounded-[6px] border-[1px] border-primary bg-primary p-[6px]`,
                    {'!cursor-default opacity-50': Boolean(cppBatch)},
                  )}
                >
                  <IconPaymentScheduled fill="#fff" />
                </button>
              </ARTooltip>
            )}
          </div>
          {selectedPaymentMethod?.PaymentMethod === PaymentMethodTypes.Airplus ? (
            <div className="mt-2 w-full px-2">
              <AirPlusPortalLoginButton
                isDisabled={props?.isLoading}
                fullWidth={Boolean(scheduledPayments83107) && isShowSchedulePayment}
              />
            </div>
          ) : null}
          {showLoyaltyPoints ? (
            <>
              <div className="flex flex-row">
                <IOSSwitch
                  name="LoyaltyToggle"
                  onChange={e => setIsLoyaltyPointsToggleOn && setIsLoyaltyPointsToggleOn(e.target.checked)}
                  checked={isLoyaltyPointsToggleOn}
                />
                <p className="flex items-center text-xs">{marketplaceSupplier?.LoyaltyDescription}</p>
              </div>
              <div className={`mt-1 ${dividerStyle}`} />
            </>
          ) : (
            <div className={`mb-1 mt-4 ${dividerStyle}`} />
          )}
        </div>

        {isOpen && <AddPaymentMethodPopOver togglePopup={handleClick} />}
      </div>
    </>
  );
};

const PaymentWidgetPaymentBreakdown = ({
  merchantSurcharge,
  totalPayableAmount,
  isPaymentFeeLoading,
  isPrepayment,
  paymentMethod,
  isPaymentMethodLoading,
  fees88078,
}: {
  merchantSurcharge?: MerchantSurcharge | IMerchantProcessingFee;
  totalPayableAmount?: number;
  isPaymentFeeLoading?: boolean;
  isPrepayment?: boolean;
  paymentMethod?: PaymentMethodTypes;
  isPaymentMethodLoading?: boolean;
  fees88078?: boolean;
}) => {
  const {creditStatementSummary, selectedBatchDetails, selectedPaymentMethod, widgetScope} =
    useContext(PaymentWidgetContext);

  const buyerFee = selectedPaymentMethod?.Fees?.find(f => f?.feeName === 'Buyer');
  const merchantFee = selectedPaymentMethod?.Fees?.find(f => f?.feeName === 'Merchant');

  const SurchargePercent = (merchantSurcharge as MerchantSurcharge)?.SurchargePercent || 0;
  const SurchargeAmount =
    (fees88078 ? merchantFee?.buyerFeeAmount : (merchantSurcharge as MerchantSurcharge)?.SurchargeAmount) || 0;
  const TotalAmount =
    (fees88078 ? selectedPaymentMethod?.TotalAmount : (merchantSurcharge as MerchantSurcharge)?.TotalAmount) || 0;
  const PayerFeeAmount =
    (fees88078 ? buyerFee?.buyerFeeAmount : (merchantSurcharge as IMerchantProcessingFee)?.PayerFeeAmount) || 0;
  const PayerFeeDescription = fees88078
    ? buyerFee?.buyerFeeDescription
    : (merchantSurcharge as IMerchantProcessingFee)?.PayerFeeDescription;

  const creditsAndClaimsAmount = useMemo(
    () =>
      selectedBatchDetails?.selectedTxList
        ?.filter(a => a.transactionTypeID === DatTypes.CreditNote || a.transactionTypeID === DatTypes.Claim)
        .reduce((sum, i) => sum + i.balance, 0),
    [selectedBatchDetails?.selectedTxList],
  );
  const prePaymentsAmount = useMemo(
    () =>
      selectedBatchDetails?.selectedTxList
        ?.filter(a => a.transactionTypeID === DatTypes.Payment)
        .reduce((sum, i) => sum + i.balance, 0),
    [selectedBatchDetails?.selectedTxList],
  );

  const appliedCredits = () => {
    if (widgetScope === PaymentWidgetScope.PSBL) {
      return creditsAndClaimsAmount || 0;
    } else {
      return creditStatementSummary?.CreditNotesAndClaimsTotalAmount || 0;
    }
  };

  const fundingAmount = selectedPaymentMethod?.FundingRate
    ? (TotalAmount / 100) * selectedPaymentMethod?.FundingRate
    : 0;

  return (
    <div className="px-4 py-1 font-poppins text-xs">
      <h2 className="pb-2">Payment Breakdown</h2>

      <div>
        {/* Amount To Pay */}
        <div className="flex justify-between pb-2">
          <p>{!isPrepayment ? 'Invoice' : ''} Amount To Pay</p>
          <span data-autoid="txtTotalPayableAmount">
            {PriceFormat(parseFloat((totalPayableAmount || 0).toFixed(2)))}
          </span>
        </div>

        {/* Funding Rate */}
        {Boolean(selectedPaymentMethod?.FundingRate) && (
          <div className="flex justify-between pb-2">
            <p>Funding Fee (+{selectedPaymentMethod?.FundingRate}%)</p>
            <span data-autoid="txtFundingAmount">{PriceFormat(parseFloat(fundingAmount.toFixed(2)))}</span>
          </div>
        )}

        {/* Credits Applied */}
        {appliedCredits() ? (
          <div className="flex justify-between  pb-2">
            <p>{'Credits Applied'}</p>
            <span data-autoid="txtCreditNotesAndClaimsTotalAmount">
              -{PriceFormat(parseFloat(appliedCredits().toFixed(2)))}
            </span>
          </div>
        ) : null}

        {/* Payments Applied */}
        {prePaymentsAmount && widgetScope !== PaymentWidgetScope.PREPAYMENT ? (
          <div className="flex justify-between  pb-2">
            <p>{'Payments Applied'}</p>
            <span data-autoid="txtPrepaymentsTotalAmount">
              -{PriceFormat(parseFloat(prePaymentsAmount.toFixed(2)))}
            </span>
          </div>
        ) : null}

        {/* SubTotal */}
        {creditStatementSummary?.CreditNotesAndClaimsTotalAmount ? (
          <div className="flex justify-between pb-2">
            <p>SubTotal</p>
            <span data-autoid="txtSubTotal">{PriceFormat(creditStatementSummary?.SubTotal)}</span>
          </div>
        ) : null}

        {/* Surcharge */}
        {(fees88078 ? Boolean(SurchargeAmount) && !isPaymentMethodLoading : Boolean(SurchargeAmount)) && (
          <div className="flex justify-between pb-2">
            <p>
              Surcharge{' '}
              {fees88078 ? merchantFee?.buyerFeeDescription : !!SurchargePercent ? `(${SurchargePercent}%)` : null}
            </p>
            <span data-autoid="txtSurchargeAmount">{PriceFormat(SurchargeAmount)}</span>
          </div>
        )}

        {/* Fee */}
        {(fees88078
          ? Boolean(PayerFeeAmount) && !isPaymentMethodLoading
          : Boolean(PayerFeeAmount) && !isPaymentFeeLoading) && (
          <div className="flex justify-between pb-2">
            <p data-autoid={`lblFeeDescription`}>
              {paymentMethod === PaymentMethodTypes.LendingFacility ? 'Funding Fee' : 'Processing Fee'} (
              {PayerFeeDescription})
            </p>
            <span data-autoid="txtPayerFeeAmount">
              {PriceFormat(parseFloat(toFixedWithoutRounding(PayerFeeAmount)))}
            </span>
          </div>
        )}
      </div>

      {/* Total */}
      <div className="flex justify-between font-bold" style={{color: '#3C9F78'}}>
        <p>Total Payment</p>
        {(fees88078 ? isPaymentMethodLoading : selectedBatchDetails?.loading) ? (
          <Spinner color="primary" className="h-3 w-3 text-primary/40" />
        ) : (
          <span data-autoid="txtTotalPayment">{PriceFormat(parseFloat((TotalAmount + fundingAmount).toFixed(2)))}</span>
        )}
      </div>
    </div>
  );
};

interface IPaymentWidgetPaymentFeedbackProps {
  message?: string;
}

const PaymentWidgetPaymentFeedback = (props: PropsWithChildren<IPaymentWidgetPaymentFeedbackProps>) => {
  const {step, selectedPaymentMethod, scheduledPaymentDate} = useContext(PaymentWidgetContext);

  const isBankTransfer = selectedPaymentMethod?.PaymentMethod === PaymentMethodTypes.BankTransfer;

  let label = 'Payment in progress ...';
  let color = 'inherit';
  let labelColor = '';
  let marginPadding = 'p-4 mb-4 mt-10';
  switch (step) {
    case PaymentWidgetPaymentSteps.APPROVED:
      label = scheduledPaymentDate ? 'Payment Scheduled' : 'Payment Successful';
      color = '#1C78AD';
      break;
    case PaymentWidgetPaymentSteps.DECLINED:
      label = 'Payment Declined';
      color = '#c55d44';
      break;
    case PaymentWidgetPaymentSteps.SUBMITTED:
      label = 'Payment Submitted';
      color = '#0082BA';
      break;
    case PaymentWidgetPaymentSteps.TIMEOUT:
      label = 'Timeout has occurred';
      color = '#4ea5c5';
      break;
    case PaymentWidgetPaymentSteps.SUBMITTED_TO_AUTHORISER:
      label = 'Payment Submitted';
      color = '#1C78AD';
      marginPadding = 'p-2 mt-16';
      break;
    default:
      label = 'Payment Processing';
      color = '#707070';
      labelColor = '#1C78AD';
      marginPadding = 'p-4 my-6';
      break;
  }

  return (
    <div className={`flex w-full flex-col items-center ${marginPadding}`}>
      <div style={{marginBottom: '15px'}}>
        {step === PaymentWidgetPaymentSteps.PROCESSING && (
          <div className="mt-8">
            <ProcessingAnimation width="114px" height="114px" />
          </div>
        )}
        {step === PaymentWidgetPaymentSteps.APPROVED && (
          <SuccessAnimation data_autoid="imgPaymentSuccessful" width="100px" height="75px" />
        )}
        {step === PaymentWidgetPaymentSteps.DECLINED &&
          (!isBankTransfer ? (
            <ErrorAnimation width="100px" height="75px" />
          ) : (
            <img
              data-autoid="imgFailedPayment"
              alt="FailedPayment"
              style={{width: '89px', height: '81px'}}
              src={FailedPayment}
            />
          ))}
        {step === PaymentWidgetPaymentSteps.TIMEOUT && <TimeoutAnimation width="100px" height="75px" />}
        {step === PaymentWidgetPaymentSteps.SUBMITTED && <IconPaymentSubmit width="83px" height="86px" />}
        {step === PaymentWidgetPaymentSteps.SUBMITTED_TO_AUTHORISER && <IconPaymentSubmit width="83px" height="86px" />}
      </div>
      <h1 className={`pt-1 font-poppins text-xl`} style={{color: labelColor || color}}>
        {label}
      </h1>
      <h1 className={`px-4 pt-1 font-poppins text-xs`} style={{color}}>
        {props.message}
      </h1>
      {step !== PaymentWidgetPaymentSteps.SUBMITTED && step !== PaymentWidgetPaymentSteps.SUBMITTED_TO_AUTHORISER && (
        <div className={`mt-6 ${dividerStyle}`} />
      )}
    </div>
  );
};
interface IAddPaymentMethodPopOverProps {
  bottom?: string;
  togglePopup?: () => void;
}

export const AddPaymentMethodPopOver = ({bottom, togglePopup}: IAddPaymentMethodPopOverProps) => {
  const {setStep, availablePaymentMethodTypes, airplus79131} = useContext(PaymentWidgetContext);
  const {isBT} = useConnectedSupplier();
  const popupRef = useRef() as any;

  const arrow = css({
    '> div': {
      '> span': {
        '& :after, & :before': {
          top: '100%',
          left: '50%',
          border: 'solid transparent',
          content: '""',
          height: '0',
          width: '0',
          position: 'absolute',
          pointerEvents: 'none',
        },
        '& :after': {
          borderColor: 'rgba(255, 255, 255, 0)',
          borderTopColor: '#fff',
          borderWidth: '8px',
          marginLeft: '-8px',
        },
        '& :before': {
          borderColor: 'rgba(226, 232, 240, 0)',
          borderTopColor: '#e2e8f0',
          borderWidth: '9px',
          marginLeft: '-9px',
        },
      },
    },
  });

  const paymentOptionIPopup = css({
    height: '125px',
    width: '293.41px',
  });

  const disablePaymentOptionIconbgCss = css({
    opacity: '50%',
    cursor: 'default',
  });

  const isCreditCardAccepted = availablePaymentMethodTypes?.some(a => a.serviceType === PaymentServiceType.CREDIT_CARD);
  const isBankPaymentAccepted = availablePaymentMethodTypes?.some(
    a => a.serviceType === PaymentServiceType.BANK_ACCOUNT,
  );
  const isAirPlusSupported = availablePaymentMethodTypes?.find(a => a.paymentMethod === PaymentMethodTypes.Airplus);

  const paymentOptionIconbgCss = css({
    backgroundColor: '#FAFAFA',
    borderRadius: ' 6px',
    borderColor: '#EAEAEA',
    width: '122px',
    height: '86px',
    fontSize: '14px',
    borderWidth: '1.4px',
    cursor: 'pointer',
  });
  const onAddBankTransfer = async () => {
    //check BT available
    if (!isBT) return;

    if (setStep) setStep(PaymentWidgetPaymentSteps.ADD_BANK_ACCOUNT);
  };

  const onAddCreditCard = () => {
    if (setStep) setStep(PaymentWidgetPaymentSteps.ADD_CREDIT_CARD);
  };

  const onAddAirPlus = () => {
    if (setStep) setStep(PaymentWidgetPaymentSteps.ADD_AIRPLUS);
  };

  // Remove this once we remove the schedule payments feature flag (because at that time, we won't be using the bigger 'Add Payment Options' button).
  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (popupRef.current && !popupRef.current.contains(e.target)) {
        togglePopup && togglePopup();
      }
    };

    addEventListener('mousedown', handleClickOutside);
    return () => {
      removeEventListener('mousedown', handleClickOutside);
    };
  }, [popupRef, togglePopup]);

  return (
    <div
      ref={popupRef}
      className={`${arrow} absolute flex w-4/5 flex-col justify-center rounded-lg border-default bg-white shadow-lg ${paymentOptionIPopup} z-50`}
      style={{bottom: bottom || '9.25rem', left: '-1.2rem', transform: 'translateX(35px'}}
    >
      {' '}
      <p className="pt-3 font-poppins text-xs font-medium">These are the payment methods your Supplier supports:</p>
      {!airplus79131 ? (
        <div className={`flex items-start justify-center pb-4 pt-2`}>
          <div
            className={`mx-2 ${paymentOptionIconbgCss} ${!isBankPaymentAccepted && disablePaymentOptionIconbgCss} `}
            onClick={() => isBankPaymentAccepted && onAddBankTransfer()}
          >
            <span data-autoid="btnAddABankAccount" className={`m-2 flex flex-col items-center pt-2 font-poppins`}>
              <IconBankWithoutOutline width="32.86px" height="32.86px" />
              <p className={`pt-1 ${isBankPaymentAccepted && 'hover:underline'}`}>Bank Account</p>
            </span>
          </div>
          <div
            className={`mx-2 ${paymentOptionIconbgCss} ${!isCreditCardAccepted && disablePaymentOptionIconbgCss}`}
            onClick={() => isCreditCardAccepted && onAddCreditCard()}
          >
            <span data-autoid="btnAddCreditCard" className="m-2 flex flex-col items-center pt-2 font-poppins">
              <IconCreditCard width="38.05px" height="32.25px" />
              <p className={`pt-1 ${isCreditCardAccepted && 'hover:underline'}`}>Credit Card</p>
            </span>
          </div>
        </div>
      ) : (
        <div className={`flex items-start justify-center py-3`}>
          <div
            className={clsx(
              'mx-2 h-[70px] w-[82px] cursor-pointer rounded-md border-[1.4px] border-spenda-scream bg-spenda-cream px-4 py-[7px] text-xs',
              {
                'cursor-pointer opacity-50': !isBankPaymentAccepted,
              },
            )}
            onClick={() => isBankPaymentAccepted && onAddBankTransfer()}
          >
            <span
              aria-disabled={!isBankPaymentAccepted}
              data-autoid="btnAddABankAccount"
              className={`flex flex-col items-center font-poppins`}
            >
              <IconBankWithoutOutline width="24px" height="24px" />
              <p className={`pt-1 ${isBankPaymentAccepted && 'hover:underline'}`}>Bank Account</p>
            </span>
          </div>
          <div
            className={clsx(
              'mx-2 h-[70px] w-[82px] cursor-pointer rounded-md border-[1.4px] border-spenda-scream bg-spenda-cream px-4 py-[7px] text-xs',
              {
                'cursor-pointer opacity-50': !isCreditCardAccepted,
              },
            )}
            onClick={() => isCreditCardAccepted && onAddCreditCard()}
          >
            <span
              aria-disabled={!isCreditCardAccepted}
              data-autoid="btnAddCreditCard"
              className="flex flex-col items-center font-poppins"
            >
              <IconCreditCard width="24px" height="24px" />
              <p className={`pt-1 ${isCreditCardAccepted && 'hover:underline'}`}>Credit Card</p>
            </span>
          </div>
          <div
            className={clsx(
              'mx-2 h-[70px] w-[82px] cursor-pointer rounded-md border-[1.4px] border-spenda-scream bg-spenda-cream px-4 py-[13px] text-xs',
              {
                'cursor-pointer opacity-50': !isAirPlusSupported,
              },
            )}
            onClick={() => isAirPlusSupported && onAddAirPlus()}
          >
            <span
              aria-disabled={!isAirPlusSupported}
              data-autoid="btnAddAirPlus"
              className="flex flex-col items-center font-poppins"
            >
              <img src={AirPlusThumbnailIcon} alt="AirPlus" className="h-[22px] w-[39px]" />
              <p className={`pt-[9px] ${isAirPlusSupported && 'hover:underline'}`}>AirPlus</p>
            </span>
          </div>
        </div>
      )}
    </div>
  );
};
