import React, {useEffect, useState} from 'react';
import {Box, Divider, Grid, makeStyles} from '@material-ui/core';
import clsx from 'clsx';
import {Radio, Tooltip} from 'spenda-ui-react';
import {FormikProps} from 'formik';

import {IconEditGray} from '../../assets/svg/IconEditGray';
import {useFeatureFlags} from '../../hooks/useFeatureFlags';
import {AccountUsage, PaymentProviderName} from '../../model/constants/Constants';
import {ICustomer} from '../../model/customer/Customer';
import {IAddCustomerClassesFormValues} from '../../screens/customers/AddCustomerClasses';
import {useCustomersAPI} from '../../services/useCustomersAPI';
import usePaymentServicesAPI from '../../services/usePaymentServicesAPI';
import {SelectEmailTemplateDialog, SelectEmailTemplateScope} from '../AccountsReceivable/SelectEmailTemplateDialog';
import {AccountCustomerAutoComplete} from '../accountCustomerDropdown/AccountCustomerAutoComplete';
import {SButton} from '../buttons/Button';
import {ConfigureSettingsDialog} from '../dialog/ConfigureSettingsDialog';
import {SCheckbox} from '../inputs/SCheckbox';
import {STextField} from '../inputs/STextField';
import {ConnectAccount} from '../paymentOnboarding/ConnectAccount';

const useCustomerClassStyle = makeStyles({
  formLabel: {
    fontSize: '1rem',
    color: '#333333',
    paddingBottom: '1.5rem',
  },
  title: {
    fontWeight: 500,
    fontSize: '1.5em',
    color: '#4D4D4D',
  },
  subTitle: {
    fontWeight: 400,
    fontSize: '1.25em',
    marginTop: '20px',
  },
  TradingTermTypeDropdown: {
    borderBottom: '1px solid black',
    outline: 'none',
  },
  postingBehaviourTitle: {
    fontWeight: 500,
    fontSize: '1.5em',
    color: '#4D4D4D',
    paddingLeft: '1.5rem',
  },
  postingBehaviourSubTitle: {
    fontSize: '1rem',
    color: '#333333',
    fontWeight: 500,
    paddingLeft: '1.5rem',
    paddingBottom: '1.5rem',
  },
  postTransactionInputs: {
    marginLeft: '1.5rem',
  },
  postingBehaviourInputs: {
    marginLeft: '1.5rem',
  },
});

export const AddCustomerClass = (
  props: FormikProps<IAddCustomerClassesFormValues> & {classId: string | undefined} & {
    postingBehaviour?: string;
  },
) => {
  const classes = useCustomerClassStyle();
  const {featLoyaltyPoints, statementsAndAutomatedStatement83193} = useFeatureFlags().tenantOwned();

  const {values, touched, errors, handleChange, setValues, setFieldValue, handleBlur} = props;
  const [accountCustomerValue, setAccountCustomerValue] = useState<any>();
  const [isShowPaymentServicesDialog, setIsShowPaymentServicesDialog] = useState(false);
  const [isShowConfigureSettingsDialog, setIsShowConfigureSettingsDialog] = useState(false);
  const [isPSBLPIBLFlagEnable, setIsPSBLPIBLFlagEnable] = useState(false);
  const [isShowSelectEmailTemplateDialog, setIsShowSelectEmailTemplateDialog] = useState(false);

  const {getCustomerById} = useCustomersAPI();
  const {checkPsblPiblFlag} = usePaymentServicesAPI();

  useEffect(() => {
    if (props.classId) {
      getCustomerById(parseInt(values?.GenericAccountCustomerID || '0')).then((res: ICustomer) => {
        setAccountCustomerValue(res || '');
      });
    }
  }, [values?.GenericAccountCustomerID]);

  const checkIsPSBLPIBLFlagEnable = async () => {
    try {
      const response = await checkPsblPiblFlag(PaymentProviderName.Spenda_Payment_Services, AccountUsage.SUPPLIER);
      if (response?.psbL_PIBLCheckFlag) {
        setIsPSBLPIBLFlagEnable(true);
      }
    } catch {}
  };

  useEffect(() => {
    checkIsPSBLPIBLFlagEnable();
  }, []);

  const clearPostingBehavior: ChangeEventHandler = event => {
    const behavior = event?.target.value;
    handleChange(event);

    if (behavior === 'None') {
      setAccountCustomerValue(null);
      setFieldValue('GenericAccountCustomerID', '');
      setFieldValue('TradingTermDays', undefined);
      setFieldValue('CreditLimit', undefined);
      setFieldValue('TradingTermType', '');
    } else if (behavior === 'Generic') {
      setFieldValue('TradingTermDays', undefined);
      setFieldValue('CreditLimit', undefined);
      setFieldValue('TradingTermType', '');
    } else if (behavior === 'Account') {
      setAccountCustomerValue(null);
      setFieldValue('GenericAccountCustomerID', '');
    }
  };

  const handleAutoPayClick = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.checked) {
      if (isPSBLPIBLFlagEnable) {
        handleChange(e);
      } else {
        setIsShowConfigureSettingsDialog(true);
      }
    } else {
      handleChange(e);
    }
  };

  return (
    <>
      {isShowPaymentServicesDialog && (
        <ConnectAccount onComplete={() => checkIsPSBLPIBLFlagEnable()} handleClose={setIsShowPaymentServicesDialog} />
      )}
      {isShowConfigureSettingsDialog && (
        <ConfigureSettingsDialog
          open={isShowConfigureSettingsDialog}
          onClose={() => setIsShowConfigureSettingsDialog(false)}
          onButtonClick={() => {
            setIsShowConfigureSettingsDialog(false);
            setIsShowPaymentServicesDialog(true);
          }}
        />
      )}
      <Box
        display="flex"
        flex="1"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="start"
        padding="0 20px 0 10px"
      >
        <Grid container spacing={1} className="justify-start py-5">
          <Grid item sm={9}>
            <Box display="flex" flexDirection="column" marginY="10px">
              <Grid container spacing={1}>
                <Grid item sm={2} className="flex">
                  <p className={`${classes.formLabel}`}>Class Name:</p>
                </Grid>
                <Grid item sm={3}>
                  <STextField
                    key="Name"
                    id="Name"
                    name="Name"
                    placeholder="Class Name"
                    value={values.Name || ''}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    helperText={touched?.Name && errors?.Name}
                    error={touched?.Name && Boolean(errors?.Name)}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1} alignItems="flex-end">
                <Grid item sm={2} className="flex">
                  <p className={`${classes.formLabel}`}>Description:</p>
                </Grid>
                <Grid item sm={9}>
                  <STextField
                    key="Description"
                    id="Description"
                    name="Description"
                    placeholder="Description"
                    value={values.Description || ''}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    helperText={touched?.Description && errors?.Description}
                    error={touched?.Description && Boolean(errors?.Description)}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1} alignItems="flex-end">
                <Grid item sm={2} className="flex">
                  <p className={`${classes.formLabel}`}>Acc Cus Ref Prefix:</p>
                </Grid>
                <Grid item sm={3}>
                  <STextField
                    key="AccountCustomerRefNumberPrefix"
                    id="AccountCustomerRefNumberPrefix"
                    name="AccountCustomerRefNumberPrefix"
                    placeholder="Acc Cus Ref Prefix"
                    value={values.AccountCustomerRefNumberPrefix || ''}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    fullWidth
                    helperText={touched?.AccountCustomerRefNumberPrefix && errors?.AccountCustomerRefNumberPrefix}
                    error={touched?.AccountCustomerRefNumberPrefix && Boolean(errors?.AccountCustomerRefNumberPrefix)}
                  />
                </Grid>
                <Grid item sm={1} />
                <Grid item sm={2} className="flex">
                  <p className={`${classes.formLabel}`}>Invoice Prefix:</p>
                </Grid>
                <Grid item sm={3}>
                  <STextField
                    key="BatchInvoicePrefix"
                    id="BatchInvoicePrefix"
                    name="BatchInvoicePrefix"
                    placeholder="Invoice Prefix"
                    value={values.BatchInvoicePrefix || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    helperText={touched?.BatchInvoicePrefix && errors?.BatchInvoicePrefix}
                    error={touched?.BatchInvoicePrefix && Boolean(errors?.BatchInvoicePrefix)}
                  />
                </Grid>
              </Grid>
              <Divider
                className="w-full"
                style={{height: '2px !important', backgroundColor: '#F1F1F1', margin: '20px 0px'}}
              />
              <p className={`${classes.title}`}>Debtor Posting Settings</p>
              <Grid container spacing={1}>
                <Grid item sm={12} className="flex">
                  <p className={`${classes.subTitle}`}>
                    Indicate if you want new customers in this class to be debtors in your account.
                  </p>
                </Grid>
                <div className="-ml-2.5 flex flex-col">
                  <Radio
                    name="PostingBehaviour"
                    value={'None'}
                    label="Do nothing- let me determine posting behavior of all new customers"
                    className="border-primary before:hover:opacity-0"
                    color="primary"
                    disabled={Boolean(props.classId) && props?.postingBehaviour !== 'None'}
                    onChange={clearPostingBehavior}
                    defaultChecked={values.PostingBehaviour === 'None'}
                  />
                  <Radio
                    name="PostingBehaviour"
                    value={'Generic'}
                    color="primary"
                    label="Post transaction to this generic debtor account"
                    className="border-primary before:hover:opacity-0"
                    disabled={Boolean(props.classId) && props?.postingBehaviour !== 'None'}
                    onChange={clearPostingBehavior}
                    defaultChecked={values.PostingBehaviour === 'Generic'}
                  />
                  {values.PostingBehaviour === 'Generic' && (
                    <div>
                      <p className={'ml-6 text-xs font-medium text-primary'}>SEARCH GENERIC DEBTOR(ACCOUNT CUSTOMER)</p>
                      <Grid className={`${classes.postTransactionInputs}`} item sm={3}>
                        <AccountCustomerAutoComplete
                          selected={accountCustomerValue ? accountCustomerValue : ''}
                          onSuggestionSelected={(e, selected) => {
                            setAccountCustomerValue(selected?.suggestion);
                            setFieldValue('GenericAccountCustomerID', selected?.suggestion?.ID);
                          }}
                        />
                      </Grid>
                    </div>
                  )}

                  <Radio
                    name="PostingBehaviour"
                    value="Account"
                    label="Post as debtor (this will create a new customer/debtor account in your accounts for each new customer in this class)"
                    className="border-primary before:hover:opacity-0"
                    color="primary"
                    disabled={Boolean(props.classId) && props?.postingBehaviour !== 'None'}
                    onChange={clearPostingBehavior}
                    defaultChecked={values.PostingBehaviour === 'Account'}
                  />
                </div>
                {values.PostingBehaviour === 'Account' && (
                  <>
                    <Grid container>
                      <div>
                        <p className={`${classes.postingBehaviourTitle}`}>Default Customer Settings</p>
                        <p className={`${classes.postingBehaviourSubTitle}`}>
                          Customers assigned to this class inherit the following default values:
                        </p>
                      </div>
                      <Grid className={`${classes.postingBehaviourInputs}`} container spacing={1}>
                        <div>
                          <p className={'text-xs font-medium text-primary'}>DUE DAYS</p>
                          <Grid item sm={9} className="ml-6 flex">
                            <STextField
                              key="TradingTermDays"
                              id="TradingTermDays"
                              disabled={
                                values.TradingTermType === 'PrePaid' || values.TradingTermType === 'Cash_On_Delivery'
                              }
                              placeholder="0"
                              name="TradingTermDays"
                              type="number"
                              value={values.TradingTermDays || ''}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              fullWidth
                              helperText={touched?.TradingTermDays && errors?.TradingTermDays}
                              error={touched?.TradingTermDays && Boolean(errors?.TradingTermDays)}
                            />
                          </Grid>
                        </div>
                        <div>
                          <p className={'text-xs font-medium text-primary'}>TRADING TERM TYPE</p>
                          <Grid item sm={11} className="ml-6 flex">
                            <select
                              className={`${classes.TradingTermTypeDropdown}`}
                              key="TradingTermType"
                              name="TradingTermType"
                              id="TradingTermType"
                              data-autoid={`selectTradingTermType`}
                              onChange={e => {
                                setValues({
                                  ...values,
                                  TradingTermType: e.target.value,
                                  TradingTermID: parseInt(e.target.id),
                                  TradingTermDays:
                                    e.target.value === 'PrePaid' || e.target.value === 'Cash_On_Delivery'
                                      ? 0
                                      : values?.TradingTermDays
                                        ? values?.TradingTermDays
                                        : 0,
                                  CreditLimit:
                                    e.target.value === 'PrePaid' || e.target.value === 'Cash_On_Delivery'
                                      ? 0
                                      : values?.CreditLimit
                                        ? values?.CreditLimit
                                        : 0,
                                });
                              }}
                              value={values.TradingTermType || ''}
                            >
                              <option data-autoid={`optionTradingTermType-0`}>Select Type</option>
                              <option value="PrePaid" id="1" data-autoid={`optionTradingTermType-1`}>
                                Prepaid
                              </option>
                              <option value="Cash_On_Delivery" id="2" data-autoid={`optionTradingTermType-2`}>
                                Cash On Delivery
                              </option>
                              <option value="Days_After_Invoice_Date" id="3" data-autoid={`optionTradingTermType-3`}>
                                Days After Invoice Date
                              </option>
                              <option value="Day_Of_current_Month" id="4" data-autoid={`optionTradingTermType-4`}>
                                Day Of current Month
                              </option>
                              <option value="Day_Of_Following_Month" id="5" data-autoid={`optionTradingTermType-5`}>
                                Day Of Following Month
                              </option>
                              <option value="Days_After_Current_Month" id="6" data-autoid={`optionTradingTermType-6`}>
                                Days After Current Month
                              </option>
                            </select>
                          </Grid>
                          {touched.TradingTermType && Boolean(errors.TradingTermType) && (
                            <p style={{color: 'red'}}>{touched.TradingTermType && errors.TradingTermType}</p>
                          )}
                        </div>
                        <Grid item sm={1} className="flex" />
                        <div>
                          <p className={'text-xs font-medium text-primary'}>CREDIT LIMIT</p>
                          <Grid item sm={9} className="ml-6 flex">
                            <STextField
                              key="CreditLimit"
                              id="CreditLimit"
                              type="number"
                              placeholder="0.00"
                              name="CreditLimit"
                              disabled={
                                values.TradingTermType === 'PrePaid' || values.TradingTermType === 'Cash_On_Delivery'
                              }
                              value={values.CreditLimit || ''}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              fullWidth
                              helperText={touched?.CreditLimit && errors?.CreditLimit}
                              error={touched?.CreditLimit && Boolean(errors?.CreditLimit)}
                            />
                          </Grid>
                        </div>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
              {touched.PostingBehaviour && Boolean(errors.PostingBehaviour) && (
                <p style={{color: 'red'}}>{touched.PostingBehaviour && errors.PostingBehaviour}</p>
              )}
              {featLoyaltyPoints ? (
                <>
                  <Divider
                    className="w-full"
                    style={{height: '2px !important', backgroundColor: '#F1F1F1', margin: '20px 0px'}}
                  />
                  <p className={`${classes.title}`}>Claim Requests</p>
                  <Grid container spacing={1} className="py-2">
                    <SCheckbox
                      id="IsAutoApprovedforClaimRequests"
                      key="IsAutoApprovedforClaimRequests"
                      name="IsAutoApprovedforClaimRequests"
                      textColor="#333333"
                      label="Automatically approve Claim Requests and generate Credit Notes"
                      onChange={handleChange}
                      checked={values.IsAutoApprovedforClaimRequests}
                    />
                  </Grid>
                  <Divider
                    className="w-full"
                    style={{height: '2px !important', backgroundColor: '#F1F1F1', margin: '20px 0px'}}
                  />
                  <p className={`${classes.title}`}>Loyalty Flag</p>
                  <Grid container spacing={1} className="py-2">
                    <SCheckbox
                      id="IsLoyaltyEligible"
                      key="IsLoyaltyEligible"
                      name="IsLoyaltyEligible"
                      textColor="#333333"
                      label="Flag payment transactions to earn loyalty points for loyalty report export"
                      onChange={e => {
                        setValues({
                          ...values,
                          IsLoyaltyEligible: e.target.checked,
                          ...(!e.target.checked ? {LoyaltyDescription: ''} : {}),
                        });
                      }}
                      checked={values.IsLoyaltyEligible}
                    />
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item sm={2} className="flex">
                      <p className={clsx(classes.formLabel, 'pt-2')}>Loyalty Description:</p>
                    </Grid>
                    <Grid item sm={9}>
                      <STextField
                        key="LoyaltyDescription"
                        id="LoyaltyDescription"
                        name="LoyaltyDescription"
                        placeholder="Loyalty Description"
                        value={values.LoyaltyDescription || ''}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        disabled={!values.IsLoyaltyEligible}
                        helperText={touched?.LoyaltyDescription && errors?.LoyaltyDescription}
                        error={touched?.LoyaltyDescription && Boolean(errors?.LoyaltyDescription)}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </>
              ) : null}
              {values.PostingBehaviour === 'Account' && (
                <>
                  <Divider
                    className="w-full"
                    style={{height: '2px !important', backgroundColor: '#F1F1F1', margin: '20px 0px'}}
                  />
                  <p className={`${classes.title}`}>Billing Settings</p>
                  <Grid container spacing={1}>
                    <SCheckbox
                      key="IsBatchInvoice"
                      id="IsBatchInvoice"
                      name="IsBatchInvoice"
                      textColor="#333333"
                      label="Batch Invoicing (If enabled, all customers in this class are auto set to batch invoicing mode)"
                      onChange={handleChange}
                      checked={values.IsBatchInvoice}
                      onBlur={handleBlur}
                    />
                    <SCheckbox
                      key="IsAutoSendPayByLinkInvoice"
                      id="IsAutoSendPayByLinkInvoice"
                      name="IsAutoSendPayByLinkInvoice"
                      textColor="#333333"
                      label="Auto Send Pay Invoice By Link"
                      onChange={e => handleAutoPayClick(e)}
                      checked={values.IsAutoSendPayByLinkInvoice}
                    />
                  </Grid>
                </>
              )}
              <Divider
                className="w-full"
                style={{height: '2px !important', backgroundColor: '#F1F1F1', margin: '20px 0px'}}
              />
              <p className={`${classes.title}`}>Sync Settings</p>
              <Grid container spacing={1}>
                <Grid item sm={12} className="flex">
                  <p className={`${classes.subTitle}`}>
                    Indicate where you want these customer records to be synced to
                  </p>
                </Grid>
                <SCheckbox
                  id="IsApprovedForWeb"
                  key="IsApprovedForWeb"
                  name="IsApprovedForWeb"
                  textColor="#333333"
                  label="Sold in Web"
                  onChange={handleChange}
                  checked={values.IsApprovedForWeb}
                />
                <SCheckbox
                  id="IsApprovedForPOS"
                  key="IsApprovedForPOS"
                  name="IsApprovedForPOS"
                  textColor="#333333"
                  label="Sold in POS"
                  onChange={handleChange}
                  checked={values.IsApprovedForPOS}
                />
                <SCheckbox
                  id="IsApprovedForService"
                  key="IsApprovedForService"
                  name="IsApprovedForService"
                  textColor="#333333"
                  label="Sold in Service"
                  onChange={handleChange}
                  checked={values.IsApprovedForService}
                />
              </Grid>
              {statementsAndAutomatedStatement83193 && (
                <>
                  <Divider
                    className="w-full"
                    style={{height: '2px !important', backgroundColor: '#F1F1F1', margin: '20px 0px'}}
                  />
                  <p className={`${classes.title}`}>Automated Statement Delivery</p>
                  <Grid container spacing={1} className="!mb-5">
                    <SCheckbox
                      id="isSendAutomatedStatementEmail"
                      key="isSendAutomatedStatementEmail"
                      name="isSendAutomatedStatementEmail"
                      textColor="#333333"
                      data-autoid="chkIsSendAutomatedStatementEmail"
                      label="Send a statement email every month"
                      checked={values?.isSendAutomatedStatementEmail}
                      onChange={e => {
                        handleChange(e);
                        if (!e?.target?.checked) {
                          setValues(prev => ({
                            ...prev,
                            isSendAutomatedStatementEmail: false,
                            alertID: undefined,
                            alertName: '',
                            automatedScheduledDate: 1,
                          }));
                        }
                      }}
                    />
                    {values?.isSendAutomatedStatementEmail && (
                      <Grid container spacing={1} alignItems="flex-end" className="!mb-5 !mt-2 flex !px-0">
                        <Grid item sm={4} className="flex">
                          <Tooltip
                            placement="bottom"
                            offset="0"
                            className="rounded-[4px] bg-white px-3 py-2 text-spenda-primarytext shadow-md"
                            content={
                              <p>
                                If the selected day does not exist in the month,
                                <br /> the email will be sent on the last day of the month.
                                <br /> EG: 30th Feb will be sent on 28th Feb
                              </p>
                            }
                          >
                            <div className="flex w-[240px] flex-row items-center justify-center">
                              <div className="flex w-full flex-col">
                                <select
                                  key="automatedScheduledDate"
                                  name="automatedScheduledDate"
                                  id="automatedScheduledDate"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  disabled={!values.isSendAutomatedStatementEmail}
                                  value={values.automatedScheduledDate}
                                  className={`${classes.TradingTermTypeDropdown}`}
                                >
                                  {Array.from({length: 31}, (_, i) => i + 1)?.map(value => (
                                    <option key={value} value={value.toString()} id={value.toString()}>
                                      {value}
                                    </option>
                                  ))}
                                  <option value="End_Of_Month" id="0">
                                    End of month
                                  </option>
                                </select>
                                {touched.automatedScheduledDate && Boolean(errors.automatedScheduledDate) && (
                                  <p style={{color: 'red'}} className="absolute whitespace-nowrap pl-1 pt-7">
                                    {touched.automatedScheduledDate && errors.automatedScheduledDate}
                                  </p>
                                )}
                              </div>
                            </div>
                          </Tooltip>
                        </Grid>
                        <Grid item sm={2} />
                        <Grid item sm={4} className="flex">
                          {values?.alertID ? (
                            <button
                              color="transparent"
                              type="button"
                              data-autoid="btnAutomatedScheduledDateSendtemplate"
                              className="my-2 flex"
                              onClick={() => setIsShowSelectEmailTemplateDialog(true)}
                            >
                              {values?.alertName}&nbsp;&nbsp;
                              <IconEditGray />
                            </button>
                          ) : (
                            <div className="relative flex flex-col">
                              <SButton
                                margin="0 0.25rem"
                                color="blueShade"
                                textColor="white"
                                lineHeight="m"
                                width="m"
                                type="button"
                                data-autoid="btnAutomatedScheduledDateSendtemplate"
                                onClick={() => setIsShowSelectEmailTemplateDialog(true)}
                              >
                                Select Template
                              </SButton>
                              {touched.alertID && Boolean(errors.alertID) && (
                                <p style={{color: 'red'}} className="whitespace-nowrap pl-1 pt-7">
                                  {touched.alertID && errors.alertID}
                                </p>
                              )}
                            </div>
                          )}
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </>
              )}
            </Box>
          </Grid>
        </Grid>
        {isShowSelectEmailTemplateDialog && statementsAndAutomatedStatement83193 && (
          <SelectEmailTemplateDialog
            isShowModal={isShowSelectEmailTemplateDialog}
            onClose={() => setIsShowSelectEmailTemplateDialog(false)}
            scope={SelectEmailTemplateScope.SelectTemplate}
            onConfirm={selectedTemplate => {
              setFieldValue('alertID', selectedTemplate?.alertID);
              setFieldValue('alertName', selectedTemplate?.name);
              setIsShowSelectEmailTemplateDialog(false);
            }}
          />
        )}
      </Box>
    </>
  );
};
