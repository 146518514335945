import {IActionResults} from '../model/ActionResults';
import useHttp from '../hooks/useHttp';
import {IMenuModule} from '../model/menu/Menu';

const useHomePageAPI = () => {
  const {GET} = useHttp();

  const GetPublishedModules = (): Promise<IMenuModule[]> => {
    return GET('tenant/GetPublishedModules').then((data: IActionResults<IMenuModule[]>) => data.Value);
  };

  const GetModules = (): Promise<IMenuModule[]> => {
    return GET('Spenda/Tenant/Modules/Menu/GetModules ').then((data: IMenuModule[]) => data);
  };

  return {
    GetPublishedModules,
    GetModules,
  };
};

export default useHomePageAPI;
