import React, {useContext} from 'react'
import {useState} from 'react';
import {SButton} from '../../../components/buttons/Button';
import {CreditCardForm} from '../../../components/form/CreditCardForm';
import {usePaymentUtilities} from '../../../hooks/usePaymentUtilities';
import {CustomCreditCardProps} from '../../../components/dialog/PaymentMethodsDialog';
import {PaymentWidgetContext, PaymentWidgetPaymentSteps, PaymentWidgetStep} from './PaymentWidget';
import {INewCreditCardAccountResponse, UpdateAccountRequest} from '../../../services/useSpendaPaymentServicesAPI';
import {ICreditCardT} from '../../../model/payment/PaymentMethod';
import {SDialog} from '../../../components/modals/modalSpendaMeterialUI';
import {DialogContent, DialogTitle, DialogActions} from '@material-ui/core';
import {PaymentServiceType, PaymentWidgetScope, VerificationStatus} from '../../../model/constants/Constants';
import {PaymentConverter} from '../../../utils/data-converters/payment-converter';
import {css} from 'glamor';
import {Button} from 'spenda-ui-react';

const heading = css({
  '& .MuiTypography-root': {
    fontFamily: 'poppins',
  },
});

export const PaymentWidgetStepAddCreditCard = () => {
  const {
    cardToEdit,
    temporalCreditCards,
    isLinkedToLendingProvider,
    isBPSP: isBPSPSupplier,
    widgetScope,
    fetchSPSAccount,
    onCancel: onCancelContext,
    removeCreditCard,
    saveCreditCard,
    setStep,
    setCardToEdit,
    setIsRefreshPaymentMethods,
    setTemporalCreditCards,
    setBpspAccountDetails,
    updateAccountDetails,
    updatePaymentMethodDetails,
    availablePaymentMethodTypes,
    paymentAuth72488,
    onDone,
    selectedBatchDetails,
    setBatchPaymentGuid,
  } = useContext(PaymentWidgetContext);

  const {getPaymentMethodFromIssuer} = usePaymentUtilities();


  const [cardTypeToSave, setCardTypeToSave] = useState<string | undefined>();
  const [savingCard, setSavingCard] = useState<boolean>(false);
  const [saveCardForFuture, setSaveCardForFuture] = useState<boolean>(true);
  const [isShowDeleteConfirmation, setIsShowDeleteConfirmation] = useState<boolean>(false);

  const onToggleRemoveCreditCardConfirmation = () => {
    if (setCardToEdit) setCardToEdit(cardToEdit);
    setIsShowDeleteConfirmation(true);
  };

  const availableCreditCards = availablePaymentMethodTypes?.map(a =>
    a?.serviceType === PaymentServiceType.CREDIT_CARD ? a.paymentMethod : [],
  );

  const onRemoveCreditCard = async () => {
    if (!removeCreditCard || !cardToEdit) return;
    const isDeleted = await removeCreditCard(cardToEdit);

    if (isDeleted || cardToEdit.PaymentAccountGUID) {
      if (widgetScope === PaymentWidgetScope.ADD_CREDIT_CARD) {
        if (onCancelContext) onCancelContext();
      }

      if (setIsRefreshPaymentMethods) setIsRefreshPaymentMethods(true);
      if (setCardToEdit) setCardToEdit(undefined);
      setIsShowDeleteConfirmation(false);
      if (setStep) setStep(PaymentWidgetPaymentSteps.NEW);
    }
  };

  const toggleSaveCardForFuture = () => {
    setSaveCardForFuture(saveCardForFuture => !saveCardForFuture);
  };

  const onCancel = () => {
    if (widgetScope === PaymentWidgetScope.ADD_CREDIT_CARD) {
      if (onCancelContext) onCancelContext();
    }

    if (setCardToEdit) setCardToEdit(undefined);

    if (setStep) setStep(PaymentWidgetPaymentSteps.NEW);
  };

  function containsAccountGUID(object: any): object is INewCreditCardAccountResponse {
    return 'accountGUID' in object;
  }

  const onSave = async (creditCard: CustomCreditCardProps) => {
    if (saveCreditCard && cardTypeToSave && creditCard) {
      setSavingCard(true);

      const creditCardT: ICreditCardT = {
        CardType: cardTypeToSave,
        CardNumber: creditCard.number?.toString(),
        CardHolderName: creditCard.name,
        ExpiryMMYY: creditCard.expiry?.toString(),
        CardVerificationNumber: creditCard.cvc?.toString(),
        FriendlyName: creditCard.fname,
        IsInvigoDefault: creditCard.isInvigoDefault,
        IsSingleUse: !saveCardForFuture,
        PaymentAccountGUID: undefined,
      };

      const res = await saveCreditCard(creditCardT);

      // Check if there's accountGUID cause that means is a BPSP card that needs verification
      if (res && containsAccountGUID(res) && !res.error && fetchSPSAccount) {
        const bpspAccount = await fetchSPSAccount(res.accountGUID);

        if (!saveCardForFuture && setTemporalCreditCards) {
          creditCardT.PaymentAccountGUID = res.accountGUID;
          setTemporalCreditCards([creditCardT, ...(temporalCreditCards || [])]);
        }
        // Response of type: INewCreditCardAccountResponse, move on to verification of BPSP
        if (setStep) {
          if (creditCardT.IsSingleUse && setIsRefreshPaymentMethods) {
            setIsRefreshPaymentMethods(true);
            setStep(PaymentWidgetPaymentSteps.NEW);
          } else {
            setBpspAccountDetails && setBpspAccountDetails(bpspAccount);
            if (bpspAccount.verificationStatus === VerificationStatus.VERIFIED) {
              const transformedCard = PaymentConverter.SpsToFiserveModel(bpspAccount);
              setCardToEdit && setCardToEdit(transformedCard);
              if (paymentAuth72488 && !isLinkedToLendingProvider) {
                setStep(PaymentWidgetPaymentSteps.SET_PAYMENT_AUTHORISATION_RULES);
              }
              //TODO: Will remove the else condition, when we intoduce authorisation rules into the prepayments
              else {
                if (widgetScope === PaymentWidgetScope.PSBL) {
                  await setBatchPaymentGuid?.({
                    paymentAccountGUID: bpspAccount?.accountGUID!,
                    invoicePaymentAllocationID: selectedBatchDetails?.invoicePaymentAllocationID!,
                  });
                }
                if (setIsRefreshPaymentMethods) setIsRefreshPaymentMethods(true);

                if (isLinkedToLendingProvider) {
                  setStep(PaymentWidgetPaymentSteps.CONFIGURE_PAYMENT_OPTIONS);
                } else if (widgetScope === PaymentWidgetScope.ADD_CREDIT_CARD) {
                  onDone && onDone();
                } else {
                  setStep && setStep(PaymentWidgetPaymentSteps.NEW);
                }
              }
            } else {
              setStep(PaymentWidgetPaymentSteps.VERIFY_CREDIT_CARD);
            }
          }
        }
        return;
      }

      setSavingCard(false);
      setTimeout(() => {
        if (widgetScope === PaymentWidgetScope.ADD_CREDIT_CARD) {
          if (onCancelContext) onCancelContext();
        }

        if (setStep) {
          if (setIsRefreshPaymentMethods) setIsRefreshPaymentMethods(true);
          setStep(PaymentWidgetPaymentSteps.NEW);
        }
      }, 500);
    } else {
      setSavingCard(true);
      updateDetails(creditCard);
      setSavingCard(false);
    }
  };

  const updateDetails = (creditCard: CustomCreditCardProps) => {
    let updateReq: UpdateAccountRequest = {
      friendlyName: creditCard.fname,
      isInvigoDefault: creditCard.isInvigoDefault!,
    };

    //Alex: SPS CC & SPS BT
    if (cardToEdit?.PaymentAccountGUID) {
      if (updateAccountDetails) {
        updateAccountDetails(cardToEdit.PaymentAccountGUID, updateReq);
      }
    } else {
      //Alex: fiserv one
      if (cardToEdit?.SupplierPaymentOptionID && updatePaymentMethodDetails) {
        updatePaymentMethodDetails(cardToEdit.SupplierPaymentOptionID.toString(), updateReq);
      }
    }
    setTimeout(() => {
      if (widgetScope === PaymentWidgetScope.ADD_CREDIT_CARD) {
        if (onCancelContext) onCancelContext();
      }

      if (setStep) {
        if (setIsRefreshPaymentMethods) setIsRefreshPaymentMethods(true);
        setStep(PaymentWidgetPaymentSteps.NEW);
      }
    }, 500);
  };

  const onIssuerChanged = (issuer: string) => {
    setCardTypeToSave(getPaymentMethodFromIssuer(issuer));
  };

  const title = cardToEdit ? 'Edit Your Card Details' : 'Enter Your Card Details';

  const isEditing = cardToEdit || widgetScope === PaymentWidgetScope.ADD_CREDIT_CARD ? false : true;

  const body = (
    <>
      <CreditCardForm
        layout="compact"
        onSubmit={onSave}
        onIssuerChanged={onIssuerChanged}
        isPaymentFromBuyer
        isSaveCard={isEditing}
        isShowAutoPaymentsCheck={isLinkedToLendingProvider}
        saveCardForFuture={saveCardForFuture}
        toggleSaveCardForFuture={toggleSaveCardForFuture}
        cardToEdit={cardToEdit}
        issuerClassName={cardTypeToSave}
        isBPSP={isBPSPSupplier ?? false}
        availableCreditCards={availableCreditCards as string[]}
      />
      <div className="font-poppins">
        <SDialog
          open={isShowDeleteConfirmation}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" className={`${heading} font-poppins`}>
            Delete Card
          </DialogTitle>
          <DialogContent className="font-poppins">
            <p>You wont be able to use this card in the future. Are you sure you want to delete it?</p>
          </DialogContent>
          <DialogActions>
            <SButton
              width="86px"
              height="40px"
              fontFamily="poppins"
              fontSize="14px"
              margin="0px 0px 10px 0px"
              color="blueShade"
              onClick={() => setIsShowDeleteConfirmation(false)}
            >
              Cancel
            </SButton>
            <SButton
              width="86px"
              height="40px"
              fontFamily="poppins"
              fontSize="14px"
              margin="0px 0px 10px 0px"
              onClick={onRemoveCreditCard}
              color="red"
              className="font-poppins"
            >
              Delete
            </SButton>
          </DialogActions>
        </SDialog>
      </div>
    </>
  );

  const footer = (
    <>
      <Button variant="outlined" color="primary" className="bg-white" onClick={onCancel}>
        Cancel
      </Button>
      {cardToEdit && (
        <>
          <SButton
            width="100px"
            height="40px"
            borderRadius="6px"
            fontSize="0.875rem"
            fontFamily="Poppins"
            type="submit"
            form="credit-card-form"
            color="darkBlue"
            isSubmitting={savingCard}
          >
            Save
          </SButton>
          <Button variant="filled" color="error" onClick={onToggleRemoveCreditCardConfirmation}>
            Remove
          </Button>
        </>
      )}
      {!cardToEdit && (
        <SButton
          width="100px"
          height="40px"
          borderRadius="6px"
          fontSize="0.875rem"
          fontFamily="Poppins"
          type="submit"
          form="credit-card-form"
          color="darkBlue"
          isSubmitting={savingCard}
          disabled={savingCard}
        >
          {saveCardForFuture ? 'Save' : 'Pay'}
        </SButton>
      )}
    </>
  );

  return <PaymentWidgetStep title={title} body={body} footer={footer} />;
};
