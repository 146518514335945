import useHttp from '../hooks/useHttp';
import {IActionResults, IPagedActionResults} from '../model/ActionResults';
import {IAlertInfo, IAlertRequestRequest} from '../model/alert/AlertInfo';
import {InvoiceStatusTypes} from '../model/constants/Constants';
import {
  ICustomerStatementsResponse,
  IPaidInvoicesResponse,
  IPaymentHistoryResponse,
  lCreatedStatementResponse,
} from '../model/customer/CustomerStatements';
import {IInvoice} from '../model/invoice/Invoice';
import {IFacility, ISetFacilityPaymentAccountRefRequest} from '../model/payment/Lending';
import {
  IMerchantProcessingFee,
  IRefBusTrans,
  MerchantSurcharge,
  SupplierPaymentRequest,
} from '../model/payment/PaymentT';
import {ISupplierPaymentResponse} from '../model/payment/SupplierPaymentResponse';
import {
  IDebitNoteDetails,
  IDebitNoteSummmary,
  IPurchaseInvoiceDetails,
  IPurchaseInvoiceSummmary,
  IStagedTransactionsResponse,
} from '../model/requires-attention/StagedTransactions';
import {ISearchSupplierStatementsFilter} from '../model/search-filters/SearchSupplierStatementsFilter';
import {IConnectedSupplierStatementSummaries} from '../model/supplier/SupplierTransaction';
import {Toast} from '../utils/Toast';
import {
  IBuyerPaymentsResponse,
  IConnectedSupplierGroupedStatementSummaries,
  ICustomerGroupByVendorResponse,
} from './../model/supplier/SupplierTransaction';
import {IStatementFilterQuery} from './useCustomerStatementsAPI';

export interface IGetSupplierTransactionRequest {
  LinkedSupplierID: number | string;
  TransactionGuid: string;
  MagicLink?: string;
}

export interface ISupplierRefreshResponse {
  Action: number;
  AdaptorID: number;
  AdaptorName: string;
  DatTypeID: number;
  FailCount: number;
  IsComplete: boolean;
  SuccessCount: number;
  TotalCount: number;
  WarningCount: number;
  WorkflowID: number;
}

export interface IStatementInvitationDetails {
  EmailAddress: string;
  IsExistingUser: boolean;
  MarketplacePath: string;
  HasInvitationBeenConsumedByAnotherUser: boolean;
  PrimaryContact: {
    FirstName: string;
    LastName: string;
    CompanyName: string;
    ABN: string;
  };
  IsConfirmed?: boolean;
  statementInvitationId?: string;
  SupplierId?: number;
  SupplierName?: string;
  SupplierModules?: number[];
}

export interface IPrefilledContact {
  CompanyName: string;
  EmailAddress: string;
  FirstName: string;
  LastName: string;
  Mobile: number;
  ABN: string;
}
export interface IInvoiceInvitationDetails {
  Contact: IPrefilledContact;
  InvoiceTotalInc: number;
  InvoiceBalance?: number;
  InvoiceStatusType: InvoiceStatusTypes;
  InvoiceStatus: string; // this should be deleted when server deployment works again
  IsExistingUser: boolean;
  IsIndividual: boolean;
  IsLinkedToSupplier: boolean;
  LogoURL: string;
  MarketplacePath: string;
  SupplierBusinessNumber: string;
  SupplierName: string;
}

export interface IBuyerStatementFilterQuery extends IStatementFilterQuery {
  filterStatementID?: number;
}
export interface ICancelScheduledPaymentPayload {
  transactionID: number;
  datTypeID: number;
}

export interface IUpdateScheduledPaymentPayload extends ICancelScheduledPaymentPayload {
  scheduleDatetime: string;
}

export interface IBuyerPaidInvoicesQuery {
  accountCustomerID?: number | null;
  paymentDate?: string | null;
  searchField?: string | null;
  searchString?: string | null;
}

export interface IPurchaseOrDebitNoteInvoiceQuery {
  supplierID?: number;
  isReconciled?: boolean;
  documentDate?: string;
  documentTotalInc?: number;
}

export const useSupplierTransactionsAPI = () => {
  const {GET, POST, isLoading} = useHttp();

  const search = (
    filter: Partial<ISearchSupplierStatementsFilter>,
  ): Promise<IPagedActionResults<IConnectedSupplierStatementSummaries>> => {
    return GET(`supplierTransactions/searchV2`, filter).then(
      (data: IPagedActionResults<IConnectedSupplierStatementSummaries>) => data,
    );
  };

  const customerStatementGroupByVendor = (
    filter: Partial<ISearchSupplierStatementsFilter>,
  ): Promise<IConnectedSupplierGroupedStatementSummaries> => {
    return GET(`Spenda/Payments/AccountsReceivable/Buyer/CustomerStatementGroupByVendorV2`, filter).then(
      (data: IConnectedSupplierGroupedStatementSummaries) => data,
    );
  };

  const getInvoice = (filter: Partial<IGetSupplierTransactionRequest>): Promise<IInvoice> => {
    return GET(`supplierTransactions/invoice`, filter).then((data: IInvoice) => data);
  };

  const getCreditNote = (filter: Partial<IGetSupplierTransactionRequest>): Promise<IInvoice> => {
    return GET(`supplierTransactions/creditnote`, filter).then((data: IInvoice) => data);
  };

  const getPayment = (filter: Partial<IGetSupplierTransactionRequest>): Promise<IInvoice> => {
    return GET(`supplierTransactions/payment`, filter).then((data: IInvoice) => data);
  };

  const payInvoice = (req: SupplierPaymentRequest): Promise<IActionResults<ISupplierPaymentResponse>> => {
    return POST('supplierTransactions/invoice/payment', req);
  };

  const getInvoicePrintout = (filter: Partial<IAlertRequestRequest>): Promise<IAlertInfo> => {
    return GET('supplierTransactions/invoice/alert', filter).then((data: IActionResults<IAlertInfo>) => {
      if (!data.IsSuccess) {
        data.Messages.forEach((msg: string) => Toast.info(msg));
      }
      return data.Value;
    });
  };

  const getCreditNotePrintout = (filter: Partial<IAlertRequestRequest>): Promise<IAlertInfo> => {
    return GET('supplierTransactions/creditnote/alert', filter).then((data: IActionResults<IAlertInfo>) => {
      if (!data.IsSuccess) {
        data.Messages.forEach((msg: string) => Toast.info(msg));
      }
      return data.Value;
    });
  };

  const getPaymentAlert = (filter: Partial<IAlertRequestRequest>): Promise<IAlertInfo> => {
    return GET('supplierTransactions/payment/alert', filter).then((data: IActionResults<IAlertInfo>) => {
      if (!data.IsSuccess) {
        data.Messages.forEach((msg: string) => Toast.info(msg));
      }
      return data.Value;
    });
  };

  const supplierRefresh = (id: number): Promise<ISupplierRefreshResponse> => {
    return POST(`supplierTransactions/refresh/${id}?maxWaitSeconds=25`, false, true, true).then(
      (data: IActionResults<ISupplierRefreshResponse>) => data.Value,
    );
  };

  const getMerchantSurcharge = (
    linkedSupplierID: number | string,
    paymentMethod: string,
    refBusTrans: IRefBusTrans[],
  ): Promise<MerchantSurcharge> => {
    return POST(`connectedSuppliers/${linkedSupplierID}/merchantSurcharge/${paymentMethod}`, refBusTrans).then(
      (data: IActionResults<MerchantSurcharge>) => data.Value,
    );
  };

  const validateInvitation = (statementGuid: string): Promise<IActionResults<IStatementInvitationDetails>> => {
    return GET(`supplierTransactions/statementInvitation/${statementGuid}`).then(
      (res: IActionResults<IStatementInvitationDetails>) => res,
    );
  };

  const getInvoicePaymentInvitation = (
    invoicePaymentLinkId: string,
  ): Promise<IActionResults<IInvoiceInvitationDetails>> => {
    return GET(`supplierTransactions/InvoicePaymentInvitation/${invoicePaymentLinkId}`).then(
      (res: IActionResults<IInvoiceInvitationDetails>) => res,
    );
  };

  const checkBatchPaymentStatus = (
    linkedSupplierID: number,
    invoicePaymentAllocationID: number,
  ): Promise<IActionResults<ISupplierPaymentResponse>> => {
    return GET(
      `supplierTransactions/invoice/GetPaymentWorkflowByInvoicePaymentAllocationID/${linkedSupplierID}/${invoicePaymentAllocationID}`,
    );
  };

  const getMerchantProcessingFee = (
    linkedSupplierID: number | string,
    paymentMethod: string,
    paymentAmount: number,
  ): Promise<IMerchantProcessingFee> => {
    return GET(
      `PaymentServices/MerchantPayment/Fee/Spenda Payment Services/${linkedSupplierID}/${paymentMethod}/${paymentAmount}`,
    ).then((data: IMerchantProcessingFee) => data);
  };

  const getFacilities = (): Promise<IFacility> => {
    return GET(`Spenda/Lending/Facilities`).then(data => data.value);
  };

  const setFacilityPaymentaccount = (facilityGUID: string, req: ISetFacilityPaymentAccountRefRequest) => {
    return POST(`Spenda/Lending/Facilities/${facilityGUID}/Accounts`, req);
  };

  const getBuyerAllStatements = (
    linkedSupplierID: number | null,
    query?: IBuyerStatementFilterQuery,
  ): Promise<ICustomerStatementsResponse> => {
    return GET(`Spenda/Payments/AccountsReceivable/Buyer/CustomerOutstandingStatement/${linkedSupplierID}`, query);
  };

  const getBuyerAllCreatedStatements = (
    linkedSupplierID: number | null,
    query?: {fromDate: string},
  ): Promise<lCreatedStatementResponse[]> => {
    return GET(`Spenda/Payments/AccountsReceivable/Buyer/Statement/${linkedSupplierID}`, query);
  };

  const getBuyerPaymentHistory = (
    linkedSupplierID: number,
    query?: {
      searchString?: string;
      searchField?: string;
    },
  ): Promise<IPaymentHistoryResponse[]> => {
    return GET(`Spenda/Payments/AccountsReceivable/Buyer/PaymentHistory/${linkedSupplierID}`, query);
  };

  const customerStatementGroupByVendorV2 = (
    linkedSupplierID: number,
    query?: IBuyerStatementFilterQuery,
  ): Promise<ICustomerGroupByVendorResponse> => {
    return GET(
      `Spenda/Payments/AccountsReceivable/Buyer/CustomerOutstandingStatementGroupedByVendor/${linkedSupplierID}`,
      query,
    );
  };

  const getBuyerPayments = (
    linkedSupplierID: number,
    query?: {searchString?: string; searchField?: string},
  ): Promise<IBuyerPaymentsResponse> => {
    return GET(`Spenda/Payments/AccountsReceivable/Buyer/PaymentHistoryV2/${linkedSupplierID}`, query);
  };

  const cancelScheduledPayment = (linkedSupplierID: number, payload: ICancelScheduledPaymentPayload) => {
    return POST(`Spenda/Payments/AccountsReceivable/Buyer/ScheduledPayment/${linkedSupplierID}/cancel`, payload);
  };

  const updateScheduledPaymentDate = (
    linkedSupplierID: string,
    payload: IUpdateScheduledPaymentPayload,
  ): Promise<any> => {
    return POST(`Spenda/Payments/AccountsReceivable/Buyer/ScheduledPayment/${linkedSupplierID}/update`, payload);
  };

  const getBuyerPaidInvoices = (
    linkedSupplierID: number,
    query?: IBuyerPaidInvoicesQuery,
  ): Promise<IPaidInvoicesResponse[]> => {
    return GET(`Spenda/Payments/AccountsReceivable/Buyer/PaidInvoices/${linkedSupplierID}`, query);
  };

  const getRequiresAttentionInvoices = (linkedSupplierID: number): Promise<IStagedTransactionsResponse> => {
    return GET(`Spenda/Payments/AccountsPayable/StagedTransactions?sourceSupplierID=${linkedSupplierID}`);
  };

  //new api for match and link

  const getPurchaseInvoiceSummary = (payload: IPurchaseOrDebitNoteInvoiceQuery): Promise<IPurchaseInvoiceSummmary> => {
    return GET(`Spenda/Payments/AccountsPayable/PurchaseInvoices`, payload);
  };

  const getPurchaseInvoiceDetails = (purchaseInvoiceID: number): Promise<IPurchaseInvoiceDetails> => {
    return GET(`Spenda/Payments/AccountsPayable/PurchaseInvoices/${purchaseInvoiceID}`);
  };

  const getDebitNoteSummary = (payload: IPurchaseOrDebitNoteInvoiceQuery): Promise<IDebitNoteSummmary> => {
    return GET(`Spenda/Payments/AccountsPayable/DebitNotes`, payload);
  };

  const getDebitNoteDetails = (debitNoteID: number): Promise<IDebitNoteDetails> => {
    return GET(`Spenda/Payments/AccountsPayable/DebitNotes/${debitNoteID}`);
  };

  return {
    getCreditNote,
    getCreditNotePrintout,
    getInvoice,
    getInvoicePrintout,
    getMerchantSurcharge,
    getPayment,
    getPaymentAlert,
    payInvoice,
    search,
    supplierRefresh,
    validateInvitation,
    getInvoicePaymentInvitation,
    checkBatchPaymentStatus,
    getMerchantProcessingFee,
    customerStatementGroupByVendor,
    getBuyerAllStatements,
    getBuyerAllCreatedStatements,
    getBuyerPaymentHistory,
    getBuyerPayments,
    customerStatementGroupByVendorV2,
    getFacilities,
    setFacilityPaymentaccount,
    cancelScheduledPayment,
    updateScheduledPaymentDate,
    getBuyerPaidInvoices,
    getRequiresAttentionInvoices,
    getPurchaseInvoiceSummary,
    getPurchaseInvoiceDetails,
    getDebitNoteSummary,
    getDebitNoteDetails,
    isLoading,
  };
};
