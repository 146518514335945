import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {ARHeader, IHeaderDetails} from '../../../components/AccountsReceivable/ARHeader';
import {TransactionView} from '../../../components/AccountsReceivable/TransactionView';
import AppContext from '../../../context/app/appContext';
import ARContext from '../../../context/ar-context/ARContext';
import {usePsblBatchHook, useTransactionViewHook} from '../../../hooks/useARHook';
import {
  ARSelectableTransactions,
  ARStatementPeriodDefaultOptions,
  DatTypes,
  PSBLBatchAction,
} from '../../../model/constants/Constants';
import {ICustomerOutstandingStatement, ICustomerStatementsResponse} from '../../../model/customer/CustomerStatements';
import {
  ICustomerGroupByVendorResponse,
  ICustomerOutstandingStatementGroupByVendorList,
} from '../../../model/supplier/SupplierTransaction';
import {IBuyerStatementFilterQuery, useSupplierTransactionsAPI} from '../../../services/useSupplierTransactionsAPI';
import {PsblTableHeader} from './PsblTableHeader';
import {RemovePartialPaymentDialog} from '../../../components/AccountsReceivable/RemovePartialPaymentDialog';
import {useFeatureFlags} from '../../../hooks/useFeatureFlags';
import {ISelectedTransactionsList} from '../../../model/accounts-receivable/AccountsReceivable';
import {IBatchOperation} from '../../../model/accounts-receivable/batch';
import PsblStandardView from './PsblStandardView';
import PsblVendorView from './PsblVendorView';
import {isWebsiteId17} from '../../../config';
interface IPartialPaymentRemoveData {
  show: boolean;
  tx: ISelectedTransactionsList;
}

interface IPsblViewProps {
  supplierId: number;
}

export const PsblView = (props: IPsblViewProps) => {
  // Props
  const {supplierId} = props;

  // Hooks
  const {getBatchDetail, updateBatchTransactions} = usePsblBatchHook(supplierId);
  const {viewingTx, handleCloseTx, handleViewTx} = useTransactionViewHook();

  // Feature Flags
  const {scheduledPaymentsV2, payStatementByLinkV289938} = useFeatureFlags().supplierDriven();

  // Context
  const {marketplaceSupplier, user, tenant, suppliers} = useContext(AppContext);
  const {psblBatch, setPsblBatch} = useContext(ARContext);

  // APIs
  const {getBuyerAllStatements, getBuyerAllCreatedStatements, customerStatementGroupByVendorV2} =
    useSupplierTransactionsAPI();

  // States
  const [headerDetails, setHeaderDetails] = useState<IHeaderDetails>();
  const [isShowInGroupView, setIsShowInGroupView] = useState(false);
  const [statementOptions, setStatementOptions] = useState<{name: string | null; value: string}[]>();
  const [vendorResponse, setVendorResponse] = useState<ICustomerGroupByVendorResponse>();
  const [expandedVendor, setExpandedVendor] = useState<ICustomerOutstandingStatementGroupByVendorList>();
  const [transactionResponse, setTransactionsResponse] = useState<ICustomerStatementsResponse>();
  const [transactionFilterQuery, setTransactionFilterQuery] = useState<IBuyerStatementFilterQuery>();
  const [isVendorPresent, setIsVendorPresent] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [removePartialPaymentDialog, setRemovePartialPaymentDialog] = useState<IPartialPaymentRemoveData>();

  const isShowTxView = isWebsiteId17 && payStatementByLinkV289938 ? false : viewingTx ? true : false;
  const {PSBLBatchStatus, selectedTxList} = psblBatch;
  const isSuccessPsblBatchStatus = PSBLBatchStatus === 'SUCCESS';
  const txList = useMemo(() => {
    if (isShowInGroupView) {
      return expandedVendor?.groupedList?.map(t => ({
        txId: t.transID,
        txGuid: t.transGUID,
        txDatType: t.transactionTypeID,
      }));
    }
    return transactionResponse?.customerOutstandingStatements?.map(t => ({
      txId: t.transID,
      txGuid: t.transGUID,
      txDatType: t.transactionTypeID,
    }));
  }, [expandedVendor?.groupedList, isShowInGroupView, transactionResponse?.customerOutstandingStatements]);

  // UseEffects
  useEffect(() => {
    fetchData();
  }, [isShowInGroupView, transactionFilterQuery]);

  useEffect(() => {
    fetchCreatedStatementOptions();
    getBatchDetail();
  }, [supplierId]);

  useEffect(() => {
    setSupplierDetails();
  }, [user?.UserID, marketplaceSupplier]);

  useEffect(() => {
    if (isSuccessPsblBatchStatus) {
      fetchData();
      setPsblBatch({...psblBatch, PSBLBatchStatus: undefined});
    }
  }, [isSuccessPsblBatchStatus]);

  //  Calling Batch API after 2 sec when user stop selecting transactions
  useEffect(() => {
    if (!psblBatch?.loading || !scheduledPaymentsV2) return;
    let timeoutId: NodeJS.Timeout | undefined;
    timeoutId = setTimeout(() => {
      const {selectedTxList, psblBatchAPIResponse} = psblBatch || {};
      const {claims = [], creditNotes = [], invoices = [], payments = []} = psblBatchAPIResponse || {};
      const alreadyInBatch = [...claims, ...creditNotes, ...invoices, ...payments];
      const payload: IBatchOperation[] = [];

      selectedTxList?.forEach(s => {
        if (!alreadyInBatch.find(a => a?.id === s?.id)) {
          payload.push({
            action: PSBLBatchAction.ADD,
            datTypeID: s?.transactionTypeID,
            id: s?.id,
          });
        }
      });

      alreadyInBatch.forEach(s => {
        if (!selectedTxList.some(a => a?.id === s?.id)) {
          payload.push({
            action: PSBLBatchAction.REMOVE,
            datTypeID: s?.datTypeInteger,
            id: s?.id,
          });
        }
      });

      payload?.length ? updateBatchTransactions(payload) : getBatchDetail();
    }, 2000);

    return () => clearTimeout(timeoutId);
  }, [psblBatch, psblBatch?.loading, psblBatch?.selectedTxList, scheduledPaymentsV2]);

  // Functions
  const fetchData = () => {
    if (isShowInGroupView) {
      fetchTransactionsGroupByVendor();
    } else {
      fetchTransactionDetails();
    }
  };

  const setSupplierDetails = async () => {
    try {
      if (marketplaceSupplier) {
        const selectedSupplier = suppliers?.find(ss => ss.SupplierID === supplierId);
        const userDetails = tenant?.Users?.find(u => u?.UserID === user?.UserID);
        setHeaderDetails({
          userEmail: userDetails?.EmailAddress || '',
          userMobile: userDetails?.Mobile || '',
          accountBalance: scheduledPaymentsV2
            ? marketplaceSupplier?.AccountBalanceIncludingScheduledPayments
            : marketplaceSupplier?.AccountBalance,
          creditLimit: marketplaceSupplier?.CreditLimit,
          creditAvailable: scheduledPaymentsV2
            ? marketplaceSupplier?.CreditAvailableIncludingScheduledPayments
            : marketplaceSupplier?.CreditAvailable,
          supplierTenantName: selectedSupplier?.SupplierName || '',
          suppName: `${marketplaceSupplier?.PrimaryContact?.FirstName} ${marketplaceSupplier?.PrimaryContact?.LastName}`,
          suppEmail: marketplaceSupplier?.PrimaryContact?.EmailAddress,
          suppMobile: marketplaceSupplier?.PrimaryContact?.PhoneMobile,
        });
      }
    } catch {}
  };

  const fetchCreatedStatementOptions = async () => {
    try {
      const options = await getBuyerAllCreatedStatements(supplierId);

      const allCreatedStatement = options
        ?.filter(s => s.status !== 'Open')
        ?.map(o => ({value: o.statementID.toString(), name: o?.name}));
      const defaultOptions = Object.entries(ARStatementPeriodDefaultOptions).map(([_key, value]) => ({
        value: value,
        name: value,
      }));

      if (allCreatedStatement?.length) {
        setStatementOptions([...defaultOptions, ...allCreatedStatement]);
        return;
      }

      setStatementOptions(defaultOptions);
    } catch {}
  };

  const fetchTransactionDetails = async () => {
    try {
      setIsLoading(true);
      const response = await getBuyerAllStatements(supplierId, transactionFilterQuery);
      const _isVendorPresent = response?.customerOutstandingStatements?.some(s => s.vendorName);
      setIsVendorPresent(_isVendorPresent);
      setTransactionsResponse(response);
      setIsLoading(false);
    } catch {
      setIsLoading(false);
    }
  };

  const fetchTransactionsGroupByVendor = async () => {
    try {
      setIsLoading(true);
      const res = await customerStatementGroupByVendorV2(supplierId, transactionFilterQuery);
      setVendorResponse(res);
      setIsLoading(false);
    } catch {
      setIsLoading(false);
    }
  };

  const isAllTransactionSelected = useCallback(
    (txList?: ICustomerOutstandingStatement[]) => {
      const {selectedTxList} = psblBatch;
      if (!selectedTxList?.length || isLoading) return false;

      if (!txList) {
        txList = isShowInGroupView
          ? vendorResponse?.customerOutstandingStatementGroupByVendorList?.flatMap(v => v.groupedList)
          : transactionResponse?.customerOutstandingStatements;
      }

      const isAllSelected = txList?.every(tr => {
        if (ARSelectableTransactions.includes(tr.transactionTypeID)) {
          if (
            scheduledPaymentsV2 &&
            tr?.scheduledInvoicePaymentAllocationSummary?.length &&
            typeof tr?.balanceIncludingScheduledIPAs === 'number' &&
            tr?.balanceIncludingScheduledIPAs <= 0
          ) {
            return true;
          }

          return selectedTxList?.some(st => st.id === tr.transID);
        }
        return true;
      });
      return Boolean(isAllSelected);
    },
    [
      isLoading,
      isShowInGroupView,
      psblBatch,
      scheduledPaymentsV2,
      transactionResponse?.customerOutstandingStatements,
      vendorResponse?.customerOutstandingStatementGroupByVendorList,
    ],
  );

  const handleSelect = useCallback(
    async (tx: ISelectedTransactionsList) => {
      try {
        if (!ARSelectableTransactions.includes(tx?.transactionTypeID)) return;

        if (scheduledPaymentsV2) {
          const records = [...psblBatch?.selectedTxList];
          const removeIndex = records?.findIndex(st => st.id === tx?.id);
          if (removeIndex > -1) {
            records?.splice(removeIndex, 1);
          } else {
            records.push(tx);
          }
          setPsblBatch({...psblBatch, loading: true, selectedTxList: records});
        } else {
          const isRemove = selectedTxList?.some(st => st.id === tx?.id);
          const payload = [
            {
              action: isRemove ? PSBLBatchAction.REMOVE : PSBLBatchAction.ADD,
              datTypeID: tx?.transactionTypeID,
              id: tx?.id,
            },
          ];
          await updateBatchTransactions(payload);
        }
      } catch {}
    },
    [psblBatch, scheduledPaymentsV2, selectedTxList, setPsblBatch, updateBatchTransactions],
  );

  const handleSelectAll = useCallback(
    async (txList?: ICustomerOutstandingStatement[], isSelectVendorAllTx?: boolean) => {
      try {
        if (scheduledPaymentsV2) {
          if (isAllTransactionSelected(txList)) {
            if (isSelectVendorAllTx) {
              const updatedList = selectedTxList?.filter(sTx => !txList?.some(l => l?.transID === sTx?.id));
              setPsblBatch({...psblBatch, loading: true, selectedTxList: updatedList?.length ? updatedList : []});
              return;
            }
            setPsblBatch({...psblBatch, loading: true, selectedTxList: []});
            return;
          }
          if (!txList) {
            txList = isShowInGroupView
              ? vendorResponse?.customerOutstandingStatementGroupByVendorList?.flatMap(v => v.groupedList)
              : transactionResponse?.customerOutstandingStatements;
          }
          if (!txList?.length) {
            setPsblBatch({...psblBatch, loading: true, selectedTxList: []});
            return;
          }

          const list = txList?.reduce((acc: ISelectedTransactionsList[], l) => {
            if (
              !ARSelectableTransactions.includes(l.transactionTypeID) ||
              (scheduledPaymentsV2 &&
                l?.scheduledInvoicePaymentAllocationSummary?.length &&
                typeof l.balanceIncludingScheduledIPAs === 'number' &&
                l.balanceIncludingScheduledIPAs <= 0)
            ) {
              return acc;
            }
            acc.push({
              id: l.transID,
              refNumber: l.refNumber,
              transactionType: l.transactionType,
              dueDate: l.dueDate,
              transDate: l.transDate,
              balance: l.balance,
              totalInc: l.totalInc,
              accountCustomerName: l.accountCustomerName,
              transactionTypeID: l.transactionTypeID,
              datTypeID: l.transactionType,
              dueDate_String: l.dueDate_String,
              transactionDate_String: l.transDate_String,
            });
            return acc;
          }, []);

          list?.length &&
            setPsblBatch({
              ...psblBatch,
              loading: true,
              selectedTxList: isSelectVendorAllTx ? [...selectedTxList, ...list] : list,
            });
        } else {
          const isAllSelected = isAllTransactionSelected(txList);
          if (!txList) {
            txList = isShowInGroupView
              ? vendorResponse?.customerOutstandingStatementGroupByVendorList?.flatMap(v => v.groupedList)
              : transactionResponse?.customerOutstandingStatements;
          }
          const payload = transactionResponse?.customerOutstandingStatements?.reduce(
            (acc: IBatchOperation[], transRecord) => {
              if (ARSelectableTransactions.includes(transRecord.transactionTypeID)) {
                acc.push({
                  datTypeID: transRecord.transactionTypeID,
                  id: transRecord.transID,
                  action: isAllSelected ? PSBLBatchAction.REMOVE : PSBLBatchAction.ADD,
                });
              }
              return acc;
            },
            [],
          );
          if (!payload) return;
          await updateBatchTransactions(payload);
        }
      } catch {}
    },
    [
      isAllTransactionSelected,
      isShowInGroupView,
      psblBatch,
      scheduledPaymentsV2,
      setPsblBatch,
      transactionResponse?.customerOutstandingStatements,
      updateBatchTransactions,
      vendorResponse?.customerOutstandingStatementGroupByVendorList,
    ],
  );

  const handleConfirm = async (tx: ICustomerOutstandingStatement) => {
    const balance =
      typeof tx.balanceIncludingScheduledIPAs === 'number' ? tx?.balanceIncludingScheduledIPAs : tx?.balance;

    const transaction = {
      id: tx.transID,
      refNumber: tx.refNumber,
      transactionType: tx.transactionType,
      dueDate: tx.dueDate,
      transDate: tx.transDate,
      balance: balance,
      totalInc: tx.totalInc,
      accountCustomerName: tx.accountCustomerName,
      transactionTypeID: tx.transactionTypeID,
    };

    if (
      selectedTxList?.length === 1 &&
      selectedTxList[0].transactionTypeID === DatTypes.Invoice &&
      selectedTxList?.[0]?.amountToPay
    ) {
      setRemovePartialPaymentDialog({show: true, tx: transaction});
      return;
    }
    handleSelect(transaction);
  };

  const handleChangeGroupView = () => {
    setTransactionsResponse(undefined);
    setVendorResponse(undefined);
    setIsShowInGroupView(!isShowInGroupView);
  };

  const getCurrentView = () => {
    if (isShowInGroupView)
      return (
        <PsblVendorView
          headerData={headerDetails}
          supplierId={supplierId}
          isLoading={isLoading}
          transactionList={vendorResponse?.customerOutstandingStatementGroupByVendorList || []}
          onSelect={tx => handleConfirm(tx)}
          expandedVendor={expandedVendor}
          setExpandedVendor={setExpandedVendor}
          onViewTransaction={tx => handleViewTx(tx)}
          handleSelectVendorAllTransactions={txList => handleSelectAll(txList, true)}
          isVendorAllTransactionSelected={txList => isAllTransactionSelected(txList)}
        />
      );
    return (
      <PsblStandardView
        headerData={headerDetails}
        isLoading={isLoading}
        transactionList={transactionResponse?.customerOutstandingStatements || []}
        onSelect={tx => handleConfirm(tx)}
        supplierId={supplierId}
        onViewTransaction={tx => handleViewTx(tx)}
      />
    );
  };

  const getTransactionsCount = () => {
    return isShowInGroupView
      ? vendorResponse?.customerOutstandingStatementGroupByVendorList?.map(v => v.groupedList)?.flat()?.length
      : transactionResponse?.customerOutstandingStatements?.length;
  };

  const handleRemovePartialPaymentConfirm = () => {
    const {tx} = removePartialPaymentDialog || {};
    if (tx) {
      handleSelect(tx);
      setRemovePartialPaymentDialog(undefined);
      return;
    }
    handleSelectAll();
  };

  return (
    <>
      {isShowTxView && viewingTx && (
        <TransactionView
          key={viewingTx?.txGuid}
          isShowInPdfView={false}
          supplierId={supplierId}
          transactionId={viewingTx?.txId}
          txDatType={viewingTx?.txDatType}
          txGuid={viewingTx?.txGuid}
          scope="PSBlView"
          handleOnClosePdfView={() => handleCloseTx()}
          txList={txList}
        />
      )}
      {removePartialPaymentDialog?.show && (
        <RemovePartialPaymentDialog
          onConfirm={handleRemovePartialPaymentConfirm}
          handleClose={() => setRemovePartialPaymentDialog(undefined)}
        />
      )}
      <div className="relative flex h-full flex-col">
        <div className="mb-[8px] bg-white">
          <ARHeader headerData={headerDetails} isLoading={false} />
        </div>
        <div className="mb-2 h-full overflow-auto !bg-white" style={{boxShadow: '0px 0px 6px 0px #D3E5EF'}}>
          <div className={`flex h-full w-full flex-col rounded-[6px] !bg-white px-0 py-2.5 font-poppins`}>
            <PsblTableHeader
              handleSelectAll={handleSelectAll}
              isSelectedAll={isAllTransactionSelected()}
              statementOptions={statementOptions}
              transactionFilterQuery={transactionFilterQuery}
              setTransactionFilterQuery={setTransactionFilterQuery}
              isVendorPresent={isVendorPresent}
              isShowInGroupView={isShowInGroupView}
              onChangeGroupView={handleChangeGroupView}
              selectAllCount={getTransactionsCount()}
            />
            {getCurrentView()}
          </div>
        </div>
      </div>
    </>
  );
};
